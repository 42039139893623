import fetch from '@utils/fetch';
import { getRuntimeEnvPath } from '@config/constant';

export const postEmail = async (to, title, content, { cc = '', bcc = '' }) => {
  const data = {
    to,
    title,
    content,
    cc,
    bcc,
  };
  try {
    await fetch('/api/xy/email', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  } catch (error) {
    const msg = '邮件发送失败';
    console.error(msg, error);
    throw error;
  }
};

export const notifyByWework = async (projectId, env, { receivers, msgType, data }, builtin = true) => {
  const url = getRuntimeEnvPath(env);
  try {
    await fetch(`${url}/notice/wework?projectid=${projectId}&builtin=${builtin}`, {
      method: 'POST',
      body: JSON.stringify({
        receivers,
        msgType,
        data,
      }),
    });
  } catch (error) {
    throw new Error('企业微信通知失败');
  }
};

export default {};
