import { ArrayForm } from './ArrayForm';
import { ObjectForm } from './ObjectForm';
import { PrimitiveForm } from './PrimitiveForm';
export const COMP_TYPES = [
    {
        type: ['string', 'number', 'integer', 'boolean', 'null', 'any'],
        comp: PrimitiveForm,
    },
    {
        type: ['array'],
        comp: ArrayForm,
    },
    {
        type: ['object'],
        comp: ObjectForm,
    },
];
export const JSON_TYPE_KEY = 'jsonStringType'; // source 定义的
export function getCompInfo(schema) {
    if (!schema.type)
        return null;
    let type;
    const isJsonString = schema.type === 'string' && typeof schema[JSON_TYPE_KEY] === 'object';
    if (isJsonString) {
        // json字符串类型
        type = schema[JSON_TYPE_KEY].type;
    }
    else {
        type = schema.type;
    }
    const target = COMP_TYPES.find(item => item.type.includes(type));
    const { comp } = target;
    if (!comp)
        return null;
    return {
        comp: target.comp,
        schema,
        isJsonString,
    };
}
