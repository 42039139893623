<template>
  <table
    v-if="Array.isArray(editValue)"
    class="paramsEditor"
  >
    <tr>
      <th
        class="keyCol"
        :style="`width: ${resizing.width}%`"
      >
        Key

        <div
          class="paramsEditorResizer"
          @mousedown="startResizing"
          @touchstart="startResizing"
        />
      </th>
      <th>Value</th>
      <th><br></th>
    </tr>

    <tr
      v-for="schema in missingProps"
      :key="schema.id"
    >
      <td class="keyCol">
        {{ schema.id }}
      </td>
      <td>
        <a-button
          size="small"
          icon="plus"
          @click="addFromMissingProp(schema.id)"
        >
          配置
        </a-button>

        <a-button
          v-if="allowBind && schema['xy:sync']"
          size="small"
          icon="link"
          @click="ev => addFromMissingProp(schema.id,true,ev)"
        >
          双向绑定数据
        </a-button>
      </td>
      <td class="opCol" />
    </tr>

    <tr
      v-for="(item, idx) in editValue"
      :key="idx"
    >
      <td class="keyCol">
        <expr-input
          v-if="keyIsInteropable"
          :value="item.key"
          class="paramInputBox"
          type="interopable-string"
          @change="v => mod(idx, 'key', v)"
        />
        <input
          v-else
          :value="item.key"
          class="paramInputBox"
          @change="ev => { ev.target.value = mod(idx, 'key', ev.target.value) }"
        >

        <div
          class="paramsEditorResizer"
          @mousedown="startResizing"
          @touchstart="startResizing"
        />
      </td>

      <td v-if="item.bind">
        <a
          href="#"
          @click.prevent="(ev) => createBinding(idx, ev)"
        >
          已绑定到 {{ item.bind }}

          <a-button
            size="small"
            icon="edit"
            @click.prevent="(ev) => createBinding(idx, ev)"
          >
            修改
          </a-button>
        </a>

        <a-button
          size="small"
          icon="disconnect"
          @click.prevent="() => mod(idx, 'bind', '')"
        >
          解绑
        </a-button>
      </td>
      <td v-else>
        <expr-input
          :value="item.value"
          class="paramInputBox"
          type="interopable-string"
          @change="v => mod(idx, 'value', v)"
        />
      </td>

      <td class="opCol">
        <a-icon
          v-if="allowBind && !item.bind && canCreateBinding(item.key)"
          type="link"
          class="opIcon"
          title="添加双向绑定"
          @click="(ev) => createBinding(idx, ev)"
        />

        <a-icon
          type="close-circle"
          class="opIcon"
          @click="() => del(idx)"
        />
      </td>
    </tr>

    <tr>
      <td colspan="3">
        <a-button
          size="small"
          icon="plus"
          @click="add"
        >
          添加参数
        </a-button>
      </td>
    </tr>
  </table>
</template>

<script>

/**
 * 数据格式：
 *
 * { key, value, bind? }[]
 *
 * - 如果有 bind 那么 value 不生效
 */

import Vue from 'vue';
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { ExprInput } from '@tencent/ui-core/lib';
import keyBy from 'lodash/keyBy';
import { startMouseMove } from '@tencent/ui-core/lib/utils';
import { clamp } from 'lodash';

const resizingLocalStorageKey = 'xy:XyPageletPropsEditor:resizing';
const resizing = Vue.observable({
  width: 30,
  ...JSON.parse(localStorage.getItem(resizingLocalStorageKey) || 'null'),
});
const saveResizing = () => {
  localStorage.setItem(resizingLocalStorageKey, JSON.stringify(resizing));
};

export default {
  name: 'XyPageletPropsEditor',
  components: {
    ExprInput,
  },
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
    wContext: { from: 'wContext', default: null },
  },
  props: {
    getPagelet: { type: Function, default: () => null },  // 可以是异步函数
    allowBind: { type: Boolean, default: false },
    keyIsInteropable: { type: Boolean, default: false },
  },
  data() {
    return {
      currPVersion: 0,
      pagelet: null,
      resizing,
    };
  },
  computed: {
    propsSchemaLUT() {
      return keyBy(this.pagelet?.propsSchema?.fields, 'id');
    },
    missingProps() {
      const schemas = this.pagelet?.propsSchema?.fields;
      if (!schemas) return [];

      return schemas.filter(X => !this.editValue.some(it => it.key === X.id));
    },
  },
  created() {
    this.$watch(
      () => {
        try {
          return this.getPagelet.call(this, this);
        } catch (e) {
          return null;
        }
      },
      (pageletPromise) => {
        // eslint-disable-next-line no-plusplus
        const pVersion = ++this.currPVersion;
        Promise.resolve(pageletPromise).then((pagelet) => {
          if (this.currPVersion === pVersion) this.pagelet = pagelet;
        });
      },
      { immediate: true },
    );

    this.$watch(
      () => this.editValue,
      (v) => {
        if (!Array.isArray(v)) this.editValue = [];
      },
      { immediate: true },
    );
  },
  methods: {
    canCreateBinding(propKey) {
      return !!this.propsSchemaLUT[propKey]?.['xy:sync'];
    },
    async createBinding(idx, event) {
      const dataPath = await this.designer.openDataPathSelector({
        place: event.target,
        currentDataPath: this.editValue[idx].bind || '',
      });
      if (!dataPath) return;

      this.mod(idx, 'bind', dataPath);
    },
    async addFromMissingProp(propKey, binding, event) {
      const schema = this.propsSchemaLUT[propKey];
      if (!schema) return;

      const defaultValue = schema.default ?? '';
      const value = typeof defaultValue === 'string' ? defaultValue : `{{ ${JSON.stringify(defaultValue, 0, 2)} }}`;

      const newItem = {
        key: propKey,
        value,
      };

      if (binding && schema['xy:sync']) {
        const dataPath = await this.designer.openDataPathSelector({
          place: event.target,
        });
        if (!dataPath) return;

        newItem.bind = dataPath;
      }

      this.editValue = [
        newItem,
        ...this.editValue,
      ];
    },
    mod(idx, key, value) {
      const list = [...this.editValue];
      // eslint-disable-next-line no-param-reassign
      if (key === 'key') value = String(value).trim();
      list[idx] = { ...list[idx], [key]: value };
      this.editValue = list;

      return value;
    },
    del(idx) {
      const list = [...this.editValue];
      list.splice(idx, 1);
      this.editValue = list;
    },
    add() {
      // 添加参数的时候key默认置空
      // const key = `custom_${Date.now().toString(16)
      //   .slice(-4)}`;
      this.editValue = [...this.editValue, { key: '', value: '' }];
    },
    startResizing(ev) {
      const td = ev.target.parentElement;
      const oldWidth = resizing.width;
      const factor = 1 / td.offsetWidth;

      ev.preventDefault();

      startMouseMove({
        initialEvent: ev,
        onMove({ deltaX }) {
          resizing.width = clamp(oldWidth * (1 + deltaX * factor), 5, 90);
        },
        onEnd() {
          saveResizing();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.paramsEditorResizer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: ew-resize;

  &:hover {
    background-color: rgba(#000, 0.3);
  }
}

.keyCol {
  position: relative;
}

.paramsEditor {
  width: 100%;
  border: 1px solid #ccc;

  td {
    padding: 2px 4px;
  }

  .opCol {
    width: 10px;
    padding-left: 2px;
    padding-right: 6px;
    white-space: nowrap;
  }

  th {
    font-weight: normal;
    padding-left: 4px;
    color: #ccc;
  }
}

.paramInputBox {
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  border: 1px solid #ccc;
}

.opIcon {
  cursor: pointer;
}
</style>
