import { uuidv4 } from '@tencent/ui-core/lib/utils';
import { ref } from '@vue/composition-api';
export const SESSION_STORAGE_KEY = 'previewConfig';
export const PREVIEW_MODE_QUERY_KEY = 'previewMode';
const PROVIDER_SOURCE = 'EDITOR_PREVIEW_DATA_CHANGE';
const CONSUMER_SOURCE = 'PREVIEWER_INIT';
const BROADCAST_CHANNEL_ID = 'preview-mode-broadcast-channel';
export const usePreviewProvider = () => {
    const broadcastChannel = ref(null);
    const editorCreatedHook = (editor) => {
        const sessionId = uuidv4();
        sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${sessionId}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source } = event.data;
            if (source === CONSUMER_SOURCE) {
                postMessage(editor);
            }
        };
    };
    const editorDestroyedHook = () => {
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    const switchActiveKeyHook = (editor, key) => {
        if (key === 'renderer') {
            postMessage(editor);
        }
    };
    const postMessage = (editor) => {
        broadcastChannel.value?.postMessage({
            source: PROVIDER_SOURCE,
            payload: {
                baseData: editor.baseData,
                projectInfo: editor.projectInfo,
            },
        });
    };
    return {
        editorCreatedHook,
        editorDestroyedHook,
        switchActiveKeyHook,
    };
};
export const isPreviewMode = () => {
    const querySearch = new URLSearchParams(window.location.search);
    return querySearch.get(PREVIEW_MODE_QUERY_KEY) === '1';
};
export const usePreviewConsumer = () => {
    const broadcastChannel = ref(null);
    const fetchPreviewData = () => {
        // 发送消息，通知编辑器已经初始化完成
        // 让编辑器推送第一次数据过来
        broadcastChannel.value?.postMessage({
            source: CONSUMER_SOURCE,
        });
    };
    const addPreviewListener = (pagelet) => {
        const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY);
        window.RUNTIME_CONFIGS.isPreviewMode = true;
        if (!sessionId) {
            console.error('[预览模式]获取sessionId失败');
            return;
        }
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${sessionId}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source, payload } = event.data;
            if (source === PROVIDER_SOURCE) {
                pagelet.setConfig(payload.baseData);
                pagelet.setProjectConfig(payload.projectInfo);
                pagelet.reloadUcRenderer();
            }
        };
        fetchPreviewData();
    };
    const removePreviewListener = () => {
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    return {
        isPreviewMode,
        addPreviewListener,
        fetchPreviewData,
        removePreviewListener,
    };
};
