<template>
  <a-drawer
    width="480"
    :mask="false"
    title="公告中心"
    placement="right"
    closable="true"
    :visible="visible"
    @close="onClose"
  >
    <a-tabs
      v-model="activeTab"
      @change="handleTabChange"
    >
      <a-tab-pane key="">
        <a-badge
          slot="tab"
          :dot="!activeTab&&unreadNum"
        >
          全部
        </a-badge>
      </a-tab-pane>
      <a-tab-pane
        v-for="classification in classifications"
        :key="classification"
      >
        <a-badge
          slot="tab"
          :dot="activeTab===classification&&unreadNum"
        >
          {{ classification }}
        </a-badge>
      </a-tab-pane>
      <a-input-search
        v-if="showInputSearch"
        slot="tabBarExtraContent"
        ref="inputSearch"
        v-model="searchStr"
        style="padding-right:5px"
        @search="handleSearch"
        @blur="handleBlur"
      />
      <a-icon
        v-else
        slot="tabBarExtraContent"
        type="search"
        style="padding-right:16px"
        @click="handleShowInputSearch"
      />
    </a-tabs>
    <div class="anncmnt-container">
      <div
        v-for="item in anncmntList"
        :key="item.id"
        class="anncmnt"
        @click="handleClick(item)"
      >
        <div class="header">
          <a-badge
            :dot="!item.read"
          >
            <a-icon
              :type="item.type==='warn'?'warning':'info-circle'"
              :two-tone-color="item.type==='warn'?'red':'#1890ff'"
              theme="twoTone"
              style="margin-right:4px"
            />
            <span
              class="title"
              :style="{color: item.type==='warn'?'red':'black'}"
            >
              {{ item.title }}
            </span>
          </a-badge>
          <span>
            {{ getDays(item.startTime) }}
          </span>
        </div>
        <public-w-cherry-markdown
          :slot="description"
          :ref="`anncmnt${item.id}`"
          class="content"
          :value="item.content"
          readonly="true"
        />
        <a-popover
          :title="item.title"
          placement="left"
          trigger="click"
          overlay-class-name="card-popover"
        >
          <template slot="content">
            <public-w-cherry-markdown
              :slot="description"
              :value="item.content"
              readonly="true"
            />
          </template>
          <a-button
            :ref="`btn${item.id}`"
            type="link"
            style="padding:0;display:none"
          >
            <a-icon type="left" />
            展开查看详情
          </a-button>
        </a-popover>
      </div>
    </div>
    <a-empty
      v-if="isEmpty"
      description="无公告"
      style="margin-top:50%"
    />
  </a-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { filter } from 'lodash';
import dayjs from 'dayjs';
import { highlightRect } from '@tencent/ui-core/lib/utils/browser';

export default ({
  name: 'AnncmntDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: '',
      searchStr: '',
      showInputSearch: false,
    };
  },
  computed: {
    ...mapState({
      anncmntList: state => state.announcement.anncmntList,
      classifications: state => state.announcement.classifications,
    }),
    isEmpty() {
      return !this.anncmntList?.length;
    },
    filterClassification() {
      return this.activeTab ? `classification='${this.activeTab}'` : '';
    },
    unreadNum() {
      return filter(this.anncmntList, anncmnt => !anncmnt.read)?.length;
    },
  },
  updated() {
    // 图片加载需要时间，延迟计算高度
    setTimeout(() => {
      const anncmntItems = Object.keys(this.$refs).filter(item => item.includes('anncmnt'));
      anncmntItems.forEach((item) => {
        const node = this.$refs[item]?.[0]?.$el;
        if (node && node.clientHeight < node.scrollHeight) {
          const id = `btn${item.slice(7)}`;
          this.$refs[id][0].$el.style.display = 'block';
        }
      });
    }, 500);
  },
  methods: {
    ...mapActions(['readAnncmntRuntime', 'fetchAnncmntRuntimeList']),
    onClose() {
      this.$emit('close');
    },
    getDays(startTime) {
      return dayjs(startTime).format('YYYY/MM/DD');
    },
    handleClick(anncmnt) {
      const { projectId, id, read, endTime } = anncmnt;
      read || this.readAnncmntRuntime({ projectId, id, endTime });
    },
    // 外部调用
    highLightAnncmntById(id) {
      this.$nextTick(() => {
        setTimeout(() => {
          const target = this.$refs[`anncmnt${id}`][0]?.$el?.parentNode;
          if (!target) return;
          target.scrollIntoView();
          highlightRect(target.getBoundingClientRect(), 1000);
        }, 500);
      });
    },
    // 外部调用
    drawerScrollToTop() {
      if (this.isEmpty) return;
      const latestAnncmnt = this.$refs[`anncmnt${this.anncmntList?.[0]?.id}`]?.[0]?.$el?.parentNode;
      if (!latestAnncmnt) return;
      latestAnncmnt.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    },
    handleTabChange() {
      this.searchStr = '';
      this.fetchAnncmntRuntimeList({ projectId: this.projectId, filter: this.filterClassification });
    },
    handleSearch() {
      const contentFilter = this.searchStr ? `(title%%'${this.searchStr}'|content%%'${this.searchStr}')` : '';
      const filterArr = [contentFilter, this.filterClassification];
      this.fetchAnncmntRuntimeList({
        projectId: this.projectId,
        filter: filterArr.filter(Boolean)?.join('&'),
      });
    },
    handleBlur() {
      if (this.searchStr) return;
      this.handleSearch();
      this.showInputSearch = false;
    },
    handleShowInputSearch() {
      this.showInputSearch = true;
      // 非原生组件手动获取焦点
      this.$nextTick(() => this.$refs?.inputSearch?.focus());
    },
  },
});
</script>

<style lang="scss" scoped>
  ::v-deep .ant-drawer-body{
    padding: 0;
    overflow: hidden;
    height: calc(100% - 55px);
    .header{
      display: flex;
      justify-content: space-between;
      line-height: 100%;
      .title{
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ant-tabs-bar{
      margin: 0;
      .ant-tabs-tab{
        margin: 0;
        min-width: 88px;
        text-align: center;
      }
    }

    .anncmnt-container {
      overflow: auto;
      height: calc(100% - 45px);
      .anncmnt{
        padding: 24px 24px 12px 24px;
        border: 1px solid #e8e8e85e;
        &:hover {
              background-color: rgba(182, 182, 182, 0.15);
              cursor: pointer;
        }
      }
      .editor-demo,.cherry{
        min-height: 0px;
      }
      .content {
        max-height: 150px;
        overflow: hidden;
      }
      .cherry.cherry--no-toolbar .cherry-previewer{
        padding: 12px 0px;
        p{
          margin: 0;
        }
      }
    }


  }
</style>
<style lang="scss">
  .card-popover .ant-popover-inner-content{
    width: 480px;
    height: 600px;
    overflow: scroll;
    padding: 24px;
    .cherry-previewer{
      padding: 0;
    }
  }
  .card-popover .ant-popover-title{
    padding: 10px 16px;
  }
</style>
