import { defineComponent } from '@vue/composition-api';
import { providePermState } from './PermState';
export default defineComponent({
    name: 'providePermState',
    props: {
        projectId: {
            type: String,
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
    },
    setup() {
        const permState = providePermState();
        return {
            permState,
        };
    },
    created() {
        this.permState.initAuthSetting(this.projectId, this.env).then(() => this.$emit('inited', this.permState));
    },
    render() {
        return <router-view />;
    },
});
