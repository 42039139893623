<template>
  <div
    class="env-select"
  >
    <a-popover
      v-model="visible"
      :overlay-class-name="`env-select-popover-${theme}`"
      trigger="click"
    >
      <template slot="content">
        <p
          v-for="env in showEnvList"
          :key="env.env"
          :class="['env-select_options', {'env-select_options-active': env.env === currentEnv}]"
          @click="handleChange(env.env, env.id)"
        >
          {{ env.name }}
        </p>
      </template>
      <span
        :class="`env-select_button ${visible?'env-select_button-active':''}`"
      >
        <span>{{ envListMap[currentEnv] || currentEnv }}</span>
        <a-icon
          v-if="loading"
          class="env-select_icon"
          type="loading"
        />
        <a-icon
          v-else
          type="swap"
          class="env-select_icon"
        />
      </span>
    </a-popover>
  </div>
</template>

<script>
import xss from 'xss';
import { match, compile } from 'path-to-regexp';
import qs from 'query-string';
import { computed, onMounted, toRefs } from '@vue/composition-api';
import { useStore } from '@store/index';
import { mapState, mapActions } from 'vuex';
import { BASE_APP_PATH_REGEX } from '@config/constant';
import { isDefaultBaseAppPath } from '@utils/path';
import { executeSwitchHook } from '@utils/executeSwitchHook';
import { useHostConfig } from '@pages/project/projectConfig/hostConfig/useHostConfig';
import logger from '@utils/logger';
import { useRouter } from '@/router/useRouter';

export default {
  name: 'EnvSelect',
  inject: {
    ctx: 'ctx',
  },
  props: {
    mode: {
      type: String,
      default: 'editor',
    },
    theme: {
      type: String,
      default: 'light',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showList: {
      type: Array,
      default: () => ['*'],
    },
    redirect: {
      type: String,
      default: 'same',
    },
  },
  setup(props) {
    const { mode } = toRefs(props);
    const store = useStore();
    const { route } = useRouter();

    // 运行时从 vuex 取; 编辑时从 query 取
    const projectId = computed(() => store.state.app.projectId || route.value?.query?.projectid);

    const { hostConfigs, getHostConfigs } = useHostConfig(projectId);

    onMounted(() => {
      getHostConfigs({ tips: false, isRuntime: mode.value === 'runtime' });
    });

    return { projectId, hostConfigs };
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapState({
      currentEnv: state => state.app.env,
      projectInfo: state => state.runtime.project,
      envList: state => state.env.envList,
      loading: state => state.loading.fetchEnvList,
    }),
    envListMap() {
      const map = {};
      this.envList.forEach((item) => {
        map[item.env] = item.name;
      });
      return map;
    },
    showEnvList() {
      if (this.showList.includes('*')) {
        return this.envList;
      }
      return this.envList.filter(item => this.showList.includes(item.id));
    },
  },
  created() {
    this.fetchEnvList({ projectId: this.projectId });
  },
  methods: {
    ...mapActions(['fetchEnvList']),
    handleChange(env) {
      this.visible = false;
      // 动画更自然
      setTimeout(async () => {
        if (this.disabled) return false;
        let compiler;
        let matcher;
        let target;

        const isDefault = isDefaultBaseAppPath();// 是否默认的运行时路径

        const { protocol } = location;
        let { host } = location;
        let path = '';
        const hostConfig = this.hostConfigs.find(item => item.envId.replace(`${item.projectId}:`, '') === env);
        const targetPrefix = hostConfig?.pathPrefix ?? '/page';
        if (isDefault) {
          // 普通链接
          if (hostConfig) {
            // 长路径 → 短路径
            host = hostConfig.host;
            compiler = compile(targetPrefix);
            matcher = match(`${BASE_APP_PATH_REGEX}/:page*`, { end: false });
          } else {
            // 长路径 → 长路径
            compiler = compile(BASE_APP_PATH_REGEX);
            matcher = match(`${BASE_APP_PATH_REGEX}/:page*`, { end: false });
          }
        } else {
          // 自定义路径
          const currentConfig = this.hostConfigs.find(item => item.envId.replace(`${item.projectId}:`, '') === this.currentEnv);
          const currentPrefix = currentConfig?.pathPrefix ?? '/page';
          if (hostConfig && currentConfig) {
            // 短路径 → 短路径
            // 有对应环境的自定义路径配置
            host = hostConfig.host;
            compiler = compile(targetPrefix);
            matcher = match(`${currentPrefix}/:page*`, { end: false });
          } else {
            // 短路径 → 长路径
            // 没有对应环境的自定义路径配置, 兜底使用长路径
            compiler = compile(BASE_APP_PATH_REGEX);
            matcher = match(`${currentPrefix}/:page*`, { end: false });
          }
        }

        const matchRes = matcher(location.pathname);
        logger.info('[env-switch].matchRes', matchRes);
        if (matchRes) {
          const { params } = matchRes;
          const paramsPage = Array.isArray(params?.page) ? params.page.join('/') : params.page;
          if (this.redirect === 'home') {
            const resolvedRoute = this.$router.resolve({ path: '/' });
            const resolvePath = resolvedRoute?.resolved?.path || '/';
            const resolveQuery = resolvedRoute?.resolved?.query ?? {};
            const currentQuery = this.$route.query ?? {};
            path = qs.stringifyUrl({ url: resolvePath, query: { ...resolveQuery, ...currentQuery } });
          } else {
            path = paramsPage ? `/${paramsPage}${location.search}` : location.search;
          }
          const prefix = compiler({ env, projectId: this.projectId }, { pretty: true });
          target = `${protocol}//${host}${prefix}`;

          // FIXME: 废弃的环境切换钩子函数
          const navs = this.projectInfo?.uiInfo.navlink?.defaultNav;
          const envConfig = navs?.find(i => i.id === 'env');
          if (envConfig?.hook) {
            const hookPath = await executeSwitchHook(
              envConfig.hook,
              { ctx: this.ctx, from: this.currentEnv, to: env, switchingKey: 'env' },
            );
            if (hookPath) {
              path = hookPath;
              logger.info('[env-switch].hook.path', path);
            }
          }
          target += path;
          logger.info('[env-switch].target', target);
          location.href = xss(target);
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.env-select {
  margin-right: 80px;
  &_button {
    height: 28px;
    border-radius: 14px;
    padding: 5px 0;
    cursor: pointer;
    white-space: nowrap;
  }
  &_button:hover, &_button-active {
    color: var(--wuji-primary-color);
  }
  &_icon {
    margin-left: 4px;
  }
  &_options {
    padding: 10px 12px;
    margin: 0;
    cursor: pointer;
    &-active {
      color: var(--wuji-primary-color);
    }
  }
}
</style>

<style lang="scss">
.env-select-popover-light .ant-popover-inner-content {
  padding: 0px;
  .env-select_options {
    &:hover,&-active {
      background: var(--wuji-primary-bg-color);
    }
    &:active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.env-select-popover-dark .ant-popover-inner-content {
  padding: 0px;
  color: var(--wuji-text-color-dark);
  background: var(--wuji-primary-bg-color-dark);
  .env-select_options {
    &:hover,&-active {
      color: var(--wuji-text-color-dark);
      background: rgba(255, 255, 255, 0.08);
    }
    &:active {
      background: rgba(255, 255, 255, 0.04);
    }
  }
}

.env-select-popover-dark.ant-popover-placement-bottom {
  > .ant-popover-content > .ant-popover-arrow {
    border-top-color: var(--wuji-primary-bg-color-dark);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--wuji-primary-bg-color-dark);
  }
}

.env-select-popover-dark.ant-popover-placement-top {
  > .ant-popover-content > .ant-popover-arrow {
    border-right-color: var(--wuji-primary-bg-color-dark);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: var(--wuji-primary-bg-color-dark);
  }
}
</style>
