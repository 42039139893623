import { defineComponent, ref } from '@vue/composition-api';
import envSelector from '@components/env/EnvSelector';
import styles from './permission.module.scss';
import { useRuntimeInfo, useEnvChange } from './utils';
import { BIZ_OR_PROJECT } from './constants';
export default defineComponent({
    name: 'PermFilter',
    emits: ['envChange', 'bizIdChange', 'searchChange', 'listAllChange'],
    props: {
        projectId: {
            type: String,
            required: true,
        },
        showEnv: {
            type: Boolean,
            default: false,
        },
        showSwitch: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            required: true,
        },
        listAll: {
            type: Boolean,
            required: true,
        },
        showListAllSwitch: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        envSelector,
    },
    setup(props) {
        const { env, bizId, isRuntimeMode } = useRuntimeInfo();
        const { bizConfig, switchOptions, fetchBizInfo, canReadPermission, canUpdatePermission, checkReadUpdatePermission } = useEnvChange(props.projectId);
        const q = ref('');
        const bizIdOnQuery = ref('');
        return {
            q,
            env,
            bizId,
            bizIdOnQuery,
            bizConfig,
            switchOptions,
            fetchBizInfo,
            canReadPermission,
            canUpdatePermission,
            checkReadUpdatePermission,
            isRuntimeMode,
        };
    },
    watch: {
        '$route.query.env': {
            async handler(newValue) {
                if (newValue)
                    this.env = newValue;
                await this.checkReadUpdatePermission(this.env);
                await this.fetchBizInfo(this.env);
                if (this.bizConfig && this.isRuntimeMode) {
                    const key = this.bizConfig?.queryKey ?? '';
                    this.bizIdOnQuery = this.$route.query[key] || '';
                    this.bizId = this.bizIdOnQuery;
                    this.$emit('bizIdChange', this.bizId);
                }
                this.$emit('envChange', {
                    env: this.env,
                    bizConfig: this.bizConfig,
                    switchOptions: this.switchOptions,
                    canReadPermission: this.canReadPermission,
                    canUpdatePermission: this.canUpdatePermission,
                });
            },
            immediate: true,
        },
    },
    render() {
        const bizIdChange = () => {
            this.$emit('bizIdChange', this.bizId);
        };
        const searchChange = () => {
            this.$emit('searchChange', this.q);
        };
        return (<div>
        <div class={styles.search}>
          {this.showListAllSwitch && <a-tooltip title="点击可显示全部（包括已过期的）">
            <a-button style="margin-right: 8px; padding: 0; width: 2.5em" onClick={() => this.$emit('listAllChange', !this.listAll)} type={this.listAll ? 'primary' : ''}>
              <svg style="display:inline-block" width="22" height="9" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.98154 8.99998H0.294037L3.36648 0.272705H5.31818L8.39489 8.99998H6.70739L4.37642 2.06248H4.30824L1.98154 8.99998ZM2.03693 5.5781H6.63921V6.84799H2.03693V5.5781Z" fill="currentColor"/>
                <path d="M9.54439 8.99998V0.272705H11.1254V7.67469H14.9691V8.99998H9.54439Z" fill="currentColor"/>
                <path d="M16.3413 8.99998V0.272705H17.9222V7.67469H21.766V8.99998H16.3413Z" fill="currentColor"/>
              </svg>
            </a-button>
          </a-tooltip>}
          {this.showSwitch && this.bizConfig ? (<div class={styles.selectWithLabel}>
              <span class={styles.label}>所属业务</span>
              <a-select style="width:160px" v-model={this.bizId} onChange={bizIdChange}>
                {!this.isRuntimeMode && <a-select-option value={BIZ_OR_PROJECT}>全部</a-select-option>}
                <a-select-option value="">
                  平台 <span style="font-size:10px;font-style:italic">应用类型角色</span>
                </a-select-option>
                {this.switchOptions
                    .filter(o => (this.isRuntimeMode && o.value === this.bizIdOnQuery) || !this.isRuntimeMode)
                    .map((option, idx) => (<a-select-option key={idx} value={option.value}>
                      {option.label}
                    </a-select-option>))}
              </a-select>
            </div>) : null}
          <a-input-search v-model={this.q} onChange={searchChange} placeholder={this.searchPlaceholder} style="width:240px"></a-input-search>
        </div>
      </div>);
    },
});
