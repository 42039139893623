import { defineComponent, ref, onMounted } from '@vue/composition-api';
export default defineComponent({
    name: 'DeptRtx',
    props: {
        type: {
            type: String,
            default: 'staff',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
        },
    },
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    emits: ['change'],
    setup() {
        const showQuickSelect = ref(false);
        onMounted(() => setTimeout(() => showQuickSelect.value = true, 1500));
        return {
            showQuickSelect,
        };
    },
    render() {
        const clickToSelect = (type) => {
            this.$refs.deptRtxRef.callToSelect(type);
        };
        const change = () => {
            this.$emit('change', this.modelValue);
        };
        return (<div>
        <public-w-dept-rtx-select ref="deptRtxRef" multiple={this.multiple} concatOrgName={this.type === 'org'} labelInValue={this.type === 'org'} v-model={this.modelValue} type={this.type} onChange={change}/>
        {this.type === 'org'
                ? <span>快速选中:
              {this.showQuickSelect
                        ? <span>
                    <a-button type="link" onClick={() => clickToSelect('dept')}>我的部门</a-button>
                    <a-button type="link" style="padding-left:0;margin-left:-3px" onClick={() => clickToSelect('group')}>我的小组</a-button>
                  </span>
                        : <a-spin size="small" style="margin-left:16px"/>}
              </span>
                : null}
      </div>);
    },
});
