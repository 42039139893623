<template>
  <div
    v-if="links.length"
    id="xy-client-layout-nav-link"
    class="nav-link spliter"
  >
    <template
      v-for="(linkConfig, index) in links"
    >
      <NavDropItem
        v-if="linkConfig.type === 'menu'"
        :key="index"
        :menu="linkConfig"
        :disabled="disabled"
        :mode="mode"
      />
      <NavLinkItem
        v-else-if="linkConfig.type=== 'link'"
        :key="index"
        :link="linkConfig"
        :class="{
          'nav-link-item': true,
          current: currentIndex === index,
        }"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
import NavLinkItem from './NavLinkItem';
import NavDropItem from './NavDropItem';

export default {
  name: 'NavLink',
  components: {
    NavLinkItem,
    NavDropItem,
  },
  props: {
    navlink: {
      type: Object,
      default() {
        return { menus: [] };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      currentIndex: -1,
    };
  },
  computed: {
    links() {
      if (!this.navlink) {
        return [];
      }
      const customLinks = this.navlink.menus
        ? this.navlink.menus.filter(({ name, isShow }) => name && isShow !== false)
        : [];
      const defaultLinks = this.navlink.defaultNav
        ? this.navlink.defaultNav
          .filter(item => item.isShow && item.isMenu !== false)
          .map(item => ({
            name: item.name,
            type: 'link',
            args: {
              target: 'blank',
              url: item.url,
              icon: '',
              menus: [],
            },
            isShow: item.isShow,
          }))
        : [];

      return [...customLinks, ...defaultLinks];
    },
  },
};
</script>

<style lang="scss" scoped>
#xy-client-layout-nav-link.nav-link {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  gap: 32px;
}

</style>
