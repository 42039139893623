<template>
  <a-form
    v-if="editValue"
    style="width: 100%"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 12 }"
  >
    <a-form-item label="数据源">
      <a-select
        v-model="editValue.sourceId"
        show-search
        filter-option
        style="display: block; flex: 1;"
        @change="()=>{this.$set(this.editValue, 'action', ''); this.commit()}"
        @focus="reloadSourceList"
      >
        <a-select-option
          v-for="source in sourceList"
          :key="source.id"
          :value="source.id"
        >
          {{ source.id }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      v-if="currentSource && currentSource.actions"
      label="操作"
      :placeholder="currentSource.actions.length ? '请选择...': '此数据源不支持“操作”'"
    >
      <a-select
        v-model="editValue.action"
        show-search
        filter-option
        style="display: block; flex: 1;"
        @change="commit"
      >
        <a-select-option
          v-for="action in currentSource.actions"
          :key="action.id"
          :value="action.id"
        >
          {{ action.title || action.id }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';

export default {
  name: 'XySourceActionEdit',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      sourceList: [],
    };
  },
  computed: {
    currentSource() {
      return this.sourceList.find(x => x.id === this.editValue?.sourceId)?.source;
    },
  },
  created() {
    this.$watch('editValue', (ed) => {
      if (!ed) this.editValue = { sourceId: '', action: '' };
    });

    this.reloadSourceList();
  },
  methods: {
    reloadSourceList() {
      const sources = this.designer.renderer.sources || {};
      this.sourceList = Object.keys(sources).map(id => ({ id, source: sources[id] }));
    },
    // 触发修改行为
    commit() {
      this.editValue = { ...this.editValue };
    },
  },
};
</script>

<style>
</style>
