import { CATEGORY } from './const';
import { message } from 'ant-design-vue';
// 提取文件数据
export const getFileDataAtom = {
    id: 'xy:getFileData',
    name: '提取文件数据',
    desc: '仅支持提取文本类文件数据\n如JSON/CSV/SQL/纯文本文件',
    category: CATEGORY.EXPORT,
    paramsSchema: {
        type: 'object',
        fields: [
            {
                id: 'multiple',
                type: 'boolean',
                title: '是否支持多选',
                default: false,
            },
            {
                id: 'enableDirectory',
                type: 'boolean',
                title: '是否支持选择文件夹',
                description: '启用后无法选择单个文件',
                default: false,
                condition: 'params.multiple',
            },
            {
                id: 'parseTimeout',
                type: 'number',
                title: '解析文件的超时时间(毫秒)',
                default: 1000,
            },
            {
                id: 'codepage',
                type: 'string',
                title: '字符集',
                enum: [
                    {
                        label: '默认',
                        value: '',
                    },
                    {
                        label: 'UTF-8',
                        value: 'UTF-8',
                    },
                    {
                        label: 'GBK / GB2312',
                        value: 'GBK',
                    },
                ],
                default: '',
            },
        ],
    },
    execute: async (_ctx, params) => {
        const { parseTimeout, multiple = false, enableDirectory = false, codepage = '' } = params;
        const sqlType = '.sql';
        const acceptType = ['text/plain', 'application/json', 'text/csv'];
        const acceptTypeStr = [...acceptType, sqlType].join(',');
        const result = await new Promise((resolve) => {
            const elInput = document.createElement('input');
            let isFileNotLoaded = true;
            elInput.setAttribute('type', 'file');
            elInput.setAttribute('accept', acceptTypeStr);
            elInput.setAttribute('style', 'display: none');
            if (multiple)
                elInput.setAttribute('multiple', 'true');
            if (multiple && enableDirectory) {
                elInput.setAttribute('webkitdirectory', 'true');
            }
            elInput.addEventListener('change', () => {
                setTimeout(() => {
                    if (isFileNotLoaded) {
                        elInput.remove();
                        message.error('提取文件数据超时');
                        resolve(null);
                    }
                }, parseTimeout);
                const files = Array.from(elInput.files ?? []).filter(file => acceptType.includes(file.type)
                    || file.name.endsWith(sqlType));
                if (files.length > 0) {
                    const fileData = {};
                    const fileObjects = {};
                    const readerPromises = files.map(file => new Promise((resolveReader) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const data = e?.target?.result;
                            if (!data || typeof data !== 'string')
                                fileData[file.name] = '';
                            else
                                fileData[file.name] = data;
                            fileObjects[file.name] = file;
                            resolveReader();
                        };
                        if (codepage)
                            reader.readAsText(file, codepage);
                        else
                            reader.readAsText(file);
                    }));
                    Promise.all(readerPromises).then(() => {
                        elInput.remove();
                        isFileNotLoaded = false;
                        if (multiple)
                            resolve({ data: fileData, __file__: fileObjects });
                        else
                            resolve({ data: Object.values(fileData)[0], __file__: Object.values(fileObjects)[0] });
                    }, (err) => {
                        elInput.remove();
                        message.error('文件提取错误', err);
                        isFileNotLoaded = false;
                        resolve(null);
                    });
                }
                else {
                    elInput.remove();
                    message.error('不支持该文件类型');
                    isFileNotLoaded = false;
                    resolve(null);
                }
            });
            document.body.appendChild(elInput);
            elInput.click();
        });
        return result;
    },
    returnValue: '文件内的数据',
};
