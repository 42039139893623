import Cookies from 'js-cookie';
import logger from '@/utils/logger';
import { BASE_API_PATH } from '@/config/constant';
import { memoizedFetchGET } from './wujiFetch';
import { fetchStaffs } from '@/services/staff';
import { keyBy } from 'lodash';


const { GLOBAL_INFO = {} } = window;
const { userInfo = {} } = GLOBAL_INFO;

export const hasDeptInfoService = () => GLOBAL_INFO.deploySettingFlag?.hasDeptInfoService;

export const getLogoutURL = (url) => {
  const { origin } = window.location;
  return (GLOBAL_INFO.logoutURL || '{{location.origin}}/_logout/?url={{origin}}')
    .replace(/{{location.origin}}/g, origin)
    .replace(/{{origin}}/g, url || origin);
};

export const getBeaconGlobalApi = () => {
  // eslint-disable-next-line no-underscore-dangle
  const { globalAPI } = window.__MICRO_APP_ENVIRONMENT__
    ? window.microApp?.getData?.() || {}
    : {};
  return globalAPI;
};
const formatBeaconUserInfo = userInfo => ({
  userId: userInfo.user.userId,
  rtx: userInfo.user.userId,
  name: userInfo.user.userId,
  avatar: userInfo.user.avatar,
  icon: userInfo.user.avatar,
  corpId: userInfo.corpId,
});
export const getBeaconUserInfo = () => {
  const globalApi = getBeaconGlobalApi();
  if (globalApi) {
    const userInfo = globalApi.user.getState();
    return formatBeaconUserInfo(userInfo);
  }
  return null;
};

let userInfoOnBeaconCloud = null;
const globalApi = getBeaconGlobalApi();
globalApi?.user.onStateChange((userInfo) => {
  userInfoOnBeaconCloud = formatBeaconUserInfo(userInfo);
});

export const getUserId = () => userInfoOnBeaconCloud?.name || userInfo.name || GLOBAL_INFO.rtx;

export const getUserAvatarURL = userId => getUserAvatarURLTemplate().replace(/{{username}}/g, userId || getUserId());

export const getUserInfo = () => {
  if (userInfoOnBeaconCloud) return userInfoOnBeaconCloud;
  const tryGetBeaconUserInfoSync = getBeaconUserInfo();
  if (tryGetBeaconUserInfoSync) return tryGetBeaconUserInfoSync;
  const rtx = getUserId();
  const avatar = getUserAvatarURL();
  return {
    rtx,
    name: rtx,
    userId: rtx,
    avatar,
    icon: avatar,
  };
};

export const getUserAvatarURLByGivenRtx = rtx => getUserAvatarURLTemplate().replace(/{{username}}/g, rtx);

export const getUserAvatarURLTemplate = () => userInfoOnBeaconCloud?.avatar
  || userInfo.avatar || 'https://puui.qpic.cn/vupload/0/common_avatar.png/0';

export const isSuper = () => GLOBAL_INFO.user_type === 'super';

export const isGuest = () => GLOBAL_INFO.user_type === 'guest';

/**
 * 真正的超管或运维标识，不关注是否有mock操作
 */
export const isRealSuper = () => window.GLOBAL_INFO.user_type === 'super';

const mockNormalCookieKey = 'isNotAdmin';

/**
 * 判断超管或运维是否有mock成普通用户
 */
export const getIsMockNormal = () => {
  try {
    return Cookies.get(mockNormalCookieKey) === 'true';
  } catch (err) {
    console.error(`get cookie "${mockNormalCookieKey}" error: ${err.message}`);
    return false;
  }
};

/**
 * mock普通用户转换触发器
 */
export const triggerMockNormal = () => {
  // 不是真正的超管或运维，不让mock
  if (!isRealSuper()) return;

  Cookies.set(mockNormalCookieKey, !getIsMockNormal());
};

// 运行时体验非管理员角色
const mockAsGeneralUserKey = 'mockAsGeneralUser';
export const isMockAsGeneralUser = () => {
  try {
    return Cookies.get(mockAsGeneralUserKey) === 'true';
  } catch (err) {
    console.error(`get cookie "${mockAsGeneralUserKey}" error: ${err.message}`);
    return false;
  }
};
export const mockUserSwitch = () => {
  Cookies.set(mockAsGeneralUserKey, !isMockAsGeneralUser());
};

export const getUserOrgInfo = fetchStaffs;

export const getUserDeptInfo = async () => {
  const rtx = getUserId();
  if (rtx) {
    const data = await getUserOrgInfo(rtx);
    const info = data?.[0];
    if (info) {
      const deptId = getDeptIdByUserInfo(info);
      return deptId;
    }
  }
  throw Error(`无法获取到当前用户${rtx}的信息`);
};


const getDeptIdByUserInfo = (userInfo) => {
  // 如果没有拿到userInfo：
  // 1. 有可能是部门的接口出问题了，没有返回
  // 2. 有些私有部署没有使用rtx作为登陆名，这种情况下也会返回空
  // 这两种情况都不要抛错，直接返回空字符串就好
  if (!userInfo || !userInfo?.deptIdString) return '';

  const { deptIdString = '', workDeptId } = userInfo;
  const deptId = deptIdString.replace(/^;0;/, '').split(';')
    .filter(Boolean);
  const workDepIdIndex = deptId.indexOf(String(workDeptId));
  // workDepIdIndex之后的是详细到小组的id
  return deptId.slice(0, workDepIdIndex + 1).join(';');
};

export const getUserDeptAndOrgInfo = async (name) => {
  const resp = await fetchStaffs(name);
  const [staff] = resp ?? [];
  let info = {};
  if (!staff) return info;
  staff.deptIdString = staff.deptIdString.replace(';0;', '');
  const chnName = staff.chnName ?? '';
  const workDeptId = staff.workDeptId ?? '';
  const { staffId } = staff;
  const deptNameString = staff.deptNameString ?? '';
  let deptName = '';
  if (staff.deptIdString && staff.deptNameString) {
    const ids = staff.deptIdString.replace(/^;0;/, '').split(';')
      .filter(Boolean);
    const idx = ids.findIndex(i => i === String(staff.workDeptId));
    deptName = staff.deptNameString.split('/')[idx] || '';
  }
  info = { chnName, workDeptId, deptName, deptNameString, deptIdString: staff.deptIdString };
  if (staffId) info.staffId = staffId;
  info.workDeptIdString = getDeptIdByUserInfo(staff);
  try {
    const orgInfo = await fetchUserOrgInfo(staff);
    if (orgInfo) Object.assign(info, orgInfo);
    info.workOrgIdString = await getOrgIdByUserInfo(staff);
  } catch (e) {
    info.workOrgIdString = '';
  }
  return info;
};

/**
 * 获取当前用户的中心Id，e.g. 前端技术中心
 * 为什么中心要叫org?
 * 我猜测是结算预算的时候按中心分配，所以我们记录组织架构的时候到中心就好
 * @param {*} userInfo
 */
export const getOrgIdByUserInfo = async (userInfo) => {
  if (!userInfo?.deptIdString) return '';
  if (!hasDeptInfoService()) return '';

  const deptIdString = userInfo.deptIdString.replace(';0;', '');
  const info = await fetchUserOrgInfo(userInfo);
  if (!info) return '';
  const centerId = info.orgCenterId;
  const ids = deptIdString.split(';');
  const orgIdIndex = ids.findIndex(id => id === centerId);
  if (orgIdIndex !== -1) {
    // 返回细致到中心层面的id
    return ids
      .slice(0, orgIdIndex + 1)
      .join(';');
  }

  // 如果没有找到中心，那就整个返回
  // 可能是这个用户所在的部门没有id
  return deptIdString.slice(0, -1);
};

const TxOrgType = {
  BG: 6,
  DEPARTMENT: 1,
  CENTER: 7,
  GROUP: 2,
};

/**
 * 获取 bg, department, center and group Id
 * @param {{deptIdString: string}} userInfo - user.deptIdString, 分号;分隔的有层级关系的, 如 1;12;123;1234
 * @returns {Promise<null | {orgBgId: string; orgDepartmentId: string; orgCenterId: string; orgGroupId: string }>}
 */
export const fetchUserOrgInfo = async (userInfo) => {
  if (!userInfo?.deptIdString) return null;
  if (!hasDeptInfoService()) return null;

  const deptIdString = userInfo.deptIdString.replace(';0;', '');
  try {
    const url = `${BASE_API_PATH}/dept-rtx/dept-info?id=${deptIdString}`;
    const data = await memoizedFetchGET(url);
    const orgMap = keyBy(data, 'typeId');
    const orgBgId = String(orgMap[TxOrgType.BG]?.id || '');
    const orgDepartmentId = String(orgMap[TxOrgType.DEPARTMENT]?.id || '');
    const orgCenterId = String(orgMap[TxOrgType.CENTER]?.id || '');
    const orgGroupId = String(orgMap[TxOrgType.GROUP]?.id || '');
    return { orgBgId, orgDepartmentId, orgCenterId, orgGroupId };
  } catch (error) {
    logger.error(`获取组织架构信息失败，${error}`);
    return null;
  }
};
