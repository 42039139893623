import RBAC from './RBAC.vue';
import RoleList from './RoleList.tsx';
import UserList from './UserList.tsx';
import PermissionList from './PermissionList.tsx';
import CloudFunction from './CloudFunction.vue';
import Apply from './Apply';

export default [
  {
    path: 'rbac',
    component: RBAC,
    name: 'rbac',
    meta: { layoutType: 'header-content' },
    children: [
      {
        path: 'user',
        component: UserList,
        name: 'rbac.user-list',
      },
      {
        path: 'role',
        component: RoleList,
        name: 'rbac.role-list',
      },
      {
        path: 'permission',
        component: PermissionList,
        name: 'rbac.permission',
      },
      {
        path: 'api',
        component: CloudFunction,
        name: 'rbac.cloud-function',
      },
    ],
  },
  {
    path: 'rbac/apply',
    component: Apply,
    name: 'rbac-apply',
    meta: { layoutType: 'header-content' },
  },
];
