import { computed, defineComponent, ref } from '@vue/composition-api';
import { message, Modal } from 'ant-design-vue';
import { permissionApi } from '@/services';
import { BIZ_OR_PROJECT } from './constants';
import DeptRtx from './DeptRtx';
import classNames from 'classnames';
import styles from './permission.module.scss';
import { useExpire } from '@pages/modules/permission/expire';
export default defineComponent({
    name: 'RoleUserForm',
    props: {
        projectId: {
            type: String,
            required: true,
        },
        env: {
            type: String,
            required: true,
        },
        isRuntimeMode: {
            type: Boolean,
            default: false,
        },
        role: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        bizId: {
            type: String,
            default: null,
        },
        operation: {
            type: String,
        },
        users: {
            type: Array,
        },
        orgs: {
            type: Array,
        },
        switchOptions: {
            type: Array,
            required: true,
        },
        validChange: {
            type: Function,
        },
        listAll: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        let initUsers = [];
        // 运行时带有默认 bizId，所以也要检查当前角色是否为应用类型
        if (props.bizId) {
            initUsers = props.users.filter(ru => (props.type === 'biz' ? ru.bizId === props.bizId : true));
        }
        else {
            initUsers = props.type === 'biz' ? [] : props.users;
        }
        const authType = ref(props.operation?.includes('org') ? 'org' : 'staff');
        const currentUsers = ref(initUsers.filter(it => it.userType === authType.value));
        const currentBizId = ref(props.bizId === BIZ_OR_PROJECT ? '' : props.bizId);
        const addUsers = ref([]);
        const delUsers = ref([]);
        const authTypeLabel = computed(() => (authType.value === 'staff' ? '用户' : '组织'));
        const { expireOptions, renderCreateExpire } = useExpire(addUsers);
        const addRoleUsers = async () => {
            if (props.type === 'biz' && !currentBizId.value) {
                message.error('所属业务不能为空');
                return false;
            }
            try {
                const result = await permissionApi.createRoleUser(props.projectId, props.env, {
                    bizId: props.type === 'biz' ? currentBizId.value : '',
                    roles: [props.role],
                    users: addUsers.value,
                    type: authType.value,
                    expireUsers: expireOptions.expireAt,
                });
                if (result.some(x => !!x?.error)) {
                    const errList = result.filter(x => x.error).map(x => `添加用户(${x.userId})失败: ${x.error}`);
                    message.warn({
                        content: <div>
              {errList.map((err, i) => <div key={i}><a-icon type="exclamation-circle" theme="filled"/>{err}</div>)}
            </div>,
                        icon: <span />,
                    });
                }
                else {
                    message.success('已保存');
                }
                return true;
            }
            catch (error) {
                message.error(`添加${authTypeLabel.value}失败`);
            }
            return false;
        };
        const delRoleUsers = async () => {
            const roleUsers = props.users.filter(ru => (props.type === 'biz' ? ru.bizId === currentBizId.value : !ru.bizId));
            const promises = [];
            roleUsers.forEach((ru) => {
                if (delUsers.value.includes(ru.userId)) {
                    promises.push(permissionApi.deleteRoleUser(props.projectId, props.env, ru._id));
                }
            });
            try {
                await Promise.all(promises);
                message.success('已保存');
                return true;
            }
            catch (error) {
                message.error(`删除${authTypeLabel.value}失败`);
            }
            return false;
        };
        const confirmToDelete = async () => {
            const promise = new Promise((resolve) => {
                Modal.confirm({
                    title: `确定删除${authTypeLabel.value}？`,
                    content: (<div>
              <br />
              {`将删除${authTypeLabel.value}: `}
              <ul>
                {delUsers.value.map(userId => (<li key={userId}>{currentUsers.value.find(i => i.userId === userId).userName}</li>))}
              </ul>
            </div>),
                    onOk: async () => {
                        const result = await delRoleUsers();
                        resolve(result);
                    },
                    onCancel: () => resolve(false),
                });
            });
            return await promise;
        };
        const save = async () => {
            const result = await (props.operation?.includes('delete') ? confirmToDelete() : addRoleUsers());
            return result;
        };
        const change = () => {
            if (props.validChange) {
                const users = props.operation?.includes('add') ? addUsers.value : delUsers.value;
                props.validChange(users.length > 0);
            }
        };
        const bizChange = (bizId) => {
            const roleUsers = props.users.filter(ru => ru.bizId === bizId).filter(ru => ru.userType === authType.value);
            currentUsers.value = [...roleUsers];
            if (props.operation?.includes('delete')) {
                delUsers.value = [];
            }
            change();
        };
        const options = computed(() => {
            const result = [...props.switchOptions.filter(x => x.value)];
            if (props.operation?.includes('add'))
                return result;
            props.users?.forEach((ru) => {
                if (!result.some(x => (ru.bizId ? x.value === ru.bizId : true))) {
                    result.push({ label: ru.bizId, value: ru.bizId });
                }
            });
            return result;
        });
        const inputBoxModel = computed(() => (props.operation?.includes('add') ? addUsers.value : delUsers.value));
        function handleClickExistingItem(it) {
            // 添加组织时，组件不能有效处理选中值，暂时不支持
            if (authType.value !== 'staff' && props.operation?.includes('add'))
                return;
            const dest = inputBoxModel;
            const index = dest.value.indexOf(it.userId);
            if (index === -1)
                dest.value.push(it.userId);
            else
                dest.value.splice(index, 1);
            change();
        }
        return {
            currentUsers,
            currentBizId,
            addUsers,
            delUsers,
            authType,
            authTypeLabel,
            save,
            bizChange,
            change,
            options,
            handleClickExistingItem,
            inputBoxModel,
            renderCreateExpire,
        };
    },
    render() {
        return (<div>
        <a-form-model ref="form" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} labelAlign="left">
          <a-form-model-item label="角色ID">
            <span>{this.role}</span>
          </a-form-model-item>
          {!!this.type && (<a-form-model-item label="所属业务" required>
              <a-select v-model={this.currentBizId} disabled={!!this.bizId && this.isRuntimeMode} onChange={(v) => this.bizChange(v)}>
                {this.options.map((option, idx) => (<a-select-option key={idx} value={option.value}>
                    {option.label}
                  </a-select-option>))}
              </a-select>
            </a-form-model-item>)}
          <a-form-model-item label={`当前关联${this.authTypeLabel}`}>
            <div style="max-height:200px;overflow:auto">
              {this.currentUsers.length > 0
                ? this.currentUsers.map((ru, idx) => {
                    const isOrg = ru.userType === 'org';
                    if (ru.disabled && !this.listAll)
                        return null;
                    return <a-tag key={idx} onClick={() => this.handleClickExistingItem(ru)} color={isOrg ? 'blue' : ''} class={classNames(styles.clickableRoleUserNameTag, ru.disabled && styles.disabledRoleUserNameTag, this.inputBoxModel.includes(ru.userId) && styles.inputedRoleUserNameTag)}>{ru.userName}</a-tag>;
                })
                : '无'}
            </div>
          </a-form-model-item>
          {this.operation?.includes('add') ? [
                <a-form-model-item label={`添加${this.authTypeLabel}`} required>
              <DeptRtx v-model={this.addUsers} multiple onChange={() => this.change()} type={this.authType === 'staff' ? 'staffs' : 'org'}/>
            </a-form-model-item>,
                this.renderCreateExpire(),
            ] : (<a-form-model-item label={`删除${this.authTypeLabel}`} required>
              <a-select v-model={this.delUsers} mode="multiple" onChange={() => this.change()}>
                {this.currentUsers.map(i => (<a-select-option key={i.userId}>{i.userName}</a-select-option>))}
              </a-select>
            </a-form-model-item>)}
        </a-form-model>
      </div>);
    },
});
