import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { useStore } from '@/store';
export default defineComponent({
    name: 'envSelector',
    model: {
        prop: 'env',
        event: 'change',
    },
    props: {
        env: {
            type: String,
            default: 'dev',
        },
        hiddenEnv: {
            type: Array,
            default: () => [],
        },
        hideDisableSync: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const store = useStore();
        const { hiddenEnv, hideDisableSync } = toRefs(props);
        const envList = computed(() => [{ name: '开发环境', env: 'dev' }, ...store.state.env.envList]);
        store.dispatch('fetchEnvList');
        const filterEnvList = computed(() => envList.value.filter((env) => {
            const isHide = hiddenEnv.value.includes(env.env);
            if (hideDisableSync.value)
                return !isHide && !env.disableSync;
            return !isHide;
        }));
        return {
            filterEnvList,
        };
    },
    render() {
        return (<a-select v-model={this.env} onChange={(val) => this.$emit('change', val)} default-value="dev" style="width: 120px">
        {this.filterEnvList.map((envItem) => (<a-select-option key={envItem.env}>{envItem.name}</a-select-option>))}
      </a-select>);
    },
});
