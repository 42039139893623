<template>
  <a-select
    v-model="editValue"
    show-search
    filter-option
    class="modal-select"
  >
    <a-select-opt-group label="页面内弹窗">
      <a-select-option
        v-for="modal in xyModals"
        :key="modal.id"
        :value="modal.id"
      >
        <a-tooltip
          :title="`${modal.name}（${modal.id}）`"
          placement="left"
        >
          <div class="modal-name">
            {{ `${modal.name}（${modal.id}）` }}
          </div>
        </a-tooltip>
        <a-button
          v-if="currentActiveKey!==modal.id"
          class="edit-button"
          size="small"
          @click="()=>handleChangeActivePagelet(modal.id)"
        >
          编辑
        </a-button>
      </a-select-option>
    </a-select-opt-group>

    <a-select-opt-group label="全局弹窗">
      <a-select-option
        v-for="page in modalPageList"
        :key="page.pageId"
        :value="page.id"
      >
        <a-tooltip
          :title="`${page.name}（${page.pageId}）`"
          placement="left"
        >
          <div class="modal-name">
            {{ `${page.name}（${page.pageId}）` }}
          </div>
        </a-tooltip>
        <a-button
          v-if="!(isGlobalModal&&page.pageId===pageId)"
          class="edit-button"
          size="small"
          @click="()=>handleEdit(page.pageId)"
        >
          编辑
        </a-button>
      </a-select-option>
    </a-select-opt-group>
  </a-select>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';

import { goToPageConfig } from '@utils/path';

export default {
  name: 'XyModalSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
    wContext: { from: 'wContext', default: null },
    xyPageConfig: { from: 'xyPageConfig' },
  },
  data() {
    return {
    };
  },
  computed: {
    isGlobalModal() {
      return this.wContext?.type === 'modal';
    },
    pageId() {
      return this.$route.query?.pageid;
    },
    modalPageList() {
      return this.wContext?.pageList?.filter(page => page.pageType === 'modal');
    },
    xyModals() {
      if (Array.isArray(this.wContext?.modalList)) return this.wContext.modalList;
      return [];
    },
    currentActiveKey() {
      return this.xyPageConfig?.designArea?.activeKey;
    },
  },
  methods: {
    handleEdit(pageid) {
      goToPageConfig({ ...this.$route.query, pageid }, true);
    },
    handleChangeActivePagelet(id) {
      this.xyPageConfig.handleSelectPagelet(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-select {
  width: 100%;
  display: block;
  flex: 1;
  ::v-deep .ant-select-selection-selected-value{
    width: 100%;
  }
}

.modal-name {
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
}
</style>
