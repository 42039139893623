import { getAppQuery } from '@utils/query';

const isDebug = getAppQuery('debug');
export const scriptsConfig = {
  echarts: {
    key: 'echarts',
    js: 'https://vfiles.gtimg.cn/wuji/echarts@5.1.2.min.js',
  },
  xlsx: {
    key: 'XLSX',
    js: 'https://vfiles.gtimg.cn/wuji/xlsx.full@0.11.19.min.js',
  },
  jstt: { // https://github.com/bcherny/json-schema-to-typescript
    key: 'jstt',
    js: 'https://vfiles.gtimg.cn/wuji/xystatic/json-schema-to-typescript.js',
  },
  react: {
    key: 'React',
    js: isDebug ? 'https://vfiles.gtimg.cn/wuji/react.development.js' : 'https://vfiles.gtimg.cn/wuji/react.production.min.js',
  },
  reactDOM: {
    key: 'ReactDOM',
    js: isDebug ? 'https://vfiles.gtimg.cn/wuji/react-dom.development.js' : 'https://vfiles.gtimg.cn/wuji/react-dom.production.min.js',
  },
  vue3: {
    key: 'Vue3',
    js: 'https://vfiles.gtimg.cn/wuji_dashboard/xy/starter/045d2dc1.js',
  },
};
