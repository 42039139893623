<template>
  <div style="width:100%">
    <a-form-model layout="vertical">
      <a-form-model-item
        label="消息发送方"
        required
      >
        <a-select v-model="editValue.weworkApp">
          <a-select-option
            v-if="builtinWeworkAppName"
            value="builtin"
          >
            {{ builtinWeworkAppName }}
            <span style="color:#8d8d8d;font-size:10px;font-style:italic"> 平台提供</span>
          </a-select-option>
          <a-select-option
            v-else
            value="no-builtin"
          >
            <span>暂无平台提供的企业微信应用</span>
          </a-select-option>
          <a-select-option
            v-if="customWeworkAppName"
            value="custom"
          >
            {{ customWeworkAppName }}
            <span style="color:#8d8d8d;font-size:10px;font-style:italic"> 应用配置</span>
          </a-select-option>
          <a-select-option
            v-else
            disabled
            value="no-custom"
          >
            <span>
              可自定义企业微信应用
              <a
                :href="customWeworkConfigUrl"
                target="_blank"
              >
                <a-icon type="link" /> 配置</a>
            </span>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="消息接收人"
        required
      >
        <a-select
          v-model="editValue.receiverType"
          style="margin-bottom:8px"
        >
          <a-select-option value="dynamic">
            动态设置
          </a-select-option>
          <a-select-option value="static">
            固定设置
          </a-select-option>
        </a-select>
        <a-input
          v-if="editValue.receiverType === 'dynamic'"
          v-model="editValue.receivers"
          :placeholder="receiverPlaceholder"
        />
        <public-w-dept-rtx-select
          v-else
          type="staffs"
          multiple
          :value="editValue.receivers"
          separator=","
          @change="(v) => editValue.receivers = v"
        />
      </a-form-model-item>
      <a-form-model-item required>
        <template slot="label">
          <span>消息类型</span>
          <a
            href="https://developer.work.weixin.qq.com/document/path/90372"
            target="_blank"
          ><a-icon type="link" /> 查看详情</a>
        </template>
        <a-select v-model="editValue.msgType">
          <a-select-option value="text">
            文本
          </a-select-option>
          <a-select-option value="textcard">
            文本卡片
          </a-select-option>
          <a-select-option value="markdown">
            Markdown
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div v-if="editValue.msgType === 'text'">
        <a-form-model-item
          label="文本内容"
          required
        >
          <a-textarea
            v-model="editValue.content"
            :max-length="2048"
            placeholder="可以支持换行、以及超链接，如: 查看<a href=&quot;http://x.y.z&quot;>详情</a>，长度限制 2048"
          />
        </a-form-model-item>
      </div>
      <div v-else-if="editValue.msgType === 'markdown'">
        <a-form-model-item
          required
        >
          <template slot="label">
            <span>Markdown内容</span>
            <a
              href="https://developer.work.weixin.qq.com/document/path/90236#%E6%94%AF%E6%8C%81%E7%9A%84markdown%E8%AF%AD%E6%B3%95"
              target="_blank"
            ><a-icon type="link" /> 支持的markdown语法</a>
          </template>
          <a-textarea
            v-model="editValue.content"
            :max-length="2048"
            placeholder="可以支持换行、以及mark语法的子集，如: [查看](http://x.y.z)，长度限制 2048"
          />
        </a-form-model-item>
      </div>
      <div v-else>
        <a-form-model-item
          label="标题"
          required
        >
          <a-input
            v-model="editValue.title"
            placeholder="卡片标题，长度限制 128"
            :max-length="128"
          />
        </a-form-model-item>
        <a-form-model-item
          label="内容"
          required
        >
          <a-textarea
            v-model="editValue.description"
            placeholder="卡片内容，长度限制 512"
            :max-length="512"
          />
        </a-form-model-item>
        <a-form-model-item
          label="链接"
          required
        >
          <a-input
            v-model="editValue.url"
            placeholder="点击卡片跳转的链接，长度限制 2048"
            :max-length="2048"
          />
        </a-form-model-item>
        <a-form-model-item
          label="按钮文字"
          required
        >
          <a-input
            v-model="editValue.btnTxt"
            placeholder="长度限制 4"
            :max-length="4"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { addPathPrefix } from '@/utils/path';

const component = {
  name: 'XyWeworkMessage',
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  mixins: [vueComponentMixin],
  data() {
    return {
      projectId: '',
      customWeworkAppName: '',
      builtinWeworkAppName: '',
      receiverPlaceholder: '如通过页面变量获取：{{ state.var }}',
    };
  },
  computed: {
    customWeworkConfigUrl() {
      return addPathPrefix(`/xy/project/config/advance?projectid=${this.projectId}`);
    },
  },
  mounted() {
    const { weworkAppName = '' } = window.CONFIGS || {};
    if (weworkAppName) {
      this.builtinWeworkAppName = weworkAppName;
      if (!this.editValue.weworkApp) this.editValue.weworkApp = 'builtin';
    }
    const { ctx } = this.designer.renderer;
    const { advanceConfig, id } = ctx.projectConfig;
    this.projectId = id;
    if (advanceConfig?.enabledWeworkApp && advanceConfig?.weworkAppConfig) {
      const { name } = advanceConfig.weworkAppConfig;
      this.customWeworkAppName = name;
    }
  },
  methods: {},
};

export default component;

const settingsSchema = null;
export const meta = {
  settingsSchema,
  component,
  hideLabel: false,
};
</script>

