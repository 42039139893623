<template>
  <a-select
    v-model="editValue"
    show-search
    style="display: block; flex: 1;width: 100%;"
    :filter-option="handlePageFilter"
  >
    <a-select-option
      v-for="page in xyPages"
      :key="page.pageId"
      :value="page.pageId"
      style="width: 100%"
    >
      <div
        class="option-label"
        :style="`width:${page.pageType === PAGE_TYPE.DATA_TABLE?'100%':'calc(100% - 60px)'}`"
      >
        <a-icon
          v-if="page.pageType === PAGE_TYPE.DATA_TABLE"
          type="database"
        />
        {{ page.name }}（{{ page.path }}）
      </div>
      <a-button
        v-if="page.pageType !== PAGE_TYPE.DATA_TABLE"
        style="position: absolute;right: 10px;top: calc(50% - 12px);"
        size="small"
        @click.stop="handleGoToPageConfig(page.pageId)"
      >
        编辑
      </a-button>
    </a-select-option>
  </a-select>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { goToPageConfig } from '@utils/path';
import { PAGE_TYPE } from '@/loaders/page/consts';

export default {
  name: 'XyPageSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
    wContext: { from: 'wContext', default: null },
  },
  data() {
    return {
      PAGE_TYPE,
    };
  },
  computed: {
    xyPages() {
      if (Array.isArray(this.wContext?.pageList)) {
        return this.wContext.pageList.filter(page => page.pageType === PAGE_TYPE.CONTENT // 页面
        || page.pageType === PAGE_TYPE.DATA_TABLE // 数据表页面
        || (page.type === 'code' && !page.pageType));// 本地开发页面
      }
      return [];
    },
  },
  methods: {
    handleGoToPageConfig(pageid) {
      goToPageConfig({ ...this.$route.query, pageid }, true);
    },
    // 页面选择器筛选
    handlePageFilter(input, option) {
      const { value = '' } = option.componentOptions.propsData ?? {};
      const page = this.xyPages?.find(item => item.pageId === value);
      if (page) {
        return page.pageId.toLowerCase().indexOf(input.toLowerCase()) >= 0
          || page.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          || page.path.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.option-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::v-deep .ant-select-selection-selected-value {
  width: 100%;
}
</style>
