<template>
  <div style="width: 100%; display: flex">
    <a-select
      v-model="editValue"
      show-search
      filter-option
      style="flex: 1 0 0; min-width: 0"
      @focus="reloadFlowList"
    >
      <a-select-option
        v-for="flow in flowList"
        :key="flow.flowId"
        :value="flow.flowId"
      >
        {{ flow.name }} ({{ flow.flowId }})
      </a-select-option>
    </a-select>

    <a-button
      v-if="editValue"
      icon="edit"
      style="margin-left: -4px"
      @click="openEditor"
    />
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import useFlowService from '@/composables/flow/useFlowService';
import { isEqual } from 'lodash';
import { addPathPrefix } from '@/utils/path';

export default {
  name: 'XyFlowSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      flowList: [],
    };
  },
  computed: {
    projectId() {
      const { ctx } = this.designer.renderer;
      const { projectId } = ctx.config;
      return projectId;
    },
  },
  created() {
    this.reloadFlowList();
  },
  methods: {
    async reloadFlowList() {
      const service = useFlowService({
        projectId: this.projectId,
        envId: 'dev',
      });
      const list = await service.list({
        include: 'flowId,name',
        sort: 'mtime',
        order: 'desc',
      });

      if (isEqual(this.flowList, list)) return;
      this.flowList = list;
    },
    openEditor() {
      const flowId = this.editValue;
      window.open(addPathPrefix(`/xy/project/editor/flow/${flowId}?projectid=${this.projectId}`));
    },
  },
};
</script>

<style></style>
