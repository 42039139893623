var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import Vue from 'vue';
import { message } from 'ant-design-vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { DataStub } from '@tencent/data-schema-core';
import { arrayRemove } from '../../../utils/lang';
import { isConfirm } from '@/utils/modal';
import Draggable from 'vuedraggable';
import styles from './ExcelFieldConfigEdit.module.scss';
let ExcelFieldConfigEdit = class ExcelFieldConfigEdit extends Vue {
    draggableHandleClassName = 'ExcelFieldConfigEditHandle';
    value;
    designer;
    isCsv;
    drag = false;
    get columns() {
        const renderTitle = (title, desc) => (<div>
        <span style="margin-right:2px;">{title}</span>
        <a-tooltip title={desc}>
          <a-icon type="question-circle"></a-icon>
        </a-tooltip>
      </div>);
        return [
            {
                title: '列名',
                dataIndex: 'colName',
                customRender: (value) => (<a-input v-model={value.colName}></a-input>),
            },
            {
                title: renderTitle('取数字段', '支持嵌套取值 eg: a.b ；a[0].b'),
                dataIndex: 'dataKey',
                customRender: (value) => (<a-input v-model={value.dataKey}></a-input>),
            },
            this.isCsv
                ? null
                : {
                    title: renderTitle('列宽', '输入展示的字符数目'),
                    dataIndex: 'colWidth',
                    customRender: (value) => (<a-input type="number" value={value.colWidth} onChange={(e) => this.$set(value, 'colWidth', Number(e.target.value))}></a-input>),
                },
            {
                title: '操作',
                isOperation: true,
                customRender: (value) => (<a-space>
            <a-button icon="delete" size="small" shape="circle" onClick={() => this.delField(value)}></a-button>
            <a-button class={this.draggableHandleClassName} title="拖动可以调整顺序" icon="swap" size="small" style="transform: rotate(90deg);cursor: move;transition:none;"></a-button>
          </a-space>),
            },
        ].filter(Boolean);
    }
    async selectDataPath(event) {
        const dataPath = await this.designer.openDataPathSelector({
            place: event.target,
            currentDataPath: '',
            filter: (schema) => ['array', 'object'].includes(schema.type || ''),
        });
        if (!dataPath)
            return;
        this.$emit('dataPathSelect', dataPath);
        const stub = this.designer.renderer.getStubAtPath(dataPath);
        if (!DataStub) {
            message.warn(`无法通过 [${dataPath}] 找到对应的DataStub`);
            return;
        }
        if (this.value.length) {
            const isRewrite = await isConfirm({ content: '是否覆盖已有字段结构' });
            if (!isRewrite)
                return;
        }
        const { schema } = stub;
        let fields = null;
        if (schema.type === 'object') {
            fields = schema.fields;
        }
        if (schema.type === 'array'
            && schema.items.type === 'object'
            && schema.items.fields) {
            fields = schema.items.fields;
        }
        if (!fields || fields.length <= 0) {
            message.error('找不到有效的字段');
            return;
        }
        const newValue = fields?.map((item) => {
            const id = String(item.id);
            const title = id + (item.title ? `(${item.title})` : '');
            return {
                colName: title,
                dataKey: id,
            };
        }) || [];
        this.$emit('change', newValue);
    }
    delField(target) {
        arrayRemove(this.value, target);
    }
    addField() {
        this.value.push({
            colName: '',
            dataKey: '',
        });
    }
    render() {
        return (<a-space direction="vertical" style="width:100%">
        <a-button onClick={this.selectDataPath}>选择导出的数据结构</a-button>
        {this.renderFlexTable()}
      </a-space>);
    }
    renderFlexTable() {
        const { value } = this;
        return (<div>
        <div class={styles.table}>
          <div class={[styles.row, styles.headerRow]}>
            {this.columns.map(item => (<span class={item?.isOperation ? styles.opCol : styles.col}>
                {item?.title}
              </span>))}
          </div>
          <Draggable v-model={this.value} handle={`.${this.draggableHandleClassName}`} ghostClass={styles.draggableHost} animation={200} onUpdate={() => this.$emit('change', this.value)} onStart={() => (this.drag = true)} onEnd={() => (this.drag = false)}>
            {value.map(dataItem => (<div class={styles.row}>
                {this.columns.map(item => (<span key={item?.dataIndex} class={item?.isOperation ? styles.opCol : styles.col}>
                    {item?.customRender(dataItem)}
                  </span>))}
              </div>))}
          </Draggable>
        </div>
        <a-button icon="plus" size="small" onClick={() => this.addField()} style="margin-top:4px;width:100%;"></a-button>
      </div>);
    }
};
__decorate([
    Prop()
], ExcelFieldConfigEdit.prototype, "value", void 0);
__decorate([
    Inject(INJECT_UICORE_DESIGNER)
], ExcelFieldConfigEdit.prototype, "designer", void 0);
__decorate([
    Prop({ default: false })
], ExcelFieldConfigEdit.prototype, "isCsv", void 0);
ExcelFieldConfigEdit = __decorate([
    Component({
        name: 'ExcelFieldConfigEdit',
        model: { prop: 'value', event: 'change' },
    })
], ExcelFieldConfigEdit);
export default ExcelFieldConfigEdit;
