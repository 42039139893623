<template>
  <div class="container">
    <a-radio-group v-model="currentTab">
      <a-radio-button value="headers">
        Headers
      </a-radio-button>
      <a-radio-button value="body">
        <a-tooltip>
          <template slot="title">
            body请用json格式书写（若用了插值表达式，插值表达式也需要用双引号包住）
          </template>
          Body
          <a-icon type="question-circle" />
        </a-tooltip>
      </a-radio-button>
      <a-radio-button value="query">
        Query
      </a-radio-button>
    </a-radio-group>
    <div class="tab-container">
      <div
        v-show="currentTab === 'headers'"
        class="headers-container"
      >
        <a-input-group
          v-for="(header, index) in editValue.headers"
          :key="index"
          class="header-item"
          compact
        >
          <a-auto-complete
            v-model="header.name"
            :data-source="headerNameList"
            style="width: 40%"
            placeholder="header name"
            :filter-option="filterOption"
            @change="updateEditValue"
          />
          <a-input
            v-model="header.content"
            style="width: 50%"
            placeholder="header content"
            @change="updateEditValue"
          />
          <a-icon
            class="dynamic-delete-button"
            type="minus-circle-o"
            @click="() => removeHeader(header)"
          />
        </a-input-group>
        <a-form-item>
          <a-button
            type="dashed"
            style="width: 100%"
            @click="addHeader"
          >
            <a-icon type="plus" /> 添加Header
          </a-button>
        </a-form-item>
      </div>
      <div
        v-show="currentTab === 'body'"
        class="body-container"
      >
        <public-w-json
          v-model="editValue.body"
          height="80px"
          @change="updateEditValue"
        />
      </div>
      <div v-show="currentTab === 'query'">
        <a-input-group
          v-for="(query, index) in editValue.querys"
          :key="index"
          class="header-item"
          compact
        >
          <a-input
            v-model="query.name"
            style="width: 40%"
            placeholder="query name"
            @change="updateEditValue"
          />
          <a-input
            v-model="query.content"
            style="width: 50%"
            placeholder="query value"
            @change="updateEditValue"
          />
          <a-icon
            class="dynamic-delete-button"
            type="minus-circle-o"
            @click="() => removeQuery(query)"
          />
        </a-input-group>
        <a-form-item>
          <a-button
            type="dashed"
            style="width: 100%"
            @click="addQuery"
          >
            <a-icon type="plus" /> 添加Query
          </a-button>
        </a-form-item>
      </div>
    </div>
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
const REQUEST_HEADRE_FIELDS = [
  'A-IM',
  'Accept',
  'Accept-Charset',
  'Accept-Datetime',
  'Accept-Encoding',
  'Accept-Language',
  'Access-Control-Request-Method',
  'Access-Control-Request-Headers',
  'Authorization',
  'Cache-Control',
  'Connection',
  'Content-Encoding',
  'Content-Length',
  'Content-MD5',
  'Content-Type',
  'Cookie',
  'Date',
  'Expect',
  'Forwarded',
  'From',
  'Host',
  'If-Match',
  'If-Modified-Since',
  'If-None-Match',
  'If-Range',
  'If-Unmodified-Since',
  'Max-Forwards',
  'Origin',
  'Pragma',
  'Prefer',
  'Proxy-Authorization',
  'Range',
  'Referer',
  'TE',
  'Trailer',
  'Transfer-Encoding',
  'User-Agent',
  'Upgrade',
  'Via',
  'Warning',
];
export default {
  name: 'XyHttpRequestConfig',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      currentTab: 'headers',
      headerNameList: REQUEST_HEADRE_FIELDS.map(item => ({
        text: item,
        value: item,
      })),
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    addHeader() {
      this.editValue.headers = [...this.editValue.headers,
        {
          name: '',
          content: '',
        },
      ];
      this.updateEditValue();
    },
    removeHeader(header) {
      this.editValue.headers = this.editValue.headers.filter(item => item !== header);
      this.updateEditValue();
    },
    addQuery() {
      this.editValue.querys.push({
        name: '',
        content: '',
      });
      this.updateEditValue();
    },
    removeQuery(query) {
      this.editValue.querys = this.editValue.querys.filter(item => item !== query);
      this.updateEditValue();
    },
    updateEditValue() {
      // 因为editValue是浅对比，这里改下指向，才能保存时触发editValue更新
      this.editValue = { ...this.editValue };
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  margin-top: 8px;
}
.tab-container {
  margin-top: 16px;
}
.header-item {
  margin-bottom: 8px;
}
.dynamic-delete-button {
  margin-left: 8px;
  height: 32px;
  line-height: 32px;
}
</style>
