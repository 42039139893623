<template>
  <div style="width: 100%">
    <a
      @click="toggleEnvConfig"
    >
      展开 <a-icon :type="visible ? 'up' : 'down'" />
    </a>
    <a-table
      v-show="visible"
      size="small"
      :pagination="false"
      style="background: #fff; margin-top: 4px"
      :columns="columns"
      :data-source="envList"
    >
      <template
        slot="cos"
        slot-scope="text, record"
      >
        <div class="editable-row-operations">
          <a-select
            style="width: 100%"
            :value="editValue[record.id]"
            @change="(cosId) => handleChange(cosId, record.id)"
          >
            <a-select-option
              v-for="cos in cosList"
              :key="cos.cosId"
              :value="cos.cosId"
            >
              {{ cos.name }}
            </a-select-option>
          </a-select>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { message } from 'ant-design-vue';

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: '环境',
    width: '100px',
  },
  {
    title: 'COS桶',
    key: 'cos',
    scopedSlots: { customRender: 'cos' },
  },
];
export default {
  name: 'XyCosEnvSelector',
  mixins: [vueComponentMixin],
  inject: ['wContext'],
  data() {
    return {
      visible: false,
      columns,
      envList: [],
      cosList: [],
    };
  },
  mounted() {
    this.fetchEnvList();
    this.fetchCosList();
  },
  methods: {
    toggleEnvConfig() {
      this.visible = !this.visible;
    },
    handleChange(cosId, env) {
      this.editValue = { ...this.editValue, [env]: cosId };
    },
    async fetchEnvList() {
      try {
        const { projectId } = this.wContext.config;
        const { data } = await this.$fetch(`/api/xy/basic/env?projectid=${projectId}`);
        this.envList = [
          { id: 'dev', name: '开发环境' },
          ...data,
        ];
      } catch (err) {
        message.error('获取环境失败');
      }
    },

    async fetchCosList() {
      try {
        const { projectId } = this.wContext.config;
        const { data } = await this.$fetch(`/api/xy/basic/cos?projectid=${projectId}`);
        this.cosList = Object.keys(data).map(cos => ({
          name: cos,
          ...data[cos],
        }));
      } catch (err) {
        message.error('获取cos桶失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
