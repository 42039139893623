// @ts-ignore
import wujiComp from '@tencent/xy-compsloader';
import {
  registerComp,
  wrapComponent,
  getUrlParamsDisabledComponent,
  disabledComponents,
} from './register';
import { compsLoaderLogger as logger, logInstalledComponent } from './logger';
import { fetchCompDBInfo } from './requestor';
import { hmrStart } from './hmr';

import { isPreviewMode } from '@/composables/pagePreview/use-preview';
import { DEFAULT_UI_LIBRARY } from '@/consts/component-hub-related';

export const getCompsList = async ({
  compsKey = null,
  editor = false,
  register = false,
  dependencies = null,
  componentHubQueryObject = {},
  forceRefresh = false,
  forceLoadComponents = [],
  lazyLoadComponents = [],
  lazyLoadRestOfAllComponents = false,
  library = DEFAULT_UI_LIBRARY,
}) => {
  const preview = isPreviewMode();
  /** webpackWhiteListForDev */
  fetchCompDBInfo(componentHubQueryObject, forceRefresh);
  hmrStart();
  const opts = { editor, forceLoadComponents, lazyLoadComponents, lazyLoadRestOfAllComponents, library };

  // 预览模式下需要全量加载组件
  const compsMap = await wujiComp.getCompsByCompKeys(preview ? '' : compsKey, opts, dependencies);

  logInstalledComponent(compsMap);

  if (register) {
    const startTime = +new Date();

    // 可以通过url阻止组件的实例化
    getUrlParamsDisabledComponent();

    Object
      .keys(compsMap)
      .forEach((compKey) => {
        /**
         * 将组件包裹一层，如果是普通的组件，不会做特殊处理
         * 如果是异步组件，会给他添加一个loading和error状态组件
         */
        compsMap[compKey].comp = wrapComponent(compsMap[compKey].comp);
        registerComp(compKey, compsMap[compKey].comp);
      });

    logger({
      functionName: 'getCompsList',
      content: `register-time.first ${+new Date() - startTime}`,
    });

    if (compsKey) {
      // 加载组件所依赖的组件
      const registeredCompKeys = Object.keys(compsMap);
      // 组件加载器已安装的组件
      const installedModulesMap = window.wujiComp?.moduleManage?.installedModules;
      // 安装依赖
      const installedDeps = await installDependencies(installedModulesMap, registeredCompKeys, editor);
      Object.keys(installedDeps).forEach((compKey) => {
        compsMap[compKey] = installedDeps[compKey];
      });

      logger({
        functionName: 'getCompsList',
        content: `register-time.second ${+new Date() - startTime}`,
      });
    }
  };

  // 阻止注册不够，还需要阻止组件实例传给uicore
  disabledComponents.forEach((key) => {
    delete compsMap[key];
  });

  return compsMap;
};

/**
 * 安装组件依赖
 * @param {*} installedModulesMap 目前已经安装了的组件
 * @param {*} registeredCompKeys 被注册的组件
 * @param {*} editor 是否是编辑时
 */
async function installDependencies(installedModulesMap, registeredCompKeys, editor) {
  const uninstalledDeps = getUninstalledDeps(installedModulesMap, registeredCompKeys);
  // 收集在安装依赖的过程中安装的组件
  const installedComponents = {};
  if (uninstalledDeps.size > 0) {
    const depsCompMap = await wujiComp.getCompsByCompKeys(Array.from(uninstalledDeps), { editor });
    Object.keys(depsCompMap).forEach((compKey) => {
      const compInfo = depsCompMap[compKey];
      if (compInfo?.comp) {
        registerComp(compKey, compInfo.comp);
        if (!installedComponents[compKey] && compInfo?.config) {
          // 将这个安装的依赖写入到最终的组件信息Map中
          installedComponents[compKey] = compInfo;
        }
      }
    });
  }
  return installedComponents;
}

/**
 * 获取没有安装的依赖列表
 * @param {*} installedModulesMap 目前已经安装了的组件
 * @param {*} registeredCompKeys 被注册的组件
 * @returns
 */
function getUninstalledDeps(installedModulesMap, registeredCompKeys) {
  const uninstalledCompKeys = new Set();

  if (installedModulesMap) {
    const DFS = (key) => {
      const dependencies = installedModulesMap[key]?.compDependencies;
      if (dependencies && dependencies.length > 0) {
        dependencies.forEach((dep) => {
          if (!registeredCompKeys.includes(dep)) {
            uninstalledCompKeys.add(dep);
            DFS(dep);
          }
        });
      }
    };

    registeredCompKeys.forEach((key) => {
      DFS(key);
    });
  }
  return uninstalledCompKeys;
}
