import { message } from 'ant-design-vue';
import wujiFetch from '@utils/wujiFetch';
import { BASE_XY_API_PATH } from '@config/constant';
import GroupAuth from './auth';
import qs from 'query-string';
import { isInBeaconAndHasSpace, getSpaceIdFromQuery } from '@/utils/beacon';
import { listUserGroup, USER_GROUP_API } from '@/utils/groupInfo';
const GROUP_API = `${BASE_XY_API_PATH}/group`;
const GROUPLIST_GROUP_API = `${BASE_XY_API_PATH}/user/projectsByGroup`;
export default {
    modules: {
        auth: GroupAuth,
    },
    state: {
        groupList: [],
        groupInfo: null,
        projectList: [],
        total: 0,
        groupListWithProject: [],
        groupConfig: {},
    },
    actions: {
        async createProjectGroup({}, payload) {
            const postData = { ...payload };
            if (isInBeaconAndHasSpace()) {
                postData.spaceId = await getSpaceIdFromQuery();
            }
            return await wujiFetch(GROUP_API, {
                method: 'POST',
                body: JSON.stringify(postData),
            });
        },
        async fetchGroupListWithProject({ commit }, payload) {
            const queryData = { ...payload };
            if (isInBeaconAndHasSpace()) {
                queryData.spaceId = await getSpaceIdFromQuery();
            }
            const data = await wujiFetch(`${GROUPLIST_GROUP_API}?${qs.stringify(queryData)}`);
            commit('updateProjectGroup', {
                groupListWithProject: data,
            });
            return data;
        },
        async fetchProjectGroupList({ commit }) {
            const data = await listUserGroup();
            commit('updateProjectGroup', {
                groupList: data,
            });
            return data;
        },
        async fetchProjectGroupInfo({ commit }, { groupId }) {
            const data = await wujiFetch(`${GROUP_API}/${groupId}`, {
                method: 'GET',
            });
            commit('updateProjectGroup', {
                groupInfo: data,
            });
            return data;
        },
        async updateProjectGroupInfo({}, { groupId, data }) {
            const res = await wujiFetch(`${GROUP_API}/${groupId}`, {
                method: 'PATCH',
                body: JSON.stringify(data),
            });
            return res;
        },
        async fetchProjectGroupConfig({ commit }, { groupId, keys }) {
            const data = await wujiFetch(`${GROUP_API}/${groupId}/kvCfg?includes=${keys.join(',')}`);
            const formatGroupConfig = (data) => {
                const groupConfig = {};
                const decodeKeys = ['headScript', 'bodyScript'];
                Object.keys(data).forEach((key) => {
                    if (decodeKeys.includes(key)) {
                        groupConfig[key] = decodeURIComponent(data[key]);
                    }
                    else {
                        groupConfig[key] = data[key];
                    }
                });
                return groupConfig;
            };
            const groupConfig = formatGroupConfig(data);
            commit('updateProjectGroup', {
                groupConfig,
            });
            return groupConfig;
        },
        async updateProjectGroupConfig({}, { groupId, kv }) {
            const updatePromise = Object.keys(kv).map(key => wujiFetch(`${GROUP_API}/${groupId}/kvCfg/${key}`, {
                method: 'POST',
                body: JSON.stringify({
                    value: kv[key],
                }),
            }));
            await Promise.all(updatePromise);
        },
        async fetchProjectGroupGlobalScript({ commit, state }, { groupId, projectId }) {
            const data = await wujiFetch(`${GROUP_API}/${groupId}/globalScript?projectId=${projectId}`);
            commit('updateProjectGroup', {
                groupConfig: {
                    ...state.groupConfig,
                    ...{
                        ...data,
                        headScript: decodeURIComponent(data.headScript),
                        bodyScript: decodeURIComponent(data.bodyScript),
                    },
                },
            });
            return data;
        },
        async archiveProjectGroup({}, { groupId }) {
            const res = await wujiFetch(`${GROUP_API}/${groupId}`, {
                method: 'delete',
            });
            return res;
        },
        async fetchGroupProjectList({ commit }, { groupId, filter = '', size = 20, page, }) {
            try {
                const params = new URLSearchParams();
                Object.entries({
                    filter,
                    size,
                    page,
                }).forEach(([key, value]) => {
                    params.set(key, `${value}`);
                });
                const projects = await wujiFetch(`${USER_GROUP_API}/${groupId}/project?${params.toString()}`, {});
                commit('updateProjectGroup', {
                    projectList: projects,
                });
                if (size && page === 1) {
                    const { total } = await wujiFetch(`${USER_GROUP_API}/${groupId}/project?${params.toString()}&count=1`);
                    commit('updateProjectGroup', {
                        total,
                    });
                }
            }
            catch (err) {
                message.error('获取应用组的应用列表失败');
                console.error('err', err);
            }
        },
    },
    mutations: {
        updateProjectGroup(state, payload) {
            Object.assign(state, payload);
        },
    },
};
