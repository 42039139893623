<template>
  <div
    ref="refModalContainer"
    :class="`xy-${renderType}-container`"
  >
    <!-- 弹窗标题 -->
    <div
      v-if="isShowTitle"
      class="xy-modal-container-title"
    >
      <slot
        v-if="isTitleSlot"
        name="title"
      />
      <div
        v-else
        class="xy-modal-container-title-text"
      >
        {{ title }}
      </div>
    </div>
    <!-- 弹窗内容 -->
    <div
      class="xy-modal-container-content"
    >
      <slot />
    </div>
    <!-- 弹窗页脚 -->
    <div
      v-if="withFooter"
      class="xy-modal-container-footer"
    >
      <!-- [兼容] withDefaultFooter 字段  -->
      <div
        v-if="withDefaultFooter"
        class="xy-modal-container-footer-default"
      >
        <a-button
          key="cancel"
          @click="handleCancel"
        >
          取消
        </a-button>
        <a-button
          key="confirm"
          type="primary"
          @click="handleOk"
        >
          确定
        </a-button>
      </div>
      <slot
        v-else
        name="footer"
      />
    </div>
  </div>
</template>
<script>
import { inject, ref, computed, toRefs } from '@vue/composition-api';
import SchemaDefault from './xy-modal-schema-default.json';
import { INJECT_MODAL_INSTANCE } from './const';
import { useWatermark } from '@components/watermark/useWatermark';
import { usePageletEvent } from '@composables/usePageletEvent';
import { useMobile } from '@/composables/useAdaptation';

export default {
  name: 'XyModalContainer',
  props: {
    withTitle: {
      type: Boolean,
      default: SchemaDefault.withTitle,
    },
    isTitleSlot: {
      type: Boolean,
      default: SchemaDefault.isTitleSlot,
    },
    type: {
      type: String,
      default: SchemaDefault.type,
    },
    drawerPlacement: {
      type: String,
      default: SchemaDefault.drawerPlacement,
    },
    title: {
      type: String,
      default: SchemaDefault.title,
    },
    width: {
      type: [String, Number],
      default: SchemaDefault.width,
    },
    height: {
      type: [String, Number],
      default: SchemaDefault.height,
    },
    keyboard: {
      type: Boolean,
      default: SchemaDefault.keyboard,
    },
    mask: {
      type: Boolean,
      default: SchemaDefault.mask,
    },
    maskClosable: {
      type: Boolean,
      default: SchemaDefault.maskClosable,
    },
    withClosableIcon: {
      type: Boolean,
      default: SchemaDefault.withClosableIcon,
    },
    withFooter: {
      type: Boolean,
      default: SchemaDefault.withFooter,
    },
    withDefaultFooter: {
      type: Boolean,
      default: SchemaDefault.withDefaultFooter,
    },
    // ui-core: 事件流的打开事件
    handleOpenEvent: {
      type: Function,
      default: null,
    },
    // ui-core: 事件流的关闭事件
    handleCloseEvent: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    usePageletEvent({ emit });

    const renderer = inject('uicore:renderer', null);
    const wContext = inject('wContext', null);
    const w = inject('w', null);
    const modalInstance = inject(INJECT_MODAL_INSTANCE, null);

    const { type } = toRefs(props);
    const { isMobile } = useMobile();

    const renderType = computed(() => {
      if (type.value === 'adaptation') {
        return isMobile.value ? 'drawer' : 'modal';
      }
      return type.value;
    });

    const { projectInfo: project, pageInfo: page } = w;
    const { rendererPageletWatermark } = useWatermark({ project: ref(project), page: ref(page) });

    return {
      renderType,
      renderer: ref(renderer),
      wContext: ref(wContext),
      w: ref(w),
      modalInstance: ref(modalInstance),
      rendererPageletWatermark,
    };
  },
  computed: {
    // 是否显示标题
    isShowTitle() {
      return this.withTitle && (this.isTitleSlot || this.title);
    },
  },
  created() {
    this.$watch(
      () => ({
        drawerPlacement: this.drawerPlacement,
        height: this.height,
        width: this.width,
        keyboard: this.keyboard,
        maskClosable: this.maskClosable,
        mask: this.mask,
        withClosableIcon: this.withClosableIcon,
      }),
      (props) => {
        this?.modalInstance?.setModalProps(props);
      },
      { immediate: true },
    );
  },
  mounted() {
    // 渲染水印
    if (['runtime', 'preview'].includes(this.w?.mode)) {
      this.rendererPageletWatermark({
        id: `wuji-modal-${this.wContext.id}-watermark`,
        container: this.$refs.refModalContainer,
        renderer: this.renderer,
      });
    }
  },
  methods: {
    handleOk() {
      this?.wContext?.hideModal(null, null, { event: 'ok' });
    },
    handleCancel() {
      this?.wContext?.hideModal(null, null, { event: 'cancel' });
    },
  },
};
</script>

<style lang="scss">
.xy-modal-container,
.xy-drawer-container {
  /* 弹窗相关变量 */
  --wuji-modal-vertical-gap: 24px;
  --wuji-modal-header-padding: 16px 24px;
  --wuji-modal-header-border-bottom: 1px solid #e8e8e8;
  --wuji-modal-body-padding: 24px;
  --wuji-modal-footer-padding: 10px 16px;
  --wuji-modal-footer-border-top: 1px solid #e8e8e8;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .xy-modal-container-title {
    padding: var(--wuji-modal-header-padding);
    border-bottom: var(--wuji-modal-header-border-bottom);
    flex-shrink: 0;
    .xy-modal-container-title-text {
      margin: 0;
      color: #161616;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      word-wrap: break-word;
    }
  }

  .xy-modal-container-content {
    padding: var(--wuji-modal-body-padding);
    flex: 1;
    overflow: auto;
  }

  .xy-modal-container-footer {
    padding: var(--wuji-modal-footer-padding);
    border-top: var(--wuji-modal-footer-border-top);
    flex-shrink: 0;
    .xy-modal-container-footer-default {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-btn:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.xy-modal-container {
  max-height: calc(100vh - var(--wuji-modal-vertical-gap));
}

.xy-drawer-container {
  height: 100%;
}
</style>
