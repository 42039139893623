<template>
  <div :class="`nav-switch ${theme}`">
    <a-popover
      v-if="visible"
      v-model="show"
      :overlay-class-name="`nav-switch-popover-${theme}`"
      trigger="click"
    >
      <template slot="content">
        <div :style="{width}">
          <a-input-search
            v-if="showSearch"
            v-model="q"
            placeholder="搜索"
            style="margin-bottom:4px"
            :enter-button="!!config.searchKey"
            :loading="refreshing"
            @search="onSearch"
          />
          <p
            v-for="option in filterOptions.slice(0, page * size)"
            :key="option.value"
            :class="['nav-switch_options', {'nav-switch_options-active': selected === option.value}]"
            @click="change(option.value)"
          >
            {{ option.label }}
          </p>
          <div
            v-if="page * size < filterOptions.length"
            class="nav-switch_options"
            style="color:var(--wuji-primary-color)"
            @click="page += 1"
          >
            点击展开更多
          </div>
          <a-list
            v-if="!options.length"
            :data-source="[]"
          />
        </div>
      </template>
      <span
        v-if="options && options.length"
        class="nav-switch_button"
      >
        <span v-if="optionsMap[selected]">{{ optionsMap[selected] }}</span>
        <span
          v-else
          style="color:var(--wuji-primary-color);font-size:small"
        >{{ selected }}</span>
        <a-icon
          type="down"
          :class="['nav-switch_icon', {'nav-switch_icon-active': show}]"
        />
      </span>
      <span
        v-else-if="refreshing"
        style="margin:0 4px;font-size:small;color:var(--wuji-primary-color)"
      ><a-spin size="small" /></span>
    </a-popover>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { getRouterPublicParams } from '@utils/routerPublicParams';
import { getAppParams } from '@utils/path';
import { getTopSwitchNamespace, getTopSwitchStorage, isSwitchVisible } from '@utils/common';
import { executeSwitchHook } from '@utils/executeSwitchHook';
import { fetchSwitchOptions } from './utils';

export default {
  name: 'TopSwitch',
  inject: {
    ctx: { from: 'ctx', default: null },
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      q: '',
      page: 1,
      size: 20,
      selected: '',
      options: [],
      width: this.config.width,
      tricky: 0,
      show: false,
      refreshing: false,
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    env() {
      return this.urlParams.env;
    },
    visible() {
      // reference it for re-compute
      this.tricky;
      if (!this.isRuntimeMode) return false;
      return isSwitchVisible(this.config);
    },
    namespace() {
      return getTopSwitchNamespace(this.projectId);
    },
    isRuntimeMode() {
      return this.ctx?.mode === 'runtime';
    },
    optionsMap() {
      const map = {};
      this.options.forEach((item) => {
        map[item.value] = item.label;
      });
      return map;
    },
    showSearch() {
      return (this.options?.length ?? 0) > this.size || this.config.searchKey;
    },
    filterOptions() {
      let options = [];
      if (!this.q || this.config.searchKey) {
        options = this.options;
      } else {
        options = this.options.filter(o => o.label.toLowerCase().includes(this.q.toLowerCase()));
      }
      return options;
    },
  },
  watch: {
    $route(newRoute) {
      const { query } = newRoute;
      const value = query[this.config.queryKey];
      this.selected = value;
      // force to re-compute
      this.tricky += 1;
    },
    visible() {
      this.init();
    },
    config() {
      this.init();
    },
  },
  created() {
    this.init();
    if (!this.isRuntimeMode) return;
    const topSwitch = this.ctx.topSwitch || {};
    topSwitch[this.config.queryKey] = this.refreshOptions.bind(this);
    this.ctx.topSwitch = topSwitch;
    this.ctx.refreshTopSwitch = (key) => {
      if (!topSwitch[key]) {
        message.error('invalid top switch key to refresh');
        return;
      }
      topSwitch[key]();
    };
  },
  mounted() {
    // 选中动态搜索得到的值不一定在列表中
    if (this.config.searchKey) {
      setTimeout(() => {
        const { query } = this.$route;
        const id = query[this.config.queryKey];
        if (id && !this.options.find(o => String(o.value) === id)) {
          this.q = id;
          this.refreshOptions();
        }
      }, 1000);
    }
  },
  methods: {
    async init() {
      if (!this.visible) return;
      if (!this.isRuntimeMode) return;
      await this.refreshOptions();
      this.initQueryKey();
      this.$store.commit('runtime/setTopSwitch', {
        [this.config.queryKey]: this.options,
      });
    },
    onSearch() {
      this.refreshOptions();
    },
    async refreshOptions() {
      this.page = 1;
      this.refreshing = true;
      console.time(`refresh top switch of ${this.config.queryKey}`);
      this.options = await fetchSwitchOptions(
        this.config,
        { projectId: this.projectId, env: this.env, ctx: this.ctx, q: this.q },
      );
      console.timeEnd(`refresh top switch of ${this.config.queryKey}`);
      // 写入上下文
      const topSwitchResult = this.ctx.topSwitchResult || {};
      topSwitchResult[this.config.queryKey] = this.options;
      this.ctx.topSwitchResult = topSwitchResult;
      this.refreshing = false;
      return this.options;
    },
    isValidValue(value) {
      // 动态搜索的直接当作有效
      return this.config.searchKey || this.options.some(o => o.value === value);
    },
    initQueryKey() {
      if (!this.isRuntimeMode) return;
      const { query } = this.$route;
      const { queryKey } = this.config;
      // 如果值不在 query 上，则需要重置路由
      let needRedirect = false;
      const initKeyMap = this.$router.TOP_SWITCHING_MAP || {};

      if (query[queryKey] && this.isValidValue(query[queryKey])) {
        this.selected = query[queryKey];
      } else {
        const queryValue = this.config.disabledLocalStorage ? '' : getTopSwitchStorage(this.projectId, queryKey);
        // localstorage(用户曾选中) > 默认值 > 选项第一个值
        this.selected = queryValue || this.config.defaultValue || this.options[0]?.value;
        initKeyMap[queryKey] = this.selected;
        this.$router.TOP_SWITCHING_MAP = initKeyMap;
        needRedirect = true;
      }

      // 如果值不在选项列表中，则使用第一个选项值
      if (!this.isValidValue(this.selected)) {
        this.selected = this.options[0]?.value;
        initKeyMap[queryKey] = this.selected;
      }

      // Hack: 让 beforeRouteUpdate 守卫函数不刷新 UcRenderer
      const params = {
        block_reload_uc_renderer: true,
      };

      if (this.visible) {
        if (needRedirect) {
          this.$router
            .replace({
              query: { ...query, ...initKeyMap, ...getRouterPublicParams(query) },
              params,
            });
        }
      } else {
        delete query[queryKey];
        this.$router
          .replace({
            query,
            params,
          });
      }
    },
    storeLocal(selected) {
      if (this.config.disabledLocalStorage) return;
      const local = getTopSwitchStorage(this.projectId);
      localStorage.setItem(this.namespace, JSON.stringify({ ...local, [this.config.queryKey]: selected }));
    },
    change(selected) {
      this.show = false;
      this.storeLocal(selected);
      this.$emit('topSwitch:change', { [this.config.queryKey]: selected });
      // 动画更自然
      setTimeout(async () => {
        const { path, query } = this.$route;
        const { hook, queryKey } = this.config;
        let target = null;
        let targetUrl = '';
        const { url } = query;
        if (url) {
          const urlObject = new URL(url);
          urlObject.searchParams.set(queryKey, selected);
          targetUrl = urlObject.href;
        }
        if (hook) {
          target = await executeSwitchHook(
            hook,
            { ctx: this.ctx, switchingKey: queryKey, from: query[queryKey], to: selected },
          );
        }
        if (!target) {
          target = {
            path,
            query: { ...query, [queryKey]: selected, ...(targetUrl ? { url: targetUrl } : {}) },
          };
        }

        this.$router.TOP_SWITCHING = true;
        this.$router.push(target);
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-switch {
  margin-right: 32px;
  &_button {
    color: var(--wuji-text-color);
    border-radius: 14px;
    padding: 5px 10px;
    cursor: pointer;
  }
  &_button:hover,
  &_button.ant-popover-open {
    color: var(--wuji-primary-color);
  }
  &_icon {
    font-size: 12px;
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &-active {
      transform: rotate(180deg);
    }
  }
  &_options {
    padding: 10px 12px;
    color: var(--wuji-text-color);
    margin: 0;
    cursor: pointer;
    &:hover,&-active {
      background: var(--wuji-primary-bg-color);
    }
    &:active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.dark.nav-switch .nav-switch {
  &_button {
    color: var(--wuji-text-color-dark);
  }
  &_button:hover,
  &_button.ant-popover-open {
    color: var(--wuji-primary-color);
  }
}

</style>

<style lang="scss">
.nav-switch-popover-light .ant-popover-inner-content ,
.nav-switch-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
  max-height: 410px;
  overflow: auto;
}

.nav-switch-popover-dark .ant-popover-inner-content {
  background: var(--wuji-primary-bg-color-dark);
  .nav-switch_options {
    color: var(--wuji-text-color-dark);
    &:hover,&-active {
      color: var(--wuji-text-color-dark);
      background: rgba(255, 255, 255, 0.08);
    }
    &:active {
      background: rgba(255, 255, 255, 0.04);
    }
  }
}

.nav-switch-popover-dark.ant-popover-placement-bottom {
  > .ant-popover-content > .ant-popover-arrow {
    border-top-color: var(--wuji-primary-bg-color-dark);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--wuji-primary-bg-color-dark);
  }
}

.nav-switch-popover-dark.ant-popover-placement-top {
  > .ant-popover-content > .ant-popover-arrow {
    border-right-color: var(--wuji-primary-bg-color-dark);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: var(--wuji-primary-bg-color-dark);
  }
}
</style>
