var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import CRenderer from '@tencent/ui-core/lib/renderer/CRenderer';
import { generateDataSchemaFromValues } from '@tencent/ui-core/lib/utils/dataSchema/generateDataSchemaFromValues';
import { walkLayout } from '@tencent/ui-core/lib/utils/index';
import UICoreXiaoyaoPlugin from '@components/uicorePlugin/UICoreXiaoyaoPlugin';
import logger from '@utils/logger';
import Modal from '../modal/Modal.vue';
import styles from './styles.module.scss';
import { TAG_NAME, RENDERER_TAG_NAME, getPropId, SEPARATOR } from './utils';
import { PageletContext } from '@utils/global/api';
import { isRuntime } from '@/utils/globalInfo';
const XyPageletComponentBase = Vue.extend({
    props: {
        pagelets: {
            type: Object,
            default: () => ([]),
        },
        slots: {
            type: Array,
            default: () => ([]),
        },
        compProp: {
            type: Object,
            default: () => ({}),
        },
    },
});
const stateTag = ['props', 'state'];
const COMPUTED_VALUE = 'componentComputed';
let XyPageletComponent = class XyPageletComponent extends XyPageletComponentBase {
    currentPagelets = [];
    modalController = {};
    paramsKeys = [];
    // uicorePlugin实例，每个页面的数据源会挂载在它上面
    pluginInstanceMap = {};
    // 每个页面的非绑定值
    computedValueMap = {};
    localW = new PageletContext({ mode: isRuntime() ? 'runtime' : 'editor', pageletContainer: this });
    // 记录每个页面当前的替换关系，
    // 比如state.a被替换为parentScope.state.b
    // 若state.a被替换为componentComputed.a
    replaceTargetMap = {};
    // 弹窗页面
    get modalPagelets() {
        return this.currentPagelets.filter(pagelet => pagelet.xyType === 'modal');
    }
    // 主页面
    get defaultPagelet() {
        return this.currentPagelets[0];
    }
    get uiCoreInstance() {
        return this.ucRenderer ?? this.ucDesigner;
    }
    get dataSourceMap() {
        const map = {};
        Object.entries(this.pluginInstanceMap).forEach(([key, plugin]) => {
            map[key] = {
                data: plugin.getRootData(),
                dataSchema: plugin.getRootDataSchema(),
            };
        });
        return map;
    }
    get rootWContext() {
        let rootContext = this.wContext;
        while (!['page', 'pagelet'].includes(rootContext.type)) {
            rootContext = rootContext.parentWContext;
        }
        return rootContext;
    }
    get defaultPageUcDataSource() {
        return this.getUcDataSourcesFromProps(this.defaultPagelet.id);
    }
    onDataSourceMapChange() {
        this.declareDataSourceInParentRenderer();
    }
    getBindings() {
        return this.$vnode.data?.['uc:layout']?.bindings;
    }
    created() {
        this.pageletPropSchemas.forEach((group) => {
            group.fields.forEach((field) => {
                this.paramsKeys.push(getPropId(field));
            });
        });
        this.paramsKeys.forEach((key) => {
            this.$watch(() => this.$props[key], val => this.handlePropChange(key, val));
        });
        this.currentPagelets = cloneDeep(this.pagelets);
        this.initLayout();
        this.initModalController();
    }
    mounted() {
        this.initSlot();
        this.declareDataSourceInParentRenderer();
    }
    beforeMount() {
        this.uiCoreInstance.uc.hooks.pageletRendererBeforeCreate.tap('pageletComponentPatch', (cRenderer) => {
            const pageId = cRenderer.$attrs['pagelet-component-pageId'];
            const uid = cRenderer.$attrs['pagelet-component-uid'];
            if (pageId && uid === this._uid) {
                const pluginInstance = this.initPluginInstanceForPagelet(pageId);
                pluginInstance.patchCRenderer(cRenderer, pluginInstance);
            }
        });
    }
    beforeDestroy() {
        this.$delete(this.ucRenderer.ucDataSources, `componentDataSource-${String(this.$vnode.key)}`);
        this.ucRenderer.ucRecomposeFullDataSchema();
    }
    handlePropChange(key, val) {
        const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
        if (stateTag.includes(tag)) {
            const bindingMap = this.getBindings();
            if (bindingMap?.[key]) {
                this.handleBindingChange(key, bindingMap?.[key]);
                return;
            }
            const currentPageMap = this.computedValueMap[pageletId] || {};
            this.$set(currentPageMap, key, val);
            this.$set(this.computedValueMap, pageletId, currentPageMap);
            const newProp = `${COMPUTED_VALUE}.${key}`;
            const oldProp = this.replaceTargetMap[key] || `${tag}.${paramsKey}`;
            const replaceOption = {
                newProp,
                oldProp,
                id: key,
            };
            this.replaceSingleVar(replaceOption, pageletId);
        }
        else {
            this.handleDataSourceChange(pageletId, paramsKey, val);
        }
    }
    handleBindingChange(key, val) {
        const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
        const oldProp = this.replaceTargetMap[key] || `${tag}.${paramsKey}`;
        const newProp = `parentScope.${val}`;
        const replaceOption = {
            oldProp,
            newProp,
            id: key,
        };
        this.replaceSingleVar(replaceOption, pageletId);
    }
    replaceSingleVar(replaceOption, pageletId) {
        const { newProp, oldProp } = replaceOption;
        if (newProp === oldProp)
            return;
        const pageletIndex = this.currentPagelets.findIndex(pagelet => pagelet.id === pageletId);
        const replacedPageletStr = this.replaceStateOrProps(replaceOption, JSON.stringify(this.currentPagelets[pageletIndex]));
        this.currentPagelets[pageletIndex] = JSON.parse(replacedPageletStr);
    }
    handleDataSourceChange(pageletId, sourceId, collectionId) {
        const pagelet = this.currentPagelets.find(pagelet => pagelet.id === pageletId);
        if (!pagelet)
            throw Error(`未知的页面${pageletId}`);
        this.replaceDataSource({ sourceId, collectionId }, pagelet);
        this.resetPluginDataSource(pageletId);
    }
    initPluginInstanceForPagelet(pageId, force = false) {
        // 弹窗的数据源需要在每次打开的时候重新初始化
        if (this.pluginInstanceMap[pageId] && !force)
            return this.pluginInstanceMap[pageId];
        const pluginInstance = new UICoreXiaoyaoPlugin({
            parent: this,
        });
        // 如果组件没有给当前页面某个数据源赋值，那么我们不要初始化这个页面的数据源
        // 不然会在初始化的过程中弹窗
        this.$set(this.pluginInstanceMap, pageId, pluginInstance);
        return pluginInstance;
    }
    declareDataSourceInParentRenderer() {
        const source = {
            id: `componentDataSource-${String(this.$vnode.key)}`,
            schema: {
                title: `组件内参数 - ${String(this.$vnode.key)}`,
                fields: [],
                type: 'object',
            },
            instance: {},
        };
        const fields = [];
        const instance = {};
        // 只展示主页面的参数
        // 因为副页面在编辑器里没有实例化，拿不到数据源实例
        const pageSources = this.defaultPageUcDataSource;
        pageSources.forEach((source) => {
            const { id, schema, instance: pageInstance } = source;
            fields.push({
                id,
                ...schema,
            });
            instance[id] = pageInstance;
        });
        source.schema.fields = fields;
        source.instance = instance;
        this.ucRenderer?.addDataSource(source);
    }
    // 刷新页面数据源
    resetPluginDataSource(pageletId) {
        if (!this.pluginInstanceMap[pageletId])
            return;
        this.pluginInstanceMap[pageletId].initDataSources(this.$refs[`${pageletId}-renderer`]);
    }
    initModalController() {
        // 初始化弹窗控制器
        this.modalPagelets.forEach((page) => {
            const modalId = this.getModalId(page.id);
            this.$set(this.modalController, modalId, false);
            this.$set(this.rootWContext.childrenData || {}, modalId, { visible: false, data: {}, params: [] });
        });
        this.currentPagelets.forEach((page) => {
            // 将对应的modalID替换成这个在线组件专属的id
            walkLayout(page.layout, (item) => {
                if (item.layout.events) {
                    Object.entries(item.layout?.events).forEach(([, value]) => {
                        if (value.steps) {
                            value.steps.forEach((step) => {
                                if (step.type === 'xy:showModal') {
                                    step.params.modalId = this.getModalId(step.params.modalId);
                                }
                            });
                        }
                    });
                }
            }, { includeScopedSlots: true });
        });
    }
    // 将弹窗的id包一层在线组件的标记
    getModalId(id) {
        return `pagelet-component-${this._uid}-${id}`;
    }
    initLayout() {
        const replaceTargetMap = this.getReplaceTargetMap();
        this.currentPagelets = this.replacePagelet(replaceTargetMap);
    }
    getReplaceTargetMap() {
        const replaceTargetMap = {};
        const bindings = this.getBindings();
        this.paramsKeys.forEach((key) => {
            const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
            const replaceType = bindings?.[key] ? 'binding' : 'computed';
            const oldProp = this.replaceTargetMap[key] || `${tag}.${paramsKey}`;
            let newProp = this.$props[key];
            if (replaceType === 'computed' && tag !== 'data') {
                const currentPageComputed = this.computedValueMap[pageletId] || {};
                this.$set(currentPageComputed, key, newProp);
                this.$set(this.computedValueMap, pageletId, currentPageComputed);
                newProp = key;
            }
            if (replaceType === 'binding') {
                newProp = bindings[key];
            }
            // 处理页面变量或页面参数
            const handleStateOrProp = (newProp, oldProp, type) => {
                const replaceValue = type === 'binding' ? `parentScope.${newProp}` : `${COMPUTED_VALUE}.${newProp}`;
                const currentPageMap = replaceTargetMap[pageletId] || {
                    state: [],
                    data: [],
                };
                currentPageMap.state.push({
                    id: key,
                    newProp: replaceValue,
                    oldProp,
                });
                replaceTargetMap[pageletId] = currentPageMap;
            };
            // 处理数据源
            const handleDataSource = (sourceId, collectionId) => {
                if (collectionId === '')
                    return;
                const currentPageMap = replaceTargetMap[pageletId] || {
                    state: [],
                    data: [],
                };
                currentPageMap.data.push({
                    sourceId,
                    collectionId,
                });
                replaceTargetMap[pageletId] = currentPageMap;
            };
            if (['props', 'state'].includes(tag)) {
                handleStateOrProp(newProp, oldProp, replaceType);
            }
            else {
                handleDataSource(paramsKey, newProp);
            }
        });
        return replaceTargetMap;
    }
    // 替换参数
    replacePagelet(replaceTargetMap) {
        const newPagelets = cloneDeep(this.pagelets);
        Object.keys(replaceTargetMap).forEach((pageletId) => {
            const curPageletIndex = newPagelets.findIndex(item => item.id === pageletId);
            const currentPageTarget = replaceTargetMap[pageletId];
            // 替换页面变量或者页面参数
            let curPageletStr = JSON.stringify(newPagelets[curPageletIndex]);
            const stateTypeTarget = [...(currentPageTarget.props || []), ...(currentPageTarget.state || [])];
            stateTypeTarget.forEach((target) => {
                curPageletStr = this.replaceStateOrProps(target, curPageletStr);
            });
            // 替换页面数据源
            const dataSourceTypeTarget = currentPageTarget.data || [];
            const pagelet = JSON.parse(curPageletStr);
            dataSourceTypeTarget.forEach((target) => {
                this.replaceDataSource(target, pagelet);
            });
            newPagelets[curPageletIndex] = pagelet;
        });
        return newPagelets;
    }
    replaceStateOrProps(target, pageletStr) {
        const { newProp, oldProp, id } = target;
        logger.info('[pagelet-component] replace', newProp, oldProp);
        const regex = new RegExp(`(?:[\b|\\n]*)${oldProp}\\b`, 'g');
        this.replaceTargetMap[id] = newProp;
        return pageletStr.replaceAll(regex, newProp);
    }
    replaceDataSource(target, pagelet) {
        const { xySources } = pagelet;
        const { sourceId, collectionId } = target;
        const source = xySources.find(source => source.id === sourceId);
        if (source)
            source.collectionId = collectionId;
    }
    initSlot() {
        this.slots.forEach((slot) => {
            const el = this.$el.querySelector(`#${slot.id}`);
            if (el) {
                this.$refs[slot.id] = el;
            }
        });
    }
    renderSlot(id, placeHolder = '') {
        return <div id={id}>
      {this.$slots?.[id] || this.renderPlaceholder(placeHolder)}
    </div>;
    }
    getSlotStyle(id) {
        return this.slots.find(item => item.id === id)?.style || {};
    }
    renderPlaceholder(placeHolder) {
        return <div class={styles.placeHolderContainer}>
      {placeHolder || '请将组件拖到此处'}
    </div>;
    }
    getUcDataSourcesFromProps(pageId) {
        const dataSource = [];
        const xyDataSource = this.dataSourceMap[pageId];
        if (xyDataSource)
            dataSource.push({
                id: 'data',
                instance: xyDataSource.data,
                schema: {
                    ...xyDataSource.dataSchema,
                    title: '组件内数据源',
                },
            });
        const computedValue = this.computedValueMap[pageId];
        if (computedValue) {
            const computedSchema = generateDataSchemaFromValues(computedValue);
            dataSource.push({
                id: COMPUTED_VALUE,
                schema: {
                    id: COMPUTED_VALUE,
                    title: '组件内参数',
                    ...computedSchema,
                },
                instance: computedValue,
            });
        }
        if (pageId !== 'default') {
            const pagelet = this.pagelets.find(item => item.id === pageId);
            // 将弹窗的props添加上去
            dataSource.push({
                id: 'props',
                schema: pagelet.propsSchema || {},
                instance: this.rootWContext.childrenData[this.getModalId(pagelet.id)].data,
            });
        }
        return dataSource;
    }
    handleModalAfterClose(modalId) {
        setTimeout(() => this.wContext.pageletInstance.$emit(`afterDialogClose:${modalId}`), 0);
    }
    render() {
        // tag是用来给事件冒泡定位容器元素的
        return <div data-tag={TAG_NAME}>
      <CRenderer data-tag={RENDERER_TAG_NAME} pagelet-component-pageId={this.defaultPagelet.id} pagelet-component-uid={this._uid} ref={`${this.defaultPagelet.id}-renderer`} uc={this.uiCoreInstance.uc} uc-initial-pagelet={this.currentPagelets[0]} uc-internal-is-root={false} uc-data-sources-from-props={this.defaultPageUcDataSource} class="uc-renderer" style="padding: 0"></CRenderer>
      {this.modalPagelets.map((pagelet) => {
                const modalId = this.getModalId(pagelet.id);
                return <Modal id={this.getModalId(pagelet.id)} visible={this.rootWContext.childrenData[modalId].visible} merge-params={this.rootWContext.childrenData[modalId].mergeParams} params={this.rootWContext.childrenData[modalId].data} rendererType="cRenderer" pagelets={[{
                            ...pagelet,
                            id: modalId,
                        }]} {...{
                    on: {
                        'after-close': () => this.handleModalAfterClose(modalId),
                    },
                }}><CRenderer data-tag={RENDERER_TAG_NAME} pagelet-component-pageId={pagelet.id} pagelet-component-uid={this._uid} ref={`${pagelet.id}-renderer`} uc={this.uiCoreInstance.uc} uc-data-sources-from-props={this.getUcDataSourcesFromProps(pagelet.id)} uc-initial-pagelet={pagelet} uc-internal-is-root={false} class="uc-renderer"/>
          </Modal>;
            })}
    </div>;
    }
};
__decorate([
    Watch('dataSourceMap')
], XyPageletComponent.prototype, "onDataSourceMapChange", null);
XyPageletComponent = __decorate([
    Component({
        name: 'XyPageletComponent',
        inject: {
            wContext: { from: 'wContext', default: null },
            ucRenderer: { from: 'uicore:renderer', default: null },
            ucDesigner: { from: 'uicore:designer', default: null },
        },
        provide() {
            return {
                pageletComponent: this,
                w: this.localW,
            };
        },
    })
], XyPageletComponent);
export default XyPageletComponent;
