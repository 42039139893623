<template>
  <a-tree
    :tree-data="treeData"
    show-icon
    :default-expanded-keys="['root']"
  >
    <template
      slot="node"
      slot-scope="{ type,title,description }"
    >
      <span>{{ title }}
        <span class="typeClass">{{ type }}</span>
        <a-popover
          v-if="description"
          title=""
        >
          <template slot="content">
            <span>{{ description }}</span>

          </template>
          <a-icon type="question-circle" />
        </a-popover>
      </span>
    </template>
  </a-tree>
</template>

<script>
export default {

  props: {
    dataSchema: {
      type: Object,
      default: null,
    },
  },

  computed: {
    treeData() {
      if (!this.dataSchema) {
        return [];
      }
      const root = {
        title: '返回值',
        key: 'root',
        children: [],
      };

      const addChildren = (dataSchema, parent) => {
        if (dataSchema.type === 'object'
        || (dataSchema.type === 'array' && dataSchema.items?.type === 'object')) {
          const children = [];
          const fields = dataSchema.fields || dataSchema.items?.fields;
          fields?.forEach((field) => {
            const child = {
              title: field.id,
              key: `${parent.key}-${field.id}`,
              children: [],
              type: field.type,
              scopedSlots: { title: 'node' },
              ...field,
            };
            children.push(child);
            addChildren(field, child);
          });
          parent.children.push(...children);
        };
      };


      addChildren(this.dataSchema, root);

      return [root];
    },
  },

};

</script>

<style scoped>
.typeClass{
  color: #979797;
  font-size: 85%;
  border-radius: 3px;
  padding: .2em .4em;
  background-color: rgba(27,31,35,.05);
}

</style>
