<template>
  <div style="display:flex">
    <aside>
      <div class="navigation">
        <a-menu
          v-model="currentMenus"
          :default-open-keys="['role']"
          @click="selectMenu"
        >
          <a-menu-item key="role">
            {{ titleMap.role }}
          </a-menu-item>
          <a-menu-item key="user">
            {{ titleMap.user }}
          </a-menu-item>
          <a-menu-item key="permission">
            {{ titleMap.permission }}
          </a-menu-item>
          <a-menu-item key="api">
            {{ titleMap.api }}
          </a-menu-item>
        </a-menu>
      </div>
    </aside>

    <div style="width:100%;">
      <div class="header-container">
        <div class="title">
          <a-icon
            type="arrow-left"
            class="breadcrumb-icon"
            @click="goBack"
          />
          <h3 class="title">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="container">
          <div>
            <a-alert
              v-if="env !== 'prod'"
              type="warning"
              closable
              :message="`权限数据环境隔离，当前环境：${currentEnvName}`"
              style="margin-bottom:12px"
            />

            <div
              v-if="!canManageInXy && panguRoleManageDoc"
              style="margin-top:160px;"
            >
              <a-result title="未完成初始化，暂不支持在无极侧管理，请自行到盘古侧管理配置">
                <template slot="extra">
                  <a
                    :href="panguRoleManageDoc"
                    target="_blank"
                  >点击前往盘古</a>
                </template>
              </a-result>
            </div>
            <provide-perm-state
              v-else
              :project-id="projectId"
              :env="env"
              @inited="(state) => canManageInXy = state.canManagePermInXy.value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getAppParams } from '@utils/path';
import module from './module';
import { getDocLink } from '@utils/links';
import ProvidePermState from './providePermState.tsx';

export default {
  components: {
    ProvidePermState,
  },
  data() {
    return {
      currentMenus: [],
      canManageInXy: true,
      titleMap: {
        role: '角色管理',
        user: '用户管理',
        permission: '自定义权限管理',
        api: '接口管理',
      },
    };
  },
  computed: {
    ...mapGetters('rbac', ['roles']),
    ...mapState({
      envList: state => state.env.envList,
    }),
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env;
    },
    currentRole() {
      if (this.currentMenus.length === 0) return {};
      return this.roles.find(role => role.name === this.currentMenus[0]);
    },
    currentEnvName() {
      if (this.env === 'dev') return '开发环境';
      const env = this.envList.find(r => r.env === this.env);
      return env?.name ?? this.env;
    },
    names() {
      return this.roles.map(r => r.name);
    },
    panguRoleManageDoc() {
      return getDocLink().PANGU_ROLE_MANAGE;
    },
    title() {
      return this.titleMap[this.currentMenus[0]];
    },
  },
  watch: {
    $route: {
      handler() {
        this.setActiveMenu();
      },
      immediate: true,
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule('rbac')) {
      this.$store.registerModule('rbac', module);
    }
  },
  created() {
    const { projectId, env } = this;
    this.$store.dispatch('rbac/check', { projectId, env });
    this.$store.dispatch('rbac/fetchRoles', { projectId, env });
  },
  methods: {
    goBack() {
      if (this.currentMenus[0] === 'role') this.$router.push('/');
      else this.$router.go(-1);
    },
    setActiveMenu() {
      const { name } = this.$route;
      switch (name) {
        case 'rbac.user-list':
        case 'rbac.user':
          this.currentMenus = ['user'];
          break;
        case 'rbac.role-list':
        case 'rbac.role':
          this.currentMenus = ['role'];
          break;
        case 'rbac.permission':
          this.currentMenus = ['permission'];
          break;
        case 'rbac.cloud-function':
          this.currentMenus = ['api'];
          break;
      }
    },
    backProject() {
      this.$router.push('/');
    },
    async selectMenu(item) {
      const { key } = item;
      const map = {
        user: 'rbac.user-list',
        role: 'rbac.role-list',
        permission: 'rbac.permission',
        api: 'rbac.cloud-function',
      };
      this.$router.push({ name: map[key] });
    },
  },
};
</script>

<style lang="scss" scoped>
.navHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 2px #f0f0f0;
  margin: 0 -12px;
  margin-bottom: 12px;
  font-size: 18px;
  padding: 6px;
  padding-bottom: 10px;
  gap: 4px;
}

.navigation {
  width: 260px;
  height: calc(100vh - var(--xy-header-height));
  background-color: #fff;
  padding: 10px;

  ::v-deep .ant-menu-vertical, .ant-menu-inline{
    border-right: none;
  }
}

.header-container {
  height: var(--xy-header-height);
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

.content-wrapper {
  width: 100%;
  min-height: calc(100vh - var(--xy-header-height) * 2);
  padding: 24px;
  background-color: #f0f2f5;

  .container {
    height: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }
}

</style>
