<template>
  <a-select
    v-model="editValue"
    show-search
    placeholder=""
    option-filter-prop="children"
    :filter-option="filterOption"
    style="width: 100%"
  >
    <a-select-option
      v-for="cos in costList"
      :key="cos.cosId"
      :value="cos.cosId"
    >
      {{ cos.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';

export default {
  name: 'XyCosSelector',
  mixins: [vueComponentMixin],
  inject: ['wContext'],
  data() {
    return {
      costList: [],
    };
  },
  mounted() {
    this.getCosList();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async getCosList() {
      try {
        const { projectId } = this.wContext.config;
        const result = await this.$fetch(`/api/xy/basic/cos?projectid=${projectId}`);
        if (result.code === 200) {
          this.costList = result.data || [];
        } else {
          console.error('获取cos列表失败', result);
        }
      } catch (e) {
        console.error('获取cos列表失败', e);
      }
    },
  },
};
</script>
