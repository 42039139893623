import wujiFetch from '@utils/wujiFetch';
import { getDesignEnvPath } from '@config/constant';
import { message } from 'ant-design-vue';
import qs from 'query-string';

const getPathPrefix = env => `${getDesignEnvPath(env)}/version`;
const PAGE_SIZE = 10;

export const VERSION_STATUS = {
  UNRELEASED: 0, // 未发布or下线了
  RELEASED: 1, // 已发布
  IN_GRAY: 2, // 灰度
  PRE_RLEASED: 3, // 预发布（只有正式环境有这个状态）
  REJECT: 4, // 打回（只有正式环境有），在预发布状态选择了取消发布
};

const getReleasedVersion = async (env, projectId) => await wujiFetch(`${getPathPrefix(env)}/project/release?projectid=${projectId}`);
const timeout = 60000 * 5; // 5分钟

export default {
  state: {
    versionList: {},
    currentVersion: {},
    envPageList: [],
    total: 0, // 总数
    page: 1, // 当前页
  },
  getters: {
  },
  actions: {
    /**
     * 获取两个环境 / 自定义分支与环境的diff
     */
    async fetchDiffContent({ commit, rootState }, { projectId, fromEnv, fromBranch, toEnv }) {
      let diffList = [];
      const proId = projectId || rootState.project.projectInfo?.id;
      try {
        if (fromEnv === 'dev') {
          if (fromBranch && fromBranch !== 'master') {
            // 分支发布
            const url = `${getPathPrefix(toEnv)}/project/branch/${fromBranch}/staged`;
            diffList = await wujiFetch(`${url}?projectid=${proId}`, { method: 'GET', timeout });
          } else {
            // 开发环境发布
            const url = `${getPathPrefix(toEnv)}/project/staged`;
            diffList = await wujiFetch(`${url}?projectid=${proId}`, { method: 'GET', timeout });
          }
        } else {
          const fromReleasedVersion = await getReleasedVersion(fromEnv, proId);
          if (!fromReleasedVersion) {
            commit('updateVersionState', { diffList: [] });
            return message.error(`${fromEnv}环境暂未发布版本，当前无可比较的版本`);
          }
          const currentVersionId = fromReleasedVersion.id;
          const targetVersion = await wujiFetch(`${getPathPrefix(toEnv)}/project/release?projectid=${proId}`, { timeout });
          diffList = await wujiFetch(`${getPathPrefix(fromEnv)}/project/compare?projectid=${proId}&fromVersionId=${currentVersionId}&toVersionId=${targetVersion?.id || ''}`, {
            timeout,
          });
        }
        return diffList;
      } catch (err) {
        message.error(`获取当前页面版本差异失败:${err.message}`);
      }
    },


    /**
     *  提交应用版本（只创建不发布）
     */
    async createProjectVersion({ dispatch, rootState }, { projectId, env, branch, desc, data }) {
      const proId = projectId || rootState.project.projectInfo?.id;
      const query = { projectid: proId };
      if (branch !== 'master') query.branch = branch;
      const result = await wujiFetch(`${getPathPrefix(env)}/project?${qs.stringify(query)}`, {
        method: 'POST',
        body: JSON.stringify({
          desc,
          data,
        }),
        timeout,
      });
      if (result && result.id) {
        await dispatch('publishProjectVersion', { projectId: proId, env, versionId: result.id });
      } else {
        throw new Error(`提交版本失败:${result}`);
      }
    },

    /**
     *  获取应用版本列表
     */
    async fetchProjectVersionList({ commit }, { projectId, env, page = 1 }) {
      try {
        const url = `${getPathPrefix(env)}/project`;
        const getTotal = wujiFetch(
          `${url}?projectid=${projectId}&count=1`,
          { method: 'GET', timeout: 5000 },
        );
        const getList = wujiFetch(
          `${url}?projectid=${projectId}&page=${page}&size=${PAGE_SIZE}&sort=_ctime&order=desc`,
          { method: 'GET', timeout: 5000 },
        );
        const [result, { total }] = await Promise.all([getList, getTotal]);
        commit('updateVersionState', { page, total });
        commit('updateVersionList', { env, versions: result });
      } catch (err) {
        message.error(`获取版本列表失败:${err.message}`);
      }
    },

    /**
     * 发布应用版本
     */
    async publishProjectVersion({ }, { projectId, env, versionId }) {
      try {
        await wujiFetch(
          `${getPathPrefix(env)}/project/release/${versionId}?projectid=${projectId}`,
          {
            method: 'POST',
            timeout,
          },
        );
      } catch (e) {
        throw new Error(`发布版本失败:${e.message}`);
      }
    },

    // 获取应用当前版本
    async fetchCurrentProjectVersion({ commit }, { projectId, env }) {
      try {
        const url = `${getPathPrefix(env)}/project/release`;
        const result = await wujiFetch(`${url}?projectid=${projectId}`, { method: 'GET' });
        commit('updateVersionState', { currentVersion: result });
      } catch (err) {
        message.error(`获取当前应用版本列表失败:${err.message}`);
      }
    },

    /**
     * 应用环境同步
     */
    async syncProjectVersion({ }, { projectId, versionId, fromEnv, toEnv, data, desc }) {
      try {
        // 如果没有versionId，默认同步当前版本到其他环境
        let verId = versionId;
        if (!versionId) {
          const releasedVersion = await getReleasedVersion(fromEnv, projectId);
          verId = releasedVersion.id;
        }
        const url = `${getPathPrefix(fromEnv)}/project/sync`;
        return wujiFetch(
          `${url}/${verId}?projectid=${projectId}`,
          {
            method: 'POST',
            body: JSON.stringify({
              from: fromEnv,
              to: toEnv,
              data,
              desc,
            }),
            // 这个接口在大型应用耗时会久些，所以延长下超时时间避免前端报错实际接口请求成功
            timeout,
          },
        );
      } catch (err) {
        const res = err.response ? await err.response.json() : { error: err.message };
        throw new Error(`同步环境数据失败:${res.error}`);
      }
    },

    /**
     * 获取已发布的版本提交内容
     */
    async fetchVersionCommitContent({ }, { projectId, env, versionId }) {
      try {
        // 发布页面版本
        return await wujiFetch(`${getPathPrefix(env)}/project/diff/${versionId}?projectid=${projectId}`);
      } catch (e) {
        throw new Error('获取版本发布内容失败');
      }
    },

    /**
     * 中断发布正式
     */
    async cancelPublish({ }, { projectId, versionId, env }) {
      try {
        await wujiFetch(`${getPathPrefix(env)}/project/cancel/${versionId}?projectid=${projectId}`, { method: 'POST' });
      } catch (err) {
        throw new Error(`版本终止失败:${err.message}`);
      }
    },

    /**
     * 撤销（开发环境的）某条变更
     */
    async discardChange({ }, { projectId, env, versionId, appId, schemaId, objectId, desc }) {
      try {
        // 变更先暂存到stash
        await wujiFetch(`${getPathPrefix('dev')}/common/stash?projectid=${projectId}`, {
          method: 'POST',
          body: JSON.stringify({
            appId,
            schemaId,
            objectId,
            desc,
          }),
        });
        // 再去更新开发环境的数据
        await wujiFetch(`${getPathPrefix(env)}/common/apply?projectid=${projectId}`, {
          method: 'POST',
          body: JSON.stringify({
            appId,
            schemaId,
            versionId,
            objectId,
          }),
        });
      } catch (err) {
        console.error(err);
        message.error(`操作失败${err.message}`);
      }
    },

    async exportVersion({ rootState }, { env, versionId }) {
      const projectId = rootState.project.projectInfo.id;
      window.open(`${getPathPrefix(env)}/project/download/${versionId}?projectid=${projectId}`, '_blank');
    },
    async importVersion({ rootState, dispatch }, { env, version, desc, beforeFunc }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix(env)}/project/import?projectid=${projectId}`;
        const result = await wujiFetch(url, {
          method: 'POST',
          body: JSON.stringify({
            version,
            desc,
            beforeFunc,
          }),
          timeout,
        });
        message.success('导入成功');
        dispatch('fetchProjectVersionList', {
          projectId,
          env,
        });
        return result;
      } catch (err) {
        message.error('导入失败', err?.message);
        throw err;
      }
    },
    async debugImportHook({ rootState }, { env, data, beforeFunc }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix(env)}/project/hook/debug?projectid=${projectId}`;
        const result = await wujiFetch(url, {
          method: 'POST',
          body: JSON.stringify({
            data,
            beforeFunc,
          }),
          timeout,
        });
        return result;
      } catch (err) {
        message.error('执行前置钩子失败', err?.message);
        throw err;
      }
    },
    async fetchImportHooks({ rootState }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix('dev')}/project/hook?projectid=${projectId}`;
        const result = await wujiFetch(url);
        return result;
      } catch (err) {
        message.error('获取导入钩子失败', err?.message);
        throw err;
      }
    },
  },
  mutations: {
    updateVersionList(state, { env, versions }) {
      const { versionList } = state;
      versionList[env] = versions;
      Object.assign(state, { versionList: { ...versionList } });
    },
    updateVersionState(state, payload) {
      return Object.assign(state, payload);
    },
  },
};
