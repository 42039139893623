import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH, stagingEnvs } from '@config/constant';
import { message } from 'ant-design-vue';
import { isRuntime } from '@utils/globalInfo';
const baseUrl = `${BASE_API_PATH}/xy/env`;
const basicBaseUrl = `${BASE_API_PATH}/xy/basic/env`;

// 不允许用户使用的环境
const EXCLUDE_ENVS = stagingEnvs;

const DEV = { id: 'dev', env: 'dev', name: '开发环境', envId: 'dev' };

const DEFAULT_ENVS =  {
  dev: '开发环境',
  test: '测试环境',
  prod: '正式环境',
};
stagingEnvs.forEach((env) => {
  DEFAULT_ENVS[env] = '预发布环境';
});

export const DEFAULT_ENV = DEFAULT_ENVS;

export default {
  state: {
    envList: [],
  },
  getters: {
    devInfo: () => DEV,
    envListWithDev(state) {
      return [DEV, ...state.envList];
    },
  },
  actions: {
    async fetchEnvList({ commit, rootState }, { projectId } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const urlPath = isRuntime() ? basicBaseUrl : baseUrl;
        const result = await wujiFetch(`${urlPath}?projectid=${projectID}`, { method: 'GET', timeout: 3000 });
        if (result) {
          commit('updateEnvList', result.filter(item => !EXCLUDE_ENVS.includes(item.id)));
        }
      } catch (err) {
        console.error(err);
        message.error('获取应用环境列表失败', err.message);
      }
    },
    async createEnv({ commit, state }, { projectId, env, name, disableSync }) {
      try {
        if ([...Object.keys(DEFAULT_ENV), 'branch'].includes(env)) {
          throw new Error('该环境已被系统使用，请设置另外一个环境id');
        }
        const result = await wujiFetch(
          `${baseUrl}?projectid=${projectId}`,
          {
            method: 'POST',
            body: JSON.stringify({
              env,
              name,
              disableSync,
            }),
          },
        );
        commit('updateEnvList', [...state.envList, result]);
      } catch (err) {
        throw err;
      }
    },
    async updateEnv({ commit, state }, { projectId, env, name, disableSync }) {
      try {
        await wujiFetch(
          `${baseUrl}/patch/${env}?projectid=${projectId}`,
          {
            method: 'POST',
            body: JSON.stringify({
              name,
              disableSync,
            }),
          },
        );
        const target = state.envList.find(item => item.env === env);
        if (target) {
          target.name = name;
          target.disableSync = disableSync;
        }
        commit('updateEnvList', state.envList);
      } catch (e) {
        throw e;
      }
    },
    async deleteEnv({ commit, state }, { projectId, env }) {
      try {
        await wujiFetch(
          `${baseUrl}/delete/${env}?projectid=${projectId}`,
          {
            method: 'POST',
          },
        );
        commit('updateEnvList', state.envList.filter(item => item.env !== env));
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    updateEnvList(state, newList) {
      state.envList = newList;
    },
  },
};
