import Vue from 'vue';
import Router from 'vue-router';
import { pick, isEqual } from 'lodash';

import runtimeMode from '@/router/runtimeMode';
import { injectRouterMethods } from '@/router/injectRouter';
import { isHmr, isLayoutHmr, getHmrPort } from '@utils/comps-loader';
import layoutLoader from '@loaders/layout/loader';
import pageLoader from '@loaders/page/loader';
import globalsLoader from '@/loaders/globals/loader';
import { executeSwitchHook } from '@/utils/executeSwitchHook';
import { getUserInfo } from '@utils/userInfo';
import { cacheRequestCollectionCgi, requestCollectionCgi } from '@utils/common';
import { wEmitter, EventName } from '@utils/global/event-emitter';

Vue.use(Router);
injectRouterMethods();

const guard = async (from, to) => {
  const { projectId, env } = window.RUNTIME_CONFIGS;
  const projectInfo = window.xy_runtime_project;
  const { enabledGuard = false, routeGuard = '' } = projectInfo.advanceConfig ?? {};
  let route = '';
  if (enabledGuard && routeGuard) {
    route = await executeSwitchHook(routeGuard, {
      ctx: {
        projectId,
        env,
        user: getUserInfo(),
        sdk: {
          requestCollectionCgi: requestCollectionCgi.bind(undefined, projectId, env),
          cacheRequestCollectionCgi: cacheRequestCollectionCgi.bind(undefined, projectId, env),
        },
      },
      from,
      to,
    });
  }

  return route;
};

export default async ({ projectId, env, branch }) => {
  if (isHmr()) {
    const port = getHmrPort();
    pageLoader.initHmr(port);
    globalsLoader.initHmr(port);
  }

  // 布局组件本地开发 HMR
  if (isLayoutHmr()) {
    const port = getHmrPort();
    layoutLoader.initHmr(port);
  }

  await globalsLoader.loadResources(projectId);

  const router = new Router(await runtimeMode({ env, projectId, branch }));
  window.xy_runtime_router = router;

  let previousRoute = '';
  // to hijack
  router.beforeEach(async (to, from, next) => {
    wEmitter.emit(EventName.ROUTER_BEFORE_EACH, ({ to, from }));
    if (!previousRoute) {
      const route = await guard(from, to);
      if (route) {
        if (decodeURIComponent(route) !== decodeURIComponent(from.fullPath)) previousRoute = route;
        next(route);
        return;
      }
    } else {
      // 重置
      previousRoute = '';
      // 直接确认跳转
      next();
      return;
    }

    const commonKeys = Vue.prototype.$commonQuery ?? [];
    const layoutKeys = ['iframe', '_header', '_sidebar'];
    const fromQuery = pick(from.query, [...commonKeys, ...layoutKeys]);
    const query = { ...fromQuery, ...to.query };
    if (isEqual(query, to.query)) next();
    else next({ ...to, query });
  });

  router.afterEach((to, from) => {
    wEmitter.emit(EventName.ROUTER_AFTER_EACH, ({ to, from }));
  });

  router.afterEach(() => {
    performance.mark('ROUTER_AFTER');
    if (router.TOP_SWITCHING) history.go();
  });


  return router;
};
