<template>
  <div style="width:100%">
    <a-select
      v-model="editValue"
      show-search
      filter-option
      style="display: block; flex: 1;"
      @focus="reload"
    >
      <span slot="notFoundContent">
        <span>
          无机器人，点击去
          <router-link
            :to="{
              path: '/project/config/settings/advance',
              query: { projectid: projectId, },
            }"
            target="_blank"
          >
            <a-icon type="link" />
            新建
          </router-link>
        </span>
      </span>
      <a-select-option
        v-for="form in robotList"
        :key="form.id"
        :value="form.id"
      >
        {{ form.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import store from '@/store';

export default {
  name: 'XyRobotSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      robotList: [],
      projectId: '',
    };
  },
  created() {
    this.reload();
  },
  methods: {
    async reload() {
      const { ctx } = this.designer?.renderer || {};
      this.projectId = ctx?.config?.projectId || this.$route.query.projectid;
      this.robotList = await this.fetchRobotListSetting(this.projectId);
    },
    async fetchRobotListSetting(projectId) {
      let robotList = [];
      try {
        const data = await store.dispatch('fetchProjectSettings', { projectId });
        robotList = (data.robots || []).map(x => ({ id: x.id, name: x.name }));
      } catch (error) {
        // ignore
      }
      return robotList;
    },
  },
};
</script>

<style>
</style>
