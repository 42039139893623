var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import styles from './style.module.scss';
import { ExprInput } from '@tencent/ui-core';
import { Label } from './Label';
let PrimitiveForm = class PrimitiveForm extends Vue {
    value;
    schema;
    uc;
    onInput(val) {
        this.$emit('input', val);
    }
    renderBoolean() {
        const enumArray = this.schema.enum;
        return (<a-radio-group value={this.value} onInput={this.onInput}>
        {[true, false].map((val) => {
                const label = enumArray.find(item => item.value === val)?.label || val;
                return (<a-radio value={val} key={val}>
              {label}
            </a-radio>);
            })}
      </a-radio-group>);
    }
    renderText() {
        const dataStub = this.uc?.designer.renderer.ucDataStub;
        return (<ExprInput onInput={this.onInput} onChange={this.onInput} type="interopable-string" dataStub={dataStub} value={this.value}></ExprInput>);
    }
    render() {
        const { id, title } = this.schema;
        const { type } = this.schema;
        return (<div class={styles.argFormItem}>
        <Label props={{ id, title }}></Label>
        <div>{type === 'boolean' ? this.renderBoolean() : this.renderText()}</div>
      </div>);
    }
};
__decorate([
    Prop()
], PrimitiveForm.prototype, "value", void 0);
__decorate([
    Prop()
], PrimitiveForm.prototype, "schema", void 0);
__decorate([
    Inject('uicore:bus')
], PrimitiveForm.prototype, "uc", void 0);
PrimitiveForm = __decorate([
    Component({
        name: 'PrimitiveForm',
    })
], PrimitiveForm);
export { PrimitiveForm };
