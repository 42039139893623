var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ObjectForm } from './ObjectForm';
import styles from './style.module.scss';
// DB 的schema 需要写死
// http 的schema需要 使用debug的schema，也不一样准确
let HttpRequestForm = class HttpRequestForm extends Vue {
    value;
    // 包含 body query 的schema
    schema;
    tabs = [
        {
            label: 'query (url参数)',
            value: 'query',
        },
        {
            label: 'body (post包体)',
            value: 'body',
        },
    ];
    get showTabs() {
        return this.tabs.filter(tab => this.schema?.fields?.find?.(f => f.id === tab.value));
    }
    currentRadio = 'query';
    render() {
        return (<div class={styles.argForm}>
        <div class={styles.argForm}>
          <a-tabs v-model={this.currentRadio} type="card">
            {this.showTabs.map((item) => {
                const onInput = (v) => {
                    const newVal = this.value || {};
                    this.$set(newVal, item.value, v);
                    this.$emit('input', newVal);
                };
                return (<a-tab-pane key={item.value} tab={item.label}>
                  <ObjectForm hideLabel={true} schema={this.schema?.fields?.find(f => f.id === item.value)} value={this.value?.[item.value]} onInput={onInput}></ObjectForm>
                </a-tab-pane>);
            })}
          </a-tabs>
        </div>
      </div>);
    }
};
__decorate([
    Prop()
], HttpRequestForm.prototype, "value", void 0);
__decorate([
    Prop()
], HttpRequestForm.prototype, "schema", void 0);
HttpRequestForm = __decorate([
    Component({
        name: 'HttpRequestForm',
    })
], HttpRequestForm);
export { HttpRequestForm };
