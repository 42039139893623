<template>
  <div class="xy-alert">
    <a-alert
      message="请勿发送业务敏感信息"
      type="warning"
      banner
    />
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
export default {
  name: 'XyGPTAlert',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
};
</script>
<style lang="scss" scoped>
  .xy-alert {
    width: 100%;
    padding: 5px 0;
    & > * {
      width: 100%;
    }
  }
</style>
