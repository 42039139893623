var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getCompInfo, JSON_TYPE_KEY } from './utils';
import styles from './style.module.scss';
import { ExprInput } from '@tencent/ui-core';
// DB 的schema 需要写死
// http 的schema需要 使用debug的schema，也不一样准确
let DBRequestForm = class DBRequestForm extends Vue {
    value;
    cgi;
    // 包含 body query 的schema
    schema;
    radios = [
        {
            label: 'query (url参数)',
            value: 'query',
            render: this.renderQuery,
        },
        {
            label: 'body (post包体)',
            value: 'body',
            render: this.renderBody,
        },
    ];
    get showRadios() {
        return this.radios.filter(item => this.schema?.fields?.some(f => f.id === item.value));
    }
    currentRadio = 'query';
    get currentSchema() {
        return this.schema?.fields.find(item => item.id === this.currentRadio);
    }
    get currentValue() {
        return this.value?.[this.currentSchema?.id];
    }
    created() {
        this.$watch(() => this.cgi, () => {
            this.currentRadio = this.showRadios[0]?.value || 'query';
        }, { immediate: true });
    }
    renderQuery() {
        const fileds = this.currentSchema?.fields?.map((item) => {
            if (!item.type)
                return null;
            const compInfo = getCompInfo(item);
            if (!compInfo)
                return null;
            const onChange = (value) => {
                const id = 'query';
                // currentValue也有可能为空的...
                const newValue = this.value || {};
                if (!newValue[id]) {
                    this.$set(newValue, this.currentSchema.id, {});
                }
                this.$set(newValue[id], item.id, value);
                this.$emit('input', newValue);
            };
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const Comp = compInfo.comp;
            return (<Comp schema={compInfo.isJsonString ? item[JSON_TYPE_KEY] : item} value={this.currentValue?.[item.id]} onInput={onChange} props={compInfo.isJsonString ? { id: item.id, title: item.title, isJsonString: true } : {}}></Comp>);
        });
        return <div class={styles.argFormContent}>{fileds}</div>;
    }
    onBodyExprChange(val) {
        const id = 'bodyExprStr';
        const newValue = this.value || {};
        this.$set(newValue, id, val);
        this.$emit('input', newValue);
    }
    renderBody() {
        return (<div class={styles.argFormContent}>
        <ExprInput multipleLine={true} type="interopable-string" value={this.value?.bodyExprStr} onChange={this.onBodyExprChange} placeholder="请输入JSON格式的对象"></ExprInput>
      </div>);
    }
    render() {
        return (<div class={styles.argForm}>
        <a-tabs v-model={this.currentRadio} type="card">
          {this.showRadios.map(item => (<a-tab-pane key={item.value} tab={item.label}>
              {item.render()}
            </a-tab-pane>))}
        </a-tabs>
      </div>);
    }
};
__decorate([
    Prop()
], DBRequestForm.prototype, "value", void 0);
__decorate([
    Prop()
], DBRequestForm.prototype, "cgi", void 0);
__decorate([
    Prop()
], DBRequestForm.prototype, "schema", void 0);
DBRequestForm = __decorate([
    Component({
        name: 'DBRequestForm',
    })
], DBRequestForm);
export { DBRequestForm };
