import {
  XyCosSelectorMeta,
  XyCosSelectorEnvMeta,
} from '../paramsFormComponents';
import uploader from '@utils/upload';
import { CATEGORY } from './const';
import excelAtoms from './excel';
import { message } from 'ant-design-vue';
import { isPlainObject } from 'lodash';
import { getFileDataAtom } from './getFileDataAtom';

// 上传至COS
export const uploadToCOSAtom = {
  id: 'xy:source:uploader.uploadToCOS',
  name: '上传至COS',
  category: CATEGORY.EXPORT,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'cosId',
        type: 'string',
        title: 'COS桶',
        'uc:allowInterop': true,
        ui: { type: 'XyCosSelector' },
      },
      {
        id: 'envConfig',
        type: 'object',
        title: '多环境配置(若无需区分环境则不需要配置此项)',
        ui: { type: 'XyCosEnvSelector' },
      },
      {
        id: 'file',
        type: 'string',
        title: '文件',
      },
      {
        id: 'path',
        type: 'string',
        title: '上传路径',
      },
      {
        id: 'headers',
        type: 'object',
        title: '上传头部',
        description: '头部 key 需要是标准写法，比如 Content-Type',
        default: '',
        'uc:allowInterop': true,
        ui: {
          type: 'exprInput',
          props: {
            multipleLine: true,
          },
        },
      },
    ],
  },
  returnValue: '上传文件路径',
  paramsFormComponents: [
    XyCosSelectorMeta,
    XyCosSelectorEnvMeta,
  ],
  async execute(ctx, params) {
    const { cosId, envConfig, file, path = '', headers = '' } = params;
    let customHeaders = null;
    if (headers && typeof headers === 'string') customHeaders = ctx.tryEval(headers);
    else if (isPlainObject(headers)) customHeaders = headers;
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const cos = envConfig[env] || cosId;
    if (!file || !cos) {
      message.error('上传至COS失败，无效的文件或COS桶配置');
      return;
    }
    const data = await uploader.upload({
      file,
      filePath: `/${path.replace(/^\//, '')}`,
      cosId: cos,
      projectId,
      headers: isPlainObject(customHeaders) ? customHeaders : null,
    });
    return data;
  },
};

export default [
  uploadToCOSAtom,
  ...excelAtoms,
  getFileDataAtom,
];
