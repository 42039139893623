import { omit } from 'lodash';
import App from '@utils/indexedDB';
import { getUserId } from '@utils/userInfo';
import { isQuotaExceedError } from './utils';
const draftSchemaDefinition = {
    version: 1,
    stores: [
        {
            name: 'drafts',
            opts: {
                keyPath: 'id',
            },
            indexes: [
                {
                    name: 'belong',
                    key: 'belong',
                },
            ],
        },
    ],
};
const generateBelongKey = (projectId, pageId, materialId = '') => {
    if (materialId) {
        return `${projectId}$$$material$$$${materialId}`;
    }
    return `${projectId}$$$${pageId}`;
};
export default class LocalDraftStore {
    store = null;
    async getDraft(key) {
        if (!this.store) {
            await this.initStore();
        }
        return await this.store.get(key);
    }
    async loadDraft(projectId, id, opts, type = 'page') {
        if (!this.store) {
            await this.initStore();
        }
        await this.handleLegacyDraft(projectId, id);
        const key = generateBelongKey(projectId, id, type === 'material' ? id : '');
        try {
            const localDrafts = await this.store.getAllFromIndex('belong', key, 1000);
            if (localDrafts) {
                // pageConfig很大，而且这个列表会被存到内存里，所以将这个字段删掉
                // 后续使用的时候会单独加缺少页面id或者物料id载
                const omitPageConfig = (draft) => omit(draft, ['pageConfig']);
                // 如果有时间范围，则过滤
                const filterDateRange = (draft) => {
                    const { ctime } = draft;
                    if (opts?.range)
                        return ctime >= opts.range[0] && ctime <= opts.range[1];
                    return true;
                };
                const simplifiedDrafts = Array.isArray(localDrafts)
                    ? localDrafts.map(omitPageConfig) : [omitPageConfig(localDrafts)];
                return simplifiedDrafts.filter(filterDateRange);
            }
            return [];
        }
        catch (error) {
            console.error(`获取本地草稿失败: ${error}`);
        }
    }
    /**
     * 将之前存在localStorage的草稿写入indexedDB
     * @param projectId
     * @param pageId
     */
    async handleLegacyDraft(projectId, pageId) {
        const SAVE_DRAFT_LOCAL_STORAGE_KEY = 'save_draft_local_storage_key';
        const key = `${SAVE_DRAFT_LOCAL_STORAGE_KEY}_${projectId}_${pageId}`;
        const localDraftStr = localStorage.getItem(key);
        if (localDraftStr) {
            const localDraft = JSON.parse(localDraftStr);
            localStorage.removeItem(key);
            const storeLegacyDraft = async (draft) => {
                const indexedDBDraft = { ...draft };
                indexedDBDraft.belong = generateBelongKey(projectId, pageId);
                await this.store?.add(indexedDBDraft);
            };
            if (Array.isArray(localDraft)) {
                await Promise.all(localDraft.map(storeLegacyDraft));
            }
            else {
                storeLegacyDraft(localDraft);
            }
        }
    }
    async saveDraft(pageConfig) {
        if (!this.store) {
            await this.initStore();
        }
        const key = generateBelongKey(pageConfig.projectId, pageConfig.pageId, pageConfig.materialId);
        const data = {
            ...pageConfig,
            ctime: Date.now(),
            id: `local_draft_${Date.now()}`,
            tag: 'local',
            creator: getUserId(),
            belong: key,
        };
        try {
            await this.store.add(data);
        }
        catch (error) {
            if (isQuotaExceedError(error)) {
                await this.handleExceedQuota(key, pageConfig);
            }
        }
    }
    async initStore() {
        const localDraftIndexedDB = new App('localDraftIndexedDB');
        await localDraftIndexedDB.init(draftSchemaDefinition);
        this.store = localDraftIndexedDB.store('drafts');
    }
    async getDraftCount(key) {
        if (!this.store) {
            await this.initStore();
        }
        return await this.store.countFromIndex('belong', key);
    }
    async handleExceedQuota(key, draft) {
        const count = await this.getDraftCount(key);
        let insertSuccess = false;
        let currentLength = count;
        // 删除掉一半的草稿，直到能够写入成功
        while (currentLength >= 1 || insertSuccess) {
            /**
             * 为什么要采取这样的策略？首先，indexedDB本身的容量很大，所以如果到触发了
             * QUOTA_EXCEEDED_ERR，一般情况下，已经是保存了大量的数据了。
             * 对于修改记录，应该是离目前越近的记录越重要，因为如果是比较久之前的记录，大概率用户已经手动保存过了。
             * 打个比方，如果用户已经存了200条数据了, 这一共是记录了1000秒，如果用户连续操作，那这是16分钟的记录，
             * 可以想象，16分钟到8分钟前的数据，肯定是不如八分钟前到现在的数据重要的，所以我们删除前半段数据可以直接删除，
             * 而越靠近当下的数据，越要少量的，谨慎的删除。这也是为什么一开始删除的量最大，到后面逐渐减少
             */
            const allDrafts = await this.store.getAllFromIndex('belong', key);
            allDrafts.sort((a, b) => b.ctime - a.ctime);
            const tx = this.store?.transaction('readwrite');
            const store = tx?.store;
            const draftToDelete = allDrafts.slice(0, Math.ceil(currentLength / 2));
            const deletePromise = draftToDelete.map(draft => store.delete(draft.id));
            await Promise.all(deletePromise);
            try {
                await this.store.add(draft);
                insertSuccess = true;
            }
            catch (error) {
                // 继续删除
            }
            currentLength = Math.ceil(currentLength / 2);
        }
    }
}
