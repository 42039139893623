var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Label } from './Label';
import { ExprInput } from '@tencent/ui-core';
let ObjectForm = class ObjectForm extends Vue {
    uc;
    value;
    schema;
    hideLabel;
    valueArray = [];
    onAdd() {
        this.valueArray.push({ key: '', value: '' });
    }
    onDelete(index) {
        this.valueArray.splice(index, 1);
        this.emit();
    }
    created() {
        this.toArray();
    }
    emit() {
        const newValue = this.valueArray.reduce((prev, { value, key }) => {
            prev[key] = value;
            return prev;
        }, {});
        this.$emit('input', Vue.observable(newValue));
    }
    toArray() {
        if (typeof this.value !== 'object') {
            return;
        }
        this.valueArray = Object.entries(this.value).reduce((prev, [key, value]) => {
            prev.push({
                key,
                value,
            });
            return prev;
        }, []);
    }
    get keySource() {
        return this.schema?.fields?.map(item => ({
            value: item.id,
            text: item.id + (item.title ? `(${item.title})` : ''),
        }));
    }
    get columns() {
        const dataStub = this.uc?.designer.renderer.ucDataStub;
        const getRender = (k) => (obj) => {
            const onChange = (v) => {
                obj[k] = v;
                this.emit();
            };
            if (k === 'key' && this.schema?.fields?.length) {
                return (<a-auto-complete style="width:100%;" value={obj[k]} onChange={onChange} dataSource={this.keySource}></a-auto-complete>);
            }
            return <ExprInput onChange={onChange} value={obj[k]} type="interopable-string" dataStub={dataStub}></ExprInput>;
        };
        return [
            {
                title: 'key',
                customRender: getRender('key'),
            },
            {
                title: 'value',
                customRender: getRender('value'),
            },
            {
                title: '',
                width: 40,
                customRender: (_, __, index) => (<a-button size="small" shape="circle" icon="delete" title="删除" onClick={() => this.onDelete(index)}></a-button>),
            },
        ];
    }
    render() {
        const { schema, hideLabel } = this;
        const rowKey = (_record, index) => index;
        return (<div>
        {!hideLabel && schema?.id && <Label props={{ id: schema.id, title: schema.title }}></Label>}
        <a-table columns={this.columns} pagination={false} size="small" rowKey={rowKey} dataSource={this.valueArray}></a-table>
        <a-button style={{ marginTop: '6px' }} key="plus" size="small" shape="circle" icon="plus" onClick={() => this.onAdd()}></a-button>
      </div>);
    }
};
__decorate([
    Inject('uicore:bus')
], ObjectForm.prototype, "uc", void 0);
__decorate([
    Prop()
], ObjectForm.prototype, "value", void 0);
__decorate([
    Prop()
], ObjectForm.prototype, "schema", void 0);
__decorate([
    Prop()
], ObjectForm.prototype, "hideLabel", void 0);
ObjectForm = __decorate([
    Component({
        name: 'ObjectForm',
    })
], ObjectForm);
export { ObjectForm };
