import { ref } from '@vue/composition-api';
import { defineInjection } from '@/utils/vue/injection';
import { fetchAuthSetting } from '@/composables/useAuthSetting';
import { hasPanguMapping, canManageInXy } from './utils';
const usePermState = () => {
    const panguMappingEnabled = ref(false);
    const canManagePermInXy = ref(false);
    let authSetting = null;
    const initAuthSetting = async (projectId, envId) => {
        authSetting = await fetchAuthSetting(projectId, true);
        initPanguState(envId);
    };
    const initPanguState = (envId) => {
        panguMappingEnabled.value = hasPanguMapping(authSetting, envId);
        canManagePermInXy.value = canManageInXy(authSetting, envId);
    };
    return {
        initAuthSetting,
        initPanguState,
        panguMappingEnabled,
        canManagePermInXy,
    };
};
export const { provideFunc: providePermState, injectFunc: injectPermState, } = defineInjection(usePermState, '@xy-client/usePermState');
