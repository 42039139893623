<template>
  <a-layout-header
    v-if="!isMiniprogram || sidebarInstance || isShowNavIcon"
    class="header"
    :class="[isDarkMode ? 'dark-header': 'light-header']"
    wj-mod="导航"
  >
    <div class="vertical-center">
      <template v-if="!isMiniprogram">
        <a-icon
          v-show="routeStack.length === 0"
          type="home"
          class="icon-button"
          @click="goHome"
        />
        <a-icon
          v-show="routeStack.length > 0"
          type="left"
          class="icon-button"
          @click="goBack"
        />
      </template>
      <a-icon
        v-if="sidebarInstance"
        type="bars"
        class="icon-button"
        @click="toggleSidebar"
      />
    </div>
    <a-icon
      v-if="isShowNavIcon"
      :type="visible ? 'close' : 'more'"
      class="icon-button"
      @click="visible = !visible"
    />
    <a-drawer
      placement="top"
      :closable="false"
      :visible="visible"
      height="100%"
      get-container="#xy-runtime-content"
      :wrap-style="{ position: 'absolute'}"
      @close="visible = false"
    >
      <!-- 环境选择 -->
      <template v-if="envSelectVisible('env')">
        <EnvSelect />
        <a-divider class="divider" />
      </template>
      <template v-if="hasWidgets">
        <div
          class="menus-tab"
          :class="{
            'menus-active': !businessCollapsed,
            'ant-menu-item-selected': !businessCollapsed,
          }"
          style="padding: 5px 10px"
          @click="collapseBusiness"
        >
          业务切换
          <a-icon :type="businessCollapsed ? 'up' : 'down'" />
        </div>
        <a-divider class="divider" />
      </template>
      <!-- 显示业务菜单 -->
      <div
        v-if="!businessCollapsed"
        class="widget-wrapper"
      >
        <Widget
          :project-id="appConfig.id"
          :widgets="widgets"
          :is-mobile-or-narrow-screen="true"
        />
      </div>
    </a-drawer>
  </a-layout-header>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { useRouter } from '@/router/useRouter';
import EnvSelect from '@components/nav/NavEnvSelect';
import Widget from '@components/nav-widget/NavWidget';
import { isMiniprogram } from '@utils/screen';
export default {
  name: 'MiniProgramNav',
  components: { EnvSelect, Widget },
  props: {
    appConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    const { router } = useRouter();

    // 简易的路由堆栈
    const routeStack = ref([]);
    onMounted(() => {
      router.value.afterEach((to, from) => {
        const topRoute = routeStack.value[routeStack.value.length - 1];
        if (from?.fullPath && from.fullPath !== to?.fullPath) {
          if (to.fullPath === topRoute) {
            routeStack.value.pop();
          } else {
            routeStack.value.push(from.fullPath);
          }
        }
      });
    });

    return {
      routeStack,
    };
  },
  data() {
    return {
      visible: false,
      businessCollapsed: true,
      isMiniprogram: isMiniprogram(),
    };
  },
  computed: {
    sidebarInstance() {
      return this.$store.state.layout.sidebar;
    },
    uiInfo() {
      return this.appConfig.uiInfo || {};
    },
    isDarkMode() {
      return this.uiInfo?.navStyle?.nav === 1 && !this.disabled;
    },
    isShowNavIcon() {
      return this.envSelectVisible('env') || this.hasWidgets;
    },
    // 是否显示业务
    widgets() {
      let widgets = [];
      if (this.uiInfo.navlink) {
        const { navlink } = this.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey && w.isShow);
      }
      return widgets;
    },
    hasWidgets() {
      return Boolean(this.widgets.length);
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goHome() {
      this.$router.replace({ path: '/' });
    },
    toggleSidebar() {
      this.sidebarInstance?.toggleCollapsed();
    },
    collapseBusiness() {
      this.businessCollapsed = !this.businessCollapsed;
    },
    // 是否呈现菜单
    envSelectVisible(id) {
      const { navlink } = this.appConfig.uiInfo || {};
      if (navlink && navlink.defaultNav) {
        return navlink.defaultNav.find(item => item.id === id)?.isShow;
      }
      return id !== 'env';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  z-index: 1001;
  height: var(--xy-header-height);
  line-height: var(--xy-header-height);
  padding: 0px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: #fff;
  .ant-menu {
    background: transparent;
  }
}
.divider{
  margin: 12px 0;
}

.vertical-center{
  display: flex;
  align-items: center
}

.icon-button{
  font-size: 20px;
  padding: 16px 12px;
}

#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  background-color: var(--wuji-primary-bg-color-dark);
  .title {
    color: var(--wuji-text-color-dark)
  }
}
</style>
