
import { CATEGORY } from './const';
import { nodeDiff3Text } from '@/utils/merge/textMerge';
export default [
  {
    id: 'utils:diff3',
    name: '三路合并',
    desc: '可以对两个版本内容基于基准版本进行对比合并',
    category: CATEGORY.LOGIC,
    paramsSchema: {
      type: 'object',
      fields: [
        {
          id: 'origin',
          title: '基准内容',
          type: 'string',
          default: '',
          'uc:allowInterop': true,
        },
        {
          id: 'remote',
          title: '远程内容',
          type: 'string',
          default: '',
          'uc:allowInterop': true,
        },
        {
          id: 'local',
          title: '当前内容',
          type: 'string',
          default: '',
          'uc:allowInterop': true,
        },
        {
          id: 'showTip',
          title: '提示合并结果',
          type: 'boolean',
          default: '',
          'uc:allowInterop': true,
        },
      ],
    },
    returnValue: '合并的结果',
    execute: async (ctx, params) => {
      const { origin, remote, local, showTip } = params;
      const result = await nodeDiff3Text(origin, remote, local, { type: 'text/plain', showTip, remainEmptyLine: true });
      if (result.success) {
        return result.data;
      }
      throw new Error('取消合并');
    },
  },
];
