import { Modal, message } from 'ant-design-vue';
import {
  XySourceSelectorMeta,
  XySourceActionEditMeta,
  XyHttpRequestConfigMeta,
} from '../paramsFormComponents';
import workflow from './workflow';
import qs from 'query-string';
import fetch from '../../../utils/fetch';
import { CATEGORY } from './const';


// (废弃): 改用数据源的刷新列表行为
export const refreshAtom = {
  id: 'xyEvent:w:refresh',
  name: '刷新数据',
  category: CATEGORY.DATASOURCE,
  visible: false,
  async execute(ctx) {
    const { rootContext } = ctx.renderer;  // xy 上下文
    rootContext.emitComponentEvent('w:refresh');
  },
};
// (旧版)从数据源重新拉数据
export const loadDataAtom = {
  id: 'xy:source:loadData',
  name: '(旧版)从数据源重新拉数据',
  visible: false,
  category: CATEGORY.DATASOURCE,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'sourceId',
        type: 'string',
        title: '数据源ID',
        ui: { type: 'XySourceSelector' },
      },
    ],
  },
  paramsFormComponents: [
    XySourceSelectorMeta,
  ],
  async execute(ctx, params) {
    const { sourceId } = params;
    const sourceController = ctx.renderer.sources[sourceId];

    const ans = await sourceController.loadData();
    return ans;
  },
};
// (旧版)提交修改到数据源
export const submitAtom = {
  id: 'xy:source:submit',
  name: '(旧版)提交修改到数据源',
  visible: false,
  category: CATEGORY.DATASOURCE,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'sourceId',
        type: 'boolean',
        title: '数据源ID',
        ui: { type: 'XySourceSelector' },
      },
      {
        id: 'actionType',
        type: 'string',
        title: '行为类型',
        enum: [
          { label: '自动(创建/更新)', value: 'upsert' },
          { label: '[单条模式] 创建', value: 'createSingle' },
          { label: '[单条模式] 更新', value: 'updateSingle' },
          { label: '[单条模式] 删除', value: 'deleteSingle' },
          { label: '[列表模式] 更新列表', value: 'updateList' },
        ],
      },
      {
        id: 'reloadData',
        type: 'boolean',
        title: '操作完成后，重新载入表单数据',
        default: true,
      },
    ],
  },
  paramsFormComponents: [
    XySourceSelectorMeta,
  ],
  async execute(ctx, params) {
    const { sourceId, actionType, reloadData } = params;
    const sourceController = ctx.renderer.sources[sourceId];

    const ans = await sourceController[actionType]();
    if (!ans || ans.code !== 200) {
      const errMsg = `操作失败：（错误码${ans?.code}）${ans?.message || ans?.error}`;
      Modal.error({
        title: '操作失败',
        content: errMsg,
      });
      throw new Error(errMsg);
    }

    if (reloadData) await sourceController.loadData();
    return ans.data;
  },
  returnValue: '数据源操作结果（创建模式下，为型如 { "id": xxxx } 的对象）',
};
// (旧版)审批驳回
export const approveRejectAtom = {
  id: 'xy:source:approveReject',
  name: '(上古版)审批驳回',
  category: CATEGORY.WORKFLOW,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'sourceId',
        type: 'string',
        title: '数据源ID',
        ui: { type: 'XySourceSelector' },
      },
      {
        id: 'comment',
        type: 'string',
        title: '审批意见',
      },
    ],
  },
  paramsFormComponents: [
    XySourceSelectorMeta,
  ],
  async execute(ctx, params) {
    const result = await workflow.transition(ctx, params, 'reject');
    return result;
  },
};
// (旧版)审批同意
export const approvePassAtom = {
  id: 'xy:source:approvePass',
  name: '(上古版)审批同意',
  category: CATEGORY.WORKFLOW,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'sourceId',
        type: 'string',
        title: '数据源ID',
        ui: { type: 'XySourceSelector' },
      },
    ],
  },
  paramsFormComponents: [
    XySourceSelectorMeta,
  ],
  async execute(ctx, params) {
    const result = await workflow.transition(ctx, params, 'pass');
    return result;
  },
};
// (旧版)发起流程
export const workflowStartAtom = {
  id: 'xy:source:workflowStart',
  name: '(上古版)发起流程',
  category: CATEGORY.WORKFLOW,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'sourceId',
        type: 'string',
        title: '数据源ID',
        ui: { type: 'XySourceSelector' },
      },
      {
        id: 'objectId',
        type: 'string',
        title: '新建对象ID',
      },
    ],
  },
  paramsFormComponents: [
    XySourceSelectorMeta,
  ],
  async execute(ctx, params) {
    const result = await workflow.start(ctx, params);
    return result;
  },
};
// 审批同意(废弃)
export const approvePassV2Atom = {
  id: 'xy:source:approvePassV2',
  name: '审批同意(废弃)',
  category: CATEGORY.WORKFLOW,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '流程实例ID',
      },
      {
        id: 'currentStatus',
        type: 'string',
        title: '当前状态',
      },
      {
        id: 'payload',
        type: 'string',
        title: '数据',
      },
      {
        id: 'comment',
        type: 'string',
        title: '审批意见',
      },
    ],
  },
  paramsFormComponents: [
  ],
  async execute(ctx, params) {
    const { entityId, currentStatus, comment, payload } = params;
    if (!entityId) {
      throw new Error('流程实例 ID 不能为空');
    }
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    try {
      const result = await workflow.transitionWithId(
        projectId,
        env,
        entityId,
        { operation: 'pass', currentStatus, comment, payload },
      );
      return result;
    } catch (error) {
      message.error('审批失败');
      throw error;
    }
  },
};
// 审批驳回(废弃)
export const approveRejectV2Atom = {
  id: 'xy:source:approveRejectV2',
  name: '审批驳回(废弃)',
  category: CATEGORY.WORKFLOW,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '流程实例ID',
      },
      {
        id: 'currentStatus',
        type: 'string',
        title: '当前状态',
      },
      {
        id: 'payload',
        type: 'string',
        title: '数据',
      },
      {
        id: 'comment',
        type: 'string',
        title: '审批意见',
      },
    ],
  },
  paramsFormComponents: [
  ],
  async execute(ctx, params) {
    const { entityId, comment, currentStatus, payload } = params;
    if (!entityId) {
      throw new Error('流程实例 ID 不能为空');
    }
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const result = await workflow.transitionWithId(
      projectId,
      env,
      entityId,
      { operation: 'reject', currentStatus, comment, payload },
    );
    return result;
  },
};
// 发送HTTP请求被数据源调用代替，默认下线该行为原子
export const invokeHttpRequestAtom = {
  id: 'xyEvent:invokeHttpRequest',
  name: '发送HTTP请求',
  category: CATEGORY.DATASOURCE,
  desc: '* 以上所有输入框都支持填写插值表达式',
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'method',
        type: 'string',
        title: '请求方法',
        enum: [
          { label: 'GET', value: 'GET' },
          { label: 'POST', value: 'POST' },
          { label: 'DELETE', value: 'DELETE' },
          { label: 'PUT', value: 'PUT' },
          { label: 'HEAD', value: 'HEAD' },
          { label: 'OPTIONS', value: 'OPTIONS' },
          { label: 'PATCH', value: 'PATCH' },
        ],
      },
      {
        id: 'url',
        type: 'string',
        title: 'URL',
      },
      {
        id: 'content',
        type: 'object',
        title: '',
        'uc:allowInterop': true,
        ui: { type: 'XyHttpRequestConfig' },
        fields: [{
          id: 'headers',
          type: 'array',
          items: {
            type: 'object',
            fields: [
              { id: 'name', type: 'string' },
              { id: 'content', type: 'string' },
            ],
          },
          default: [],
        }, {
          id: 'body',
          type: 'string',
          default: '{}',
        }, {
          id: 'querys',
          type: 'array',
          items: {
            type: 'object',
            fields: [
              { id: 'name', type: 'string' },
              { id: 'content', type: 'string' },
            ],
          },
        }],
      },
      {
        id: 'withCredentials',
        type: 'boolean',
        title: '请求是否带上Cookie',
        default: false,
      },
      {
        id: 'timeout',
        type: 'number',
        title: '超时时间(毫秒)',
        default: true,
        default: 60000,
      },
      {
        id: 'errorText',
        type: 'string',
        title: '请求失败的提示文案',
        default: true,
        default: '',
      },
    ],
  },
  paramsFormComponents: [
    XyHttpRequestConfigMeta,
  ],
  returnValue: '请求的响应结果',
  async execute(ctx, params) {
    const { method, url, content, withCredentials, timeout, errorText } = params;
    const { headers, body, querys } = content;
    let requestUrl = url;
    if (querys) {
      const querysObj = querys.reduce((obj, query) => ({ ...obj, [query.name]: [query.content] }), {});
      const querysString = qs.stringify(querysObj);
      if (querysString) requestUrl += `?${querysString}`;
    }
    try {
      const headerObj = headers.reduce((obj, item) => ({ ...obj, [item.name]: item.content }), {});
      const result = await fetch(
        requestUrl, Object.assign({
          method,
          headers: headerObj,
          credentials: withCredentials ? 'include' : 'omit',
        }, (method === 'HEAD' || method === 'GET') ? {} : { body }),
        timeout, false, false,
      );
      return result;
    } catch (err) {
      // 请求失败，流程终止
      message.error(errorText ? errorText : `请求失败${err.message}`);
      ctx.abort();
    }
  },
};
// 数据源操作（弃用）
export const dispatchAtom = {
  id: 'xy:source:dispatch',
  name: '数据源操作（弃用）',
  category: CATEGORY.DATASOURCE,
  visible: false,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'action',
        type: 'any',
        title: '',
        default: { sourceId: '', action: '' },
        ui: { type: 'XySourceActionEdit' },
      },
    ],
  },
  paramsFormComponents: [
    XySourceActionEditMeta,
  ],
  async execute(ctx, params) {
    const { sourceId, action } = params.action || {};
    const sourceController = ctx.renderer.sources[sourceId];

    const ans = await sourceController.dispatch(action);
    return ans;
  },
  returnValue: '数据源操作结果（创建模式下，为型如 { "id": xxxx } 的对象）',
};

export default [
  refreshAtom,
  loadDataAtom,
  submitAtom,
  approveRejectAtom,
  approvePassAtom,
  workflowStartAtom,
  approvePassV2Atom,
  approveRejectV2Atom,
  invokeHttpRequestAtom,
  dispatchAtom,
];
