import { computed, defineComponent, ref, onMounted } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { permissionApi as services } from '@/services';
import { PermissionType, PermissionTypeMap, PermissionTypeDesc } from './constants';
import { hasPanguMapping, usePageList } from './utils';
import { fetchAuthSetting } from '@/composables/useAuthSetting';
import { injectPermState } from './PermState';
export default defineComponent({
    name: 'PermissionForm',
    props: {
        projectId: {
            type: String,
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        permission: {
            type: Object,
            default: null,
        },
        permissionList: {
            type: Array,
            default: [],
        },
    },
    setup(props) {
        const formRef = ref(null);
        const permState = injectPermState();
        const form = ref(props.permission
            ? { relatedPages: '', ...props.permission }
            : { name: '', cname: '', type: PermissionType.PAGE, relatedPages: '' });
        const relatedPageList = ref(form.value.relatedPages?.split(',').filter(Boolean) ?? []);
        const { pageList, fetchPageList } = usePageList(props.projectId, props.env);
        const isEdit = computed(() => !!props.permission);
        const enabledPangu = ref(false);
        const fetchCheckPangu = async () => {
            const authSetting = await fetchAuthSetting(props.projectId, true);
            enabledPangu.value = hasPanguMapping(authSetting, props.env);
        };
        const types = ref([PermissionType.PAGE, PermissionType.API, PermissionType.SET]);
        onMounted(() => {
            if (!isEdit.value && permState.panguMappingEnabled.value) {
                form.value.type = PermissionType.MODULE;
                types.value = [PermissionType.MODULE, PermissionType.API];
            }
        });
        const save = async () => {
            try {
                await formRef.value.validate();
            }
            catch (error) {
                return '';
            }
            try {
                isEdit.value
                    ? await services.updatePermission(props.projectId, props.env, form.value)
                    : await services.createPermission(props.projectId, props.env, form.value);
                message.success('已保存');
                return form.value.name;
            }
            catch (error) {
                message.error('保存失败');
            }
            return '';
        };
        return {
            pageList,
            fetchPageList,
            isEdit,
            form,
            relatedPageList,
            formRef,
            save,
            fetchCheckPangu,
            enabledPangu,
            permState,
            types,
        };
    },
    created() {
        this.fetchPageList();
        this.fetchCheckPangu();
    },
    render() {
        const checkName = (rule, value, callback) => {
            if (!this.isEdit && !/^[/\w.\\-]+$/.test(value)) {
                callback('有效字符：英文字母、数字、下划线、"/"以及"-"');
                return;
            }
            if (!this.isEdit && this.permissionList.map(x => x.name).includes(value)) {
                callback('权限重复');
                return;
            }
            callback();
        };
        const rules = {
            name: [
                { required: true, message: '必填' },
                { max: 128, message: '最大长度 128' },
                { validator: checkName },
            ],
            cname: [
                { required: true, message: '必填' },
                { max: 36, message: '最大长度 36' },
            ],
        };
        return (<div>
        <a-form-model ref="formRef" props={{ model: this.form }} rules={rules} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left">
          <a-form-model-item label="权限类型">
            <div>
              {this.isEdit
                ? PermissionTypeMap[this.form.type]
                : <a-radio-group v-model={this.form.type} disabled={this.isEdit} button-style="solid">
                      {this.types.map((type) => <a-radio-button key={type} value={type}>{PermissionTypeMap[type]}</a-radio-button>)}
                    </a-radio-group>}
              <div style="color:#9e9e9e">{PermissionTypeDesc[this.form.type]}</div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="权限ID" prop="name" required>
            <a-input v-model={this.form.name} max-length={128} disabled={this.isEdit}/>
          </a-form-model-item>
          <a-form-model-item label="权限名称" prop="cname" required>
            <a-input v-model={this.form.cname} max-length={36}/>
          </a-form-model-item>
          {this.form.type === PermissionType.PAGE
                ? <a-form-model-item label="关联页面">
                <a-select v-model={this.relatedPageList} mode="multiple" placeholder="请选择关联页面，不填则可在所有页面上使用" style="width:100%" onChange={() => this.form.relatedPages = this.relatedPageList.join()}>
                  {this.pageList.map(x => <a-select-option key={x.pageId} value={x.pageId}>{x.pageName}</a-select-option>)}
              </a-select></a-form-model-item>
                : null}
        </a-form-model>
      </div>);
    },
});
