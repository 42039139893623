<template>
  <div style="width: 100%">
    <div v-if="paramsSchema">
      <a-button
        size="small"
        @click="makeDefaultValue"
      >
        生成默认值
      </a-button>
      <a-tooltip>
        <span>
          <a-icon type="info" />
          参数说明
        </span>

        <div
          slot="title"
          style="margin: 0"
        >
          <ul>
            <li
              v-for="it in paramsSchema.fields"
              :key="it.id"
            >
              <code>{{ it.id }}</code>: {{ it.type }}
            </li>
          </ul>
        </div>
      </a-tooltip>
    </div>
    <expr-input
      v-model="editValue"
      type="interopable-string"
    />
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { ExprInput } from '@tencent/ui-core';
import useFlowService from '@/composables/flow/useFlowService';
import { getDefaultValue } from '@tencent/data-schema-core';

export default {
  name: 'XyFlowApproveDataEditor',
  components: { ExprInput },
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      paramsSchema: null,
    };
  },
  created() {
    this.$watch(
      () => {
        try {
          const { type, flowId, workflowId } = this.container.stub.parent.value;
          return `${type}/${type === 'workflow' ? workflowId : flowId}`;
        } catch {
          return '';
        }
      },
      (tag) => {
        if (!tag) return;
        const [type, id] = tag.split('/');

        // 拉取流程参数信息
        const promise = (async () => {
          if (!id) return;

          const { ctx } = this.designer.renderer;
          const { projectId } = ctx.config;
          if (type === 'flow') {
            const service = useFlowService({ projectId, envId: 'dev' });
            const flow = await service.getForRuntime(id);
            const arr = flow.flowGraph.params;
            if (Array.isArray(arr)) return { type: 'object', fields: arr };
          }
        })();

        this.paramsPromise = promise;
        promise.then((result) => {
          if (this.paramsPromise === promise) this.paramsSchema = result;
        });
      },
      { immediate: true },
    );
  },
  methods: {
    makeDefaultValue() {
      this.editValue = `{{ ${JSON.stringify(getDefaultValue(this.paramsSchema), null, 2)} }}`;
    },
  },
};
</script>

<style></style>
