<template>
  <div style="width:100%">
    <a-select
      v-model="editValue"
      show-search
      filter-option
      style="display: block; flex: 1;"
      @focus="reloadWorkflows"
    >
      <a-select-option
        v-for="form in workflows"
        :key="form.id"
        :value="form.id"
      >
        {{ form.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import services from '../../../pages/modules/workflow/services';

export default {
  name: 'XyWorkflowSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      sourceId: '',
      workflows: [],
      sourceList: [],
    };
  },
  created() {
    this.reloadSourceList();
    this.reloadWorkflows();
  },
  methods: {
    async reloadWorkflows() {
      const { ctx } = this.designer.renderer;
      const result = await services.fetchWorkflows(ctx.config.projectId, 'dev');
      this.workflows = result;
    },
    reloadSourceList() {
      const sources = this.designer.renderer.sources || {};
      this.sourceList = Object.values(sources).map(source => ({ type: source.config.type, config: source.config }));
    },
  },
};
</script>

<style>
</style>
