import wujiFetch from '@/utils/wujiFetch';
import { designPath } from '@config/constant';
import { message } from 'ant-design-vue';
import { PROJECT_ADVANCE_CODE_PATH, getPageletLessCodePath, getModuleCodePath } from '@components/CodeEditor/utils/builder';
import { keyBy } from 'lodash';

const baseUrl = `${designPath}/modify-record`;

export default {
  state: {
    modifyRecords: {},
  },
  actions: {
    // 根据ID获取单条记录
    async getModifyRecordsById({ rootState }, { projectId, id }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await wujiFetch(`${baseUrl}/${projectID}/${id}`, {
          method: 'GET',
        });
        return res;
      } catch (err) {
        message.error(`获取id为${id}的修改记录失败: ${err}`);
      }
    },

    // 根据类型获取记录列表
    async getModifyRecordsByType({ commit, rootState }, { projectId, type }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const records = await wujiFetch(`${baseUrl}/${projectID}?type=${type}`, {
          method: 'GET',
        });
        commit('setModifyRecordsByType', { type, records });
      } catch (err) {
        message.error(`获取${type}类型修改记录列表失败: ${err}`);
      }
    },
    // 保存修改记录
    async saveModifyRecord({ commit, state, rootState }, { projectId, record } = {}) {
      console.l;
      const { type } = record;
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await wujiFetch(
          `${baseUrl}/${projectID}`,
          {
            method: 'POST',
            body: JSON.stringify(record),
          },
        );
        const records = state.modifyRecords[type] ?? [];
        records.unshift(res);
        commit('setModifyRecordsByType', { type, records });
        return true;
      } catch (err) {
        message.error(`保存${type}类型修改记录失败: ${err.message}`);
        return false;
      }
    },
    // 保存项目的修改记录
    async saveProjectRecord({ dispatch }, { projectId, local, remote }) {
      if (!projectId) return;
      const localAdvanceConfig = local?.advanceConfig;
      const remoteAdvanceConfig = remote?.advanceConfig ?? {};
      // 项目 LessCode
      if (localAdvanceConfig) {
        Object.entries(PROJECT_ADVANCE_CODE_PATH).forEach(([key, type]) => {
          const localValue = localAdvanceConfig[key] ?? '';
          const remoteValue = remoteAdvanceConfig[key] ?? '';
          if (localValue !== remoteValue) {
            dispatch('saveModifyRecord', {
              projectId,
              record: {
                type,
                data: JSON.stringify(localValue),
              },
            });
          }
        });
      }

      // 项目模块
      const localModules = local?.lessCodeModules ?? {};
      const remoteModules = remote?.lessCodeModules ?? {};
      Object.keys(localModules).forEach((key) => {
        const localModule = localModules[key];
        const remoteModule = remoteModules[key];
        if (localModule?.code !== remoteModule?.code) {
          dispatch('saveModifyRecord', {
            projectId,
            record: {
              type: getModuleCodePath(localModule.filename),
              data: JSON.stringify(localModule.code),
            },
          });
        }
      });
    },
    // 保存页面的修改
    async savePageRecord({ dispatch }, { projectId, local, remote }) {
      const localPageConfig = local?.pageConfig;
      const remotePageConfig = remote?.pageConfig;
      if (!Array.isArray(localPageConfig) || !Array.isArray(remotePageConfig) || !projectId) return;
      const localKeys = localPageConfig.map(item => item.id);
      const localMap = keyBy(localPageConfig, 'id');
      const remoteMap = keyBy(remotePageConfig, 'id');
      localKeys.forEach((key) => {
        const localLessCode = localMap[key]?.lessCode ?? '';
        const remotePagelet = remoteMap[key];
        const remoteLessCode = remotePagelet?.lessCode ?? '';
        if ((remotePagelet && localLessCode !== remoteLessCode) || !remotePagelet) {
          dispatch('saveModifyRecord', {
            projectId,
            record: {
              type: getPageletLessCodePath(local.pageId, localMap[key].id),
              data: JSON.stringify(localLessCode),
            },
          });
        }
      });
    },
  },
  mutations: {
    setModifyRecordsByType(state, { type, records }) {
      state.modifyRecords[type] = records;
    },
  },
};
