import { OPEN_AI_HELPER } from '@config/constant';

class AiHelper {
  onOpen(callbackFn) {
    if (OPEN_AI_HELPER) {
      callbackFn?.call(null);
    }
  }
}

export const aiHelper = new AiHelper();
