<template>
  <div style="width:100%">
    <public-w-dept-rtx-select
      type="staffs"
      multiple
      :value="editValue"
      @change="change"
    />
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';

export default {
  name: 'XyRtxSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
    };
  },
  methods: {
    change(value) {
      this.$emit('change', value.join(','));
    },
  },
};
</script>

<style>
</style>
