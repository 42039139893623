import { difference } from 'lodash';
import { message, Modal } from 'ant-design-vue';
import { defineComponent, ref } from '@vue/composition-api';
import { permissionApi } from '@/services';
import { API_PERMISSION_BINDING_TYPE, COLLECTION_BINDING_TYPE, PermissionType, PermissionTypeMap } from './constants';
import { usePermission, useApiPermission, useFilterPermissions, useCloudFuncs, useCollectionCgis, useRuntimeInfo, } from './utils';
import PermissionForm from './PermissionForm';
import PermFilter from './PermFilter';
import styles from './permission.module.scss';
import ManagePermModal from './ManagePermModal';
export default defineComponent({
    name: 'PermissionList',
    components: {
        PermFilter,
    },
    props: {
        projectId: {
            type: String,
            default: window.RUNTIME_CONFIGS?.projectId || '',
        },
    },
    setup(props) {
        const { isRuntimeMode } = useRuntimeInfo();
        const env = ref('');
        const showApiModal = ref(false);
        const showManagePermModal = ref(false);
        const currentPermAsRole = ref({ name: '', cname: '', type: 'perm-set' });
        const envChangeLoading = ref(false);
        const canReadPermission = ref(false);
        const canUpdatePermission = ref(false);
        const selectedApis = ref([]);
        const binding = ref({
            type: '',
            origin: [],
        });
        const handleApiModal = (key, record) => {
            if (key === PermissionType.PAGE)
                return;
            if (key === PermissionType.SET) {
                showManagePermModal.value = true;
                currentPermAsRole.value.name = `perm-set/${record.name}`;
                currentPermAsRole.value.cname = record.cname;
                return;
            }
            binding.value.type = key;
            binding.value.record = record;
            const origin = apiPermissionList.value
                .filter(ap => ap.permission === record.name && ap.type === key)
                .map(ap => ap.name);
            binding.value.origin = [...origin];
            selectedApis.value = origin;
            showApiModal.value = true;
        };
        const handlePermissionModal = (key, record) => {
            if (key === 'delete-permission') {
                Modal.confirm({
                    title: '确认删除当前权限？',
                    content: () => (<div>
              <br />
              <div>
                {record.cname}({record.name})
              </div>
              <div>注意：同时会删除绑定，如角色-权限，接口-权限</div>
            </div>),
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: async () => {
                        await permissionApi.deletePermission(props.projectId, env.value, record.name);
                        message.success('已删除');
                        await fetchPermissions(env.value);
                    },
                });
                return;
            }
            permission.value.name = record.name;
            permission.value.cname = record.cname;
            permission.value.type = record.type;
            permission.value.relatedPages = record.relatedPages || '';
            showPermModal.value = true;
        };
        const columns = [
            {
                title: '权限ID',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: '权限名',
                dataIndex: 'cname',
                key: 'cname',
            },
            {
                title: '权限类型',
                dataIndex: 'type',
                key: 'type',
                customRender: (txt) => PermissionTypeMap[txt] || txt,
            },
            {
                title: '操作',
                customRender: (record) => (<div>
            <a-dropdown disabled={!canUpdatePermission.value}>
              <a href="javascript:;" disabled={!canUpdatePermission.value}>
                管理关联 <a-icon type="down"></a-icon>
              </a>
              <a-menu slot="overlay" onClick={({ key }) => handleApiModal(key, record)}>
                {record.type === PermissionType.SET
                        ? <a-menu-item key={PermissionType.SET}>权限集合</a-menu-item>
                        : null}
                {record.type === PermissionType.API
                        ? <a-menu-item key={API_PERMISSION_BINDING_TYPE}>云函数接口</a-menu-item>
                        : null}
                {record.type === PermissionType.API
                        ? <a-menu-item key={COLLECTION_BINDING_TYPE}>数据源接口</a-menu-item>
                        : null}
                {[PermissionType.MODULE, PermissionType.PAGE].includes(record.type)
                        ? <a-menu-item key={PermissionType.PAGE} disabled>无</a-menu-item>
                        : null}
              </a-menu>
            </a-dropdown>
            <a-dropdown style="margin-left:12px" disabled={!canUpdatePermission.value}>
              <a className="ant-dropdown-link" disabled={!canUpdatePermission.value}>
                更多 <a-icon type="down"></a-icon>
              </a>
              <a-menu slot="overlay" onClick={({ key }) => handlePermissionModal(key, record)}>
                <a-menu-item key="edit-permission">修改权限</a-menu-item>
                <a-menu-item key="delete-permission">删除权限</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>),
            },
        ];
        const expandedRowKeys = ref([]);
        const showPermModal = ref(false);
        const permission = ref({ name: '', cname: '', type: 'module', relatedPages: '' });
        const { permissions, fetchPermissions } = usePermission(props.projectId);
        const { apiPermissionList, fetchApiPermissionList } = useApiPermission(props.projectId);
        const { keyword, filterPermissions } = useFilterPermissions(permissions, apiPermissionList);
        const { cloudFuncs, fetchCloudFuncs } = useCloudFuncs(props.projectId);
        const { cgis, fetchCollectionCgis } = useCollectionCgis(props.projectId);
        const createBinding = async (data) => {
            await permissionApi.createApiBinding(props.projectId, env.value, data);
        };
        const unbind = async (id) => {
            await permissionApi.unbind(props.projectId, env.value, id);
        };
        return {
            env,
            isRuntimeMode,
            canReadPermission,
            canUpdatePermission,
            permissions,
            apiPermissionList,
            selectedApis,
            keyword,
            filterPermissions,
            columns,
            showPermModal,
            showApiModal,
            showManagePermModal,
            currentPermAsRole,
            envChangeLoading,
            binding,
            permission,
            expandedRowKeys,
            cloudFuncs,
            cgis,
            fetchPermissions,
            fetchApiPermissionList,
            fetchCloudFuncs,
            fetchCollectionCgis,
            createBinding,
            unbind,
        };
    },
    watch: {
        env: {
            async handler() {
                if (!this.env)
                    return;
                if (!this.canReadPermission)
                    return;
                this.envChangeLoading = true;
                // 切换环境时置空
                this.permissions = [];
                await Promise.all([
                    this.fetchPermissions(this.env),
                    this.fetchApiPermissionList(this.env),
                    this.fetchCloudFuncs(this.env),
                    this.fetchCollectionCgis(this.env),
                ]);
                this.envChangeLoading = false;
            },
            immediate: true,
        },
    },
    render() {
        const showCreatePermission = () => {
            this.showPermModal = true;
            this.permission = {
                name: '',
                cname: '',
                type: 'module',
            };
        };
        const save = async () => {
            const ok = await this.$refs.permissionFormRef.save();
            if (ok) {
                this.showPermModal = false;
                this.fetchPermissions(this.env);
            }
        };
        const clickExpandIcon = (record) => {
            let keys = [...this.expandedRowKeys];
            if (keys.includes(record.name)) {
                keys = keys.filter(k => k !== record.name);
            }
            else {
                keys.push(record.name);
            }
            this.expandedRowKeys = keys;
        };
        const expand = ({ record }) => {
            const show = !!record.apiPermList;
            return (<span style="margin-right:8px">
          <a-icon style={{ visibility: show ? 'visible' : 'hidden' }} type={this.expandedRowKeys.includes(record.name) ? 'down' : 'right'} onClick={() => clickExpandIcon(record)}/>
        </span>);
        };
        const renderExpanded = (record) => {
            const scfs = record.apiPermList?.filter(i => i.type === API_PERMISSION_BINDING_TYPE);
            const cgis = record.apiPermList?.filter(i => i.type === COLLECTION_BINDING_TYPE);
            return (<div>
          {scfs?.length ? (<div style="margin-bottom:4px">
              <div>关联云函数 ({scfs.length})</div>
              <div style="margin-top:4px">
                {scfs.map(s => (<a-tag key={s.name}>{s.name}</a-tag>))}
              </div>
            </div>) : null}
          {cgis?.length ? (<div style="margin-top:8px">
              <div>关联数据源 ({cgis.length})</div>
              <div style="margin-top:4px">
                {cgis.map(s => (<a-tag key={s.name}>{s.name}</a-tag>))}
              </div>
            </div>) : null}
        </div>);
        };
        const updateBinding = async () => {
            const { origin, type, record } = this.binding;
            const createList = difference(this.selectedApis, origin).map((name) => {
                const apiList = type === 'cloud-function' ? this.cloudFuncs : this.cgis;
                const api = apiList.find(api => api.name === name);
                return {
                    name,
                    method: api.method,
                    api: api.api,
                    permission: this.binding.record.name,
                    type: this.binding.type,
                };
            });
            const deleteList = difference(origin, this.selectedApis)
                .map((name) => {
                const apiPerm = this.apiPermissionList
                    .find(ap => ap.permission === record?.name && ap.type === type && ap.name === name);
                return apiPerm?._id;
            })
                .filter(Boolean);
            try {
                await Promise.all([
                    ...createList.map(i => this.createBinding(i)),
                    ...deleteList.map(id => this.unbind(id)),
                ]);
                this.fetchApiPermissionList(this.env);
                this.showApiModal = false;
                message.success('保存成功');
            }
            catch (error) {
                message.error('保存失败');
            }
        };
        return (<div>
        <div class={styles.operationAndSearch}>
          {this.canUpdatePermission ? (<a-button type="primary" onClick={() => showCreatePermission()}>
              新建权限
            </a-button>) : (<a-alert message={this.canReadPermission ? '无权更新，只读模式' : '无权查看'} type="warning" show-icon/>)}
          <PermFilter projectId={this.projectId} showEnv={!this.isRuntimeMode} searchPlaceholder="请输入权限名搜索" onEnvChange={(data) => {
                this.env = data.env;
                this.canReadPermission = data.canReadPermission;
                this.canUpdatePermission = data.canUpdatePermission;
            }} onSearchChange={(q) => (this.keyword = q)}/>
        </div>
        <div>
          <a-table loading={this.envChangeLoading} columns={this.columns} dataSource={this.filterPermissions} rowKey="name" expandIcon={expand} expandedRowKeys={this.expandedRowKeys} expandedRowRender={(record) => renderExpanded(record)}></a-table>
        </div>

        <a-modal title={this.permission.name ? '编辑权限' : '新建权限'} visible={this.showPermModal} destroyOnClose={true} width={540} onOk={() => save()} onCancel={() => (this.showPermModal = false)}>
          <PermissionForm ref="permissionFormRef" projectId={this.projectId} env={this.env} permission={this.permission.name ? this.permission : null} permissionList={this.permissions}/>
        </a-modal>
        <a-modal title={this.binding.type === API_PERMISSION_BINDING_TYPE ? '管理云函数接口' : '管理数据源接口'} visible={this.showApiModal} destroyOnClose={true} okText="保存" onOk={() => updateBinding()} onCancel={() => (this.showApiModal = false)}>
          <a-form-model labelAlign="left" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <a-form-model-item label="权限ID">
              <span>{this.binding.record?.name}</span>
            </a-form-model-item>
            {this.binding.type === API_PERMISSION_BINDING_TYPE && (<a-form-model-item label="关联云函数">
                <a-select mode="multiple" v-model={this.selectedApis}>
                  {this.cloudFuncs.map(c => (<a-select-option key={c.name}>
                      <span>{c.name}</span>
                      <span style={{ fontStyle: 'italic', color: '#9f9f9f' }}> {`${c.method}:${c.api}`}</span>
                    </a-select-option>))}
                </a-select>
              </a-form-model-item>)}
            {this.binding.type === COLLECTION_BINDING_TYPE && (<a-form-model-item label="关联数据源接口">
                <a-select mode="multiple" v-model={this.selectedApis}>
                  {this.cgis.map(c => (<a-select-option key={c.name}>
                      <span>{c.name}</span>
                      <span style={{ fontStyle: 'italic', color: '#9f9f9f' }}> {c.desc}</span>
                    </a-select-option>))}
                </a-select>
              </a-form-model-item>)}
          </a-form-model>
        </a-modal>
        <ManagePermModal roleOrPerm={this.currentPermAsRole} projectId={this.projectId} env={this.env} showModal={this.showManagePermModal} canUpdatePermission={this.canUpdatePermission} isPermSet={true} onHide={() => this.showManagePermModal = false}/>
      </div>);
    },
});
