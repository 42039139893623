export const PAGE_TYPE = {
  CONTENT: 'content',
  PAGELET: 'pagelet',
  NAV: 'nav',
  SIDEBAR: 'sidebar',
  HEADER: 'header',
  FOOTER: 'footer',
  DATA_TABLE: 'datatable',
  MODAL: 'modal',
  CODE: 'code',
};
export const pageType = [
  {
    value: 'content',
    label: '页面',
  },
  {
    value: 'pagelet',
    label: '页面片',
  },
  {
    value: 'nav',
    label: '导航',
  },
  {
    value: 'sidebar',
    label: '菜单',
  },
  {
    value: 'header',
    label: '页头',
  },
  {
    value: 'footer',
    label: '页脚',
  },
  {
    value: 'modal',
    label: '弹窗',
  },
];

export const layoutType = [
  {
    value: 'header-sidebar-content',
    label: '导航-菜单-内容',
  },
  {
    value: 'header-content',
    label: '导航-内容',
  },
  {
    value: 'sidebar-content',
    label: '菜单-内容',
  },
  {
    value: 'content',
    label: '内容',
  },
];

export const scrollModeOptions = [
  {
    value: 'system',
    label: '跟随应用',
  },
  {
    value: 'fix-top',
    label: '固定',
  },
  {
    value: 'not-fix-top',
    label: '不固定',
  },
];

export const watermarkOptions = [
  {
    value: 'system',
    label: '跟随应用',
  },
  {
    value: 'on',
    label: '开启',
  },
  {
    value: 'off',
    label: '关闭',
  },
];
