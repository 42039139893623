<template>
  <div
    v-if="comps.length > 0"
    id="xy-client-layout-nav-comps"
  >
    <template v-for="(c, cIndex) of comps">
      <NavCompsRender
        v-if="loadedComponents.includes(c.name)"
        ref="comps"
        :key="cIndex"
        :comp="c"
        :mode="mode"
      />
    </template>
  </div>
</template>

<script>
import { getCompsList } from '@/utils/comps-loader';
import NavCompsRender from './NavCompsRender';

export default {
  name: 'NavComps',
  components: {
    NavCompsRender,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    position: {
      type: String,
      default: 'right',
    },
    mode: {
      type: String,
      default: 'runtime',
    },
  },
  data() {
    return {
      isComponentLoaded: false,
      loadedComponents: [],
    };
  },
  computed: {
    compsKey() {
      return [...new Set(this.comps.map(c => c.name))].join(';');
    },
    comps() {
      const currentPageId = this.$route?.meta?.pageId;
      const dynamicComps = this.project?.uiInfo?.navlink?.dynamicComps ?? [];
      return dynamicComps.filter(c => c.isShow
      && this.matchPosition(c)
      && this.matchPage(c, currentPageId));
    },
  },
  watch: {
    comps: {
      async handler(comps) {
        const keys = comps.map(c => c.name).filter(name => !this.loadedComponents.includes(name));
        this.loadNecessaryComponents(keys);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async loadNecessaryComponents(keys) {
      if (Array.isArray(keys) && keys.length > 0) {
        await getCompsList({
          compsKey: keys,
          register: true,
        });
        this.loadedComponents.push(...keys);
      }
    },

    // 根据组件的展示位置过滤
    matchPosition(comp) {
      if (this.position === 'right') {
        // 兼容没有position字段的旧数据，过去默认在右边
        return comp.position === 'right' || !comp.position;
      }
      return comp.position === this.position;
    },

    // 根据页面过滤
    matchPage(comp, pageId) {
      if (!comp.pages || !pageId) return true;
      return comp.pages.includes(pageId);
    },
  },
};
</script>

<style lang="scss" scoped>
#xy-client-layout-nav-comps {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
