import { get } from 'lodash';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { usePermission, useBuiltinPermissionList, usePageList } from './utils';
import { PAGE_PERM_MAPPING, PAGE_PERMISSIONS, PermissionType } from './constants';
import { permissionApi } from '@/services';
import { message } from 'ant-design-vue';
import { checkPermission } from '@/pages/modules/permission/utils';
const authFromMap = {
    datasource: '第三方鉴权',
    'custom-auth': '平台配置-运行时自定义鉴权',
};
export default defineComponent({
    name: 'DebugModal',
    model: {
        event: 'change',
        prop: 'showModal',
    },
    props: {
        projectId: {
            type: String,
            default: window.RUNTIME_CONFIGS?.projectId || '',
        },
        env: {
            type: String,
            default: 'dev',
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        roles: {
            type: Array,
            default: () => [],
        },
        bizList: {
            type: Array,
            default: null,
        },
    },
    setup(props) {
        const userId = ref('');
        const permId = ref('');
        const pageId = ref('');
        const bizId = ref('');
        const permQ = ref('');
        const debugResult = ref(null);
        const debugging = ref(false);
        const { pageList: pages, fetchPageList: fetchPages } = usePageList(props.projectId, props.env);
        const { permissions, fetchPermissions } = usePermission(props.projectId);
        const builtinPagePerms = PAGE_PERMISSIONS.map(i => ({ name: i, cname: get(PAGE_PERM_MAPPING, i) }));
        const { filterPermissions: builtinPerms, fetchBuiltinPermissionList } = useBuiltinPermissionList(props.projectId);
        onMounted(async () => {
            await fetchPages();
            await fetchPermissions(props.env);
            await fetchBuiltinPermissionList(props.env);
        });
        const relatedPageList = computed(() => {
            if (!permId.value)
                return [];
            if (builtinPerms.value.some(x => x.name === permId.value))
                return [];
            const perm = permissions.value.find(x => x.name === permId.value);
            if (!perm)
                return pages.value;
            if (perm.type !== PermissionType.PAGE)
                return [];
            if (!perm.relatedPages)
                return pages.value;
            const related = perm.relatedPages.split(',');
            const result = pages.value.filter(x => related.includes(x.pageId));
            return result;
        });
        const debugBtnDisabled = computed(() => {
            if (!userId.value || !permId.value)
                return true;
            if (relatedPageList.value.length && !pageId.value)
                return true;
            return false;
        });
        const filterBuiltinPerms = computed(() => {
            const arr = [...builtinPagePerms, ...builtinPerms.value];
            const result = arr.filter(x => [x.name, x.cname].join('\n').includes(permQ.value));
            return result;
        });
        const filterCustomPerms = computed(() => {
            const arr = [...permissions.value];
            const result = arr.filter(x => [x.name, x.cname].join('\n').includes(permQ.value));
            return result;
        });
        watch(() => relatedPageList.value.length, (newLen) => {
            if (!newLen)
                pageId.value = '';
        });
        watch(() => [userId.value, permId.value, pageId.value, bizId.value], () => debugResult.value = null);
        return {
            userId,
            permId,
            pageId,
            bizId,
            relatedPageList,
            debugBtnDisabled,
            pages,
            permissions,
            builtinPagePerms,
            builtinPerms,
            debugResult,
            debugging,
            permQ,
            filterBuiltinPerms,
            filterCustomPerms,
        };
    },
    render() {
        const debug = async () => {
            this.debugging = true;
            let path = '';
            if (this.pageId) {
                const page = this.relatedPageList.find(x => x.pageId === this.pageId);
                path = page?.path || '';
            }
            try {
                const result = await permissionApi.debugPermission(this.projectId, this.env, {
                    rtx: this.userId,
                    permission: this.permId,
                    pageId: this.pageId,
                    path,
                    bizId: this.bizId,
                });
                this.debugResult = result;
            }
            catch (error) {
                message.error('检测用户权限失败');
            }
            finally {
                this.debugging = false;
            }
        };
        const renderAuthFrom = () => {
            const authResultFrom = this.debugResult?.authResultFrom;
            if (!authResultFrom)
                return <span>无极</span>;
            return <span>{authFromMap[authResultFrom]}</span>;
        };
        const renderAuthResult = () => <span>{this.debugResult?.authResult ? '✅ 持有权限' : '🚫 无权限'}</span>;
        const renderWhy = () => {
            if (this.debugResult?.userView.isAdmin)
                return <span>
        用户有管理员身份
        <a-tooltip title="管理员身份可能来自：应用信息里的管理者、开发环境的开发者，或权限管理里的管理员角色成员、或应用组管理者等">
          <a-icon type="info-circle" style="margin-left:8px"></a-icon>
        </a-tooltip>
      </span>;
            const roleCNames = [];
            this.debugResult?.userView.roles.forEach((x) => {
                const result = checkPermission({ isAdmin: false, roles: [x] }, this.permId, this.pageId, { givenBizId: this.bizId, givenPermList: this.permissions });
                if (result) {
                    const role = this.roles.find(r => r.name === x.role);
                    role && roleCNames.push(role.cname);
                }
            });
            return <span>
        <span style="font-style:italic;font-size:10px;margin-right:8px">来自角色</span>
        {roleCNames.length ? roleCNames.map(i => <a-tag key={i}>{i}</a-tag>) : '-'}
      </span>;
        };
        const renderDebugResult = () => {
            if (!this.debugResult)
                return <a-empty />;
            return <a-form-model labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <a-form-model-item label="鉴权来源">
          {renderAuthFrom()}
        </a-form-model-item>
        <a-form-model-item label="鉴权结果">
          {renderAuthResult()}
        </a-form-model-item>
        {this.debugResult.authResult && !this.debugResult.authResultFrom
                    ? <a-form-model-item label="持有权限分析">{renderWhy()}</a-form-model-item>
                    : null}
      </a-form-model>;
        };
        return <div>
      <a-modal title="检测用户权限" width={960} visible={this.showModal} onCancel={() => this.$emit('change', false)}>
        <span slot="footer">
          <a-button onClick={() => this.$emit('change', false)}>关闭</a-button>
        </span>
        <div style={'display:grid;grid-template-columns:1fr 1fr'}>
          <a-form-model label-col={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <a-form-model-item label="用户">
              <public-w-dept-rtx-select type="staff" placeholder="请选择" v-model={this.userId}>
              </public-w-dept-rtx-select>
            </a-form-model-item>
            <a-form-model-item label="权限">
              <a-select placeholder="请选择" v-model={this.permId} allow-clear show-search onSearch={(q) => this.permQ = (q || '').toLowerCase()}>
                <a-select-opt-group label="内置权限">
                  {this.filterBuiltinPerms.map(x => <a-select-option key={x.name} value={x.name}>
                      {`${x.cname}(${x.name})`}
                    </a-select-option>)}
                </a-select-opt-group>
                <a-select-opt-group label="自定义权限">
                  {this.filterCustomPerms.map(x => <a-select-option key={x.name} value={x.name}>
                      {`${x.cname}(${x.name})`}
                    </a-select-option>)}
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            {this.relatedPageList.length
                ? <a-form-model-item label="相关页面">
                    <a-select v-model={this.pageId} allow-clear show-search filter-option={(q, option) => {
                        if (!q)
                            return true;
                        const page = this.relatedPageList.find(x => x.id === option.key);
                        const str = [page?.pageName, page?.pageId].filter(Boolean).join('\n')
                            .toLowerCase();
                        return str.includes(q.toLowerCase());
                    }}>
                      {this.relatedPageList.map(x => <a-select-option key={x.id} value={x.pageId}>{x.pageName}</a-select-option>)}
                    </a-select>
                  </a-form-model-item>
                : null}
            {this.bizList?.length
                ? <a-form-model-item label="相关业务">
                    <a-select placeholder="选择隔离业务" v-model={this.bizId} allow-clear show-search filter-option={(q, option) => {
                        if (!q)
                            return true;
                        const biz = this.bizList.find(x => x.value === option.key);
                        const str = [biz?.label, biz?.value].join('\n').toLowerCase();
                        return str.includes(q.toLowerCase());
                    }}>
                    {this.bizList
                        .filter(x => !!x.value)
                        .map(x => <a-select-option key={x.value} value={x.value}>{x.label}({x.value})</a-select-option>)}
                    </a-select>
                  </a-form-model-item>
                : null}
            <a-form-model-item wrapperCol={{ offset: 8 }}>
              <a-button type="primary" disabled={this.debugBtnDisabled} onClick={debug}>检测</a-button>
            </a-form-model-item>
          </a-form-model>
          <div>
            <a-spin spinning={this.debugging}>{renderDebugResult()}</a-spin>
          </div>
        </div>
      </a-modal>
    </div>;
    },
});
