var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Vue } from 'vue-property-decorator';
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import styles from './XySourceMethodEdit.module.scss';
import { DataPathSelector, DataSourceMethodSelector, MethodArgumentsInput, } from '@tencent/ui-core';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import ArgumentstForm from '../../argumentsForm';
import { get, has } from 'lodash';
// @ts-ignore
import { isJSStateModel, methodEditorInjectKey } from '@tencent/wuji-source';
import MarkdownComp from '@tencent/ui-core/lib/builtin/components/Markdown';
import reporter from '@/reporter/instance/designerReport';
import { getDocLink } from '@/utils/links';
import XySourceMethodReturnSchema from './XySourceMethodReturnSchema/index.vue';
let XySourceMethodEdit = class XySourceMethodEdit extends Vue {
    designer;
    methodEditor;
    wContext;
    get argumentsTypes() {
        const items = [
            {
                type: 'none',
                label: this.is$methods ? '不传参' : '使用默认参数',
                render: this.renderNone,
            },
            {
                type: 'form',
                label: '表单传参',
                render: this.renderArgumentsForm,
            },
            {
                type: 'js',
                label: 'javascript传参',
                render: this.renderJSForm,
            },
        ];
        if (this.is$methods) {
            // 新数据源方法不需要表单传参
            items.splice(1, 1);
        }
        return items;
    }
    get showTypesRadio() {
        const { dataPath, methodName } = this.editValue;
        return dataPath && methodName;
    }
    get currentType() {
        return this.editValue.argumentsType || 'none';
    }
    set currentType(val) {
        this.editValue.argumentsType = val;
        this.commit();
    }
    get stub() {
        const { dataPath } = this.editValue;
        const stub = this.designer?.renderer?.getStubAtPath(dataPath) || null;
        return stub;
    }
    get methodSchema() {
        const { dataPath, methodName } = this.editValue;
        const { stub } = this;
        if (!dataPath || !methodName || !stub)
            return null;
        return stub.schema?.methods.find((item) => item.id === methodName);
    }
    get hasArgs() {
        if (this.is$methods) {
            return true;
        }
        const args = this.methodSchema?.arguments;
        return args?.length;
    }
    get is$methods() {
        if (!this.stub)
            return false;
        const { id, parent } = this.stub;
        return id === '$methods' && isJSStateModel(parent?.value?.stateModel);
    }
    get sourceId() {
        if (!this.editValue.dataPath) {
            return null;
        }
        const regex = /data.([^.]+)/;
        return this.editValue.dataPath.match(regex)?.[1];
    }
    get sourceLayout() {
        if (!this.sourceId)
            return;
        const layout = this.designer.rootPagelet.xySources.find((i) => i.id === this.sourceId);
        return layout;
    }
    onMethodChange(val) {
        this.editValue.methodName = val;
        this.setDefault();
        this.commit();
    }
    onDataPathChange() {
        this.goto$MethodsIfNeeded();
        this.setDefault();
        this.editValue.methodName = '';
        this.commit();
    }
    /**
     * 新数据源直接跳到$methods
     * @returns
     */
    goto$MethodsIfNeeded() {
        const { stub } = this;
        if (!stub)
            return;
        const { schema, value } = stub;
        if (!schema['xy:sourceId']) {
            return;
        }
        if (isJSStateModel(value?.stateModel)) {
            this.editValue.dataPath = `${this.editValue.dataPath}.$methods`;
        }
    }
    onArguementTypeChange(_val) {
        this.updateExtraInfo();
        this.commit();
    }
    commit() {
        this.editValue = { ...this.editValue };
    }
    updateExtraInfo() {
        if (!this.methodSchema) {
            this.$delete(this.editValue, 'extraInfo');
        }
        const args = this.methodSchema?.arguments || [];
        const index = args.findIndex(item => has(item, 'sourceRequestParamsType'));
        if (index > -1) {
            this.editValue.extraInfo = {
                requestParamsIndex: index,
                requestParamsType: get(args[index], 'sourceRequestParamsType'),
            };
        }
    }
    setDefault() {
        this.updateExtraInfo();
        this.editValue.formArguments = {};
        this.editValue.arguments = [];
    }
    onJSFormChange(val) {
        this.editValue.arguments = val;
        this.commit();
    }
    openMethod() {
        const { stub } = this;
        if (!stub)
            return;
        const sourceStub = stub.parent;
        const sourceId = sourceStub?.schema?.['xy:sourceId'];
        if (!sourceId)
            return;
        this.methodEditor.edit({
            sourceId,
            methodId: this.editValue.methodName,
            dataStub: sourceStub,
        });
        reporter.report('click', {
            eid: '编辑该方法',
            mod: '数据源方法',
        });
    }
    renderNone() {
        if (!this.editValue.methodName
            || !this.editValue.dataPath
            || this.is$methods)
            return null;
        const message = (<div>
        {'如果想自定义参数，可切换到[表单传参]或[javascript传参]进行配置。'}
        <br />
        * 查询数据的操作，参数比较固定，推荐使用[表单传参]
        <br />*
        create/update等接口的调用、或者参数比较复杂，推荐[javascript传参]
        {/* {'参数文档参考 todo'} */}
      </div>);
        return <a-alert message={message}></a-alert>;
    }
    renderArgumentsForm() {
        const args = this.methodSchema?.arguments;
        return args?.length ? (<ArgumentstForm argumentSchemas={args} v-model={this.editValue.formArguments}></ArgumentstForm>) : null;
    }
    renderJSForm() {
        const showArgs = this.is$methods && this.editValue.methodName
            ? true
            : this.methodSchema?.arguments?.length;
        return showArgs ? (<MethodArgumentsInput style={this.is$methods ? 'margin-top:10px' : ''} showDoc={!this.is$methods} getMethodSchema={() => this.methodSchema} value={this.editValue.arguments} onChange={this.onJSFormChange} showAddArg={!this.is$methods}></MethodArgumentsInput>) : null;
    }
    renderReturnValue() {
        const content = `数据源操作调用结果,结构如下:
${'```'}ts
{ 
  data?: object; //返回的数据
  success: boolean; // 操作是否成功
  message?: string; 
}
${'```'}`.trim();
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const Markdown = MarkdownComp;
        return <Markdown source={content}></Markdown>;
    }
    onDocClick() {
        window.open(getDocLink().DS_METHOD_PARAM);
    }
    render() {
        const { dataPath, methodName } = this.editValue;
        const typeInfo = this.argumentsTypes.find(item => item.type === this.currentType);
        return (<div class={styles.root}>
        <a-form-model class={styles.form} layout="horizontal">
          <a-form-item class={styles.item} label="哪个数据源">
            <DataPathSelector v-model={this.editValue.dataPath} onChange={this.onDataPathChange} filter={filterSchema}></DataPathSelector>
          </a-form-item>

          {dataPath && (<a-form-item class={[styles.item, styles.selectMethod]} label={`哪个${this.is$methods ? '方法' : '操作'}`}>
              <DataSourceMethodSelector showMethodMarkdown={false} targetDataPath={dataPath} value={methodName} onChange={this.onMethodChange}></DataSourceMethodSelector>
              {this.is$methods ? (<a-button onClick={this.openMethod} type="primary" style="margin-left: 4px;" disabled={!this.editValue.methodName}>
                  编辑该方法
                </a-button>) : null}
            </a-form-item>)}
        </a-form-model>

        {this.showTypesRadio && this.hasArgs && !this.is$methods ? (<a-radio-group class={styles.types} v-model={this.currentType} onChange={this.onArguementTypeChange}>
            {this.argumentsTypes.map(item => (<a-radio value={item.type}>{item.label}</a-radio>))}
          </a-radio-group>) : null}

        {this.is$methods ? this.renderJSForm() : typeInfo?.render()}

        {this.is$methods
                ? null
                : [<a-divider></a-divider>, this.renderReturnValue()]}

        {this.is$methods ? (<a-button style="padding:0;" icon="read" type="link" onClick={this.onDocClick}>
            如何传递参数给方法
          </a-button>) : null}
        {this.is$methods ? <xy-source-method-return-schema source={this.sourceLayout} selectedMethod={this.editValue.methodName} onOpenMethod={this.openMethod}></xy-source-method-return-schema> : null}
      </div>);
    }
};
__decorate([
    Inject(INJECT_UICORE_DESIGNER)
], XySourceMethodEdit.prototype, "designer", void 0);
__decorate([
    Inject(methodEditorInjectKey)
], XySourceMethodEdit.prototype, "methodEditor", void 0);
__decorate([
    Inject('wContext')
], XySourceMethodEdit.prototype, "wContext", void 0);
XySourceMethodEdit = __decorate([
    Component({
        name: 'XySourceMethodEdit',
        mixins: [vueComponentMixin],
        components: {
            XySourceMethodReturnSchema,
        },
    })
], XySourceMethodEdit);
export default XySourceMethodEdit;
const filterSchema = (schema) => {
    // @ts-ignore
    const res = schema?.methods?.length || (schema?.['xy:sourceId']
        && schema.fields?.find(item => item.id === '$methods'));
    return res;
};
