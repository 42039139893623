import COS from '@tencent/innercos-js-sdk';
import getXyHeaders from './getXyHeaders';
import wujiFetch from './wujiFetch';
import { addPathPrefix } from '@utils/path';
import { pick } from 'lodash';

const isInnerCos = host => !!host.match(/tencent-cloud\.com$/);
const uploadPathPrefix = window.GLOBAL_INFO.uploadPathPrefix || '/xy/';
const imageUploadPathPrefix = window.GLOBAL_INFO.imageUploadPathPrefix || '/wupload/xy/';
// 内部cos和外部cos从host获取地区有一点差异，内部cos在第一个，外部cos在第二个数组项
// 内部cos的host： sz.gfp.tencent-cloud.com
// 外部cos的host：cos.ap-guangzhou.myqcloud.com
const getRegion = (host) => {
  const innerCos = !!host.match(/tencent-cloud\.com$/);
  const hostArr = host.split('.');
  if (innerCos) {
    return hostArr[0];
  }
  return hostArr[1];
};

class Uploader {
  cosId = 'default';
  cosInfo = {};
  cosInstance;

  /**
   * cos实例初始化
   * @param cosId 默认为wuji,使用调用无极2.0server配置的无极cos; 如果指定应用的cosid，则会使用业务方自定义好的cos配置上传
   */
  async init({ cosId, projectId, fileType } = {}, timeout) {
    this.cosId = cosId || this.cosId;
    const res = await wujiFetch(`/api/xy/basic/cos/${this.cosId}?projectid=${projectId}`, {}, timeout);
    if (!res) {
      throw new Error(`cosID${this.cosId}不存在`);
    }
    this.cosInfo = res;
    const { appid, bucket, domain } = this.cosInfo;
    this.cosInstance = new COS({
      CompatibilityMode: true,
      UploadCheckContentMd5: true,
      Domain: `${bucket}-${appid}.${domain}`,
      getAuthorization: async (options, callback) => {
        try {
          const data = pick(options, ['Method', 'method', 'Headers',
            'headers', 'Host', 'host', 'Pathname', 'pathname',
            'Parameters', 'parameters', 'Query']);
          const res = await wujiFetch(`/api/xy/cos/auth/${this.cosId}?projectid=${projectId}`, {
            method: 'POST',
            body: JSON.stringify({
              options: {
                ...data,
                Query: {
                  ...(data.Query || {}),
                  fileType,
                },
              },
            }),
          });
          callback({
            Authorization: res,
          });
        } catch (err) {
          callback({
            Authorization: '',
          });
        }
      },
    });
  }

  /**
   * 上传文件方法（需根据projectId等信息进行鉴权）
   * @deprecated
   * @param {any} file 文件对象
   * @param {any} filePath 文件上传路径
   * @param {any} onProgress 上传进度处理函数
   * @returns {any}
   */
  upload({ file, filePath, onProgress, projectId, cosId, headers = {} }) {
    return new Promise(async (resolve, reject) => {
      // 如果之前没有初始过cos实例, 先初始化一个
      try {
        await this.init({ cosId, projectId, fileType: file.type });
      } catch (err) {
        reject('初始化cos实例失败');
      }
      const { bucket, publicDomain, domain, appid, pathPrefix, auth } = this.cosInfo;
      const uri = pathPrefix ? `${pathPrefix.replace(/\/$/, '')}/${filePath.replace(/^\//, '')}` : `${uploadPathPrefix}${projectId}${filePath}`;
      const options = {
        Bucket: `${bucket}-${appid}`,
        Region: getRegion(domain),
        Key: uri,
        Headers: headers,
        Body: file, // 上传文件对象
        isInnerCos: isInnerCos(domain),
        onProgress(progressData) {
          const { percent } = progressData;
          onProgress(Math.round(percent * 100));
        },
      };
      const handler = (err, data) => {
        if (err) {
          if (err.message === 'getAuthorization callback params missing "TmpSecretId"') {
            reject('当前页面没有『新建数据』权限，无法上传');
          }
          reject(err);
        }
        if (data.statusCode === 200) {
          let path = `https://${data.Location}`;
          // 开启cos跟随项目鉴权的话，默认返回一个无极的访问地址进行重定向
          if (auth === 1) {
            path = addPathPrefix(`/api/xy/file/cosfile?key=${uri}&cosid=${this.cosId}`);
          } else if (publicDomain) {
            path = path.replace(/(^https:\/\/)([.a-zA-Z0-9_-]+)(\/.*$)/, `$1${publicDomain}$3`);
          }
          resolve(decodeURI(path));
        }
      };
      // 大于100MB走分片上传，否则走简单上传
      if ((file.size || file.length) <= 1024 * 1024 * 100) {
        this.cosInstance.putObject(options, handler);
      } else {
        this.cosInstance.sliceUploadFile({
          ...options,
          SliceSize: 1024 * 1024 * 30, // 每片分片大小为30M
        }, handler);
      }
    });
  }

  /**
   * @deprecated
   * 上传图片方法（需根据projectId等信息进行鉴权）
   * @param {any} file 文件对象
   * @param {any} path 文件上传路径
   * @param {any} keepFilename 上传文件是否保持原名
   * @param {any} onProgress 上传进度处理函数
   * @returns {any}
   */
  uploadImage({ file, filePath, onProgress }, { projectId, cosId }) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.init({ cosId, projectId });
        const formData = new FormData();
        formData.append('file', file);
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded * 100) / event.total);
            onProgress(progress);
          }
        }, false);

        xhr.addEventListener('load', () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const res = JSON.parse(xhr.response);
            if (res.code === 200) {
              resolve(res.data);
            } else {
              reject(res);
            }
          } else {
            reject({ code: xhr.status, message: xhr.statusText });
          }
        }, false);
        xhr.addEventListener('error', (event) => {
          reject(event);
        }, false);
        xhr.open('post', addPathPrefix(`/api/xy/file/uploadImage?projectid=${projectId}&cosid=${cosId}&key=${imageUploadPathPrefix}${projectId}${filePath}`));
        Object.entries(getXyHeaders()).forEach(([key, value]) => {
          xhr.setRequestHeader(key, value);
        });
        xhr.withCredentials = true;
        xhr.send(formData);
      } catch (e) {
        reject(e);
        console.error(e);
      }
    });
  }

  /**
   * @deprecated
   * 无需根据projectId鉴权的上传方法
   **/
  async uploadFile({ file, pathPrefix = 'wuji_material', filename }) {
    const formData = new FormData();
    formData.append('data', file);
    formData.append('pathPrefix', pathPrefix);
    formData.append('filename', filename);
    const result = await wujiFetch('/api/xy/devtool/upload', {
      method: 'POST',
      body: formData,
      noContentType: false,
    });
    return result.path;
  }
}

const uploader = new Uploader();

export default uploader;
