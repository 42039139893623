const getDefaultDataSchema = (entity) => ({
    type: 'object',
    fields: [
        {
            id: 'code',
            type: 'number',
            description: '返回码,0为成功，1为失败',
        },
        {
            id: 'message',
            type: 'string',
            description: '返回信息',
            optional: true,
        },
        {
            ...(entity ? entity : {}),
            id: 'data',
            type: 'object',
            optional: true,
        },
    ],
});
const getListMethodSchema = (entity) => ({
    type: 'object',
    fields: [
        {
            id: 'listResult',
            type: 'object',
            fields: [
                {
                    id: 'body',
                    type: 'object',
                    fields: [{
                            id: 'code',
                            type: 'number',
                            description: '返回码,0为成功，1为失败',
                        },
                        {
                            id: 'message',
                            type: 'string',
                            description: '返回信息',
                            optional: true,
                        },
                        {
                            id: 'data',
                            type: 'array',
                            optional: true,
                            items: {
                                ...(entity ? entity : {}),
                            },
                        }],
                },
                {
                    id: 'headers',
                    type: 'object',
                    description: '返回头',
                },
            ],
        },
        {
            id: 'countResult',
            type: 'object',
            fields: [
                {
                    id: 'body',
                    type: 'object',
                    fields: [
                        {
                            id: 'data',
                            type: 'object',
                            fields: [
                                {
                                    id: 'total',
                                    type: 'number',
                                },
                            ],
                        },
                        {
                            id: 'code',
                            type: 'number',
                        },
                    ],
                },
                {
                    id: 'headers',
                    type: 'object',
                    description: '返回头',
                },
            ],
        },
    ],
});
const getMethodReturnSchema = (dataSourceConfig, selectedMethod) => {
    switch (dataSourceConfig?.type) {
        case 'db':
            return getDBDataSourceDataSchema(dataSourceConfig, selectedMethod);
        case 'http':
            return getHTTPDataSourceDataSchema(dataSourceConfig);
        default:
            return getHTTPDataSourceDataSchema(dataSourceConfig);
    }
};
const getDBDataSourceDataSchema = (dataSourceConfig, selectedMethod) => {
    if (selectedMethod === 'list') {
        return getListMethodSchema(dataSourceConfig?.entity);
    }
    return getDefaultDataSchema(dataSourceConfig?.entity);
};
const getHTTPDataSourceDataSchema = (dataSourceConfig) => dataSourceConfig?.entity;
export { getDefaultDataSchema, getListMethodSchema, getMethodReturnSchema, };
