import { message, Modal } from 'ant-design-vue';
import { defineComponent, ref, onBeforeMount, computed } from '@vue/composition-api';
import { permissionApi } from '@/services';
import { BASE_PATH } from '@config/constant';
import { isGuestRole, isBuiltInRole, useRuntimeInfo, useOrg } from './utils';
import RoleForm from './RoleForm.vue';
import ManagePermission from './ManagePermission';
import RoleUserForm from './RoleUserForm';
import styles from './permission.module.scss';
import PermFilter from './PermFilter';
import { BIZ_OR_PROJECT } from './constants';
import SyncRole from './SyncRole';
import useProjectInfo from '@/pages/modules/useProjectInfo';
import { injectPermState } from './PermState';
import ManagePermModal from './ManagePermModal';
import classNames from 'classnames';
const Org = defineComponent({
    name: 'Org',
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { canCheck, valid } = useOrg(props.record);
        return {
            canCheck,
            valid,
        };
    },
    render() {
        const invalid = this.canCheck && !this.valid;
        const disabled = invalid || this.record.disabled;
        const getColor = () => {
            if (this.record.disabled)
                return '';
            if (invalid)
                return 'orange';
            return 'blue';
        };
        return <span>
      <a-tooltip key={`${this.record._id}:tooltip`}>
        <span slot="title">
          <span>{this.record.userName}</span>
          {invalid ? <span style="color:orange"> - 无效组织，组织ID或已变更</span> : null}
        </span>
        <a-tag key={this.record._id} color={getColor()} class={classNames({ [styles.disabledRoleUserNameTag]: disabled })}>
          {this.record.userName?.split('/').pop()}
        </a-tag>
      </a-tooltip>
    </span>;
    },
});
export default defineComponent({
    name: 'RoleList',
    components: {
        PermFilter,
        ManagePermission,
        RoleForm,
        RoleUserForm,
    },
    props: {
        projectId: {
            type: String,
            default: window.RUNTIME_CONFIGS?.projectId || '',
        },
    },
    setup(props) {
        const showPermModal = ref(false);
        const showRoleModal = ref(false);
        const showSyncRoleModal = ref(false);
        const showAdvancedSettingModal = ref(false);
        const syncRoleStatus = ref('');
        const showRoleUserModal = ref(false);
        const { bizId, isRuntimeMode } = useRuntimeInfo();
        const q = ref('');
        const env = ref('');
        const userOperation = ref('');
        const isBizIsolation = ref(false);
        const canReadPermission = ref(false);
        const canUpdatePermission = ref(false);
        const okBtnDisabled = ref(true);
        const okBtnLoading = ref(false);
        const initLoading = ref(false);
        const envChangeLoading = ref(false);
        const disabledPageApply = ref(false);
        const pageApplyChanging = ref(false);
        const applyModeChanging = ref(false);
        const listAll = ref(false);
        const permissionApplyMode = ref('all-in-one');
        const customWorkflowConfig = ref(null);
        const role = ref({ name: '', cname: '', type: '', users: [] });
        const roles = ref([]);
        const hasCustomRuntimeAuth = !!window.GLOBAL_INFO.deploySettingFlag.hasCustomRuntimeAuth;
        const thirdDsAuth = ref(false);
        const switchOptions = ref([]);
        const getBizName = (bizId) => {
            const option = switchOptions.value.find(o => o.value === bizId);
            return option?.label ?? bizId;
        };
        const permState = injectPermState();
        const { projectInfo, fetchProjectInfo, updateProjectInfo } = useProjectInfo(props.projectId);
        const handleUserClick = (record, key) => {
            userOperation.value = key;
            role.value = record;
            showRoleUserModal.value = true;
            okBtnDisabled.value = true;
        };
        const handlerMenuClick = (currentRole, key) => {
            switch (key) {
                case 'edit-role':
                    role.value = { ...currentRole };
                    showRoleModal.value = true;
                    break;
                case 'delete-role':
                    Modal.confirm({
                        title: '确认删除当前角色？',
                        content: () => (<div>
                <br />
                <div>
                  {currentRole.cname}({currentRole.name})
                </div>
                <div>注意：同时会删除所有关联用户</div>
              </div>),
                        okText: '删除',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk: async () => {
                            await permissionApi.deleteRoleByPost(props.projectId, env.value, currentRole.name);
                            message.success('已删除');
                            await fetchRoleUser();
                        },
                    });
            }
        };
        const columns = [
            {
                title: '角色ID',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                customRender: (role) => (<span>
            {isBuiltInRole(role) && (<a-tooltip title="内置角色">
                <a-icon type="lock"/>
              </a-tooltip>)}{' '}
            {role}
          </span>),
            },
            {
                title: '角色名',
                dataIndex: 'cname',
                key: 'cname',
                width: 150,
            },
            {
                title: '角色类型',
                dataIndex: 'type',
                key: 'type',
                width: 120,
                customRender: (type) => (type === 'biz' ? '业务' : '应用'),
            },
            {
                title: '关联用户/组织',
                dataIndex: 'users',
                key: 'users',
                scopedSlots: { customRender: 'users' },
                customRender: (text, record) => {
                    if (isGuestRole(record.name))
                        return <span>所有用户</span>;
                    if (!record.users?.length)
                        return <span>-</span>;
                    let items = record.users;
                    if (record.type === 'biz' && (bizId.value !== BIZ_OR_PROJECT || !bizId.value)) {
                        // 按照所属业务过滤
                        items = items.filter(ru => ru.bizId === bizId.value);
                    }
                    return (<div>
              {items.map((ru) => {
                            if (ru.disabled && !listAll.value)
                                return null;
                            const isOrg = ru.userType === 'org';
                            if (isOrg)
                                return <Org record={ru}/>;
                            const el = <a-tag key={ru._id} class={classNames({ [styles.disabledRoleUserNameTag]: !!ru.disabled })}>
                  {ru.userName}
                  {(record.type === 'biz') && `（${getBizName(ru.bizId)}）`}
                </a-tag>;
                            return el;
                        })}
            </div>);
                },
            },
            {
                title: '操作',
                width: 300,
                customRender: (text, record) => (<div>
            <a href="javascript:;" onClick={() => {
                        showPermModal.value = true;
                        if (role.value.name !== record.name) {
                            role.value = { ...record };
                            okBtnDisabled.value = true;
                        }
                    }}>
              管理权限
            </a>
            {isGuestRole(record.name) || (record.type === 'biz' && !isBizIsolation.value) ? (<a-tooltip title={isGuestRole(record.name) ? '所有用户都属于访客角色' : '角色为业务类型，但应用没有启用业务隔离'}>
                <a-dropdown style="margin-left:12px">
                  <span>
                    <a className="ant-dropdown-link" disabled>
                      管理用户/组织 <a-icon type="down"></a-icon>
                    </a>
                  </span>
                </a-dropdown>
              </a-tooltip>) : (<a-dropdown style="margin-left:12px" disabled={!canUpdatePermission.value}>
                <a className="ant-dropdown-link" disabled={!canUpdatePermission.value}>
                  管理用户/组织 <a-icon type="down"></a-icon>
                </a>
                <a-menu slot="overlay" onClick={({ key }) => handleUserClick(record, key)}>
                  <a-menu-item key="add-user">添加用户</a-menu-item>
                  <a-menu-item key="delete-user" disabled={!record.users?.length}>
                    删除用户
                  </a-menu-item>
                  {permState.panguMappingEnabled.value ? <a-menu-item disabled>暂未对接盘古组织</a-menu-item> : null}
                  {!permState.panguMappingEnabled.value
                            ? <a-menu-item key="add-org">添加组织</a-menu-item>
                            : null}
                  {!permState.panguMappingEnabled.value
                            ? <a-menu-item key="delete-org" disabled={!record.users?.length}>
                          删除组织
                        </a-menu-item>
                            : null}
                </a-menu>
              </a-dropdown>)}
            {isBuiltInRole(record.name) ? (<a-tooltip title="角色已锁定">
                <span style="margin-left:12px;color:#8D8D8D">
                  管理角色 <a-icon type="down"></a-icon>
                </span>
              </a-tooltip>) : (<a-dropdown style="margin-left:12px" disabled={!canUpdatePermission.value}>
                <a className="ant-dropdown-link" disabled={!canUpdatePermission.value}>
                  管理角色 <a-icon type="down"></a-icon>
                </a>
                <a-menu slot="overlay" onClick={({ key }) => handlerMenuClick(record, key)}>
                  <a-menu-item key="edit-role">修改角色名</a-menu-item>
                  <a-menu-item key="delete-role">删除角色</a-menu-item>
                </a-menu>
              </a-dropdown>)}
          </div>),
            },
        ];
        const fetchRoleUser = async () => {
            const [roleList, roleUsers] = await Promise.all([
                permissionApi.fetchRoles(props.projectId, env.value),
                permissionApi.fetchRoleUsers(props.projectId, env.value),
            ]);
            roleList.forEach((r, idx) => {
                roleList[idx].users = roleUsers.filter((ru) => ru.role === r.name);
                roleList[idx].orgs = roleUsers.filter((ru) => ru.role === r.name && ru.userType === 'org');
            });
            roles.value = roleList;
        };
        const projectInfoRefresh = async () => {
            const flowConfig = await permissionApi.fetchCustomWorkflow(props.projectId, env.value);
            customWorkflowConfig.value = flowConfig;
            await fetchProjectInfo(env.value);
            disabledPageApply.value = !!projectInfo.value?.disabledPageApply;
            permissionApplyMode.value = projectInfo.value?.permissionApplyMode || 'all-in-one';
            thirdDsAuth.value = !!(projectInfo.value?.advanceConfig?.authCollectionId
                && projectInfo.value?.advanceConfig?.authCgiId);
        };
        onBeforeMount(async () => {
            await projectInfoRefresh();
        });
        const filterRoles = computed(() => {
            if (hasCustomRuntimeAuth || thirdDsAuth.value)
                return [];
            const s = q.value.toLowerCase();
            let result = roles.value.filter(r => r.name.includes(s) || r.cname.includes(s));
            if (bizId.value === BIZ_OR_PROJECT)
                return result;
            if (bizId.value === '') {
                result = result.filter(r => !r.type);
            }
            else if (bizId.value) {
                result = result.filter(r => r.type === 'biz');
            }
            return result;
        });
        return {
            q,
            userOperation,
            env,
            columns,
            role,
            filterRoles,
            roles,
            showPermModal,
            showRoleModal,
            showSyncRoleModal,
            showAdvancedSettingModal,
            syncRoleStatus,
            showRoleUserModal,
            listAll,
            okBtnDisabled,
            okBtnLoading,
            switchOptions,
            bizId,
            isBizIsolation,
            initLoading,
            envChangeLoading,
            disabledPageApply,
            pageApplyChanging,
            applyModeChanging,
            permissionApplyMode,
            projectInfoRefresh,
            updateProjectInfo,
            customWorkflowConfig,
            isRuntimeMode,
            canReadPermission,
            canUpdatePermission,
            fetchRoleUser,
            permState,
            hasCustomRuntimeAuth,
            thirdDsAuth,
        };
    },
    watch: {
        env: {
            async handler() {
                this.envChangeLoading = true;
                // 切换环境时置空
                this.roles = [];
                if (this.canReadPermission) {
                    await this.fetchRoleUser();
                }
                await this.projectInfoRefresh();
                this.envChangeLoading = false;
            },
            immediate: true,
        },
    },
    render() {
        const createRole = () => {
            this.role.name = '';
            this.role.cname = '';
            this.role.type = '';
            this.showRoleModal = true;
        };
        const syncRole = () => {
            this.showSyncRoleModal = true;
        };
        const saveRole = async () => {
            this.okBtnLoading = true;
            this.okBtnDisabled = true;
            const ok = await this.$refs.roleForm.save();
            this.okBtnLoading = false;
            this.okBtnDisabled = false;
            if (ok) {
                this.showRoleModal = false;
                message.success('已保存');
                this.fetchRoleUser();
            }
        };
        const saveRoleUser = async () => {
            this.okBtnLoading = true;
            this.okBtnDisabled = true;
            const ok = await this.$refs.roleUserRef.save();
            this.okBtnLoading = false;
            this.okBtnDisabled = false;
            if (ok) {
                this.showRoleUserModal = false;
                this.fetchRoleUser();
            }
        };
        const getDetailWorkflowUrl = () => {
            if (!this.customWorkflowConfig)
                return '';
            const { id } = this.customWorkflowConfig;
            return `${BASE_PATH}project/config/workflow/edit/${id}?projectid=${this.projectId}&env=${this.env}`;
        };
        const initCustomWorkflow = async () => {
            this.initLoading = true;
            try {
                const flowConfig = await permissionApi.initCustomWorkflow(this.projectId);
                this.customWorkflowConfig = flowConfig;
                message.success('生成申请流程成功');
            }
            catch (error) {
                message.error('生成申请流程失败');
            }
            this.initLoading = false;
        };
        const confirmInitCustomWorkflow = async () => {
            Modal.confirm({
                title: '确认生成自定义申请流程？',
                content: () => (<div>
            <br />
            <div>权限申请流程默认由平台提供</div>
            <div>生成自定义申请流程后，可以</div>
            <ul>
              <li>修改审批人，默认审批人为应用管理员角色</li>
              <li>自行添加申请成功钩子</li>
            </ul>
            <div>如无以上需求，使用平台提供的申请流程即可</div>
          </div>),
                okText: '确认',
                okType: 'primary',
                cancelText: '取消',
                onOk: initCustomWorkflow,
            });
        };
        const advancedSettingBtn = (<span style="margin-left:8px">
        <a-button onClick={() => this.showAdvancedSettingModal = true}>权限申请设置</a-button>
      </span>);
        const customWorkflowBtn = (<span style="margin-left:8px">
        {this.customWorkflowConfig ? (<span>
            <a href={getDetailWorkflowUrl()} target="_blank">
              <a-icon type="link"></a-icon> 点击查看
            </a>
          </span>) : (<span>
            {this.env === 'dev'
                    ? <a-button size="small" onClick={() => confirmInitCustomWorkflow()} loading={this.initLoading}>
                    点击生成
                  </a-button>
                    : <a-tag>无</a-tag>}
          </span>)}
      </span>);
        const pageApplyChange = async (checked) => {
            this.pageApplyChanging = true;
            try {
                await this.updateProjectInfo({ disabledPageApply: checked });
                message.success('保存成功');
            }
            catch (error) {
                message.error('保存失败');
                this.disabledPageApply = !checked;
            }
            this.pageApplyChanging = false;
        };
        const applyModeChange = async (e) => {
            const val = e.target.value;
            this.applyModeChanging = true;
            try {
                await this.updateProjectInfo({ permissionApplyMode: val });
                message.success('保存成功');
                this.permissionApplyMode = val;
            }
            catch (error) {
                message.error('保存失败');
            }
            this.applyModeChanging = false;
        };
        const renderOpBtns = () => {
            if (this.hasCustomRuntimeAuth)
                return <a-alert message="平台级别自定义鉴权启用，在这里配置无效" type="warning" show-icon/>;
            if (this.thirdDsAuth)
                return <a-alert message="第三方鉴权启用，在这里配置无效" type="warning" show-icon/>;
            if (this.canUpdatePermission)
                return <div>
        <a-button type="primary" onClick={createRole}>
          新建角色
        </a-button>
        <a-button onClick={syncRole} style="margin-left:8px">
          同步角色配置
        </a-button>
        {this.isRuntimeMode ? null : advancedSettingBtn}
      </div>;
            return <a-alert message={this.canReadPermission ? '无权更新，只读模式' : '无权查看'} type="warning" show-icon/>;
        };
        return (<div>
        <div class={styles.operationAndSearch}>
          {renderOpBtns()}
          <PermFilter projectId={this.projectId} showEnv={!this.isRuntimeMode} showSwitch={true} showListAllSwitch={true} listAll={this.listAll} onListAllChange={(v) => (this.listAll = v)} searchPlaceholder="请输入角色ID或角色名关键字" onEnvChange={(data) => {
                this.env = data.env;
                this.isBizIsolation = !!data.bizConfig;
                this.switchOptions = data.switchOptions;
                this.canReadPermission = data.canReadPermission;
                this.canUpdatePermission = data.canUpdatePermission;
            }} onBizIdChange={(bizId) => (this.bizId = bizId)} onSearchChange={(q) => (this.q = q)}/>
        </div>
        <div>
          <a-table loading={this.envChangeLoading} columns={this.columns} dataSource={this.filterRoles} rowKey="name"></a-table>
        </div>
        <ManagePermModal roleOrPerm={this.role} projectId={this.projectId} env={this.env} showModal={this.showPermModal} canUpdatePermission={this.canUpdatePermission} onHide={() => this.showPermModal = false}/>
        <a-modal title={this.role.name ? '编辑角色' : '新建角色'} visible={this.showRoleModal} okButtonProps={{ props: { disabled: this.okBtnDisabled, loading: this.okBtnLoading } }} okText="保存" destroyOnClose={true} onOk={() => saveRole()} onCancel={() => (this.showRoleModal = false)}>
          <RoleForm ref="roleForm" projectId={this.projectId} env={this.env} names={this.roles.map(r => r.name)} 
        // @ts-ignore
        action={this.role.name ? 'edit' : 'create'} roleName={this.role.name} roleCName={this.role.cname} roleType={this.role.type || ''} hasBizIsolation={this.isBizIsolation} handleChange={() => (this.okBtnDisabled = false)}/>
        </a-modal>
        <a-modal title={`管理${this.userOperation.includes('user') ? '用户' : '组织'}`} width="760px" visible={this.showRoleUserModal} okButtonProps={{ props: { disabled: this.okBtnDisabled, loading: this.okBtnLoading } }} destroyOnClose={true} okText="保存" onOk={() => saveRoleUser()} onCancel={() => (this.showRoleUserModal = false)}>
          <RoleUserForm ref="roleUserRef" key={this.role.name} projectId={this.projectId} env={this.env} isRuntimeMode={this.isRuntimeMode} role={this.role.name} type={this.role.type || ''} users={this.userOperation.includes('user') ? this.role.users : this.role.orgs} bizId={this.bizId} operation={this.userOperation} switchOptions={this.switchOptions} validChange={(isValid) => (this.okBtnDisabled = !isValid)} listAll={this.listAll}></RoleUserForm>
        </a-modal>
        <a-modal title="同步角色配置" visible={this.showSyncRoleModal} width="700px" onCancel={() => {
                if (this.syncRoleStatus !== 'syncing')
                    this.showSyncRoleModal = false;
            }} footer={null}>
          <SyncRole projectId={this.projectId} key={this.env} envId={this.env} onSync={(v) => (this.syncRoleStatus = v)}></SyncRole>
        </a-modal>
        <a-modal title={`权限申请设置${this.env !== 'dev' ? ' - 非开发环境，只读' : ''}`} visible={this.showAdvancedSettingModal} footer={null} onCancel={() => (this.showAdvancedSettingModal = false)}>
          {this.env === 'dev'
                ? <a-alert message="以下配置均需要通过发版才能在其他环境生效" show-icon></a-alert>
                : null}
          <a-form-model labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
            <a-form-model-item>
              <span slot="label">
                <span>自定义权限申请流程</span>
                <a-tooltip>
                  <a-icon style="margin-left:8px" type="info-circle"></a-icon>
                  <span slot="title">若无，则使用内置权限申请流程</span>
                </a-tooltip>
              </span>
              {customWorkflowBtn}
            </a-form-model-item>
            <a-form-model-item>
              <span slot="label">
                <span>禁止申请页面权限</span>
                <a-tooltip>
                  <a-icon style="margin-left:8px" type="info-circle"></a-icon>
                  <span slot="title">
                    申请页面查看权限，平台将会自动生成 /page-path 角色，该角色权限只有该页面的查看权限，无法变更
                  </span>
                </a-tooltip>
              </span>
              <span>
                <a-switch style="margin:0 8px" disabled={this.pageApplyChanging || this.env !== 'dev'} v-model={this.disabledPageApply} onChange={pageApplyChange}/>
                {this.pageApplyChanging
                ? <a-spin>
                        <a-icon slot="indicator" type="loading" style="font-size: 24px"/>
                      </a-spin>
                : null}
              </span>
            </a-form-model-item>
            <a-form-model-item>
              <span slot="label">
                <span>申请角色模式</span>
                <a-tooltip>
                  <a-icon style="margin-left:8px" type="info-circle"></a-icon>
                  <span slot="title">用户申请多个角色时，是合并成一条审批单还是拆分成一个角色一个审批单</span>
                </a-tooltip>
              </span>
              <div>
                {this.env === 'dev'
                ? <span>
                        <a-radio-group button-style="solid" style="margin:0 8px" value={this.permissionApplyMode} disabled={this.applyModeChanging} onChange={applyModeChange}>
                          <a-radio-button value="all-in-one">合并</a-radio-button>
                          <a-radio-button value="one-by-one">拆分</a-radio-button>
                        </a-radio-group>
                        {this.applyModeChanging
                        ? <a-spin>
                                <a-icon slot="indicator" type="loading" style="font-size: 24px"/>
                              </a-spin>
                        : null}
                      </span>
                : <a-tag style="margin:0 8px">
                      {this.permissionApplyMode === 'all-in-one' ? '合并' : '拆分'}
                    </a-tag>}
              </div>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>);
    },
});
