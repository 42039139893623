<template>
  <a-form-model
    v-if="editValue"
    style="width: 100%"
    layout="horizontal"
  >
    <a-form-item
      label="数据源"
      class="xy-cgi-form-item"
      :extra="stateHelp"
    >
      <a-select
        v-model="editValue.sourceId"
        show-search
        filter-option
        style="display: block; flex: 1;"
        @change="resetSource"
        @focus="reloadSourceList"
      >
        <a-select-option
          v-for="(source) in sourceList"
          :key="source.source.id"
          :value="source.source.id"
          :disabled="source.disabled"
        >
          {{ source.id }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      v-if="currentSource"
      label="接口"
      :placeholder="cgis.length ? '请选择...': '此数据源未配置接口'"
      class="xy-cgi-form-item"
    >
      <a-select
        v-model="editValue.cgi"
        show-search
        filter-option
        style="display: block; flex: 1;"
        @change="commit"
      >
        <a-select-option
          v-for="cgi in cgis"
          :key="cgi"
          :value="cgi"
        >
          {{ cgi }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      v-if="editValue.cgi && !isBatchRequest"
      label="请求参数"
      class="xy-cgi-form-item xy-cgi-params-radio-item"
    >
      <a-radio-group
        v-model="currentType"
      >
        <a-radio
          v-for="(item) in paramTypes"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item
      v-if="editValue.cgi && currentType ==='js'"
      class="xy-cgi-form-item xy-cgi-params-form-item"
    >
      <a-space
        style="margin-top:-6px;"
      >
        <a-button
          size="small"
          @click="setParams"
        >
          生成默认参数
        </a-button>
        <a-button
          v-if="!isBatchRequest"
          size="small"
          @click="setBatchParams"
        >
          生成批量请求参数
        </a-button>
      </a-space>
      <ExprInput
        v-model="editValue.code"
        :data-stub="completionDataStub"
      />
    </a-form-item>

    <a-form-item
      v-if="editValue.cgi && currentType ==='form'"
      class="xy-cgi-form-item"
    >
      <HttpRequestForm
        v-if="collectionType==='http'"
        v-model="formValue"
        :schema="cgiSchema"
      />
      <DBRequestForm
        v-else
        v-model="formValue"
        :cgi="editValue.cgi"
        :schema="cgiSchema"
      />
      <a-alert
        style="margin-top:6px"
        :message="message"
      />
    </a-form-item>
    <a-button
      style="margin-top:6px"
      type="primary"
      size="small"
      icon="book"
      @click="openDocs"
    >
      查看文档
    </a-button>
  </a-form-model>
</template>

<script>
import { message } from 'ant-design-vue';
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { ExprInput } from '@tencent/ui-core';
import { DBRequestForm } from '@components/argumentsForm/DBRequestForm';
import { HttpRequestForm }  from '@components/argumentsForm/HttpRequestForm';
import { docsModal } from '@tencent/wuji-source';

const defaultCode = `
{
  // url参数
  query:{

  },
  // post包体
  body:{}
}
`.trim();

const defaultBatchCode = `
{
  // 将需要批量的请求参数填入params中
  // url参数
  query:{
    params: []
  },
  // post包体
  body:{
    params:[]
  }
}
`.trim();

const batchCode = `
[
${defaultCode},
${defaultCode}
]
`;
/**
 * sourceId,
 * cgi,
 * paramType js form
 * code  js代码字符串
 * paramObject 参数对象 包含表达式 { query body bodyExprStr }
 *
 */
const BATCH_REQUEST_ACTION_ID = 'xy:source:batchRequestCgi';
export default {
  name: 'XySourceCgiEdit',
  components: {
    ExprInput,
    DBRequestForm,
    HttpRequestForm,
  },
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
    flowEditor: { from: 'uicore:simpleFlowStepEditor' },
  },
  data() {
    return {
      sourceList: [],
      paramTypes: [
        {
          label: 'javascript传参',
          value: 'js',
        },
        {
          label: '表单传参',
          value: 'form',
        },
      ],
      collectionType: '',
      httpSchema: null,
      message: '如果想进行动态绑定，请使用 {{}} 符号, eg: {{ state.foobar }}',
    };
  },
  computed: {
    isJSModel() {
      const state = this.designer?.data?.[this.editValue.sourceId];
      return state?.isJSStateModel;
    },
    stateHelp() {
      return this.isJSModel ? '该数据源支持自定义数据源方法，推荐使用自定义数据源方法进行请求，以获得更好的体验' : '';
    },
    currentSource() {
      return this.sourceList.find(x => x.id === this.editValue?.sourceId)?.source;
    },
    cgis() {
      // const allCgis =  Object.keys(this.currentSource?.data?.$requests || {});
      const allCgis = Object.keys(this.currentSource?.data?.collection?.collectionData?.cgiInfo || {});
      if (!this.isBatchRequest) {
        return allCgis;
      }
      const tempaltes = this.currentSource
        .S.state.collection.template.apiTemplates;
      return allCgis.filter((c) => {
        const currentTemplate =  tempaltes.find(t => t.name === c);
        return currentTemplate && currentTemplate.canBeBatched;
      });
    },
    hasCgis() {
      return this.cgis.length > 0;
    },
    completionDataStub() {
      return this.designer.renderer.ucDataStub;
    },
    currentType: {
      get() {
        return this.editValue.paramType || 'js'; // 如果 paramType为空，走js，兼容以前
      },
      set(value) {
        this.$set(this.editValue, 'paramType', value);
        this.commit();
      },
    },
    formValue: {
      get() {
        return this.editValue.paramObject;
      },
      set(value) {
        this.$set(this.editValue, 'paramObject', value);
      },
    },
    cgiSchema() {
      return this.getCgiParamSchema();
    },
    currentActionAtomId() {
      return this.flowEditor.step.type;
    },
    isBatchRequest() {
      return this.currentActionAtomId === BATCH_REQUEST_ACTION_ID;
    },
    templateCode() {
      return this.isBatchRequest ? defaultBatchCode : defaultCode;
    },
  },
  created() {
    this.$watch('editValue', (ed) => {
      if (!ed) this.editValue = { sourceId: '', cgi: '', code: '' };
    });
    this.reloadSourceList();
    if (!this.editValue.code) {
      this.$set(this.editValue, 'code', this.templateCode);
      this.commit();
    }
    this.$watch(() => this.editValue.cgi, () => {
      if (this.editValue.paramType === 'form') {
        this.$set(this.editValue, 'paramObject', {});
      }
    });
    this.$watch(() => this.editValue.sourceId, () => {
      this.setType();
    }, { immediate: true });
  },
  methods: {
    reloadSourceList() {
      const sources = this.designer.renderer.sources || {};
      const isBatch = this.isBatchRequest;
      this.sourceList = Object.keys(sources).map(id => ({
        id,
        source: sources[id],
        // 只有db数据源支持批量行为
        disabled: isBatch && sources[id].S?.state.collection.collectionData.type !== 'db',
      }));
    },
    // 触发修改行为
    commit() {
      this.editValue = { ...this.editValue };
    },
    resetSource() {
      this.$set(this.editValue, 'cgi', '');
      this.commit();
    },
    setParams() {
      this.$set(this.editValue, 'code', this.templateCode);
      this.commit();
    },
    setBatchParams() {
      this.$set(this.editValue, 'code', batchCode);
      this.commit();
    },
    setType() {
      if (!this.editValue.sourceId) return;
      const collectionData = this.designer.data[this.editValue.sourceId]?.collection.collectionData;
      if (!collectionData) {
        message.error('获取collection信息失败');
      }
      this.collectionType = collectionData.type;
    },
    getCgiParamSchema() {
      const state = this.designer.data[this.editValue.sourceId];
      if (!state) {
        console.error(`getCgiParamSchema state is not found -- sourceId:${this.editValue.sourceId}`);
        return null;
      }
      const schema = state.stateSchema?.fields
        ?.find(item => item.id === '$requests')?.methods
        ?.find(item => item.id === this.editValue.cgi)?.arguments?.[0];
      return schema || null;
    },
    openDocs() {
      docsModal.toggle();
    },
  },
};
</script>

<style lang="scss">
  .xy-cgi-form-item{
    margin-bottom: 0px;
  }
  .xy-cgi-params-form-item{
    .ant-form-item-label{
      line-height: inherit;
      margin-top: 8px;
    }
  }
  .xy-cgi-params-radio-item{
    .ant-form-item-control-wrapper{
      margin-top: -10px;
    }
  }
</style>
