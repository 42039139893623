
import XySourceSelector from './XySourceSelector';
import XySourceActionEdit from './XySourceActionEdit';
import XyLessCodeMethodSelector from './XyLessCodeMethodSelector';
import XyPageSelector from './XyPageSelector';
import XyModalSelector from './XyModalSelector';
import XyPageletPropsEditor from './XyPageletPropsEditor';
import XyHttpRequestConfig from './XyHttpRequestConfig';
import XyWorkflowSelector from './XyWorkflowSelector';
import XyFlowSelector from './XyFlowSelector';
import XyFlowApproveDataEditor from './XyFlowApproveDataEditor';
import XyFlowAtomDataEditor from './XyFlowAtomDataEditor';
import XyRobotSelector from './XyRobotSelector';
import XyRtxSelector from './XyRtxSelector';
import XySourceCgiEdit from './XySourceCgiEdit';
import XySourceMethodEdit from './XySourceMethodEdit';
import XyWxworkSelector from './XyWxworkSelector';
import ExcelFieldConfigEdit from './ExcelFieldConfigEdit';
import XyWeworkMessage from './XyWeworkMessage';
import XyCosSelector from './XyCosSelector';
import XyCosEnvSelector from './XyCosEnvSelector';
import XyExcelEdit from './ExcelEdit';
import XyFlowTypeSelector from './GPTCall/XyFlowTypeSelector';
import XyGPTAlert from './GPTCall/XyGPTAlert';
import XyGlobalModalSetting from './XyGlobalModalSetting';
import { ExprInput } from '@tencent/ui-core';

export const XySourceSelectorMeta = {
  id: 'XySourceSelector',
  visible: false,
  component: XySourceSelector,
};

export const XySourceActionEditMeta = {
  id: 'XySourceActionEdit',
  visible: false,
  component: XySourceActionEdit,
};

export const XyLessCodeMethodSelectorMeta = {
  id: 'XyLessCodeMethodSelector',
  visible: false,
  component: XyLessCodeMethodSelector,
};

export const XyPageSelectorMeta = {
  id: 'XyPageSelector',
  visible: false,
  component: XyPageSelector,
};

export const XyModalSelectorMeta = {
  id: 'XyModalSelector',
  visible: false,
  component: XyModalSelector,
};

export const XyPageletPropsEditorMeta = {
  id: 'XyPageletPropsEditor',
  visible: false,
  component: XyPageletPropsEditor,
};

export const XyHttpRequestConfigMeta = {
  id: 'XyHttpRequestConfig',
  visible: false,
  component: XyHttpRequestConfig,
};

export const XyWorkflowSelectorMeta = {
  id: 'XyWorkflowSelector',
  visible: false,
  component: XyWorkflowSelector,
};

export const XyFlowSelectorMeta = {
  id: 'XyFlowSelector',
  visible: false,
  component: XyFlowSelector,
};

export const XyFlowApproveDataEditorMeta = {
  id: 'XyFlowApproveDataEditor',
  visible: false,
  component: XyFlowApproveDataEditor,
};

export const XyFlowAtomDataEditorMeta = {
  id: 'XyFlowAtomDataEditor',
  visible: false,
  component: XyFlowAtomDataEditor,
};

export const XyRobotSelectorMeta = {
  id: 'XyRobotSelector',
  visible: false,
  component: XyRobotSelector,
};

export const XyRtxSelectorMeta = {
  id: 'XyRtxSelector',
  visible: false,
  component: XyRtxSelector,
};

export const XySourceCgiEditMeta = {
  id: 'XySourceCgiEdit',
  visible: false,
  component: XySourceCgiEdit,
};

export const XySourceMethodEditMeta = {
  id: 'XySourceMethodEdit',
  visible: false,
  component: XySourceMethodEdit,
};

export const XyWxworkSelectorMeta = {
  id: 'XyWxworkSelector',
  visible: false,
  component: XyWxworkSelector,
};

export const XyExcelFieldConfigMeta = {
  id: 'XyExcelFieldConfigEdit',
  visible: false,
  component: ExcelFieldConfigEdit,
};

export const XyWeworkMessageMeta = {
  id: 'XyWeworkMessage',
  visible: false,
  component: XyWeworkMessage,
};


export const XyCosSelectorMeta = {
  id: 'XyCosSelector',
  visible: false,
  component: XyCosSelector,
};

export const XyCosSelectorEnvMeta = {
  id: 'XyCosEnvSelector',
  visible: false,
  component: XyCosEnvSelector,
};


export const XyExcelEditMeta = {
  id: 'XyExcelEdit',
  visible: false,
  component: XyExcelEdit,
};

export const XyFlowTypeSelectorMeta = {
  id: 'XyFlowTypeSelector',
  visible: false,
  component: XyFlowTypeSelector,
};

export const XyGPTAlertMeta = {
  id: 'XyGPTAlert',
  visible: false,
  component: XyGPTAlert,
};

export const XyGlobalModalSettingMeta = {
  id: 'XyGlobalModalSetting',
  visible: false,
  hideLabel: true,
  component: XyGlobalModalSetting,
};

export const ExprInputMeta = {
  id: 'ExprInput',
  visible: false,
  component: ExprInput,
};
