/* eslint-disable no-param-reassign */
import getUICoreConfig from '@/components/pagelet-component/get-ui-core-config';
import xyPageContainer from '@/components/pagelet-component/index';
import dataSourceSelector from '@/components/pagelet-component/props-panel/form-item/data';
import { HIDDEN_GROUP } from '@/pages/project/pageConfig/pagelet-component-editor/composable/use-props-schema';

export const getCompInfo = (extraInfo, name, category, icon) => {
  const { propsSchema, bindings, fitData } = getUICoreConfig(extraInfo.pageletPropSchemas, extraInfo.fitData);

  const configKeys = ['projectId', 'pagelets', 'pageletPropSchemas', 'events', 'slots'];
  configKeys.forEach((key) => {
    const field = propsSchema.fields.find(field => field.id === key);
    if (field) {
      if (key === 'pageletPropSchemas') {
        field.default = extraInfo.pageletPropSchemas.filter(group => group.id !== HIDDEN_GROUP);
        return;
      }
      field.default = extraInfo[key];
    }
  });

  const props = {};
  propsSchema.fields.forEach((field) => {
    props[field.id] = {
      required: false,
    };
  });
  const comp = xyPageContainer.component.extend({
    props,
  });

  return {
    comp,
    config: {
      ...xyPageContainer,
      category,
      icon,
      propPanel: {
        dataSourceSelector: {
          component: dataSourceSelector,
        },
      },
      propsSchema,
      slots: extraInfo.slots,
      name,
      events: extraInfo.events,
      fitData,
      bindings,
    },
  };
};


const moduleLoader = async (module, _, extraInfo) => {
  const compInfo = getCompInfo(extraInfo, module.compDB.name, module.compDB.category.name, module.compDB.icon);

  module.setCompInfo(compInfo);
  module.lazyLoad = false; // 在线组件不支持懒加载
  module.compDependencies = extraInfo.compDependencies || [];
};

export default moduleLoader;
