import { isEmpty } from 'lodash';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from '@vue/composition-api';
import envSelector from '@components/env/EnvSelector';
import { permissionApi } from '@/services';
import { ADMIN_ROLE_ID } from './constants';
import { syncRoles } from './sync-role';
import styles from './permission.module.scss';
export default defineComponent({
    props: {
        projectId: {
            type: String,
            required: true,
        },
        envId: {
            type: String,
            required: true,
        },
        onSync: {
            type: Function,
            required: false,
        },
    },
    emits: ['sync'],
    components: {
        envSelector,
    },
    setup() {
        const roles = ref([]);
        const selectedRoles = ref([]);
        const status = ref('');
        const fromEnv = ref('');
        const targetEnv = ref('');
        const needSyncRoleUser = ref(false);
        const syncResult = ref({});
        const txtMap = {
            '': '开始同步',
            syncing: '同步中',
            finished: '同步结束',
        };
        return {
            roles,
            selectedRoles,
            status,
            fromEnv,
            targetEnv,
            needSyncRoleUser,
            txtMap,
            syncResult,
        };
    },
    render() {
        const reset = () => {
            this.status = '';
            this.syncResult = {};
        };
        const fromEnvChange = async (env) => {
            this.fromEnv = env;
            this.roles = [];
            this.selectedRoles = [];
            reset();
            if (this.targetEnv === env)
                this.targetEnv = '';
            try {
                const ok = await permissionApi.checkPermission(this.projectId, env, 'read.permission');
                if (!ok) {
                    this.fromEnv = '';
                    message.error('无权查看当前环境');
                    return;
                }
                const roles = await permissionApi.fetchRoles(this.projectId, env);
                this.roles = roles.filter((r) => r.name !== ADMIN_ROLE_ID);
            }
            catch (error) {
                message.error('获取角色列表失败');
            }
        };
        const targetEnvChange = async (env) => {
            this.targetEnv = env;
            reset();
            const ok = await permissionApi.checkPermission(this.projectId, env, 'update.permission');
            if (!ok) {
                this.targetEnv = '';
                message.error('无权更新目标环境');
            }
        };
        const startSync = async () => {
            this.status = 'syncing';
            this.$emit('sync', this.status);
            const roles = this.roles.filter(r => this.selectedRoles.includes(r.name));
            try {
                this.syncResult = await syncRoles(this.projectId, this.fromEnv, this.targetEnv, roles, this.needSyncRoleUser);
            }
            catch (error) {
                message.error('同步失败');
            }
            finally {
                this.status = 'finished';
                this.$emit('sync', this.status);
            }
        };
        const getRoleName = (roleId) => this.roles.find(i => i.name === roleId)?.cname ?? roleId;
        const formatMessage = (result) => {
            const operationMap = {
                fetch: '获取',
                update: '更新',
                create: '新建',
            };
            const typeMap = {
                role: '角色',
                permission: '权限',
                'api-permission': '接口绑定权限',
                'role-permission': '角色权限',
                'role-user': '角色用户',
            };
            const txt = result.success ? '成功' : '失败，或已存在';
            return `${operationMap[result.operation] ?? result.operation}${typeMap[result.type] ?? result.type}(${result.id})${txt}`;
        };
        if (!this.fromEnv) {
            fromEnvChange(this.envId);
        }
        return (<div>
        <a-alert show-icon message="将会同步角色、权限、角色-权限绑定及接口类型权限绑定的接口关系" description="但不会同步角色-用户关系，涉及到的页面或数据源请自行通过发版发布到对应环境中" style="margin-bottom:12px"></a-alert>
        <a-form-model labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <a-form-model-item label="选择环境">
            <env-selector projectId={this.projectId} env={this.fromEnv} width="100%" disabled={this.status === 'syncing'} hideDisableSync={true} onChange={(env) => fromEnvChange(env)}/>
          </a-form-model-item>
          <a-form-model-item label="需要同步的角色">
            <a-select v-model={this.selectedRoles} onChange={() => reset()} mode="multiple" disabled={this.status === 'syncing'}>
              {this.roles.map(role => (<a-select-option key={role.name}>{role.cname}</a-select-option>))}
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="同步到环境">
            <env-selector projectId={this.projectId} env={this.targetEnv} width="100%" disabled={this.status === 'syncing'} hiddenEnv={[this.fromEnv]} onChange={(env) => targetEnvChange(env)}/>
          </a-form-model-item>
          <a-form-model-item label="同步角色-用户">
            <span>
              <a-switch v-model={this.needSyncRoleUser}>
              </a-switch>
              {this.needSyncRoleUser ? <span style="margin-left:8px">不会删除目标环境里的现存用户</span> : null}
            </span>
          </a-form-model-item>
          <a-form-model-item wrapperCol={{ offset: 4 }}>
            <a-button type="primary" loading={this.status === 'syncing'} disabled={!this.selectedRoles.length || !this.targetEnv} onClick={startSync}>
              {this.txtMap[this.status]}
            </a-button>
            {!isEmpty(this.syncResult) ? (<a-popover title="同步结果" trigger="click">
                <div slot="content" style="max-height:60vh;overflow:scroll">
                  <div style="width:700px" class={styles.syncResultHeader}>
                    <a-collapse>
                      {Object.keys(this.syncResult).map(roleId => (<a-collapse-panel key={roleId}>
                          <div slot="header">
                            {this.syncResult[roleId].find(i => i.type === 'role-permission' && i.operation === 'update')?.success ? (<div style="background-color:#f8fff0">
                                <a-icon style="color:#52c41a;margin-right:8px" type="check-circle"></a-icon>
                                <span>成功同步角色配置 {getRoleName(roleId)}</span>
                              </div>) : (<div style="background-color:#fff1f0">
                                <a-icon style="color:#f5222d;margin-right:8px" type="close-circle"></a-icon>
                                <span>同步角色配置 {getRoleName(roleId)} 失败</span>
                              </div>)}
                          </div>
                          <div class={styles.syncResultWrapper}>
                            {this.syncResult[roleId].map((i) => {
                        const failedType = i.operation === 'fetch' ? 'error' : 'warning';
                        return (<a-alert show-icon type={i.success ? 'success' : failedType} message={formatMessage(i)}></a-alert>);
                    })}
                          </div>
                        </a-collapse-panel>))}
                    </a-collapse>
                  </div>
                </div>
                <a-button type="link">查看结果</a-button>
              </a-popover>) : null}
          </a-form-model-item>
        </a-form-model>
      </div>);
    },
});
