<template>
  <div style="width:100%">
    <a-checkbox
      v-model="editValue.keepAlive"
    >
      关闭时不销毁
    </a-checkbox>
    <div
      v-if="editValue.keepAlive"
      style="display: flex;align-items: center;gap: 5px;"
    >
      <span>
        实例id
        <a-tooltip title="该id用于打开多个不销毁的全局弹窗实例时区分实例">
          <a-icon type="question-circle" />
        </a-tooltip>
        :
      </span>
      <a-input
        v-model="editValue.suffix"
        style="flex:1"
      />
    </div>
  </div>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';

export default {
  name: 'XyGlobalModalSetting',
  mixins: [vueComponentMixin],
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    this.$watch('editValue', (v) => {
      if (v && !v?.keepAlive) this.editValue.suffix = '';
      if (!v) this.editValue = { keepAlive: false, suffix: '' };
      else this.$emit('change', v);
    }, { deep: true });
  },
};
</script>

<style>
</style>
