<template>
  <div class="wrapper">
    <!-- 返回小程序首页 -->
    <BackToHomePage v-if="isMiniprogram" />
    <!-- 菜单栏 -->
    <div
      class="menus ant-menu"
      :class="{
        'menus-hidden': visible('env') && mode !== 'editor',
      }"
    >
      <div
        v-if="showSidebarGroup.length > 0"
        class="menus-tab"
        :class="{
          'menus-active': !menuCollapsed,
          'ant-menu-item-selected': !menuCollapsed,
        }"
        @click="collapseMenu"
      >
        主菜单切换
        <a-icon :type="menuCollapsed ? 'up' : 'down'" />
      </div>
    </div>
    <!-- 显示主菜单切换 -->
    <div
      v-if="!menuCollapsed"
      class="menus-wrapper"
    >
      <MenusGroup :show-sidebar-group="showSidebarGroup" />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import BackToHomePage from '@miniprogram/header/back-to-homepage.vue';
import MenusGroup from '@miniprogram/header/menus-group.vue';
import { formatSideBarToUIState } from '@loaders/project/utils';
import { isRoleMatched, isBizMatched, getVisibleMatchedGroups } from '@components/sidebar/utils';
import { isMiniprogram } from '@utils/screen';
import { setMeta } from '@miniprogram/utils/add-meta';
import useLayout from '@composables/useLayout';

export default {
  name: 'MiniprogramHeader',
  components: { BackToHomePage, MenusGroup },
  props: {
    mode: {
      type: String,
      default: 'editor', // 'editor'(编辑时) | 'runtime'(运行时) | 'preview'(预览时)
    },
  },
  setup() {
    const {
      project,
      roles,
      biz,
      isAdmin,
    } = useLayout();

    return {
      project,
      roles,
      biz,
      isAdmin,
    };
  },
  data() {
    return {
      menuCollapsed: true,
      businessCollapsed: true,
      isMiniprogram: isMiniprogram(),
    };
  },
  computed: {
    uiInfo() {
      return this.project.uiInfo || {};
    },
    // 是否显示业务
    widgets() {
      let widgets = [];
      if (this.uiInfo.navlink) {
        const { navlink } = this.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey && w.isShow);
      }
      return widgets;
    },
    hasWidgets() {
      return Boolean(this.widgets.length);
    },
    // 是否显示菜单 - 沿用@components/nav/NavMenuGroup参数
    showMenuGroup() {
      return (this.project?.uiInfo?.navlink?.menuGroup || [])
        .filter(c => c.isShow && isRoleMatched(c?.roles, this.roles, this.isAdmin) && isBizMatched(c?.bizes, this.biz));
    },
    showSidebarGroup() {
      const showMenuGroupIds = this.showMenuGroup.map(g => `${g.groupId}`);
      const sidebarGroupWithRole = getVisibleMatchedGroups(this.sidebarGroup, {
        roles: this.roles,
        biz: this.biz,
        isAdmin: this.isAdmin,
      });

      const groups = [];
      showMenuGroupIds.forEach((id) => {
        const group = sidebarGroupWithRole.find(group => `${group.groupId}` === `${id}`);
        if (group) groups.push(group);
      });
      return groups;
    },
    sidebarGroup() {
      return formatSideBarToUIState(cloneDeep(this.project?.uiInfo?.sidebar));
    },
  },
  created() {
    if (this.isUserScalable()) {
      setMeta('viewport');
    }
  },
  methods: {
    // 是否呈现菜单
    visible(id) {
      const { navlink } = this.project.uiInfo || {};
      if (navlink && navlink.defaultNav) {
        return navlink.defaultNav.find(item => item.id === id)?.isShow;
      }
      return id !== 'env';
    },
    collapseMenu() {
      this.menuCollapsed = !this.menuCollapsed;
    },
    collapseBusiness() {
      this.businessCollapsed = !this.businessCollapsed;
    },
    // 是否禁止缩放
    isUserScalable() {
      const scalable = '_userScalable';
      const isScalable = this.$route?.query?.[scalable] === '0' || this.isMiniprogram;
      return isScalable;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #dddddd;
}

.menus {
  background: #dddddd;
  padding-top: 16px;
  &-tab {
    padding-left: 24px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-active {
    color: var(--wuji-primary-color) !important;
  }
}

.menus-hidden {
  padding-top: 11px;
}

.ant-menu {
  box-shadow: none !important;
}

.widget-wrapper {
  background: #ffffff;
  padding-left: 24px;
  padding-top: 16px;
}

.menus-wrapper {
  background: #ffffff;
  padding-top: 16px;
}
</style>
