// @ts-ignore
import wujiComp from '@tencent/xy-compsloader';
import queryString from 'query-string';
import openLocalLoadErrorModal from '@/components/localLoadErrorModal';
import { compsLoaderLogger } from './logger';
/**
 * 判断当前是否为HMR环境
 *
 * @param {*} query
 */
export const isHmr = (query) => !!getQueryValue('debug', query);
/**
 * 判断当前是否为布局调试
 *
 * @param {*} query
 */
export const isLayoutHmr = (query) => getQueryValue('debuglayout', query);
/**
 * 获取当前HMR的端口号
 *
 * @param {*} query
 */
export const getHmrPort = (query) => getQueryValue('debugport', query) || 9998;
/**
 * 开始组件的HMR模式
 *
 * @export
 * @param {*} query
 */
export const hmrStart = (query) => {
    try {
        const isHmrMode = isHmr(query);
        if (!isHmrMode)
            return;
        const hmrPort = getHmrPort(query);
        if (hmrPort && !wujiComp.debug) {
            wujiComp.hmrStart(hmrPort, {
                onLoadError: () => openLocalLoadErrorModal({
                    debugType: '组件',
                }),
            });
        }
        compsLoaderLogger({
            functionName: 'hmrStart',
            content: '初始化组件的hmr',
        });
    }
    catch (e) {
        console.error('hmr init failed', e);
    }
};
export const getQueryValue = (key, query) => {
    if (query)
        return query[key];
    const qs = queryString.parse(location.search);
    return qs[key];
};
