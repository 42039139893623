import ChatSdk from '@tencent/wuji-chat-sdk';
import { XyFlowTypeSelectorMeta, XyGPTAlertMeta } from '../paramsFormComponents';
import { CATEGORY } from './const';
import { aiHelper } from '@/utils/aiHelper';

document.body.classList.add('ai-hidden');
aiHelper.onOpen(() => {
  document.body.classList.remove('ai-hidden');
});

const chatSdk = new ChatSdk({
  dns: `${location.host}${window.GLOBAL_INFO?.pathPrefix || ''}`,
  proto: location.protocol.slice(0, -1),
});

// 消息返回类型
const RETURN_TYPE = {
  sync: 'sync',
  flow: 'flow',
};

// 语言模型，对应system
const SYSTEM = {
  混元: 'hy',
  ChatGPT: 'azure',
};

// 流式响应类型，拼接字符串、调用LessCode方法
const FLOW_TYPE = {
  joinString: 'joinString',
  callMethod: 'callMethod',
};

// 生成sessionId
const getSessionId = () => `${Math.random().toString(16)
  .slice(2, 10)}`;

// 根据路径拼接字符串
const appendValueByPath = (obj, path, value) => {
  try {
    const props = path.split('.');
    let current = obj;
    for (let i = 0; i < props.length - 1; i++) {
      current = current[props[i]];
    }
    current[props[props.length - 1]] += value;
  } catch (error) {
    console.error('路径错误！', error);
  }
};

const getErrorMessage = (message = '') => `[行为]AI模型调用出错：${message}`;

// 发送消息
const sendData = (params, renderer) => new Promise(async (resolve) => {
  const { message, systemContent, type, flowSetting: { flowType, joinString, callMethod }, system } = params;
  const sessionId = getSessionId();
  let event;
  if (Array.isArray(message)) {
    if (systemContent && message?.[0]?.role !== 'system') {
      message.unshift({
        role: 'system',
        content: systemContent,
      });
    }
    event = await chatSdk.sendAll({
      messages: message,
      sessionId,
    }, {
      system,
    });
  } else {
    const params = {
      sessionId,
    };
    if (message) {
      params.message = message;
      systemContent && (params.systemContent = systemContent);
    } else if (systemContent) {
      // 兼容只有prompt，没有message的情况
      params.message = systemContent;
    }
    event = await chatSdk.send(params, { system });
  }

  let content = '';
  if (type === RETURN_TYPE.flow) {
    event.on('data', (data) => {
      const responseMessage = data.delta === undefined ? '' : data.delta;
      if (flowType === FLOW_TYPE.joinString) {
        appendValueByPath(renderer, joinString, responseMessage);
        content += responseMessage;
      }
      if (flowType === FLOW_TYPE.callMethod) {
        let topRenderer = renderer;
        while (topRenderer.ucParentRenderer) topRenderer = topRenderer.ucParentRenderer;
        (topRenderer[callMethod](responseMessage));
      }
    });
  }

  event.on('end', (data) => {
    resolve({
      content: data.value,
      sessionId,
    });
  });

  event.on('error', (error) => {
    resolve({
      content,
      sessionId,
      errorMessage: error.error,
    });
  });
});

export default [
  {
    id: 'xy:ai:sendMessage',
    name: 'AI模型调用',
    desc: '',
    category: CATEGORY.AI,
    paramsSchema: {
      type: 'object',
      fields: [
        {
          id: 'alert',
          title: '',
          type: 'string',
          default: '',
          ui: { type: 'XyGPTAlert' },
          'uc:allowExpr': false,
          'uc:allowReset': false,
        },
        {
          id: 'systemContent',
          title: '预置prompt',
          type: 'string',
          default: '',
          'uc:allowInterop': true,
          description: '当message为数组时，prompt会作为第一条信息插入message数组，但如果message数组第一条就是"system"，则忽略该prompt',
        },
        {
          id: 'message',
          title: 'message（文本或者数组）',
          type: 'string',
          default: '',
          description: 'message支持文本、对话上下文数组。数组格式如下：\n```\n[\n  {\n    "role": "system",\n    "content": "你是一个只会用中文回答问题的助理"\n  },\n  {\n    "role": "user",\n    "content": "Hi！"\n  },\n  {\n    "role": "assistant",\n    "content": "你好，有什么我可以帮助你的吗？"\n  }\n]\n```',
          'uc:allowInterop': true,
        },
        {
          id: 'showErrorNotification',
          title: '出错时显示错误信息',
          type: 'boolean',
          default: true,
        },
        {
          id: 'type',
          title: '返回消息形式',
          type: 'string',
          enum: [
            {
              value: RETURN_TYPE.sync,
              label: '同步返回（等待全部消息接收完成后返回）',
            },
            {
              value: RETURN_TYPE.flow,
              label: '流式返回（拼接字符串或调用LessCode方法）',
            },
          ],
          default: RETURN_TYPE.sync,
        },
        {
          id: 'flowSetting',
          type: 'string',
          ui: { type: 'XyFlowTypeSelector' },
          title: '',
          condition: `params.type === "${RETURN_TYPE.flow}"`,
          default: {
            flowType: FLOW_TYPE.joinString,
            joinString: '',
            callMethod: 'onData',
          },
        },
        {
          id: 'system',
          title: '语言模型',
          type: 'string',
          enum: Object.entries(SYSTEM).map(([label, value]) => ({
            value, label,
          })),
          default: 'openai',
        },
      ],
    },
    execute: async (ctx, params) => {
      const { systemContent, message } = params;
      if (!systemContent && !message) {
        message.error(getErrorMessage('预置prompt、message不能同时为空！'));
        return;
      }

      const result = await sendData(params, ctx.renderer);
      if (params.showErrorNotification && result.errorMessage) {
        message.error(getErrorMessage(result.errorMessage));
      }
      return result;
    },
    returnValue: 'AI模型 的响应 \n\n 同步返回、流式返回都会返回接收到的全部消息，返回格式如下：\n ```{"sessionId":"7caa5345" // 会话ID,"content":"你好，有什么我可以帮助你的吗？"// 接收到的消息,"errorMessage":""// 仅在出错时有，返回错误信息}```',
    paramsFormComponents: [
      XyFlowTypeSelectorMeta,
      XyGPTAlertMeta,
    ],
  },
];
