import { keyBy } from 'lodash';
import { permissionApi as services } from '@/services';
import { getRoleUserKey } from './utils';
import { API_PERMISSION_BINDING_TYPE, COLLECTION_BINDING_TYPE } from './constants';

export default {
  namespaced: true,
  state: {
    roles: [],
    // project scope read and update
    canRead: false,
    canUpdate: false,
    permissions: [],
    customPermissions: [],
    cloudFunctions: [],
    apiPermissions: [],
    userView: { isAdmin: false, roles: [] },
    roleUsers: [],
    cgis: [],
  },
  getters: {
    hasReadPermission: state => state.canRead,
    hasUpdatePermission: state => state.canUpdate,
    roles: state => state.roles,
    roleUsers: state => state.roleUsers,
    permissions: state => state.permissions,
    customPermissions: state => state.customPermissions,
    cloudFunctions: state => state.cloudFunctions,
    cgis: state => state.cgis,
    cgiPermissions: (state) => {
      const map = {
        create: '新建数据',
        update: '更新数据',
        delete: '删除数据',
        others: '查看数据',
      };
      const { cgis, apiPermissions } = state;
      cgis.forEach((cf, index) => {
        const bindings = apiPermissions.filter(rec => rec.name === cf.name);
        cgis[index].bindings = bindings;
        if (bindings.length) {
          cgis[index].memo = '';
        } else {
          cgis[index].memo = `默认绑定页面的"${map[cf.cgiId] || map.others}"权限`;
        }
      });
      return cgis;
    },
    funcPermissions: (state) => {
      const { cloudFunctions, apiPermissions } = state;
      cloudFunctions.forEach((cf, index) => {
        const bindings = apiPermissions.filter(rec => rec.name === cf.name);
        cloudFunctions[index].bindings = bindings;
      });
      return cloudFunctions;
    },
    userToRoles: (state) => {
      const map = {};
      const { roles, roleUsers } = state;
      roleUsers.forEach((i) => {
        const key = getRoleUserKey(i);
        const rs = map[key] || [];
        const role = roles.find(r => r.name === i.role);
        if (!rs.some(r => r.name === i.role)) {
          if (role) {
            rs.push({ ...role, displayName: `${role.cname}` });
          } else {
            rs.push({ name: i.role, displayName: i.role });
          }
        }
        map[key] = rs;
      });
      return map;
    },
    roleToUsers: (state) => {
      const map = {};
      const { roles, roleUsers } = state;
      roles.forEach((r) => {
        const rs = roleUsers.filter(ru => ru.role === r.name);
        map[r.name] = rs;
      });
      return map;
    },
  },
  actions: {
    async check({ commit }, { projectId, env }) {
      const [canUpdate, canRead] = await Promise.all([
        services.checkPermission(projectId, env, 'update.permission', 'project'),
        services.checkPermission(projectId, env, 'read.permission', 'project'),
      ]);
      commit('setPermission', { canUpdate, canRead });
    },
    async fetchFuncs({ commit }, { projectId, env }) {
      try {
        commit('setCouldFunctions', { cloudFunctions: [] });
        const cloudFunctions = await services.fetchFuncs(projectId, env);
        commit('setCouldFunctions', { cloudFunctions });
      } catch (err) {
        const msg = '获取云函数失败';
        console.error(msg);
        throw new Error(err);
      }
    },
    async fetchCollections({ commit }, { projectId, env }) {
      try {
        commit('setCgis', { collections: [] });
        const collections = await services.fetchCollections(projectId, env);
        commit('setCgis', { collections });
      } catch (err) {
        const msg = '获取数据源列表失败';
        throw new Error(msg);
      }
    },
    async fetchRoles({ commit }, { projectId, env }) {
      const roles = await services.fetchRoles(projectId, env);
      commit('setRoles', { roles });
    },
    async fetchPermissions({ commit }, { projectId, env }) {
      const permissions = await services.fetchPermissions(projectId, env);
      commit('setPermissions', { permissions });
    },
    async fetchRoleUsers({ commit }, { projectId, env, bizId }) {
      const result = await services.fetchRoleUsers(projectId, env, bizId);
      commit('setRoleUsers', { roleUsers: result });
    },
    async fetchUserView({ commit }, { projectId, env }) {
      const userView = await services.fetchUserView(projectId, env);
      commit('setUserView', { userView });
      return userView;
    },
    async fetchBindingApis({ commit }, { projectId, env }) {
      commit('setPermissionApis', { permissions: [], bindingApis: [] });
      const [permissions, bindingApis] = await Promise.all([
        services.fetchPermissions(projectId, env),
        services.listApiBinding(projectId, env, API_PERMISSION_BINDING_TYPE),
      ]);
      commit('setPermissionApis', { permissions, bindingApis });
    },
  },
  mutations: {
    setPermission(state, { canUpdate, canRead }) {
      Object.assign(state, { canUpdate, canRead });
    },
    setRoles(state, { roles }) {
      Object.assign(state, { roles });
    },
    setPermissions(state, { permissions }) {
      Object.assign(state, { permissions });
    },
    setUserView(state, { userView }) {
      Object.assign(state, { userView });
    },
    setRoleUsers(state, { roleUsers }) {
      Object.assign(state, { roleUsers });
    },
    setPermissionApis(state, { bindingApis, permissions }) {
      const map = keyBy(permissions, 'name');
      bindingApis.forEach((api) => {
        const permissionId = api.permission;
        if (!map[permissionId]) return;
        const apis = map[permissionId].apis || [];
        map[permissionId].apis = [...apis, api];
      });
      const customPermissions = Object.values(map);
      Object.assign(state, { permissions, customPermissions, apiPermissions: bindingApis });
    },
    setCouldFunctions(state, { cloudFunctions }) {
      // eslint-disable-next-line no-param-reassign
      cloudFunctions.forEach(cf => cf.type = API_PERMISSION_BINDING_TYPE);
      Object.assign(state, { cloudFunctions });
    },
    setCgis(state, { collections }) {
      const result = [];
      collections.forEach((c) => {
        Object.keys(c.cgiInfo).forEach((id) => {
          result.push({
            _id: `${c.collectionId}.${id}`,
            cgiId: id,
            name: `${c.collectionId}.${id}`,
            type: COLLECTION_BINDING_TYPE,
            method: (c.cgiInfo[id].type || 'get').toUpperCase(),
            api: '',
          });
        });
      });
      Object.assign(state, { cgis: result });
    },
  },
};
