import { permissionApi } from '@/services';
import { BUILT_IN_ROLES, PermissionType } from './constants';
const createRole = async (projectId, envId, role) => {
    if (BUILT_IN_ROLES.includes(role.name))
        return false;
    if (!role.cname)
        return false;
    try {
        await permissionApi.createRole(projectId, envId, {
            name: role.name,
            cname: role.cname,
            type: role.type,
        });
    }
    catch (error) {
        return false;
    }
    return true;
};
const createRoleUser = async (projectId, envId, roleUser) => {
    try {
        const data = {
            bizId: roleUser.bizId,
            roles: [roleUser.role],
            users: roleUser.userType === 'staff' ? [roleUser.userId] : [{ label: roleUser.userName, value: roleUser.userId }],
            type: roleUser.userType,
        };
        await permissionApi.createRoleUser(projectId, envId, data);
    }
    catch (error) {
        return false;
    }
    return true;
};
const fetchRolePermissions = async (projectId, fromEnv, roleId) => {
    let roleView;
    try {
        roleView = await permissionApi.fetchRoleView(projectId, fromEnv, roleId);
    }
    catch (error) {
        return null;
    }
    const permissions = [];
    roleView.permissions.forEach((rp) => {
        permissions.push({ pageId: rp.pageId || '', permission: rp.permission });
    });
    const users = [];
    roleView.users.forEach((ru) => users.push({
        role: ru.role,
        bizId: ru.bizId,
        userId: ru.userId,
        userName: ru.userName,
        userType: ru.userType,
    }));
    return { permissions, users };
};
const updateRoleView = async (projectId, toEnv, roleId, permissions) => {
    try {
        await permissionApi.updateRoleView(projectId, toEnv, { role: roleId, permissions });
    }
    catch (error) {
        return false;
    }
    return true;
};
const fetchPermissions = async (projectId, fromEnv) => {
    const permissionList = await permissionApi.fetchPermissions(projectId, fromEnv);
    return permissionList;
};
const createPermission = async (projectId, toEnv, data) => {
    try {
        await permissionApi.createPermission(projectId, toEnv, data);
    }
    catch (error) {
        return false;
    }
    return true;
};
const createApiBinding = async (projectId, toEnv, data) => {
    try {
        await permissionApi.createApiBinding(projectId, toEnv, data);
    }
    catch (error) {
        return false;
    }
    return true;
};
const syncPermissions = async (projectId, toEnv, data) => {
    const result = [];
    for (const perm of data) {
        const ok = await createPermission(projectId, toEnv, { name: perm.name, cname: perm.cname, type: perm.type, relatedPages: perm.relatedPages || '' });
        result.push({
            type: 'permission',
            id: perm.name,
            operation: 'create',
            success: ok,
        });
    }
    return result;
};
const syncApiBindings = async (projectId, fromEnv, toEnv, permissions) => {
    const result = [];
    let bindings = [];
    try {
        bindings = await permissionApi.listApiBinding(projectId, fromEnv);
    }
    catch (error) {
        result.push({
            type: 'api-permission',
            id: '',
            operation: 'fetch',
            success: false,
        });
    }
    const records = bindings.filter(i => permissions.includes(i.permission));
    for (const rec of records) {
        const ok = await createApiBinding(projectId, toEnv, {
            api: rec.api,
            name: rec.name,
            method: rec.method,
            permission: rec.permission,
            type: rec.type,
        });
        result.push({
            type: 'api-permission',
            id: `'${rec.method}:${rec.name}' -- '${rec.permission}'`,
            operation: 'create',
            success: ok,
        });
    }
    return result;
};
export const syncRoles = async (projectId, fromEnv, toEnv, roles, needSyncRoleUser) => {
    const result = {};
    const permissionList = await fetchPermissions(projectId, fromEnv);
    for (const role of roles) {
        let resultList = [];
        let ok = await createRole(projectId, toEnv, role);
        resultList.push({
            type: 'role',
            id: role.name,
            operation: 'create',
            success: ok,
        });
        const resp = await fetchRolePermissions(projectId, fromEnv, role.name);
        const { permissions, users } = resp || {};
        if (!permissions) {
            resultList.push({
                type: 'role-permission',
                id: role.name,
                operation: 'fetch',
                success: false,
            });
            continue;
        }
        const customPermissions = permissions.map(rp => rp.permission);
        let permSetList = [];
        if (customPermissions.length) {
            const customPermissionList = permissionList.filter(p => customPermissions.includes(p.name));
            permSetList = customPermissionList.filter(x => x.type === PermissionType.SET);
            const syncPermResult = await syncPermissions(projectId, toEnv, customPermissionList);
            resultList = resultList.concat(syncPermResult);
            // api 类型权限
            const apiPermissions = permissionList.filter(p => p.type === 'api' && customPermissions.includes(p.name));
            const syncBindingResult = await syncApiBindings(projectId, fromEnv, toEnv, apiPermissions.map(p => p.name));
            resultList = resultList.concat(syncBindingResult);
        }
        // 若有集合权限, 需要同步集合权限角色
        if (permSetList.length) {
            const permSetRoles = permSetList.map(x => ({ name: `perm-set/${x.name}`, cname: '', type: 'perm-set' }));
            await syncRoles(projectId, fromEnv, toEnv, permSetRoles, false);
        }
        if (needSyncRoleUser && users) {
            for (const user of users) {
                ok = await createRoleUser(projectId, toEnv, user);
                resultList.push({
                    type: 'role-user',
                    id: `${user.role} -- ${user.userId}`,
                    operation: 'create',
                    success: ok,
                });
            }
        }
        ok = await updateRoleView(projectId, toEnv, role.name, permissions);
        resultList.push({
            type: 'role-permission',
            id: role.name,
            operation: 'update',
            success: ok,
        });
        result[role.name] = resultList;
    }
    return result;
};
