<template>
  <div class="XyFlowTypeSelector">
    <a-radio-group
      v-model="editValue.flowType"
      button-style="solid"
    >
      <a-radio-button value="joinString">
        拼接字符串
      </a-radio-button>
      <a-radio-button value="callMethod">
        调用LessCode方法
      </a-radio-button>
    </a-radio-group>
    <div
      v-if="editValue.flowType==='joinString'"
      class="flow-type-item"
    >
      <span
        v-if="!editValue.joinString"
        class="ds-fieldLabel-text"
      >请选择字符串变量</span>
      <DataPathSelector
        v-model="editValue.joinString"
      />
    </div>
    <div
      v-else
      class="flow-type-item"
    >
      <span
        v-if="!editValue.callMethod"
        class="ds-fieldLabel-text"
      >接收流式消息的 LessCode 方法</span>
      <XyLessCodeMethodSelector v-model="editValue.callMethod" />
    </div>
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';
import { DataPathSelector } from '@tencent/ui-core';
import XyLessCodeMethodSelector from '../XyLessCodeMethodSelector.vue';
export default {
  name: 'XyFlowTypeSelector',
  components: {
    DataPathSelector,
    XyLessCodeMethodSelector,
  },
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
    };
  },
  created() {
    this.$watch('editValue', (ed) => {
      if (!ed) this.editValue = { flowType: '', joinString: '', callMethod: '' };
    });
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.XyFlowTypeSelector{
  padding: 10px 0;
}
.flow-type-item{
  margin-top: 10px;
  min-height: 36px;
  span{
    display: inline-block;
    height: 28px;
  }
}
</style>
<style>
body.ai-hidden .ucd-flowAtomSelector-dropdown ul[label="AI行为"]{
  display: none;
}
</style>
