/**
 * 本模块用于做一些无极平台侧自己的的统计功能
 */

import { BASE_API_PATH, OPEN_DEPLOY } from '@/config/constant';
import wujiFetch from '@/utils/wujiFetch';
import { isBuiltinPageTemplate } from '@/utils/common';

const prefix = `${BASE_API_PATH}/xy/report/telemetry`;

const disabledReport = OPEN_DEPLOY;
/**
 * @param {string} type - 见  server 的 src/Server/Routers/xy/reportRouter.js，例如 pageView
 * @param {string} pageId - 当前页面，for rbac auth
 * @param {Record<string, any>} payload
 */
export function reportTelemetry(type, pageId, payload = {}) {
  if (disabledReport || isBuiltinPageTemplate(pageId)) return;
  if (!window.RUNTIME_CONFIGS) return;

  wujiFetch(`${prefix}/${type}?pageid=${pageId}`, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(() => {
    // 失败无需处理
  });
}
