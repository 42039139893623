<template>
  <a-auto-complete
    v-model="editValue"
    placeholder="请输入方法名"
    :data-source="methodList"
    style="display: block; flex: 1;"
    @focus="reloadMethodList"
  />
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import LessCodePlugin from '@tencent/ui-core/exts/LessCode';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';

export default {
  name: 'XyLessCodeMethodSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      methodList: [],
    };
  },
  created() {
    this.reloadMethodList();
  },
  methods: {
    reloadMethodList() {
      // 目前逍遥只能在最外层编写 LessCode，所以限制一下先
      let { renderer } = this.designer;
      while (renderer.ucParentRenderer) renderer = renderer.ucParentRenderer;

      const pagelet = renderer.ucPagelet;
      const rawMethods = LessCodePlugin.defaultDeflateMethod(pagelet)?.methods || {};
      this.methodList = Object.keys(rawMethods);
    },
  },
};
</script>

<style>
</style>
