import Entity from './entity/index';
import Start from './entity/Start';
import Approve from './entity/Approve';
import Index from './index';
import NewFlowDetail from './newFlow/Detail.vue';

export default {
  path: 'workflow',
  component: Index,
  meta: { layoutType: 'header-content' },
  children: [
    {
      path: '',
      redirect: 'entity',
    },
    {
      path: 'start',
      name: 'workflow.start',
      component: Start,
    },
    {
      path: 'restart',
      name: 'workflow.restart',
      component: Start,
    },
    {
      path: 'entity-list',
      name: 'workflow.entity-list',
      component: Entity,
    },
    {
      path: 'entity-detail',
      name: 'workflow.entity-detail',
      component: Approve,
    },
    {
      path: 'flow/:flowId',
      name: 'workflow.new.flow-detail',
      component: ({
        name: 'NewFlowDetailWrapper',
        render(h) {
          const route = this.$route;
          return h(NewFlowDetail, { key: `${route.params.flowId}/${route.query.jobId || ''}` });
        },
      }),
    },
  ],
};
