import { defineComponent, ref } from '@vue/composition-api';
import styles from './dataTable.module.scss';
export default defineComponent({
    name: 'DataTablePage',
    setup() {
        const wujie = ref();
        return {
            wujie,
        };
    },
    props: {
        appId: {
            type: String,
            required: true,
        },
        schemaId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            origin: window.location.origin,
            loading: true,
            plugins: [
                {
                    // 兼容wujie容器导致e.target 变成 wujie-app引起el-select的remoteMethod方法入参为undefined问题
                    jsLoader: (code, url) => {
                        if (url.includes('element-ui.js')) {
                            return code.replace('e.handleQueryChange(t.target.value)', 'e.handleQueryChange(e.$refs.input.value);');
                        }
                        return code;
                    },
                },
            ],
        };
    },
    methods: {
        afterMount() {
            this.loading = false;
        },
    },
    render() {
        return (<div style="position: relative">
        {this.loading && <div class={styles.spin}>
          <a-spin spinning={this.loading}/>
        </div>}
       {this.appId ? <public-w-wujie props={{
                    attrs: { src: `${this.origin}/wujie` },
                }} name={`datatable-${this.appId}-${this.schemaId}`} ref="wujie" width="100%" height="100%" afterMount={this.afterMount} url={`${this.origin}/p/edit?appid=${this.appId}&schemaid=${this.schemaId}&xy=1&frame=1&menutype=hide`} plugins={this.plugins}/> : null}
      </div>);
    },
});
