import { groupBy, keyBy } from 'lodash';
import { message, Modal } from 'ant-design-vue';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import { permissionApi } from '@/services';
import styles from './permission.module.scss';
import { useRawRoleUserList, useRoles, useRuntimeInfo, usePageList, useOrg } from './utils';
import UserRoleForm from './UserRoleForm';
import ManagePermission from './ManagePermission';
import PermFilter from './PermFilter';
import { BIZ_OR_PROJECT } from './constants';
import usePageTags from '@/components/pageTag/usePageTags';
import DebugModal from './DebugModal';
import classNames from 'classnames';
import { UserRoleExpireForm } from '@pages/modules/permission/expire/UserRoleExpireForm';
import { usePermissionSettings } from '@pages/modules/permission/expire/utils';
import { injectPermState } from '@pages/modules/permission/PermState';
import ExpireAt from './expireAt';
const Org = defineComponent({
    name: 'Org',
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { canCheck, valid } = useOrg(props.record);
        return {
            canCheck,
            valid,
        };
    },
    render() {
        return <span>
      {this.canCheck && !this.valid
                ? <a-tooltip>
              <span slot="title" style="color:orange">无效组织，组织ID或已变更</span>
              <span>
                <span>{this.record.userName}</span>
                <a-icon type="warning" style="color:orange;margin-left:8px"></a-icon>
              </span>
            </a-tooltip>
                : this.record.userName}
    </span>;
    },
});
export default defineComponent({
    name: 'UserList',
    components: {
        PermFilter,
        UserRoleForm,
    },
    props: {
        projectId: {
            type: String,
            default: window.RUNTIME_CONFIGS?.projectId || '',
        },
    },
    setup(props) {
        const { bizId, isRuntimeMode } = useRuntimeInfo();
        const q = ref('');
        const env = ref('');
        const bizConfig = ref(null);
        const showUserRoleModal = ref(false);
        const showUserRoleModalExpire = ref(false);
        const showDebugModal = ref(false);
        const listAll = ref(false);
        const canReadPermission = ref(false);
        const canUpdatePermission = ref(false);
        const okBtnDisabled = ref(true);
        const okBtnLoading = ref(false);
        const envChangeLoading = ref(false);
        const selectedRole = ref(null);
        const operation = ref('');
        const currentUser = ref(null);
        const switchOptions = ref([]);
        const { rawRoleUserList, fetchRawRoleUserList } = useRawRoleUserList(props.projectId);
        const { pageList: pages, fetchPageList: fetchPages } = usePageList(props.projectId, env.value);
        const { projectId: refProjectId } = toRefs(props);
        let { tagList, getTagList } = usePageTags(refProjectId);
        if (window.RUNTIME_CONFIGS) {
            const { basicTagList, getBasicTagList } = usePageTags(refProjectId);
            tagList = basicTagList;
            getTagList = getBasicTagList;
        }
        const { roles, fetchRoles } = useRoles(props.projectId);
        const getBizName = (bizId) => {
            const option = switchOptions.value.find(o => o.value === bizId);
            return option?.label ?? bizId;
        };
        const roleUserList = computed(() => {
            const roleIdMap = keyBy(roles.value, 'name');
            // 过滤业务
            let ruList = [];
            if (bizId.value === BIZ_OR_PROJECT) {
                ruList = rawRoleUserList.value;
            }
            else if (bizId.value === '') {
                ruList = rawRoleUserList.value.filter(ru => !ru.bizId);
            }
            else {
                ruList = rawRoleUserList.value.filter(ru => ru.bizId === bizId.value);
            }
            if (!listAll.value)
                ruList = ruList.filter(b => !b.disabled);
            const c = groupBy(ruList, 'userId');
            const qq = q.value.toLowerCase();
            const userIds = Object.keys(c)
                .sort()
                // 过滤搜索
                .filter(userId => userId.includes(qq) || c[userId]?.[0]?.userName?.toLowerCase()?.includes(qq));
            return userIds.map((userId) => {
                const roles = c[userId].map((ru) => {
                    const role = roleIdMap[ru.role];
                    if (!role)
                        return { ...ru, label: ru.role, name: ru.role, cname: ru.role };
                    const bizName = role.type === 'biz' ? `（${getBizName(ru.bizId)}）` : '';
                    const label = `${role.cname}${bizName}`;
                    return { ...ru, label, name: ru.role, cname: role.cname };
                });
                return {
                    userId,
                    userName: roles[0].userName,
                    userType: roles[0].userType,
                    roles,
                };
            });
        });
        const { permissionSettings } = usePermissionSettings();
        const { panguMappingEnabled } = injectPermState();
        return {
            q,
            env,
            bizId,
            showUserRoleModal,
            showUserRoleModalExpire,
            showDebugModal,
            listAll,
            canReadPermission,
            canUpdatePermission,
            okBtnDisabled,
            okBtnLoading,
            envChangeLoading,
            operation,
            currentUser,
            roleUserList,
            bizConfig,
            switchOptions,
            roles,
            rawRoleUserList,
            isRuntimeMode,
            fetchRawRoleUserList,
            fetchRoles,
            getBizName,
            selectedRole,
            pages,
            fetchPages,
            tagList,
            getTagList,
            permissionSettings,
            panguMappingEnabled,
        };
    },
    watch: {
        env: {
            async handler() {
                if (!this.env)
                    return;
                if (!this.canReadPermission)
                    return;
                this.envChangeLoading = true;
                // 切换环境时置空
                this.rawRoleUserList = [];
                await Promise.all([
                    this.fetchRawRoleUserList(this.env),
                    this.fetchRoles(this.env),
                    this.fetchPages(),
                    this.getTagList(),
                ]);
                this.envChangeLoading = false;
            },
            immediate: true,
        },
    },
    render() {
        const addRoleUser = (operation, user = null) => {
            this.operation = operation;
            this.okBtnLoading = false;
            this.showUserRoleModal = true;
            this.okBtnDisabled = true;
            if (['add-user', 'add-org'].includes(operation)) {
                this.currentUser = {
                    userType: operation === 'add-user' ? 'staff' : 'org',
                };
            }
            else {
                this.currentUser = user;
            }
        };
        const save = async () => {
            this.okBtnLoading = true;
            this.okBtnDisabled = true;
            const ok = await this.$refs.userRoleRef.save();
            this.okBtnLoading = false;
            this.okBtnDisabled = false;
            if (ok) {
                this.showUserRoleModal = false;
                this.showUserRoleModalExpire = false;
                this.fetchRawRoleUserList(this.env);
            }
        };
        const deleteUser = (userId, userName) => {
            Modal.confirm({
                title: '确认移除当前用户/组织？',
                content: () => (<div>
            <br />
            <div>{userName}</div>
            <br />
            <div>注意：同时会删除所有关联角色</div>
          </div>),
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk: async () => {
                    const record = this.roleUserList.find(i => i.userId === userId);
                    await Promise.all(record.roles.map(r => permissionApi.deleteRoleUser(this.projectId, this.env, r._id)));
                    message.success('已移除');
                    await this.fetchRawRoleUserList(this.env);
                },
            });
        };
        // 捞一下项目数据
        const expireTimeRolesRender = (roles) => {
            const lines = roles.map(r => <ExpireAt expireAt={r.expireAt}></ExpireAt>);
            return lines;
        };
        const editUser = (user) => {
            this.showUserRoleModal = true;
            this.showUserRoleModalExpire = true;
            this.currentUser = user;
            this.okBtnDisabled = true;
        };
        const columns = [
            {
                title: '名称',
                dataIndex: 'userName',
                key: 'userId',
                width: 400,
                customRender: (userName, record) => (record.userType === 'org'
                    ? <Org record={record}/>
                    : userName),
            },
            {
                title: '角色',
                dataIndex: 'roles',
                key: 'roles',
                customRender: (roles) => roles.map(r => <a-tag key={r._id} color='blue' style="cursor:pointer" class={classNames(r.disabled && styles.disabledRoleUserNameTag)} onClick={() => this.selectedRole = r}>{r.label}</a-tag>).map((vNode) => {
                    // 开了盘古就横向排列
                    if (this.panguMappingEnabled)
                        return vNode;
                    return (<div style="padding-top:2px">{vNode}</div>);
                }),
            },
            !this.panguMappingEnabled && {
                title: '到期时间',
                dataIndex: 'roles',
                key: 'roleExpire',
                customRender: expireTimeRolesRender,
            },
            {
                title: '操作',
                dataIndex: 'userId',
                key: 'operation',
                width: 240,
                customRender: (userId, row) => (<div>
            {!this.panguMappingEnabled && <a href="javascript:;" style="margin-right:12px" onClick={() => editUser(row)}>
              编辑
            </a>}
            {/* 开了盘古用这个 */}
            {this.panguMappingEnabled && <a-dropdown disabled={!this.canUpdatePermission}>
              <a className="ant-dropdown-link" disabled={!this.canUpdatePermission}>
                管理角色 <a-icon type="down"></a-icon>
              </a>
              <a-menu slot="overlay" onClick={({ key }) => addRoleUser(key, row)}>
                <a-menu-item key="add-role">添加角色</a-menu-item>
                <a-menu-item key="delete-role">删除角色</a-menu-item>
              </a-menu>
            </a-dropdown>}
            <a href="javascript:;" style="margin-left:12px" onClick={() => deleteUser(userId, row.userName)} disabled={!this.canUpdatePermission}>
              {`移除${row.userType === 'staff' ? '用户' : '组织'}`}
            </a>
          </div>),
            },
        ].filter(Boolean);
        return (<div>
        <div class={styles.operationAndSearch}>
          <div>
          {this.canUpdatePermission ? (<span>
              <a-button type="primary" onClick={() => addRoleUser('add-user')}>
                添加用户
              </a-button>
              <a-button type="primary" style="margin-left:8px" onClick={() => addRoleUser('add-org')}>
                添加组织
              </a-button>
            </span>) : (<a-space>
              <a-alert message={this.canReadPermission ? '无权更新，只读模式' : '无权查看'} type="warning" show-icon/>
            </a-space>)}
          {this.canReadPermission
                ? <a-button onClick={() => this.showDebugModal = true} style="margin-left:8px">检测用户权限</a-button>
                : null}
          </div>
          <PermFilter projectId={this.projectId} showEnv={!this.isRuntimeMode} showSwitch={true} showListAllSwitch={true} listAll={this.listAll} onListAllChange={(v) => (this.listAll = v)} searchPlaceholder="请输入名称搜索" onEnvChange={(data) => {
                this.env = data.env;
                this.bizConfig = data.bizConfig;
                this.switchOptions = data.switchOptions;
                this.canReadPermission = data.canReadPermission;
                this.canUpdatePermission = data.canUpdatePermission;
            }} onBizIdChange={(bizId) => (this.bizId = bizId)} onSearchChange={(q) => (this.q = q)}/>
        </div>

        <div>
          <a-table loading={this.envChangeLoading} columns={columns} dataSource={this.roleUserList} rowKey="userId"></a-table>
        </div>

        <a-modal title={this.selectedRole?.cname} okButtonProps={{ props: { disabled: this.okBtnDisabled } }} visible={!!this.selectedRole} width="64%" destroyOnClose={true} onCancel={() => (this.selectedRole = null)}>
          <ManagePermission key={this.selectedRole?.name} projectId={this.projectId} env={this.env} role={this.selectedRole?.name} pages={this.pages} tagList={this.tagList} mode='view' maxHeight="60vh"/>
        </a-modal>
        <a-modal width={this.showUserRoleModalExpire ? '1000px' : '420px'} title={['add-user', 'add-org'].includes(this.operation)
                ? `添加${this.operation === 'add-user' ? '用户' : '组织'}` : '管理角色'} visible={this.showUserRoleModal} okButtonProps={{ props: { disabled: this.okBtnDisabled, loading: this.okBtnLoading } }} okText="保存" destroyOnClose={true} onOk={() => save()} onCancel={() => (this.showUserRoleModal = false, this.showUserRoleModalExpire = false)}>
          {this.showUserRoleModalExpire
                ? <UserRoleExpireForm ref="userRoleRef" key={this.currentUser?.userId || 'add-user'} projectId={this.projectId} env={this.env} isRuntimeMode={this.isRuntimeMode} defaultBizId={this.bizId} bizList={this.bizConfig ? this.switchOptions : undefined} roles={this.roles} operation={this.operation} userId={this.currentUser?.userId} userName={this.currentUser?.userName} userType={this.currentUser?.userType} userRoleList={this.roleUserList} onChange={(data) => (this.okBtnDisabled = data.roles.length === 0)}/>
                : <UserRoleForm 
                // @ts-ignore
                ref="userRoleRef" key={this.currentUser?.userId || 'add-user'} projectId={this.projectId} env={this.env} isRuntimeMode={this.isRuntimeMode} defaultBizId={this.bizId} bizList={this.bizConfig ? this.switchOptions : undefined} roles={this.roles} operation={this.operation} userId={this.currentUser?.userId} userName={this.currentUser?.userName} userType={this.currentUser?.userType} userRoleList={this.roleUserList} onChange={(data) => (this.okBtnDisabled = data.roles.length === 0)}></UserRoleForm>}
        </a-modal>
        <DebugModal projectId={this.projectId} env={this.env} v-model={this.showDebugModal} roles={this.roles} bizList={this.bizConfig ? this.switchOptions : undefined}/>
      </div>);
    },
});
