import { set, isEmpty, isPlainObject, mergeWith, isArray, cloneDeep } from 'lodash';
import { IS_SHOW_XIAOMAN, IS_SHOW_AEGIS, IS_SHOW_AGENT_REPORT, DEFAULT_THEME_COLOR_LIST, CUSTOM_THEME_COLOR_LIST } from '@config/constant';
import { PAGES } from '@utils/searchKeys';
import { DEFAULT_UI_LIBRARY } from '@/consts/component-hub-related';
import { getUUID } from '@utils/common';
const { keys } = PAGES.navbar;
const { GLOBAL_INFO } = window;
export const getDefaultProjectInfo = () => ({
    id: '',
    name: '',
    desc: '',
    favicon: '',
    logo: '',
    admin: GLOBAL_INFO?.rtx ?? '',
    participant: '',
    homePage: { path: '' },
    uiInfo: {
        sidebar: getDefaultSidebar(),
        navlink: getDefaultNavLink(),
        theme: getDefaultProjectTheme(),
        hideLoadingIndicator: false,
    },
    authAppIds: [],
    componentHubGroupsList: '',
    advanceConfig: getDefaultAdvanceConfig(),
    lessCodeModules: {},
    department: '',
    orgId: '',
    orgName: '',
    projectType: 'formal',
    componentHubUILibrary: DEFAULT_UI_LIBRARY,
});
// 获取应用默认的主题色
const getDefaultProjectTheme = () => {
    const defaultTheme = window.GLOBAL_INFO.defaultTheme || 'pcgBlue';
    const target = [
        ...DEFAULT_THEME_COLOR_LIST,
        ...CUSTOM_THEME_COLOR_LIST,
    ].find((item) => defaultTheme === item.id);
    if (target) {
        return {
            id: target.id,
            label: target.label,
        };
    }
    return { id: 'pcgBlue', label: 'PCG蓝' };
};
export var AfterPublishType;
(function (AfterPublishType) {
    AfterPublishType["wxRobot"] = "wx_robot";
    AfterPublishType["custom"] = "custom";
})(AfterPublishType || (AfterPublishType = {}));
export const haveCustomProjectSettings = () => Object.keys(window.CONFIGS?.customProjectSetting ?? {}).length > 0;
export const DEFAULT_ESLINT_CONFIG = `{
  env: {
    browser: true,
    es6: true,
  },
  globals: {
    Vue: 'readonly',
  },
  rules: {
    // 'no-dupe-keys': 'error',
  },
}`;
export const getDefaultProjectSettings = () => {
    const defaultProjectSetting = {
        versionSetting: {
            devToProd: false,
            needStaging: false,
            enabledProdVersionApproval: false,
            enabledBranch: false,
            enablePublishHook: false,
            publishHookSetting: {
                type: AfterPublishType.wxRobot,
                value: {
                    robotId: '',
                    corpid: '',
                    data: '`${params.text}\n发布人：${params.publisher}\n发布环境: ${params.env}\n发布描述：${params.versionInfo.desc}\n查看发布详情：[http://${params.host}/xy/project/config/version?projectid=${params.projectId}](http://${params.host}/xy/project/config/version?projectid=${params.projectId})\n预览地址: [${params.host}/xy/app/${params.env}/${params.projectId}](http://${params.host}/xy/app/${params.env}/${params.projectId})`',
                },
            },
        },
        branchSetting: {
            protectBranch: false,
        },
        permissionSetting: {
            maxDays: 0,
            autoRemoveByDept: false,
            expireOptions: [],
            expireRules: [],
        },
        robots: [],
        editorSetting: {
            eslintEnabled: true,
            eslintConfig: `module.exports = ${DEFAULT_ESLINT_CONFIG};`,
        },
    };
    if (haveCustomProjectSettings()) {
        return deepMergeObject(defaultProjectSetting, window.CONFIGS?.customProjectSetting ?? {});
    }
    return defaultProjectSetting;
};
export const getDefaultNav = () => [{
        id: 'user',
        // Reference: 用户信息
        name: keys.user,
        isShow: true,
        isMenu: false,
    }, {
        id: 'name',
        // Reference: 应用名称
        name: keys.name,
        isShow: true,
        isMenu: false,
    }, {
        id: 'projectLogo',
        // Reference: 应用Logo
        name: keys.projectLogo,
        isShow: true,
        isMenu: false,
    }, {
        id: 'env',
        // Reference: 环境切换
        name: keys.env,
        isShow: false,
        isMenu: false,
        showList: ['*'],
        redirect: 'same',
    }, {
        id: 'help',
        // Reference: 帮助文档
        name: keys.help,
        isShow: false,
        url: '',
        isMenu: true,
    }, {
        id: 'feedback',
        // Reference: 我要反馈
        name: keys.feedback,
        isShow: false,
        url: '',
        isMenu: true,
    }];
export const getDefaultAva = () => {
    const defaultAva = [{
            id: 'pending',
            name: '待办',
            isShow: true,
        }, {
            id: 'anncmnt',
            name: '公告',
            isShow: false,
        }, {
            id: 'pageConfig',
            name: '跳转至编辑页',
            isShow: true,
        }];
    if (window.GLOBAL_INFO.wxQRCode)
        defaultAva.push({
            id: 'wxQRCode',
            name: '用小程序访问',
            isShow: true,
        });
    return defaultAva;
};
export const getDefaultNavConfig = () => ({
    navFixTop: true,
    showBreadcrumb: false,
    showTagsView: false,
    showNavWaterMark: true,
});
export const getDefaultWujiNavigator = () => ({
    isShow: true,
});
// 获取默认的菜单组
export const getDefaultSidebarGroup = (id = '0', name = '默认菜单') => ({
    groupId: id,
    groupName: name,
    width: 209,
    withTitle: false,
    withMenuTitle: false,
    openAll: false,
    showIcons: [true, false],
    menus: [],
});
// 获取默认的菜单数据
export const getDefaultSidebar = () => ([getDefaultSidebarGroup()]);
export const getDefaultNavLink = () => ({
    defaultNav: getDefaultNav(),
    navConfig: getDefaultNavConfig(),
    wujiNavigator: getDefaultWujiNavigator(),
    defaultAva: getDefaultAva(),
    menus: [],
    widgets: [getDefaultWidget()],
    avatar: [],
    dynamicComps: [],
    menuGroup: [],
    showPermissionButton: false,
});
// 获取默认的菜单名称
export const getDefaultMenuName = (type = 'link') => (type === 'link' ? `菜单-${getUUID().slice(3, 9)}` : '下拉菜单');
export const isDefaultMenuName = (name = '') => (name === '默认文本' || /^菜单-[a-zA-Z0-9]{6}$/.test(name));
export const getDefaultMenu = ({ type = 'link', isRoot = false } = {}) => ({
    name: getDefaultMenuName(type),
    type,
    args: {
        target: 'self',
        trigger: 'hover',
        url: '',
        icon: isRoot ? 'folder' : '',
        open: false,
        menus: [],
        urlType: 'page',
        params: '',
        pathParams: '', // params 参数
    },
});
// 获取默认的顶部导航菜单组配置
export const getDefaultMenuGroup = () => ({
    isShow: false,
    groupId: '',
    showMenus: false,
    roles: ['*'],
});
export const getDefaultDynamicComps = (type) => ({
    isShow: false,
    name: undefined,
    props: null,
    style: null,
    position: 'right',
    type,
});
const transferMenu = (menu) => {
    const newMenu = {
        ...menu,
    };
    if (newMenu.args.menus) {
        newMenu.args.menus = newMenu.args.menus.map(item => transferMenu(item));
    }
    delete newMenu.notSaveParams;
    return newMenu;
};
// 入库前转换
export const uiMenuToSaveMenu = obj => ({
    ...obj,
    menus: obj.menus.map(item => transferMenu(item)),
});
const formatMenu = menu => ({
    ...menu,
    args: {
        ...getDefaultMenu().args,
        ...menu.args,
        menus: menu.args.menus ? menu.args.menus.map(item => formatMenu(item)) : [],
    },
});
// 出库前数据作预处理，处理成UI可以直接渲染的数据
export const saveMenuToUiMenu = obj => ({
    ...obj,
    menus: obj.menus.map(item => formatMenu(item)),
});
// 出库前homePage和page403预处理，转换为JSON对象，兼容路径字符串和JSON字符串
export const savePathStrToObject = (str) => {
    try {
        const obj = JSON.parse(str);
        if (isPlainObject(obj) && !isEmpty(obj)) {
            return obj;
        }
        return {
            path: '',
        };
    }
    catch (e) {
        return {
            path: str || '',
        };
    }
};
// 入库前homePage和page403JSON对象转字符串
export const objectSaveToPathStr = (obj) => JSON.stringify(obj);
export const formatSideBarToUIState = (sidebar) => {
    let result = sidebar;
    if (!Array.isArray(sidebar)) {
        result = [{
                ...getDefaultSidebarGroup(),
                ...sidebar,
            }];
    }
    return result.map(group => ({
        ...getDefaultSidebarGroup(),
        ...group,
        menus: group.menus.map(item => formatMenu(item)),
    }));
};
// 入库前转换
export const uiAdvanceConfigToSaveAdvanceConfig = (uiConfig) => {
    const saveConfig = cloneDeep(uiConfig);
    const { headScript, bodyScript } = saveConfig;
    if (headScript)
        saveConfig.headScript = encodeURIComponent(headScript);
    if (bodyScript)
        saveConfig.bodyScript = encodeURIComponent(bodyScript);
    return saveConfig;
};
// 出库前数据作预处理，处理成UI可以直接渲染的数据
export const saveAdvanceConfigToUiAdvanceConfig = (saveConfig) => {
    const uiConfig = { ...getDefaultAdvanceConfig(), ...cloneDeep(saveConfig) };
    const { headScript, bodyScript } = uiConfig;
    if (headScript)
        uiConfig.headScript = decodeURIComponent(headScript);
    if (bodyScript)
        uiConfig.bodyScript = decodeURIComponent(bodyScript);
    return uiConfig;
};
export const getDefaultAdvanceConfig = () => {
    const config = {
        headScript: '',
        bodyScript: '',
        enabledGuard: false,
        routeGuard: '',
        customNav: 'default',
        customSidebar: 'default',
        customHeader: '',
        customFooter: '',
        openAnncmnt: false,
        anncmntConfig: null,
        globalIcons: {},
    };
    if (IS_SHOW_XIAOMAN)
        Object.assign(config, { xiaoman: { open: false, appid: '' } });
    if (IS_SHOW_AEGIS)
        Object.assign(config, { aegis: true });
    if (IS_SHOW_AGENT_REPORT)
        Object.assign(config, { agentReport: false });
    return config;
};
export const setDefaultWatermark = (config) => {
    // 新建应用时，配置了水印信息的部署默认开启暗水印、关闭明水印
    if (window.CONFIGS?.configuredWatermark) {
        return {
            ...config,
            showWatermark: true,
            watermarkConfig: {
                showVisibleWatermark: false,
                showInvisibleWatermark: true,
            },
        };
    }
    return config;
};
export const getDefaultWidget = () => ({
    type: 'switch',
    config: { type: 'static', options: [{ label: '', value: '' }], queryKey: '' },
});
export const LINK_TARGETS = [
    { id: 'self', label: '当前页打开' },
    { id: 'blank', label: '新开窗口' },
];
export const MENU_TRIGGER = [
    { value: 'hover', label: '鼠标悬停' },
    { value: 'click', label: '点击标题' },
];
export const mergeArrayOfObject = (newArray, oldArray, predicate = 'id') => {
    for (const item of newArray) {
        const oldItem = oldArray.find((o) => o[predicate] === item[predicate]);
        if (oldItem !== undefined)
            Object.assign(item, oldItem);
    }
    return newArray;
};
export const deepMergeObject = (object, other) => {
    const customizer = (objValue) => {
        if (isArray(objValue)) {
            return objValue;
        }
    };
    return mergeWith(object, other, customizer);
};
export function formatToSaveProjectInfo(projectInfo, isCreate = false) {
    const result = cloneDeep(projectInfo);
    result.visibility = Number(projectInfo.visibility || '0');
    // 格式化菜单和导航数据
    if (projectInfo.uiInfo?.navlink) {
        result.uiInfo.navlink = uiMenuToSaveMenu(projectInfo.uiInfo.navlink);
    }
    // 格式化扩展配置
    if (projectInfo.advanceConfig) {
        const advanceConfig = isCreate ? setDefaultWatermark(projectInfo.advanceConfig) : projectInfo.advanceConfig;
        result.advanceConfig = uiAdvanceConfigToSaveAdvanceConfig(advanceConfig);
    }
    // 格式化首页和403重定向页面
    if (projectInfo.homePage) {
        result.homePage = objectSaveToPathStr(projectInfo.homePage);
    }
    if (projectInfo.page403) {
        result.page403 = objectSaveToPathStr(projectInfo.page403);
    }
    return result;
}
// 移除应用中过期的字段
const removeOutdatedFields = (project) => {
    const assignProject = project;
    ['sourceCode', 'status'].forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(assignProject, item)) {
            delete assignProject[item];
        }
    });
    return assignProject;
};
// 将没有带前缀的旧数据更新为新数据
const upgradeComponentHubGroupsList = (project) => {
    const assignProject = project;
    if (!assignProject?.componentHubGroupsList)
        return assignProject;
    let groups = assignProject.componentHubGroupsList;
    if (!Array.isArray(groups)) {
        groups = groups.split(',').filter(Boolean);
    }
    if (groups.some(group => !group.includes('@'))) {
        groups = groups.map((group) => {
            if (group.includes('@'))
                return group;
            return `local@${group}`;
        });
    }
    assignProject.componentHubGroupsList = groups.join(',');
    return assignProject;
};
// 应用配置升级逻辑(增删改字段等)
export function formatToUiProjectInfo(projectInfo) {
    const navlink = saveMenuToUiMenu({ ...getDefaultNavLink(), ...projectInfo?.uiInfo?.navlink });
    navlink.defaultNav.forEach((item) => {
        set(item, 'name', item.name.replace(/项目/g, '应用'));
    });
    navlink.defaultNav = mergeArrayOfObject(getDefaultNav(), projectInfo.uiInfo?.navlink?.defaultNav ?? []);
    navlink.defaultAva = mergeArrayOfObject(getDefaultAva(), projectInfo.uiInfo?.navlink?.defaultAva ?? []);
    navlink.navConfig = { ...getDefaultNavConfig(), ...projectInfo.uiInfo?.navlink?.navConfig };
    navlink.wujiNavigator = { ...getDefaultWujiNavigator(), ...projectInfo.uiInfo?.navlink?.wujiNavigator };
    let formatProject = {
        ...projectInfo,
        visibility: projectInfo.visibility ? String(projectInfo.visibility) : '0',
        uiInfo: {
            ...projectInfo.uiInfo,
            sidebar: formatSideBarToUIState(projectInfo?.uiInfo?.sidebar),
            navlink,
            theme: projectInfo.uiInfo?.theme || { id: 'default', label: '默认' },
        },
        lessCodeModules: projectInfo.lessCodeModules ?? {},
        advanceConfig: saveAdvanceConfigToUiAdvanceConfig(projectInfo.advanceConfig),
        homePage: savePathStrToObject(projectInfo.homePage),
        page403: savePathStrToObject(projectInfo.page403),
    };
    formatProject = upgradeComponentHubGroupsList(formatProject);
    formatProject = removeOutdatedFields(formatProject);
    return formatProject;
}
