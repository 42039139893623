import Atta from './Atta';
import keyBy from 'lodash/keyBy';
import { parse } from 'query-string';
import throttle from 'lodash/throttle';
import { addPgEventListener, removePgEventListener } from './utils';
import delegate from '@utils/delegate';
const onview = require('@tencent/onview');
onview.set('throttle', 100);

const publicDimAttr = 'data-uc-report-public-dimensions';

const selfAttr = 'data-uc-report-self';

const publicAttrs = {
  id: 'data-uc-report-id',
  path: 'data-uc-report-path',
};

const eventAttrs = {
  click: {
    eid: 'data-uc-report-click-eid',
    value: 'data-uc-report-click-value',
  },
  onview: {
    eid: 'data-uc-report-onview-eid',
    value: 'data-uc-report-onview-value',
  },
};

class RuntimeBaseReport {
  /**
   * @param {string} attrId
   * @param {string} attrToken
   * @param {string} projectInfo
   * @param {string} pageId
   * @param {array} reportDimensions 布局内存储的上报维度信息
   * @param {HTMLElement} $dom 布局根节点 -> 拖拽页面实际上是$uicore
   * @param {string} envId 环境ID
   * @param {boolean} onlyPv 有的业务想使用自己的attrId进行自主上报，平台方仅上报PV，UV则ok（详见./RuntimeReport）
   */
  constructor({ attaId, attaToken, projectInfo, pageId, reportDimensions, $dom, envId, onlyPv, agentReport }) {
    this.reporter = new Atta({
      attaId,
      token: attaToken,
      getPublicDims: () => ({
        env: envId,
      }),
      agentReport,
    });

    this.reporter.setProject(projectInfo);
    this.reporter.setPage(pageId);
    this.reportDimensions = keyBy(reportDimensions || [], 'id');

    this.$dom = $dom;

    if (!onlyPv) {
      this.bindClick();
      this.onviewListener();
      this.refreshElView();
    }

    // 其他一些实例初始化
    // 绑定页面 多次来回切的 曝光与反曝光 事件
    this.pvHandler = addPgEventListener({
      pgIn: () => this.pv(),
      pgOut: () => this.pgOut(),
    });
    this.observer = null;
  }

  pv() {
    const dims = this.dimsToAttrDims(this.getPublicDims());
    this.reporter.pv(dims);

    return this;
  }

  destroy() {
    removePgEventListener(this.pvHandler);
    this.observer?.disconnect();
    return this;
  }

  pgOut() {
    const dims = this.dimsToAttrDims(this.getPublicDims());
    this.reporter.pgOut(dims);
    return this;
  }

  report(data) {
    const {
      event,
      path = '',
      id = '',
      eid = '',
      dimensions,
      ...reportParams
    } = data;

    // click为标准事件，其他事件无法在系统上逐个注册，因此直接用customEvent代替
    // 报表筛选时直接过滤subAction则可
    const reportEvent = event === 'click' ? 'click' : 'customEvent';
    const externalParams = reportEvent === 'customEvent' ? {
      subAction: id ? `${id}:${event}` : event,
    } : {};

    this.reporter.report(reportEvent, {
      eid,
      compPath: path,
      ...reportParams,

      ...externalParams,
      ...this.dimsToAttrDims({
        ...this.getPublicDims(),
        ...dimensions,
      }),
    });
  }

  bindClick() {
    const binded = 'data-xy-report-bind';
    if (!this.$dom || this.$dom.getAttribute(binded)) return;

    this.$dom.setAttribute(binded, 1);

    delegate(this.$dom, 'click', `[${eventAttrs.click.value}]`, (el) => {
      // 组件自主上报
      if (el.getAttribute(selfAttr)) return;
      this.reporter.report('click', this.getCompDims('click', el));
    });
  }

  /**
   * 绑定元素曝光
   */
  refreshElView() {
    if (!this.$dom) return;

    const els = this.$dom.querySelectorAll(`[${eventAttrs.onview.value}]`);
    els.forEach((el) => {
      // 为了不重复绑定onview，又能记录已经绑定过onview
      const val = el.getAttribute(eventAttrs.onview.value);
      el.setAttribute(`_${eventAttrs.onview.value}`, val);
      el.removeAttribute(eventAttrs.onview.value);

      const unbind = onview(el, {
        once: true,
        recalc: false,
      }, () => {
        this.reporter.report('onview', this.getCompDims('onview', el, val));
        unbind && unbind();
      });
    });
  }

  /**
   * 监听元素变动
   */
  onviewListener() {
    if (!this.$dom) return;

    const config = { childList: true, subtree: true };
    this.observer = new MutationObserver(throttle(this.refreshElView, 100));
    this.observer.observe(this.$dom, config);
  }

  /**
   * 将自定义维度转化为上报能识别的扩展参数维度d1,d2,d3...
   */
  dimsToAttrDims(dims) {
    const attrDims = {};
    Object.entries(dims).forEach(([key, value]) => {
      const d = this.reportDimensions[key]?.dimension;
      // 如果没有找到维度定义，不上报
      if (!d) return;

      attrDims[d] = value;
    });

    return attrDims;
  }

  getCompDims(event, el, value) {
    const attrs = keyBy([...el.attributes]
      .map(({ name, value }) => ({ name, value })), 'name');

    const getAttrVal = (id) => {
      const attrId = eventAttrs[event][id];
      return attrs[attrId]?.value || '';
    };

    // 获取组件eid
    const eid = getAttrVal('eid') || el.getAttribute(publicAttrs.id);

    // 获取组件路径
    const compPath = el.getAttribute(publicAttrs.path);

    // 标签里面的value是encode过的，此处需要decode
    const dims = parse(value || getAttrVal('value'));
    Object.entries(dims).forEach(([key, value]) => {
      dims[key] = decodeURIComponent(value);
    });

    return {
      eid,
      compPath,

      ...this.dimsToAttrDims({
        ...this.getPublicDims(),
        ...dims,
      }),
    };
  }

  getPublicDims() {
    if (!this.$dom) return {};

    const attr = [...this.$dom.attributes]
      .find(({ name }) => name === publicDimAttr);
    return attr ? parse(attr.value || '') : {};
  }
}

export default RuntimeBaseReport;
