import { ApprovalOperation } from '@/services/flow';
const getFlowSchema = () => ({
    id: 'flow',
    title: '高级流程(beta)',
    type: 'object',
    readonly: true,
    'uc:collapsed': true,
    'uc:bindable': false,
    methods: [
        {
            id: 'fetchJob',
            title: '获取流程',
            arguments: [{ id: 'jobId', type: 'string', description: '流程id' }],
        },
        {
            id: 'approval',
            title: '审批',
            arguments: [
                { id: 'jobId', type: 'string', description: '流程id' },
                { id: 'nodeId', type: 'string', description: '审批节点id' },
                {
                    id: 'operation', type: 'string', description: '同意/拒绝', enum: ApprovalOperation,
                },
                { id: 'comment', type: 'string', description: '审批意见' },
                { id: 'form', type: 'object', description: '表单数据' }
            ],
        },
        {
            id: 'startFlow',
            title: '启动流程',
            arguments: [
                { id: 'flowId', type: 'string', description: '流程id' },
                { id: 'params', type: 'object', description: '流程数据' },
            ],
        },
        {
            id: 'fetchFlow',
            title: '获取流程配置',
            arguments: [{ id: 'flowId', type: 'string', description: '流程id' }],
        },
        {
            id: 'fetchApprovalNodeInfo',
            title: '获取审批节点信息',
            arguments: [
                { id: 'jobId', type: 'string', description: '流程id' },
                { id: 'nodeId', type: 'string', description: '审批节点id' }
            ],
        },
        {
            id: 'fetchLogNodeInfo',
            title: '获取日志节点信息',
            arguments: [
                { id: 'jobId', type: 'string', description: '流程id' },
                { id: 'nodeId', type: 'string', description: '审批节点id' }
            ],
        },
        {
            id: 'fetchJobList',
            title: '获取全量流程',
            arguments: [
                { id: 'title', title: '标题', type: 'string', description: '流程标题，空字符串为不过滤' },
                { id: 'creator', title: '发起人', type: 'string', description: '发起人，空字符串为不过滤' }
            ],
        },
        {
            id: 'fetchJobListByType',
            title: '根据类型获取流程',
            arguments: [
                { id: 'title', title: '标题', type: 'string', description: '流程标题，空字符串为不过滤' },
                {
                    id: 'type', title: '流程类型', type: 'string', description: '流程类型', enum: [{
                            label: '我发起的',
                            value: 'created',
                        }, {
                            label: '我参与的',
                            value: 'participated',
                        },
                        {
                            label: '与我相关',
                            value: 'related',
                        },
                        {
                            label: '审批代办',
                            value: 'pending',
                        }],
                }
            ],
        },
    ],
});
export default getFlowSchema;
