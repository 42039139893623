<template>
  <div
    class="nav-user"
    :class="mode"
  >
    <a-popover
      v-model="show"
      :overlay-class-name="`nav-user-popover-${mode}`"
      placement="bottomRight"
      arrow-point-at-center
    >
      <template
        v-if="!isGuest"
        slot="content"
      >
        <user-info-card
          :src="avatar"
          :page-mode="pageMode"
          :mode="mode"
        >
          <template #default>
            <slot :close="close" />
          </template>
          <template #logout>
            <slot name="logout" />
          </template>
        </user-info-card>
      </template>
      <span
        class="nav-user_button"
        @click="handleUserButtonClick"
      >
        <a-badge
          :count="taskNumber"
          :offset="[-32,-3]"
        >
          <img :src="avatar">
        </a-badge>
        <span class="nav-user-name">{{ displayName }}</span>
        <a-tag
          v-if="isMock"
          color="orange"
        >模拟</a-tag>
        <a-icon
          v-if="!isGuest"
          type="down"
          :class="['nav-user_icon', {'nav-user_icon-active': show}]"
        />
      </span>
    </a-popover>
  </div>
</template>

<script>
import { getUserId, getUserAvatarURL } from '@utils/userInfo';
import userInfoCard from './NavUserInfoCard.vue';
import { isGuest } from '@/utils/userInfo';
import { fetchStaffs } from '@/services/staff';

export default {
  name: 'NavUser',
  components: {
    userInfoCard,
  },
  props: {
    avatar: {
      type: String,
      default: getUserAvatarURL(),
    },
    username: {
      type: String,
      default: getUserId(),
    },
    mode: {
      type: String,
      default: 'light',
    },
    taskNumber: {
      type: Number,
      default: 0,
    },
    pageMode: {
      type: String,
      default: 'editor',
    },
  },
  data() {
    return {
      displayName: this.username,
      show: false,
      isGuest: isGuest(),
    };
  },
  computed: {
    isMock() {
      return !!window.GLOBAL_INFO.mockedBy;
    },
  },
  mounted() {
    this.fetchChnName();
    // eslint-disable-next-line no-underscore-dangle
    if (window.__MICRO_APP_ENVIRONMENT__) {
      this.show = true;
      this.$nextTick(() => this.show = false);
    }
  },
  methods: {
    handleUserButtonClick() {
      if (this.isGuest) {
        window.location.href = '/sso/login';
        return;
      }
    },

    close() {
      this.show = false;
    },
    async fetchChnName() {
      if (this.isGuest) {
        this.displayName = '未登录';
        return;
      }

      try {
        const resp = await fetchStaffs(this.username);
        const account = resp[0];
        if (account?.chnName) this.displayName += `(${account.chnName})`;
      } catch (error) {
        console.error('fetch chn name failed', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-user {
  margin-left: 32px;
  line-height: 32px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 100%;
  }
  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--wuji-text-color);
    .nav-user-name {
      font-size: 14px;
      line-height: 22px;
      margin-right: 6px;
    }
  }
  &_icon {
    font-size: 10px;
    color: var(--wuji-text-color);
  }
  &.dark {
    pointer-events: none;
    .nav-user_icon {
      display: none
    }
    .nav-user_button{
      color:#fff;
    }
  }
}
#xy-runtime-layout .light-header,
#xy-preview-layout .light-header{
  .nav-user {
    &_button,&_icon {
      color: var(--wuji-text-color);
    }
  }
}

#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  .nav-user {
    &_button,&_icon {
      color: var(--wuji-text-color-dark);
    }
    &.dark {
      pointer-events: auto;
      .nav-user_icon {
        display: inline-block;
      }
    }
  }
}
#xy-runtime-layout .light-header,
#xy-preview-layout .light-header,
#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  .nav-user_button {
    &:hover,
    &:hover .nav-user_icon,
    &.ant-popover-open,
    &.ant-popover-open .nav-user_icon {
      color: var(--wuji-primary-color);
    }
  }
}
</style>

<style lang="scss">
.nav-user-popover-light .ant-popover-inner-content,
.nav-user-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
}
.nav-user-popover-light.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow,
.nav-user-popover-dark.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: -4px;
}
</style>
