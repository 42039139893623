import logger from '@utils/logger';
import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
import { message } from 'ant-design-vue';
import { BASE_API_PATH } from '@/config/constant';


export default {
  name: 'datatable',
  state: {
    list: [],
    appList: [],
  },
  getters: {
  },
  actions: {
    /**
    * 获取项目数据表列表
    */
    async fetchDataTables({ commit }, { projectId }) {
      try {
        const list = await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable?projectid=${projectId}&join=page`);
        commit('setDataTable', {
          list,
        });
        return list;
      } catch (e) {
        logger.error(e);
        message.error(`获取数据表列表失败：${e.message}`);
      }
    },
    /**
     * 新增项目数据表
     */
    async addDataTable({ }, { projectId, data }) {
      const res = await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable?projectid=${projectId}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      });
      return res;
    },
    /**
     * 更新项目数据表
     */
    async updateDataTable({ }, { projectId, data }) {
      const res = await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable/${data.id}?projectid=${projectId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          ...data,
        }),
      });
      return res;
    },
    /**
     * 删除项目数据表
     */
    async deleteDataTable({ }, { projectId, id }) {
      const res = await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable/${id}?projectid=${projectId}`, {
        method: 'DELETE',
      });
      return res;
    },
    /**
     * 获取已经授权的app和schemas
     */
    async getProjectAppSchemas({ commit }, { projectId }) {
      const result = await wujiFetch(`${BASE_API_PATH}/xy/project/${projectId}/schemas`);
      commit('setDataTable', {
        appList: result,
      });
      return result;
    },
  },
  mutations: {
    setDataTable(state, payload) {
      Object.assign(state, payload);
    },
  },
};
