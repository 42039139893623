import { ref, onBeforeUnmount } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import wujiFetch from '@utils/wujiFetch';
import logger from '@utils/logger';
import { BASE_API_PATH } from '@/config/constant';
export var NewStatus;
(function (NewStatus) {
    NewStatus[NewStatus["APPROVING"] = 0] = "APPROVING";
    NewStatus[NewStatus["APPROVED"] = 1] = "APPROVED";
    NewStatus[NewStatus["REJECTED"] = 2] = "REJECTED";
})(NewStatus || (NewStatus = {}));
;
export var LoginType;
(function (LoginType) {
    LoginType[LoginType["IOA"] = 0] = "IOA";
    LoginType[LoginType["QQ"] = 1] = "QQ";
})(LoginType || (LoginType = {}));
const url = `${BASE_API_PATH}/xy/setting/auth`;
const map = new Map();
export const fetchAuthSetting = async (projectId, useCache = false) => {
    if (!window.GLOBAL_INFO?.deploySettingFlag?.hasPanguService)
        return null;
    let promise = null;
    if (map.has(projectId) && useCache) {
        promise = map.get(projectId);
    }
    else {
        promise = wujiFetch(`${url}?projectid=${projectId}`);
        map.set(projectId, promise);
    }
    const data = await promise;
    return data;
};
export const generatePanguProject = async (projectId, envId) => {
    const data = await wujiFetch(`${url}/${envId}?projectid=${projectId}`, { method: 'POST' });
    return data;
};
// 轮询盘古系统状态
export const updateFetchAuthSetting = async (projectId) => {
    const result = await wujiFetch(`${url}?projectid=${projectId}`, {
        method: 'PUT',
    });
    return result;
};
export const resetAuthSetting = async (projectId, envId) => {
    const data = await wujiFetch(`${url}/${envId}?projectid=${projectId}`, { method: 'DELETE' });
    return data;
};
export const initPanguProject = async (projectId, envId) => {
    const data = await wujiFetch(`${url}/${envId}/init?projectid=${projectId}`, { method: 'POST', timeout: 60000 });
    return data;
};
export const migratePanguProject = async (projectId, envId) => {
    const data = await wujiFetch(`${url}/${envId}/migration?projectid=${projectId}`, { method: 'POST', timeout: 60000 });
    return data;
};
export const patchPanguProject = async (projectId, envId, data) => {
    const result = await wujiFetch(`${url}/${envId}?projectid=${projectId}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
    });
    return result;
};
export const saveAuthSetting = async (projectId, data) => {
    const result = await wujiFetch(`${url}?projectid=${projectId}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    return result;
};
export const useAuthSetting = (projectId) => {
    const authSetting = ref(null);
    const saving = ref(false);
    const task = ref(0);
    const fetchProjectAuthSetting = async (needSetup = true) => {
        try {
            authSetting.value = await fetchAuthSetting(projectId);
            if (needSetup)
                setupUpdateTask(true);
        }
        catch (error) {
            logger.error('获取鉴权设置失败', error);
            message.error('获取鉴权设置失败');
        }
    };
    const updateFetchProjectAuthSetting = async () => {
        try {
            authSetting.value = await updateFetchAuthSetting(projectId);
            setupUpdateTask();
        }
        catch (error) {
            logger.error('查询盘古系统状态失败', error);
            message.error('查询盘古系统状态失败');
        }
    };
    const clearTask = () => clearTimeout(task.value);
    onBeforeUnmount(() => clearTask());
    const setupUpdateTask = (firstTime = false) => {
        const envMapping = authSetting.value?.envMapping;
        let needTaskToUpdate = false;
        if (envMapping) {
            for (const envInfo of Object.values(envMapping)) {
                if (envInfo.panguId && envInfo.newStatus === NewStatus.APPROVING) {
                    needTaskToUpdate = true;
                }
            }
        }
        if (needTaskToUpdate) {
            clearTask();
            task.value = window.setTimeout(() => {
                (async () => await updateFetchProjectAuthSetting())();
            }, firstTime ? 1000 : 15000);
        }
    };
    const saveProjectAuthSetting = async (data) => {
        saving.value = true;
        try {
            await saveAuthSetting(projectId, data);
            message.success('已保存');
            return true;
        }
        catch (error) {
            logger.error('保存鉴权设置失败', error);
            message.error('保存鉴权设置失败');
        }
        saving.value = false;
        return false;
    };
    const newPanguProject = async (envId) => {
        try {
            await generatePanguProject(projectId, envId);
            message.success('已生成');
            return true;
        }
        catch (error) {
            logger.error('生成盘古系统失败', error);
            message.error('生成盘古系统失败');
        }
    };
    const resetEnvAuthSetting = async (envId) => {
        try {
            await resetAuthSetting(projectId, envId);
            message.success('重置环境成功');
            return true;
        }
        catch (error) {
            logger.error('重置环境失败', error);
            message.error('重置环境失败');
        }
    };
    const manualSetPanguProject = async (envId, data) => {
        try {
            await patchPanguProject(projectId, envId, { envMapping: { [envId]: data } });
            message.success('自行关联成功');
            return true;
        }
        catch (error) {
            logger.error('自行关联失败', error);
            message.error('自行关联失败');
        }
        return false;
    };
    const markAsSuccessPanguProject = async (envId) => {
        try {
            await patchPanguProject(projectId, envId, { envMapping: { [envId]: { migrationStatus: 'success' } } });
            message.success('已设置迁移成功');
            return true;
        }
        catch (error) {
            logger.error('设置迁移状态失败', error);
            message.error('设置迁移状态失败');
        }
    };
    const updatePrefix = async (envId, prefix) => {
        try {
            await patchPanguProject(projectId, envId, { envMapping: { [envId]: { prefix } } });
            message.success('已更新前缀');
            return true;
        }
        catch (error) {
            logger.error('更新前缀失败', error);
            message.error('更新前缀失败');
        }
    };
    const enabledPanguProject = async (envId) => {
        try {
            await patchPanguProject(projectId, envId, { envMapping: { [envId]: { enabled: true } } });
            message.success('已启用');
            return true;
        }
        catch (error) {
            logger.error('启用盘古系统失败', error);
            message.error('启用盘古系统失败');
        }
    };
    const disabledPanguProject = async (envId) => {
        try {
            await patchPanguProject(projectId, envId, { envMapping: { [envId]: { enabled: false } } });
            message.success('已禁用');
            return true;
        }
        catch (error) {
            logger.error('禁用盘古系统失败', error);
            message.error('禁用盘古系统失败');
        }
    };
    const initialize = async (envId) => {
        try {
            const resp = await initPanguProject(projectId, envId);
            authSetting.value = resp.authSetting;
            if (resp.result) {
                message.success('已初始化');
                return true;
            }
            message.warn('初始化盘古系统失败');
        }
        catch (error) {
            logger.error('初始化盘古系统失败', error);
            message.error('初始化盘古系统失败');
        }
    };
    const migrate = async (envId) => {
        try {
            const resp = await migratePanguProject(projectId, envId);
            if (!resp.hasError) {
                await fetchProjectAuthSetting(false);
                message.success('迁移成功');
                return null;
            }
            message.warn('迁移数据失败');
            return resp;
        }
        catch (error) {
            logger.error('迁移数据失败', error);
            message.error('迁移数据失败');
            return null;
        }
    };
    return {
        authSetting,
        fetchProjectAuthSetting,
        saveProjectAuthSetting,
        newPanguProject,
        saving,
        initialize,
        migrate,
        manualSetPanguProject,
        markAsSuccessPanguProject,
        updatePrefix,
        enabledPanguProject,
        disabledPanguProject,
        resetEnvAuthSetting,
    };
};
