import { computed, onBeforeUnmount, onActivated, onDeactivated } from '@vue/composition-api';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import { renderWatermark, removeWatermark, getLooseConfig } from '@components/watermark/utils';
export function useWatermark({ project, page }) {
    let showCallee;
    let hideCallee;
    let isRendered = false;
    const isShowWatermark = computed(() => {
        if (page.value?.pageAttr?.showWatermark === 'on') {
            return true;
        }
        if (page.value.pageAttr?.showWatermark === 'off') {
            return false;
        }
        return !!project.value?.advanceConfig?.showWatermark;
    });
    const rendererPageletWatermark = ({ id, container, renderer = {} }) => {
        if (isShowWatermark.value) {
            // 若水印开启，隐水印必须开启
            const { appId = '', serviceId = '' } = window.GLOBAL_INFO.watermark || {};
            const configuredWatermark = appId && serviceId;
            if (configuredWatermark && !isRendered) {
                window?._hspeed?.init(appId, serviceId, { enable: false })?.then(() => {
                    isRendered = true;
                });
            }
            // 这里修改的原因是 config 中可能会有 showVisibleWatermark、showInvisibleWatermark，不能直接用默认配置覆盖
            const watermarkConfig = project.value?.advanceConfig?.watermarkConfig || {};
            const config = { ...getLooseConfig(), ...watermarkConfig };
            // 未明确指定关闭明水印，且未配置水印 sdk 的情况都默认渲染明水印
            if (config.showVisibleWatermark === false && configuredWatermark) {
                return;
            }
            if (container) {
                const watermarkConfig = {
                    id,
                    ...config,
                    // 插值计算水印内容
                    content: stringInterop(config.content, {
                        ...renderer,
                        // 扩展的插值计算字段
                        user: window?.w.user,
                        project: project.value,
                        page: {
                            ...page.value ?? {},
                            title: stringInterop(page.value?.title ?? '', renderer),
                        },
                    }),
                    container,
                };
                // 容器尺寸变化时重新渲染水印
                const resizeObserver = new ResizeObserver(() => {
                    renderWatermark(watermarkConfig);
                });
                // 防止删除水印
                const mutationObserver = new MutationObserver(() => {
                    const el = document.getElementById(id);
                    if (!el) {
                        // 判断水印容器是否还在 DOM 上(fix: #941)
                        const isContainerExist = document.body.contains(container);
                        if (isContainerExist)
                            renderWatermark(watermarkConfig);
                    }
                });
                showCallee = () => {
                    resizeObserver?.observe(container);
                    mutationObserver?.observe(container, {
                        childList: true,
                    });
                };
                hideCallee = () => {
                    resizeObserver?.disconnect();
                    mutationObserver?.disconnect();
                };
                showCallee();
                renderWatermark(watermarkConfig);
            }
        }
        else {
            removeWatermark();
        }
    };
    // 监听 Observer
    onActivated(() => showCallee?.());
    // 移除 Observer
    onDeactivated(() => hideCallee?.());
    onBeforeUnmount(() => hideCallee?.());
    return {
        rendererPageletWatermark,
    };
}
