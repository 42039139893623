import entity from './entity';
import workflow from './workflow';
import permission from './permission';
import staff from './staff';
import organization from './organization';
import robot from './robot';
import flow from './flow';

export const workflowApi = workflow;
export const workflowEntityApi = entity;
export const permissionApi = permission;
export const staffApi = staff;
export const organizationApi = organization;
export const robotApi = robot;
export const flowApi = flow;

// 对用户开放的接口，如在页面中使用 `ctx.sdk.entityApi.fetch`
export const getExportableToCtxSdk = () => ({
  workflowApi,
  workflowEntityApi,
  permissionApi,
  staffApi,
});
