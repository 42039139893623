import { Modal } from 'ant-design-vue';
/**
 * 弹出确认弹窗
 * @param modalOptios
 * @returns
 */
export function isConfirm(modalOptios) {
    return new Promise((r) => {
        Modal.confirm({
            ...modalOptios,
            onOk() {
                r(true);
            },
            onCancel() {
                r(false);
            },
        });
    });
}
