import { computed, watch, onBeforeMount, ref, defineComponent } from '@vue/composition-api';
import { fetchRtxInfo, ONE_DAY_MS, usePermissionSettings } from './utils';
import { permissionApi } from '@/services';
import { GUEST_ROLE_ID } from '@pages/modules/permission/constants';
import { useExpire } from './index';
import { cloneDeep, groupBy, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { message } from 'ant-design-vue';
import moment from 'moment';
import { userRoleFormProps } from '@pages/modules/permission/UserRoleForm';
function useUserMaxExpire(userId, type) {
    if (type === 'org') {
        return { userMaxExpire: ref(0) };
    }
    const { permissionSettings } = usePermissionSettings();
    const userInfo = ref({});
    onBeforeMount(async () => {
        const res = await fetchRtxInfo([userId]);
        userInfo.value = res[0];
    });
    // 捞出当前user对应的expireRule
    const userMaxExpire = computed(() => {
        const { expireRules, maxDays } = permissionSettings.value;
        const { typeName } = userInfo.value;
        const userExpireRule = expireRules
            ?.find((rule) => typeName && rule.typeName.includes(typeName))
            || { maxDays };
        return (userExpireRule.maxDays
            ? Date.now() + userExpireRule.maxDays * ONE_DAY_MS : 0);
    });
    return { userMaxExpire };
}
export const UserRoleExpireForm = defineComponent({
    name: 'UserRoleExpireForm',
    props: userRoleFormProps,
    setup(props, { emit }) {
        const selectedRowKeys = ref([]);
        const userData = ref();
        const initUserData = ref();
        {
            // 初始化表单数据
            const record = props.userRoleList.find(i => i.userId === props.userId) || { userId: '', roles: [] };
            userData.value = cloneDeep(record);
            initUserData.value = cloneDeep(userData.value);
            // 监测到变化时抛出事件点亮保存按钮
            watch(() => userData.value, () => {
                if (!isEqual(userData.value, initUserData.value)) {
                    emit('change', { roles: [[]] });
                }
            }, { deep: true });
        }
        const { userMaxExpire } = useUserMaxExpire(props.userId, props.userType);
        const resetRowExpire = (row) => {
            // eslint-disable-next-line no-param-reassign
            row.expireAt = userMaxExpire.value;
        };
        const changeRowExpire = (val, row) => {
            if (!val) {
                // 是重置按钮 设置日期到最大值
                resetRowExpire(row);
                return;
            }
            // 限制最大过期时间
            // eslint-disable-next-line no-param-reassign
            row.expireAt = userMaxExpire.value ? Math.min(userMaxExpire.value, +val) : +val;
        };
        const addRoleOptions = computed(() => {
            const bizList = [{ value: '', label: '平台(应用类型角色)' },
                ...(props.bizList?.filter(o => (props.isRuntimeMode ? o.value === props.defaultBizId : true)) || [])];
            const getRolesByBiz = (bizId) => props.roles
                ?.filter(r => r.name !== GUEST_ROLE_ID)
                .filter(r => (bizId ? r.type === 'biz' : !r.type)) || [];
            const options = bizList.map((bizInfo) => {
                const currentBizRoles = userData.value?.roles.filter(r => r.bizId === (bizInfo?.value || '')) || [];
                return {
                    value: bizInfo.value,
                    label: bizInfo.label,
                    // 过滤掉已经添加的角色
                    children: getRolesByBiz(bizInfo.value).filter(role => !currentBizRoles.find(r => r.role === role.name))
                        .map(role => ({
                        value: role.name,
                        label: role.cname,
                    })),
                };
            }).filter(b => b.children.length > 0);
            return options;
        });
        const addRole = (value) => {
            const [bizId, roleName] = value;
            const role = props.roles.find(role => role.name === roleName
                && (!!bizId) === (role.type === 'biz'));
            const newRole = {
                _id: `${Math.random()}`,
                bizId,
                cname: role.cname,
                expireAt: userMaxExpire.value,
                label: role.cname,
                name: role.name,
                role: role.name,
            };
            userData.value?.roles.push(newRole);
        };
        const deleteRoles = (ids) => {
            if (!userData.value)
                return;
            userData.value.roles = userData.value.roles.filter(role => !ids.includes(role._id));
        };
        const { expireOptions, renderCreateExpire } = useExpire(props.userType === 'staff' ? ref([props.userId]) : ref([]));
        const setExpires = () => {
            if (!userData.value)
                return;
            userData.value.roles.filter(role => selectedRowKeys.value.includes(role._id)).forEach((role) => {
                role.expireAt = expireOptions.expireAt;
            });
        };
        const save = async () => {
            const deleteRole = async (id) => permissionApi.deleteRoleUser(props.projectId, props.env, id);
            // 找被删掉了的
            const deletedRoles = initUserData.value?.roles
                .filter((item) => !userData.value?.roles.find(r => r._id === item._id)) || [];
            try {
                await Promise.all(deletedRoles.map(r => r._id).map(id => deleteRole(id)));
            }
            catch (e) {
                message.error('删除角色失败');
                return false;
            }
            // 找新增 & 修改的行
            const changedRoles = userData.value?.roles.filter((item) => {
                const oldRole = initUserData.value?.roles.find(r => r._id === item._id);
                // 是新增的行
                if (!oldRole)
                    return true;
                // expireTime有修改
                if (Math.abs(+dayjs(item.expireAt) - (+dayjs(oldRole.expireAt))) > ONE_DAY_MS / 2)
                    return true;
                return false;
            }) || [];
            // 聚合请求
            const rolesByBiz = groupBy(changedRoles, 'bizId');
            const addUserRoles = async (bizId, roles) => {
                let user = props.userId;
                if (props.userType === 'org' && props.userId) {
                    user = { label: props.userName, value: props.userId };
                }
                try {
                    await permissionApi.createRoleUser(props.projectId, props.env, {
                        bizId,
                        users: [user],
                        roles: roles.map(r => r.role),
                        type: props.userType,
                        expireRoles: roles.map(r => +dayjs(r.expireAt)),
                    });
                }
                catch (e) {
                    console.error('添加角色失败', roles.map(r => r.role));
                    throw e;
                }
            };
            try {
                await Promise.all(Object.entries(rolesByBiz).map(([bizId, roles]) => addUserRoles(bizId, roles)));
            }
            catch (e) {
                message.error('添加角色失败');
                return false;
            }
            return true;
        };
        return {
            selectedRowKeys,
            deleteRoles,
            setExpires,
            userData,
            resetRowExpire,
            save,
            addRoleOptions,
            addRole,
            renderCreateExpire,
            changeRowExpire,
        };
    },
    render() {
        const selected = this.selectedRowKeys.length > 0;
        const selection = { selectRowKeys: this.selectedRowKeys, onChange: (v) => this.selectedRowKeys = v };
        const columns = [
            {
                dataIndex: 'label',
                key: 'label',
                title: '角色',
                customRender: (data) => (<span>{data}</span>),
            },
            {
                dataIndex: 'expireAt',
                key: 'expireAt',
                title: '到期时间',
                customRender: (data, row) => (<a-date-picker placeholder="不限期" value={data ? moment(data) : data} onChange={(v) => this.changeRowExpire(v, row)}/>),
            },
            {
                dataIndex: '_id',
                title: '操作',
                customRender: (id) => (<a onClick={() => this.deleteRoles([id])}>删除</a>),
            },
        ];
        const tableFooter = () => (<a-cascader options={this.addRoleOptions} onChange={this.addRole} notFoundContent="已添加全部角色">
          <a-button type="primary">添加角色</a-button>
        </a-cascader>);
        return (<div>
        <a-form-model labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} labelAlign="left">
          <a-form-model-item label={this.userType === 'staff' ? '用户' : '组织'} prop="userId">
            <span>{this.userName}</span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" style="margin-right:16px" disabled={!selected} onClick={() => this.deleteRoles(this.selectedRowKeys)}>删除</a-button>
            <a-popconfirm icon={<span />} placement="right" trigger={selected ? 'click' : ''} onConfirm={this.setExpires}>
              <div slot="title">
                <a-form-model>
                  {this.renderCreateExpire()}
                </a-form-model>
              </div>
              <a-button type="primary" disabled={!selected}>批量设置到期时间</a-button>
            </a-popconfirm>
          </a-form-model-item>
          <a-form-model-item label="角色">
            <a-table pagination={false} rowSelection={selection} columns={columns} dataSource={this.userData?.roles} rowKey="_id" footer={tableFooter}/>
          </a-form-model-item>
        </a-form-model>
      </div>);
    },
});
