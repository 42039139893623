export const DEFAULT_LESSCODE = `export default {
  data() {
    return {
      // param: '',      // 自定义变量
    };
  },
  mounted() {

  },
  methods: {

  },
};
`;
export const DEFAULT_PROJECT_LESSCODE = `export default {
  data() {
    return {
      // globalParam: '',
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
};
`;
export const DEFAULT_MODULE = `module.exports = {

};
`;
export default {
    DEFAULT_LESSCODE,
    DEFAULT_PROJECT_LESSCODE,
    DEFAULT_MODULE,
};
