<template>
  <div class="xy-wxwork-selector">
    <div class="xy-wxwork-selector-wrap">
      <a-select
        v-model="editValue.type"
        class="xy-wxwork-selector-type"
        :options="typeOptions"
        @change="handleTypeChange"
      />
      <!-- 企业微信名 -->
      <public-w-dept-rtx-select
        v-if="editValue.type === 'username'"
        class="xy-wxwork-selector-value"
        type="staffs"
        :value="editValue.username"
        placeholder="企业微信名"
        @change="(value) => (editValue.username = value)"
      />
      <!-- 企业微信名（动态） -->
      <expr-input
        v-else-if="editValue.type === 'usernameExpr'"
        v-model="editValue.usernameExpr"
        type="interopable-string"
        class="xy-wxwork-selector-value"
        placeholder="企业微信名"
      />
      <!-- 企业微信客服号 -->
      <expr-input
        v-else-if="editValue.type === 'uin'"
        v-model="editValue.uin"
        type="interopable-string"
        class="xy-wxwork-selector-value"
        placeholder="uin"
      />
    </div>
    <div
      v-if="editValue.type === 'uin' && robotDocLink"
      class="xy-wxwork-selector-tips"
    >
      <a
        :href="robotDocLink"
        target="_blank"
      >机器人文档和内部客服文档</a>
    </div>
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { getDocLink } from '@utils/links';
import { ExprInput } from '@tencent/ui-core/lib';

const component = {
  name: 'XyWxworkSelector',
  mixins: [vueComponentMixin],
  components: {
    ExprInput,
  },
  data() {
    return {
      typeOptions: [
        { value: 'username', label: '企业微信个人(固定)' },
        { value: 'usernameExpr', label: '企业微信个人(动态)' },
        { value: 'uin', label: '企业微信客服' },
      ],
      robotDocLink: getDocLink().ROBOT_SERVICE_DOC,
    };
  },
  methods: {
    handleTypeChange() {
      this.editValue.username = '';
      this.editValue.usernameExpr = '';
      this.editValue.uin = '';
    },
  },
};

export default component;

const settingsSchema = null;
export const meta = {
  settingsSchema,
  component,
  hideLabel: false,
};
</script>

<style lang="scss" scope>
.xy-wxwork-selector {
  width: 100%;
  .xy-wxwork-selector-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    .xy-wxwork-selector-type {
      flex-basis: 158px;
      margin-right: 8px;
    }
    .xy-wxwork-selector-value {
      flex: 1;
    }
  }
  .xy-wxwork-selector-tips {
    margin-top: 8px;
  }
}
</style>
