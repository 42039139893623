<template>
  <w-sys-pagelet
    v-if="project && pageList && page"
    ref="w-sys-header-pagelet"
    :root="true"
    mode="runtime"
    type="header"
    :project-config="project"
    :config="page"
    :page-list="pageList"
    :main="false"
  />
</template>

<script>
import { computed, toRefs } from '@vue/composition-api';
import { useRuntimePagelet } from '@composables/useRuntimePagelet';

export default {
  name: 'CustomHeader',
  props: {
    project: {
      type: Object,
      default: null,
    },
    pageId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { project, pageId } = toRefs(props);

    const projectId = computed(() => project.value.id);

    const { page, pageList } = useRuntimePagelet({
      projectId: projectId.value,
      pageId: pageId.value,
    });

    return {
      page,
      pageList,
    };
  },
};
</script>
