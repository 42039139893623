import { pageScrollTo, pageScrollToTop, pageScrollToBottom, getContentClientHeight } from '@utils/browser';
import {
  XyPageSelectorMeta,
  XyPageletPropsEditorMeta,
} from '../paramsFormComponents';
import { getRouterPublicParams } from '@utils/routerPublicParams';
import { normalizeRouteConfig, operatePageUrl } from '@utils/path';
import { compile } from 'path-to-regexp';
import {
  BASE_APP_PATH_REGEX,
  BASE_BRANCH_APP_PATH_REGEX,
} from '@config/constant';
import qs from 'query-string';
import { message } from 'ant-design-vue';
import { CATEGORY } from './const';
import { isRuntime as isRuntimeFn } from '@/utils/globalInfo';

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }
  return true;
};

// 滚动类型处理函数
const SCROLL_TYPE_STRATEGY = {
  top: (params) => {
    const { behavior } = params;
    pageScrollToTop({ behavior });
  },
  bottom: (params) => {
    const { behavior } = params;
    pageScrollToBottom({ behavior });
  },
  y: (params) => {
    const { yCoord, behavior } = params;
    pageScrollTo({ top: yCoord, behavior });
  },
  component: (params, ctx) => {
    const { behavior, componentId, compOffsetType = 'start', compOffset = 0 } = params;
    let { renderer } = ctx;
    let compPath = '';
    // 跨页面片的情况, 需要切换 renderer
    ({ renderer, compPath } = renderer?.ucQuerySelector(componentId) ?? {});
    const target = renderer?.getRefByPath(compPath)?.$el;
    // 开发环境提示
    if (!target && componentId && renderer?.w?.env === 'dev') {
      return message.warning(`滚动失败, 找不到 ${componentId} 的组件`);
    }

    if (!target) return;

    if (!compOffset) {
      target.scrollIntoView({ behavior, block: compOffsetType });
    } else {
      const layoutInstance = window.xy_runtime_default_layout_instance;
      const { offsetTop } = target;

      // 顶部导航偏移量
      const isNvaFixTop = !!layoutInstance?.isNavFixTop;
      const navHeight = layoutInstance?.isShowNav ? parseInt(layoutInstance?.headerHeight ?? 0, 10) : 0;

      // 计算实际滚动高度
      let computedTop = offsetTop;
      if (compOffsetType === 'start') {
        computedTop = offsetTop - compOffset + (isNvaFixTop ? 0 : navHeight);
      } else {
        const contentHeight = getContentClientHeight();
        computedTop = offsetTop - contentHeight + target.offsetHeight + navHeight + compOffset;
      }

      pageScrollTo({ top: computedTop, behavior });
    }
  },
};

// 滚动页面
export const pageScrollAtom = {
  id: 'xy:pageScroll',
  name: '滚动页面',
  category: CATEGORY.NAVIGATOR,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        type: 'string',
        title: '滚动位置',
        enum: [
          { value: 'top', label: '顶部' },
          { value: 'bottom', label: '底部' },
          { value: 'y', label: '指定位置' },
          { value: 'component', label: '指定组件' },
        ],
        default: 'top',
      },
      {
        id: 'yCoord',
        type: 'number',
        title: '纵轴位置 (单位: px)',
        condition: 'params.type === "y"',
        default: 0,
      },
      {
        id: 'componentId',
        type: 'string',
        title: '组件',
        condition: 'params.type === "component"',
        ui: {
          type: 'compSelectorInput',
        },
      },
      {
        id: 'compOffsetType',
        type: 'string',
        title: '组件偏移类型',
        condition: 'params.type === "component"',
        enum: [
          { value: 'start', label: '顶部' },
          { value: 'end', label: '底部' },
        ],
        default: 'start',
      },
      {
        id: 'compOffset',
        type: 'number',
        title: '组件偏移值 (单位: px)',
        condition: 'params.type === "component"',
        default: 0,
      },
      {
        id: 'behavior',
        type: 'string',
        title: '滚动行为',
        enum: [
          { value: 'instant', label: '瞬间' },
          { value: 'smooth', label: '平滑' },
        ],
        default: 'instant',
      },
    ],
  },
  execute: async (ctx, params) => SCROLL_TYPE_STRATEGY[params?.type]?.call(null, params, ctx),
};
// 跳转页面
export const openPageAtom = {
  id: 'xy:openPage',
  name: '跳转页面',
  category: CATEGORY.NAVIGATOR,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'pageId',
        type: 'string',
        title: '页面ID',
        ui: { type: 'XyPageSelector' },
      },
      {
        id: 'data',
        type: 'array',
        title: '页面 query 参数',
        'uc:allowInterop': true,
        ui: {
          type: 'XyPageletPropsEditor',
          props: {
            keyIsInteropable: true,
          },
        },
        items: {
          type: 'object',
          fields: [
            { id: 'key', type: 'string', ui: { type: 'exprInput', props: { type: 'interopable-string' } } },
            { id: 'value', type: 'string', ui: { type: 'exprInput', props: { type: 'interopable-string' } } },
          ],
        },
      },
      {
        id: 'paramsData',
        type: 'array',
        title: '页面 params 参数(替换页面路径中的动态参数)',
        'uc:allowInterop': true,
        ui: {
          type: 'XyPageletPropsEditor',
          props: {
            keyIsInteropable: true,
          },
        },
        items: {
          type: 'object',
          fields: [
            { id: 'key', type: 'string', ui: { type: 'exprInput', props: { type: 'interopable-string' } } },
            { id: 'value', type: 'string', ui: { type: 'exprInput', props: { type: 'interopable-string' } } },
          ],
        },
      },
      { id: 'newWindow', type: 'boolean', title: '新窗口打开', default: true },
      {
        id: 'method',
        type: 'string',
        title: '跳转方式',
        default: 'push',
        condition: 'params.newWindow !== true',
        enum: [
          { label: 'push (history 添加新记录)', value: 'push' },
          { label: 'replace (history 不添加新记录)', value: 'replace' },
        ],
      },
    ],
  },
  paramsFormComponents: [
    XyPageSelectorMeta,
    XyPageletPropsEditorMeta,
  ],
  async execute(ctx, params) {
    const { $route, $router } = ctx.renderer;  // xy 上下文
    const { pageId, data, paramsData, newWindow, method = 'push' } = params;

    const isRuntime = isRuntimeFn();

    let projectId = ''; // 页面所属的应用ID
    let pagePath = '';  // 页面真实路径
    if (Array.isArray(window.w?.pageList)) {
      // page.id === pageId 是为了兼容老的版本用了id作为页面选择器的值
      const [page] = window.w.pageList.filter(page => page.id === pageId || page.pageId === pageId);
      if (page) {
        ({ projectId, path: pagePath } = page);
      }
    }

    if (!pagePath) {
      message.error('无效页面路径');
    }

    const query = { ...getRouterPublicParams($route.query) };
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((kv) => {
        const { key, value } = kv;
        query[key] = value;
      });
    }
    const queryString = qs.stringify(query);

    const pathParams = {};
    if (Array.isArray(paramsData) && paramsData.length > 0) {
      paramsData.forEach((kv) => {
        const { key, value } = kv;
        pathParams[key] = value;
      });
    }
    const paramsString = qs.stringify(pathParams);

    if (isRuntime) {
      // 运行时
      operatePageUrl(
        { path: pagePath, query: queryString, params: paramsString },
        { open: newWindow, slash: true, method, $router, $route },
      );
    } else {
      // 编辑时
      const {
        path: normalizePath,
      } = normalizeRouteConfig({ path: pagePath, params: paramsString, query: queryString, $route });
      const { branch } = $route?.query || {};
      let url;
      if (branch) {
        url = compile(BASE_BRANCH_APP_PATH_REGEX)({ projectId, branch });
      } else {
        url = compile(BASE_APP_PATH_REGEX)({ projectId, env: 'dev' });
      }
      url += normalizePath;
      if (queryString) url += `?${queryString}`;

      const pageConfig = ctx.uc?.designer?.$parent;
      if (pageConfig?.setOpenLinkPopover) {
        pageConfig.setOpenLinkPopover({
          text: `是否打开：${url}`,
          target: url,
        });
      } else {
        window.open(url);
      }
    }
  },
};
// 跳转链接
export const openLinkAtom = {
  id: 'xy:openLink',
  name: '跳转链接',
  category: CATEGORY.NAVIGATOR,
  paramsSchema: {
    type: 'object',
    fields: [
      { id: 'url', type: 'string' },
      { id: 'newWindow', type: 'boolean', title: '新窗口打开' },
    ],
  },
  execute(ctx, params) {
    const { url, newWindow } = params;
    if (!url) {
      message.error('无效跳转链接');
      return;
    }
    if ((url.startsWith('http') || url.startsWith('ftp')) && !isValidUrl(url)) {
      message.error('无效跳转链接');
      return;
    }
    // 相对协议
    if (url.startsWith('//') && !isValidUrl(location.protocol + url)) {
      message.error('无效跳转链接');
      return;
    }
    // 相对路径
    if ((url.startsWith('/') && !url.startsWith('//')) && !isValidUrl(location.origin + url)) {
      message.error('无效跳转链接');
      return;
    }

    const pageConfig = ctx.uc?.designer?.$parent;
    if (pageConfig?.setOpenLinkPopover) {
      pageConfig.setOpenLinkPopover({
        text: `是否打开：${url}`,
        target: url,
      });
    } else {
      if (newWindow) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  },
};
// 返回上一页
export const hitstoryBackAtom = {
  id: 'xy:hitstoryBack',
  name: '返回上一页',
  category: CATEGORY.NAVIGATOR,
  async execute(ctx) {
    const target = () => {
      ctx.uc.$router.go(-1);
    };

    const pageConfig = ctx.uc?.designer?.$parent;
    if (pageConfig?.setOpenLinkPopover) {
      pageConfig.setOpenLinkPopover({
        text: '是否返回上一页',
        target,
      });
    } else {
      target();
    }
  },
};
// 刷新页面
export const refreshPageAtom = {
  id: 'xy:refreshPage',
  name: '刷新页面',
  category: CATEGORY.NAVIGATOR,
  execute(ctx) {
    const target = () => {
      ctx.renderer.$router.go(0);
    };

    const pageConfig = ctx.uc?.designer?.$parent;
    if (pageConfig?.setOpenLinkPopover) {
      pageConfig.setOpenLinkPopover({
        text: '是否刷新页面',
        target,
      });
    } else {
      target();
    }
  },
};
// 刷新页面片
export const reloadUcRendererAtom = {
  id: 'xy:reloadUcRenderer',
  name: '刷新页面片',
  category: CATEGORY.NAVIGATOR,
  execute(ctx) {
    const { wContext } = ctx.renderer;
    wContext.reloadUcRenderer();
  },
};

export default [
  openPageAtom,
  openLinkAtom,
  hitstoryBackAtom,
  refreshPageAtom,
  reloadUcRendererAtom,
  pageScrollAtom,
];
