/**
 * 透传相关路由参数
 */
// eslint-disable-next-line import/prefer-default-export
export const getRouterPublicParams = (query) => {
  const { debug, debuglayout, debugport = '', debugfunchost = '' } = query || {};
  if (debug) {
    return {
      debug,
      debugport,
      debugfunchost,
    };
  }
  if (debuglayout) {
    return {
      debuglayout,
    };
  }
  return {};
};
