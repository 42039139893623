export const HOOK_SNIPPET = `/**
 * 全局路由守卫
 * @param {Object} ctx - 页面上下文
 * @param {Object} ext - 扩展数据
 * @param {Route} ext.from - 切换前路由
 * @param {Route} ext.to - 目标路由
 * @param {Object} ext.w - 全局上下文
 * @returns {string} 跳转路由
 *
 */
(ctx, { from, to, w }) => {
  // const { user: { rtx }, env, sdk: { cacheRequestCollectionCgi } } = ctx;
  // 返回跳转路由，如 /index?appid=8
  // 将会跳转至路由 /xy/app/\${env}/\${projectId}/index?appid=8
  // 若返回值为空，执行默认跳转路由
  return '';
}`;

/**
 * 执行切换钩子，环境切换、业务切换
 * @param {string} code - 代码
 * @param {Object} ctx - 页面上下文
 * @param {string} from - 切换前 id
 * @param {string} to - 切换目标 id
 */
export const executeSwitchHook = async (code, { ctx, from, to, switchingKey }) => {
  const codeWithPreset = `
    const window = {};
    const eval = () => { throw new Error('not supported'); };
    const alert = () => { throw new Error('not supported'); };

    const fn = ${code};

    return Promise.resolve().then(() => fn(ctx, ext));
  `;

  try {
    // eslint-disable-next-line no-new-func
    const fn = new Function('ctx', 'ext', codeWithPreset);
    const ret = await fn(ctx, { from, to, switchingKey, pages: window.xy_runtime_pages, w: window.w });
    return ret;
  } catch (error) {
    console.error('execute switch hook', error);
    throw error;
  }
};
