import Vue from 'vue';
import { message } from 'ant-design-vue';
import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api';
import { BASE_PATH } from '@/config/constant';
import { getLogoutURL } from '@utils/userInfo';
import wujiFetch from '@/utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import styles from './index.module.scss';
let reLoginConfirming = false;
export const reLogin = () => {
    if (reLoginConfirming)
        return;
    reLoginConfirming = true;
    const instance = new Vue({
        methods: {
            onClose() {
                reLoginConfirming = false;
                this.$destroy();
            },
        },
        render() {
            return (<ReLoginModal onClose={this.onClose}/>);
        },
    });
    const el = document.createElement('div');
    document.body.appendChild(el);
    instance.$mount(el);
};
const MAX_COUNT = 150;
const ReLoginModal = defineComponent({
    name: 'ReLoginModal',
    setup(props, { emit }) {
        const handleClose = () => emit('close');
        // 请求接口判断是否有登录态
        const detect = async () => {
            try {
                const result = await wujiFetch(`${BASE_API_PATH}/xy/healthcheck`);
                return !!result;
            }
            catch (err) {
                return false;
            }
        };
        let count = 0;
        const setTimeoutDetect = () => {
            if (count > MAX_COUNT)
                return;
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(async () => {
                const result = await detect();
                if (result) {
                    handleClose();
                }
                else {
                    count += 1;
                    setTimeoutDetect();
                }
            }, 2 * 1000);
        };
        const handleDetect = async () => {
            const result = await detect();
            if (result) {
                handleClose();
            }
            else {
                message.warning('登录失败, 请点击重新登录');
            }
        };
        const handleReLogin = () => {
            // 跳转到新窗口，重新拉起登录...登录后新页面立刻关闭
            const loginPageUrl = `${window.location.origin}${BASE_PATH}login`;
            opener = window.open(getLogoutURL(loginPageUrl));
        };
        const handleLoginSuccess = (e) => {
            if (e?.data?.event === 'login')
                handleClose();
        };
        onMounted(() => {
            setTimeoutDetect();
            window.addEventListener('message', handleLoginSuccess);
        });
        onUnmounted(() => {
            window.removeEventListener('message', handleLoginSuccess);
        });
        return {
            handleDetect,
            handleReLogin,
        };
    },
    render() {
        return (<a-modal class={styles.reLoginModal} visible={true} centered={true} footer={null} closable={false}>
        <div class={styles.contentWrap}>
          <a-icon class={styles.contentIcon} type="exclamation-circle"/>
          <div>
            <div class={styles.contentTitle}>登录态已过期</div>
            <div class={styles.content}>检测到登录态已过期，请重新登录</div>
          </div>
        </div>
        <div class={styles.footer}>
          <a-space>
            <a-button onClick={this.handleDetect}>已登录</a-button>
            <a-button type="primary" onClick={this.handleReLogin}>重新登录</a-button>
          </a-space>
        </div>
      </a-modal>);
    },
});
