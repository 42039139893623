import { ref } from '@vue/composition-api';
import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@/config/constant';
import { message } from 'ant-design-vue';

export default function usePageTag(projectId) {
  const tagList = ref([]);
  const basicTagList = ref([]);
  const getTagList = async () => {
    try {
      tagList.value = await wujiFetch(`${BASE_API_PATH}/xy/pagetag?projectid=${projectId.value}`);
    } catch (err) {
      message.error(`获取页面标签列表失败:${err.message}`);
    }
  };

  const getBasicTagList = async () => {
    try {
      basicTagList.value = await wujiFetch(`${BASE_API_PATH}/xy/runtime/pagetag?projectid=${projectId.value}`);
    } catch (err) {
      message.error(`获取页面标签列表失败:${err.message}`);
    }
  };

  const createTag = async ({ name }) => {
    try {
      await wujiFetch(`${BASE_API_PATH}/xy/pagetag?projectid=${projectId.value}`, {
        method: 'POST',
        body: JSON.stringify({
          name,
        }),
      });
      getTagList();
    } catch (err) {
      message.error(`创建标签失败：${err.message}`);
    }
  };

  const deleteTag = async (id) => {
    try {
      await wujiFetch(`${BASE_API_PATH}/xy/pagetag/delete/${id}?projectid=${projectId.value}`, {
        method: 'POST',
      });
      getTagList();
    } catch (err) {
      message.error(`删除标签失败:${err.message}`);
    }
  };

  const updateTag = async ({ id, name }) => {
    try {
      await wujiFetch(`${BASE_API_PATH}/xy/pagetag/patch/${id}?projectid=${projectId.value}`, {
        method: 'POST',
        body: JSON.stringify({
          name,
        }),
      });
      getTagList();
    } catch (err) {
      message.error(`更新标签失败:${err.message}`);
    }
  };
  return {
    tagList,
    getTagList,
    basicTagList,
    getBasicTagList,
    createTag,
    deleteTag,
    updateTag,
  };
}
