<template>
  <a-select
    v-model="editValue"
    show-search
    filter-option
    style="display: block; flex: 1;"
    @focus="reloadSourceList"
  >
    <a-select-option
      v-for="form in sourceList"
      :key="form.id"
      :value="form.id"
    >
      {{ form.id }}
    </a-select-option>
  </a-select>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { INJECT_UICORE_DESIGNER } from '@tencent/ui-core/lib/utils/consts';

export default {
  name: 'XySourceSelector',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: INJECT_UICORE_DESIGNER },
  },
  data() {
    return {
      sourceList: [],
    };
  },
  created() {
    this.reloadSourceList();
  },
  methods: {
    reloadSourceList() {
      const sources = this.designer.renderer.sources || {};
      this.sourceList = Object.keys(sources).map(id => ({ id, source: sources[id] }));
    },
  },
};
</script>

<style>
</style>
