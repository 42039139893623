<template>
  <div class="nav-drop">
    <a-popover
      v-model="show"
      :overlay-class-name="`nav-drop-popover-${mode}`"
      :trigger="menu.args.trigger"
    >
      <template slot="content">
        <p
          v-for="(item, index) in menu.args.menus"
          :key="index"
          class="nav-drop_options"
          @click="click"
        >
          <NavLinkItem
            :link="item"
            :disabled="disabled"
          />
        </p>
      </template>
      <span class="nav-drop_button">
        <span>{{ menu.name }}</span>
        <a-icon
          type="down"
          :class="['nav-drop_icon', {'nav-drop_icon-active': show}]"
        />
      </span>
    </a-popover>
  </div>
</template>

<script>
import NavLinkItem from './NavLinkItem';

export default {
  components: {
    NavLinkItem,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    click() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drop {
  &_button {
    border-radius: 14px;
    padding: 5px 0;
    cursor: pointer;
  }
  &_icon {
    font-size: 12px;
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &-active {
      transform: rotate(180deg);
    }
  }

  &_button:hover,
  &_button.ant-popover-open {
    color: var(--wuji-primary-color);
  }
  &_options {
    margin: 0;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.nav-drop-popover-light .ant-popover-inner-content,
.nav-drop-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
  max-height: 410px;
  overflow: auto;

  .nav-link-item {
    padding: 10px 12px;
  }
}

.nav-drop-popover-light .ant-popover-inner-content {
  .nav-drop_options,.nav-link-item {
    &:hover,&-active {
      color: var(--wuji-text-color);
      background:var(--wuji-primary-bg-color);
    }
    &:active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.nav-drop-popover-dark .ant-popover-inner-content {
  color: var(--wuji-text-color-dark);
  background: var(--wuji-primary-bg-color-dark);
  .nav-drop_options,.nav-link-item {
    &:hover,&-active {
      color: var(--wuji-text-color-dark);
      background:rgba(255, 255, 255, 0.08);
    }
    &:active {
      background:rgba(255, 255, 255, 0.04);
    }
  }
}

.nav-user-popover-dark.ant-popover-placement-bottomRight,
.nav-drop-popover-dark.ant-popover-placement-bottom {
   > .ant-popover-content > .ant-popover-arrow {
    border-top-color: var(--wuji-primary-bg-color-dark);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--wuji-primary-bg-color-dark);
  }
}
</style>
