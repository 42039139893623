var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ExcelFieldConfigEdit from './ExcelFieldConfigEdit';
import { ExprInput } from '@tencent/ui-core';
import { Radio } from 'ant-design-vue';
import styles from './ExcelEdit.module.scss';
import { isConfirm } from '@/utils/modal';
import { getDocLink } from '@utils/links';
let XySourceMethodEdit = class XySourceMethodEdit extends Vue {
    /**
     * 触发 pagelet更新 必须
     */
    commit() {
        this.editValue = { ...this.editValue };
    }
    get mode() {
        return this.editValue.mode || 'form';
    }
    set mode(v) {
        this.editValue.mode = v;
    }
    get jsExample() {
        return `
const cols = [
  {
    // 表头名
    colName: '电影名',
    // 取值
    dataKey: 'title',
    // 列宽（字符数）
    // colWidth: 10
  },
  {
    colName: '介绍',
    dataKey: 'description'
  },
];
return cols;
`.trim();
    }
    get isCsv() {
        return this.editValue?.format === 'csv';
    }
    useJsExample() {
        this.editValue.fieldConfigJsCode = this.jsExample;
        this.commit();
    }
    async onDataPathSelect(dataPath) {
        if (!this.editValue.jsCode) {
            const isUse = await isConfirm({
                content: `是否同时使用 [${dataPath}] 的数据作为Excel导出的数据`,
                okText: '使用',
                cancelText: '不使用',
            });
            if (isUse) {
                this.editValue.jsCode = dataPath;
                this.commit();
            }
        }
    }
    renderLink() {
        if (window.GLOBAL_INFO.externalDeploy) {
            return null;
        }
        const url = getDocLink().STARTER_EXCEL_EXPORT;
        return (url ? <div class={styles.link}>
        <a href={url} target="_blank">
          <a-icon class={styles.icon} type="book"/>
          点此查看使用方法
        </a>
      </div> : null);
    }
    render() {
        return (<div style="width:100%">
        {this.renderLink()}
        <a-form-model layout="horizontal">
          <a-form-item label="导出的数据" class={styles.formItem}>
            <ExprInput v-model={this.editValue.jsCode} onChange={this.commit} props={{
                type: 'javascript',
                multipleLine: true,
                placeholder: '1、使用页面变量 eg: data.yourSource.listData \n2、使用上一个流程的执行结果 eg: flowState.res.listData',
            }}></ExprInput>
          </a-form-item>

          <a-form-item label="导出字段配置" class={[styles.formItem, styles.colConfig]}>
            <a-radio-group v-model={this.mode} onChange={this.commit}>
              <a-radio value="form">配置模式(固定列)</a-radio>
              <a-radio value="js">代码模式(动态列)</a-radio>
            </a-radio-group>

            {this.mode === 'form' && (<ExcelFieldConfigEdit v-model={this.editValue.fieldConfig} onChange={this.commit} onDataPathSelect={this.onDataPathSelect} isCsv={this.isCsv}></ExcelFieldConfigEdit>)}

            {this.mode === 'js' && (<div>
                <a-button onClick={this.useJsExample} style="margin-bottom:8px;">
                  使用模板参数
                </a-button>
                <ExprInput v-model={this.editValue.fieldConfigJsCode} onChange={this.commit} props={{
                    type: 'javascript',
                    multipleLine: true,
                }}></ExprInput>
              </div>)}
          </a-form-item>
          {this.renderOtherFields()}
        </a-form-model>
      </div>);
    }
    renderOtherFields() {
        const fields = [
            {
                key: 'fileName',
                title: '文件名',
                Comp: ExprInput,
                props: {
                    type: 'interopable-string',
                },
            },
            {
                key: 'sheetName',
                title: '表名',
                Comp: ExprInput,
                props: {
                    type: 'interopable-string',
                },
                hidden: this.isCsv,
            },
            {
                key: 'format',
                title: '支持的格式',
                Comp: Radio.Group,
                className: styles.formatControl,
                props: {
                    options: [
                        { label: 'xlsx', value: 'xlsx' },
                        { label: 'xlsm', value: 'xlsm' },
                        { label: 'csv', value: 'csv' },
                    ],
                },
            },
        ];
        return fields.filter(item => !item.hidden).map(f => (<a-form-item label={f.title} class={[styles.formItem, f.className || '']}>
        <f.Comp onChange={this.commit} onInput={this.commit} v-model={this.editValue[f.key]} props={f.props}></f.Comp>
      </a-form-item>));
    }
};
XySourceMethodEdit = __decorate([
    Component({
        name: 'XyExcelEdit',
        mixins: [vueComponentMixin],
    })
], XySourceMethodEdit);
export default XySourceMethodEdit;
