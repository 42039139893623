<template>
  <w-sys-page
    v-if="isConfigLoaded"
    ref="wSysPage"
    :key="pageInstanceKey"
    mode="runtime"
    :project-config="projectConfig"
    :config="config"
    :page-list="pageList"
  />
</template>

<script>
import { mapState } from 'vuex';
import { pageScrollToTop } from '@utils/browser';
import { usePreviewConsumer } from '@/composables/pagePreview/use-preview';
import { useRouter } from '@/router/useRouter';

export default {
  name: 'RuntimePage',
  provide() {
    return {
      routeContext: this.routeContext,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.beforeUnloadHandler) {
      return await this.beforeUnloadHandler(to, from, next);
    }
    return next();
  },
  // 在当前路由改变，但是该组件被复用时调用
  beforeRouteUpdate(to, from, next) {
    // 判断是否为 Hash 变化
    const isHashChange = (to.hash || from.hash) && from.name === to.name && to?.fullPath?.replace(to?.hash, '') === from?.fullPath?.replace(from?.hash, '');

    // 当组件为非 keep-alive 时, 刷新对应的 UcRenderer 实例
    // block_reload_uc_renderer 为页面 props 双向绑定时对路径修改时会带上的参数
    if (!to.meta?.isKeepAlive && to?.params?.block_reload_uc_renderer !== true) {
      if (isHashChange) return next();
      this.reloadUcRenderer();
    }

    // 非 Hash 变化重置滚动条位置
    if (!isHashChange) pageScrollToTop();

    return next();
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    pageId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { route } = useRouter();
    const { isPreviewMode, addPreviewListener, removePreviewListener, fetchPreviewData } = usePreviewConsumer(route);
    return {
      isPreviewMode,
      addPreviewListener,
      removePreviewListener,
      fetchPreviewData,
    };
  },
  data() {
    return {
      pageInstanceKey: Date.now().toString(36),
      config: null,
      projectConfig: null,
      pageList: [],
      // 路由组件的上下文
      beforeUnloadHandler: null,

      routeContext: {
        setBeforeUnloadHandler: this.setBeforeRouteLeave,
        getBeforeUnloadHandler: this.getBeforeRouteLeave,
      },
    };
  },
  computed: {
    ...mapState({
      env: state => state.app.env,
      branch: state => state.app.branch,
      project: state => state.runtime.project,
    }),
    isConfigLoaded() {
      return !!(this.config && this.projectConfig);
    },
  },
  watch: {
    pageId: {
      handler() {
        this.config = null;
        this.init();
        this.removeBeforeUnloadHandler();
      },
      immediate: true,
    },
  },
  created() {
    if (this.isPreviewMode()) {
      this.addPreviewListener(this);
    }
  },
  destroyed() {
    this.removeBeforeUnloadHandler();
    if (this.isPreviewMode()) {
      this.removePreviewListener();
    }
  },
  methods: {
    // 刷新页面实例
    reloadPageInstance() {
      this.pageInstanceKey = Date.now().toString(36);
    },
    // 刷新渲染器实例
    reloadUcRenderer() {
      this.$refs.wSysPage?.reloadUcRenderer('*');
    },
    async init() {
      const { projectId, pageId, env, branch } = this;

      // 合并获取应用, 页面列表, 当前页面的数据
      const [appInfo, pageList, page] = await Promise.all([
        this.$store.dispatch('runtime/getProject', { projectId, env }),
        this.$store.dispatch('runtime/getBasicPageList', { projectId, env }),
        this.$store.dispatch('runtime/getPageDetail', { projectId, pageId, env, branch }),
      ]);

      this.projectConfig = appInfo;
      this.pageList = pageList;
      this.config = page;

      if (this.isPreviewMode()) {
        this.fetchPreviewData();
      }
    },
    setConfig(config) {
      this.config = config;
    },
    setProjectConfig(config) {
      this.projectConfig = config;
    },
    removeBeforeUnloadHandler() {
      this.beforeUnloadHandler = null;
    },
    setBeforeRouteLeave(handler) {
      this.beforeUnloadHandler = handler;
    },
    getBeforeRouteLeave() {
      return this.beforeUnloadHandler;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
