import { pick, chunk } from 'lodash';
import { defineComponent, computed, ref, onBeforeMount } from '@vue/composition-api';
import { isAdminRole, usePermission, useFlowPermissions, useActiveTabPerm, hasPanguMapping } from './utils';
import GeneralPermission from './GeneralPermission';
import CustomPermission from './CustomPermission';
import BuiltinPermission from './BuiltinPermission';
import { permissionApi as services } from '@/services';
import { message } from 'ant-design-vue';
import useFlowService from '@/composables/flow/useFlowService';
import RelatedPermission from './RelatedPermission';
import { fetchAuthSetting } from '@/composables/useAuthSetting';
export default defineComponent({
    name: 'ManagePermission',
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        pages: {
            type: Array,
            required: true,
        },
        tagList: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: 'view',
        },
        maxHeight: {
            type: String,
            default: '',
        },
        isPermSet: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const rolePerms = ref([]);
        const flowList = ref([]);
        const { flowPerms, fetchFlowPerms } = useFlowPermissions(props.projectId);
        const { activeTab, activeRow } = useActiveTabPerm();
        const enabledPangu = ref(false);
        const requesting = ref(false);
        const fetchCheckPangu = async () => {
            const authSetting = await fetchAuthSetting(props.projectId, true);
            enabledPangu.value = hasPanguMapping(authSetting, props.env);
        };
        const readonly = computed(() => props.mode === 'view' || isAdminRole(props.role) || props.role[0] === '/');
        const { permissions, fetchPermissions } = usePermission(props.projectId);
        const { all: fetchAllFlow } = useFlowService({ projectId: props.projectId, envId: props.env });
        const isValidPageId = (pageId) => props.pages.some(x => x.pageId === pageId)
            || flowList.value.some(x => x.flowId === pageId);
        const refreshRolePerms = async () => {
            if (!props.role)
                return;
            const roleView = await services.fetchRolePermission(props.projectId, props.env, props.role);
            rolePerms.value = roleView.permissions
                .map((rp) => pick(rp, ['pageId', 'permission', '_id']))
                .filter((rp) => (rp.pageId ? isValidPageId(rp.pageId) : true));
        };
        const init = async () => {
            await fetchPermissions(props.env);
            await fetchCheckPangu();
            if (!enabledPangu.value)
                flowList.value = await fetchAllFlow();
            if (flowList.value.length) {
                await fetchFlowPerms(props.env);
            }
            if (!activeTab.value)
                activeTab.value = 'page';
            await refreshRolePerms();
        };
        onBeforeMount(() => init());
        return {
            refreshRolePerms,
            permissions,
            rolePerms,
            readonly,
            flowList,
            flowPerms,
            activeTab,
            activeRow,
            requesting,
        };
    },
    render() {
        const batchUpdate = async (type, data) => {
            if (type === 'checked') {
                await services.createRolePermission(this.projectId, this.env, { role: this.role, perms: data.map(x => ({ pageId: x.pageId, permission: x.permission })) });
                return;
            }
            const ids = data.map((item) => {
                const record = this.rolePerms.find(x => x.permission === item.permission && x.pageId === item.pageId);
                return record?._id;
            }).filter(Boolean);
            await services.deleteRolePermission(this.projectId, this.env, {
                role: this.role,
                id: 'batch',
                ids: ids,
            });
        };
        const requestCheckChange = async (type, data, showTip = true) => {
            const permissionName = data.permName
                || this.permissions.find(x => x.name === data.permission)?.cname
                || data.permission;
            if (type === 'checked') {
                const path = this.pages.find(x => x.pageId === data.pageId)?.path || '';
                await services.createRolePermission(this.projectId, this.env, { role: this.role, permission: data.permission, pageId: data.pageId, path });
                showTip && message.success(`已新增权限: ${permissionName}`);
            }
            else {
                const record = this.rolePerms.find(x => x.permission === data.permission && x.pageId === data.pageId);
                if (!record?._id) {
                    this.rolePerms = this.rolePerms.filter(x => x.permission !== data.permission || x.pageId !== data.pageId);
                    this.$emit('change', this.rolePerms);
                    return;
                }
                await services.deleteRolePermission(this.projectId, this.env, { id: record._id, role: this.role });
                showTip && message.warn(`已删除权限: ${permissionName}`);
            }
        };
        const checkChange = async (event) => {
            const { type, data } = event;
            try {
                if (Array.isArray(data)) {
                    console.time(`${type} role perm ${data.length}`);
                    const chunks = chunk(data, 50);
                    this.requesting = true;
                    for (const arr of chunks) {
                        await batchUpdate(type, arr);
                    }
                    console.timeEnd(`${type} role perm ${data.length}`);
                    type === 'checked' ? message.success('已新增角色权限') : message.warn('已删除角色权限');
                }
                else {
                    await requestCheckChange(type, data);
                }
            }
            catch (error) {
                message.error('更新失败');
            }
            await this.refreshRolePerms();
            this.requesting = false;
            this.$emit('refresh', this.rolePerms);
        };
        return (<div>
        <a-spin spinning={this.requesting}>
        <a-tabs v-model={this.activeTab}>
          <a-tab-pane key="page" tab="页面权限">
            <GeneralPermission projectId={this.projectId} env={this.env} role={this.role} tagList={this.tagList} pages={this.pages} rolePerms={this.rolePerms} readonly={this.readonly} maxHeight={this.maxHeight} onCheckChange={checkChange} onChange={(v) => {
                this.rolePerms = v;
                this.$emit('change', v);
            }}></GeneralPermission>
          </a-tab-pane>
          {this.flowList.length
                ? <a-tab-pane key="flow" tab="流程权限">
                  <RelatedPermission projectId={this.projectId} env={this.env} role={this.role} readonly={this.readonly} rolePerms={this.rolePerms} rowResources={this.flowList.map(x => ({ id: x.flowId, name: `${x.name}(${x.flowId})` }))} colPerms={this.flowPerms.map(x => ({ id: x.name, name: x.cname }))} title="流程" onCheckChange={checkChange}/>
              </a-tab-pane>
                : null}
          <a-tab-pane key="custom" tab="自定义接口/集合权限">
            <CustomPermission projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} permissions={this.permissions} readonly={this.readonly} maxHeight={this.maxHeight} isPermSet={this.isPermSet} onCheckChange={checkChange} onChange={(v) => {
                this.rolePerms = v;
                this.$emit('change', v);
            }}></CustomPermission>
          </a-tab-pane>
          <a-tab-pane key="builtin" tab="内置权限">
            <BuiltinPermission projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} permissions={this.permissions} readonly={this.readonly} maxHeight={this.maxHeight} onCheckChange={checkChange} onChange={(v) => {
                this.rolePerms = v;
                this.$emit('change', v);
            }}></BuiltinPermission>
          </a-tab-pane>
        </a-tabs>
        </a-spin>
      </div>);
    },
});
