<template>
  <div class="container textStyle">
    <div
      class="header"
      @click="()=>{display=!display}"
    >
      <span>输出结构</span>
      <a-icon
        class="visibleIcon"
        :type="displayIconStyle"
      />
    </div>
    <div
      v-if="display"
      class="displayArea"
    >
      <div v-if="displayOpenEditorLink">
        <span>此方法返回值由自定义方法返回👉<a-button
          class="textStyle"
          type="link"
          style="padding:0"
          @click="openMethod"
        >查看自定义代码</a-button></span>
      </div>
      <div v-else-if="displayDataSchema">
        <DataSchemaTreeView :data-schema="methodDataSchema" />
      </div>
      <div
        v-else
        style="margin-top: 4px"
      >
        <span class="noDataSchemaText"><span class="methodName">{{ selectedMethod }}</span>方法没有返回值</span>
      </div>
    </div>
  </div>
</template>

<script>
import DataSchemaTreeView from './DataSchemaTreeView.vue';
import { getMethodReturnSchema } from './utils';
import { mapState, mapActions } from 'vuex';

export default {
  components: { DataSchemaTreeView },
  inject: {
    wContext: {
      default: null,
      from: 'wContext',
    },
  },
  props: {
    source: {
      type: Object,
      default: null,
    },
    selectedMethod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      display: true,
      observer: null,
    };
  },
  computed: {
    ...mapState({
      dataSourceConfigs: state => state.dataSource.dataSourceConfigs,
    }),
    selectedMethodConfig() {
      return this.source?.methods.find(item => item.id === this.selectedMethod);
    },
    selectedDataSourceConfig() {
      return this.dataSourceConfigs.find(config => config.id === `${this.projectId}:${this.source.id}`);
    },
    displayOpenEditorLink() {
      return this.selectedMethodConfig && this.selectedMethodConfig.type !== 'form';
    },
    displayDataSchema() {
      return this.selectedMethod !== 'init';
    },
    methodDataSchema() {
      return getMethodReturnSchema(this.selectedDataSourceConfig, this.selectedMethod);
    },
    displayIconStyle() {
      return this.display ? 'eye-invisible' : 'eye';
    },
    projectId() {
      return this.wContext.projectId;
    },
  },
  created() {
    this.fetchDataSourceConfig({ projectId: this.projectId });
  },
  methods: {
    ...mapActions({
      fetchDataSourceConfig: 'fetchDataSource',
    }),
    openMethod() {
      this.$emit('openMethod');
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.textStyle {
  font-size: 12px;
}

.header{
  cursor: pointer;
  font-weight: bold;

  .visibleIcon{
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:hover{
    .visibleIcon{
      opacity: 1;
      transition: opacity 0.1s ease-out;
    }
  }
}

.noDataSchemaText{
  color: black;
  font-size: 14px;

  .methodName{
    color: #979797;
    font-size: 85%;
    border-radius: 3px;
    padding: .2em .4em;
    background-color: rgba(27,31,35,.05);
  }
}

</style>
./utils
