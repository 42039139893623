
/** lodash的remove不会触发vue更新，这里用Array的api实现一个 */
export function arrayRemove(arr, item) {
  const index = arr.indexOf(item);
  if (index < 0) return;
  arr.splice(index, 1);
}

export function getJSONObject(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}

export function getJSONString(obj) {
  try {
    return JSON.stringify(obj, undefined, '\t');
  } catch (error) {
    console.error('getJSONString fail:', error);
    return '';
  }
}

export function setArrayPropsToJSONObject(arr, props = []) {
  if (!Array.isArray(arr)) {
    return;
  }
  arr.forEach((item) => {
    props.forEach((key) => {
      if (typeof item[key] === 'string') {
        // eslint-disable-next-line no-param-reassign
        item[key] = getJSONObject(item[key]);
      }
    });
  });
}

export function trimObjectValue(data) {
  return Object.keys(data).reduce((acc, curr) => {
    acc[curr] = typeof data[curr] === 'string' ? data[curr].trim() : data[curr];
    return acc;
  }, {});
}

export function useRequestIdleCallback(cb) {
  if (typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(cb, 500);
  }
}

export default {
  arrayRemove,
  getJSONObject,
  getJSONString,
  trimObjectValue,
  setArrayPropsToJSONObject,
};

