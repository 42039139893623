import SchemaDefault from './xy-modal-schema-default.json';

export default {
  type: 'object',
  fields: [
    {
      id: 'attr',
      title: '属性',
      type: 'string',
      'uc:allowExpr': false,
      'uc:allowReset': false,
      group: '属性',
      ui: { type: 'PageletAttr' },
      condition: 'designer.pageletId!=="default"',
    },
    {
      id: 'type',
      title: '展示类型',
      type: 'string',
      'uc:allowExpr': false,
      default: SchemaDefault.type,
      group: '外观',
      enum: [
        { value: 'modal', label: '弹窗' },
        { value: 'drawer', label: '抽屉' },
        { value: 'adaptation', label: '移动端适配' },
      ],
    },
    {
      id: 'drawerPlacement',
      title: '抽屉的方向',
      type: 'string',
      group: '外观',
      default: SchemaDefault.drawerPlacement,
      enum: [
        { value: 'top', label: '上' },
        { value: 'right', label: '右' },
        { value: 'bottom', label: '下' },
        { value: 'left', label: '左' },
      ],
      condition: 'props.type === "drawer" || props.type === "adaptation"',
    },
    {
      id: 'width',
      title: '宽度',
      type: 'string',
      group: '外观',
      default: SchemaDefault.width,
      ui: {
        type: 'XyModalSizeEditor',
        unitOptions: [
          { value: 'px', label: 'px' },
          { value: 'vw', label: 'vw' },
          { value: '%', label: '%' },
        ],
      },
      condition: 'props.type === "modal" || props.type === "adaptation" || props.drawerPlacement === "right" || props.drawerPlacement === "left"',
    },
    {
      id: 'height',
      title: '高度',
      type: 'string',
      group: '外观',
      default: SchemaDefault.width,
      ui: {
        type: 'XyModalSizeEditor',
        unitOptions: [
          { value: 'px', label: 'px' },
          { value: 'vh', label: 'vh' },
          { value: '%', label: '%' },
        ],
      },
      condition: '(props.type === "drawer" || props.type === "adaptation") && (props.drawerPlacement === "top" || props.drawerPlacement === "bottom")',
    },
    {
      id: 'withTitle',
      title: '显示标题',
      type: 'boolean',
      group: '外观',
      default: SchemaDefault.withTitle,
    },
    {
      id: 'withFooter',
      title: '显示页脚',
      type: 'boolean',
      group: '外观',
      default: SchemaDefault.withFooter,
    },
    {
      id: 'isTitleSlot',
      title: '自定义标题',
      type: 'boolean',
      group: '标题',
      default: SchemaDefault.isTitleSlot,
      condition: '!!props.withTitle',
    },
    {
      id: 'title',
      title: '标题',
      type: 'string',
      group: '标题',
      default: SchemaDefault.title,
      condition: '!props.isTitleSlot && props.withTitle',
    },
    {
      id: 'withClosableIcon',
      title: '显示右上角的关闭按钮',
      type: 'boolean',
      group: '功能',
      default: SchemaDefault.withClosableIcon,
    },
    {
      id: 'mask',
      title: '展示蒙层',
      type: 'boolean',
      group: '功能',
      default: SchemaDefault.mask,
    },
    {
      id: 'maskClosable',
      title: '支持点击蒙层关闭',
      type: 'boolean',
      group: '功能',
      default: SchemaDefault.maskClosable,
      condition: 'props.mask',
    },
    {
      id: 'keyboard',
      title: '支持键盘 ESC 关闭',
      type: 'boolean',
      group: '功能',
      default: SchemaDefault.keyboard,
      condition: 'props.mask',
    },
    {
      id: 'keepAlive',
      title: '关闭时保留实例',
      type: 'boolean',
      group: '功能',
      default: SchemaDefault.keepAlive,
      condition: 'designer.pageletId!=="default"',
    },
  ],
};
