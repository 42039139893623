var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DBRequestForm } from './DBRequestForm';
import { HttpRequestForm } from './HttpRequestForm';
import { COMP_TYPES } from './utils';
import styles from './style.module.scss';
import { Label } from './Label';
/**
 * 该组件暂时没有用
 */
let ArgumentstForm = class ArgumentstForm extends Vue {
    value; // {0:true,1:{}}
    argumentSchemas;
    render() {
        const message = '如果想进行动态绑定，请使用 {{}} 符号, eg: {{ state.foobar }}';
        const buildIn = [
            {
                key: 'db',
                comp: DBRequestForm,
            },
            {
                key: 'http',
                comp: HttpRequestForm,
            },
        ];
        return (<div class={styles.root}>
        {this.argumentSchemas.map((schema, index) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const ParamsComp = buildIn.find(item => Reflect.get(schema, 'sourceRequestParamsType') === item.key)?.comp;
                if (ParamsComp) {
                    const { id, title } = schema;
                    return (<div class={[styles.argFormItem, styles.parentFormItem]}>
                <Label props={{ id, title }}></Label>
                <ParamsComp v-model={this.value[index]} schema={schema}></ParamsComp>
              </div>);
                }
                const target = COMP_TYPES.find(t => t.type.includes(schema.type));
                if (target) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const Comp = target.comp;
                    return <Comp class={styles.parentFormItem} schema={schema} v-model={this.value[index]}></Comp>;
                }
                return null;
            })}

        <a-alert message={message}></a-alert>
      </div>);
    }
};
__decorate([
    Prop()
], ArgumentstForm.prototype, "value", void 0);
__decorate([
    Prop()
], ArgumentstForm.prototype, "argumentSchemas", void 0);
ArgumentstForm = __decorate([
    Component({
        name: 'ArgumentstForm',
    })
], ArgumentstForm);
export default ArgumentstForm;
