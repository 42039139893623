<template>
  <div>
    <div class="content-header">
      <a-alert
        v-if="!hasUpdatePermission"
        :message="canReadPermission ? '无权更新，只读模式' : '无权查看'"
        type="warning"
        show-icon
      />
      <span v-else />
      <PermFilter
        :project-id="projectId"
        :show-env="!isRuntimeMode"
        search-placeholder="输入请求路径进行过滤"
        @envChange="envChange"
        @searchChange="searchChange"
      />
    </div>
    <a-table
      :loading="envChangeLoading"
      :columns="columns"
      :data-source="filterData"
      row-key="_id"
    >
      <div
        slot="expandedRowRender"
        slot-scope="record"
      >
        <div v-if="!record.bindings || record.bindings.length === 0">
          无
        </div>
        <div v-else>
          <a-table
            :columns="bindingColumns"
            :data-source="record.bindings"
            row-key="_id"
            size="small"
            style="width:50%"
            :pagination="false"
          >
            <div
              slot="bindingOperation"
              slot-scope="text,bindingRecord"
            >
              <a
                href="javascript:;"
                :disabled="!hasUpdatePermission"
                @click="unbind(bindingRecord)"
              >
                <a-icon type="delete" />
                解绑
              </a>
            </div>
          </a-table>
        </div>
      </div>
      <div
        slot="operation"
        slot-scope="txt,record"
      >
        <a
          href="javascript:;"
          :disabled="!hasUpdatePermission"
          @click="create(record)"
        >
          <a-icon type="plus-circle" />
          绑定权限
        </a>
      </div>
      <div
        slot="relativeTime"
        slot-scope="text"
      >
        <a-tooltip
          :title="formatTime(text)"
          placement="right"
        >
          <span>{{ relativeTime(text) }}</span>
        </a-tooltip>
      </div>
    </a-table>
    <a-modal
      :visible="visible"
      title="绑定权限"
      ok-text="确认"
      cancel-text="取消"
      :ok-button-props="{ props: { disabled: !form.name || !form.permission }}"
      @ok="save"
      @cancel="visible = false"
    >
      <div>
        <a-form-model :model="form">
          <a-form-model-item label="接口">
            <a-input
              v-model="form.name"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="请求方法">
            <a-input
              v-model="form.method"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item
            label="所需权限"
            required
          >
            <a-select v-model="form.permission">
              <a-select-option
                v-for="p of apiPermissions"
                :key="p._id"
                :value="p.name"
              >
                {{ `${p.cname}(${p.name})` }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { keyBy } from 'lodash';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { permissionApi as services } from '@/services';
import { API_PERMISSION_BINDING_TYPE, COLLECTION_BINDING_TYPE } from './constants';
import PermFilter from './PermFilter.tsx';


export default {
  components: { PermFilter },
  props: {
    projectId: {
      type: String,
      default: window.RUNTIME_CONFIGS?.projectId || '',
    },
  },
  data() {
    return {
      bindingColumns: [
        {
          title: '权限ID',
          dataIndex: 'permission',
          key: 'permission',
        },
        {
          title: '权限名',
          dataIndex: 'cname',
          key: 'cname',
          customRender: (txt, record) => this.pNameMap[record.permission]?.cname ?? record.permission,
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'bindingOperation' },
        },
      ],
      columns: [
        {
          title: '接口',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '类型',
          dataIndex: 'type',
          key: 'type',
          customRender: text => (text === COLLECTION_BINDING_TYPE ? '数据源' : '云函数'),
        },
        {
          title: '请求方法',
          dataIndex: 'method',
          key: 'method',
        },
        {
          title: '备注',
          dataIndex: 'memo',
          key: 'memo',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
        },

      ],
      funcs: [],
      data: [],
      visible: false,
      form: null,
      q: '',
      env: '',
      envChangeLoading: false,
      hasUpdatePermission: false,
      canReadPermission: false,
      isRuntimeMode: !!window.RUNTIME_CONFIGS,
    };
  },
  computed: {
    ...mapGetters('rbac', ['permissions', 'funcPermissions', 'cgiPermissions']),
    filterData() {
      const q = this.q.toLowerCase();
      const data = [...this.funcPermissions, ...this.cgiPermissions];
      return data.filter(r => r.name.toLowerCase().includes(q));
    },
    apiPermissions() {
      return this.permissions.filter(p => p.type === 'api');
    },
    pNameMap() {
      return keyBy(this.permissions, 'name');
    },
  },
  created() {
    this.resetForm();
  },
  methods: {
    envChange(data) {
      this.env = data.env;
      this.hasUpdatePermission = data.canUpdatePermission;
      this.canReadPermission = data.canReadPermission;
      this.init();
    },
    searchChange(q) {
      this.q = q;
    },
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
    relativeTime(time) {
      return dayjs(time).fromNow();
    },
    async init() {
      this.envChangeLoading = true;
      await this.$store.dispatch('rbac/fetchBindingApis', { projectId: this.projectId, env: this.env });
      await this.$store.dispatch('rbac/fetchFuncs', { projectId: this.projectId, env: this.env });
      await this.$store.dispatch('rbac/fetchCollections', { projectId: this.projectId, env: this.env });
      this.envChangeLoading = false;
    },
    async create(record) {
      this.form = {
        name: record.name,
        method: record.method,
        api: record.api || '',
        permission: '',
        type: record.type,
      };
      this.visible = true;
    },
    async unbind(record) {
      const { _id: id, permission } = record;
      Modal.confirm({
        title: '确认删除当前绑定？',
        content: `${permission} - (${this.pNameMap[permission]?.cname ?? permission})`,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          await services.unbind(this.projectId, this.env, id);
          await this.init();
        },
      });
    },
    handleChange() {
      const func = this.funcs.find(f => f.name === this.form.name);
      this.form.method = func.method;
      this.form.api = func.api;
    },
    resetForm() {
      this.form = {
        name: '',
        method: '',
        api: '',
        permission: '',
        type: API_PERMISSION_BINDING_TYPE,
      };
    },
    async save() {
      try {
        await services.createApiBinding(this.projectId, this.env, this.form);
        await this.init();
        this.resetForm();
        this.visible = false;
      } catch (error) {
        const msg = '保存失败';
        console.error(msg, error);
        message.error(msg);
      }
    },
    async getApiPermissions() {
      try {
        const resp = await services.listApiBinding(this.projectId, this.env, API_PERMISSION_BINDING_TYPE);
        return resp;
      } catch (error) {
        const msg = '获取数据失败';
        console.error(msg, error);
        message.error(msg);
      }
      return [];
    },
    async getPermissions() {
      try {
        const resp = await services.fetchPermissions(this.projectId, this.env);
        return resp;
      } catch (error) {
        const msg = '获取权限数据';
        console.error(msg, error);
        message.error(msg);
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
</style>
