<template>
  <XyPageContainer
    v-if="comp.type === 'pagelet'"
    type="page"
    :page-id="comp.name"
    :params="arrayProps"
    :style="compStyle"
    loading-mode="silence"
    :hide-loading-indicator="true"
  />
  <component
    :is="comp.name"
    v-else
    v-bind="compProps"
    :style="compStyle"
  />
</template>

<script>
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import XyPageContainer from '@components/page/xy-page-container';
import { PageletContext } from '@utils/global/api';

export default {
  name: 'NavCompsRender',
  components: { XyPageContainer },
  provide() {
    return {
      w: this.w,
    };
  },
  props: {
    comp: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'runtime',
    },
  },
  data() {
    return {
      w: new PageletContext({ mode: this.mode, pageletContainer: this }),
    };
  },
  computed: {
    // 组件自定义 props
    compProps() {
      const props = this.comp.props ?? {};
      try {
        const propsStr = JSON.stringify(props);
        return JSON.parse(stringInterop(propsStr, {
          ...(this.w?.mainRenderer ?? {}),
          $app: this.$app,
          w: this.w,
        }));
      } catch {
        return props;
      }
    },
    // 组件自定义 props 数组
    arrayProps() {
      return Object.keys(this.compProps).map(key => ({
        key,
        value: this.compProps[key],
      }));
    },
    // 组件自定义 style
    compStyle() {
      const style = this.comp.style ?? {};
      try {
        const styleStr = JSON.stringify(style);
        return JSON.parse(stringInterop(styleStr, {
          ...(this.w?.mainRenderer ?? {}),
          $app: this.$app,
          w: this.w,
        }));
      } catch {
        return style;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#xy-client-layout-nav-comps {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
