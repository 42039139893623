<template>
  <div>
    <a-form-model
      ref="form"
      :model="role"
      :rules="rules"
      :label-col="{span: 4}"
      :wrapper-col="{span: 20}"
      label-align="left"
      @validate="validate"
    >
      <a-form-model-item
        label="角色类型"
        prop="type"
      >
        <a-select
          v-model="role.type"
          :disabled="action === 'edit'"
        >
          <a-select-option value="">
            应用
          </a-select-option>
          <a-select-option
            value="biz"
            :disabled="!hasBizIsolation"
          >
            业务
            <span
              v-if="!hasBizIsolation"
              style="font-size:10px;font-style:italic"
            >未启用业务隔离</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="角色ID"
        prop="name"
      >
        <a-input
          v-model="role.name"
          :disabled="action === 'edit'"
          placeholder="例如：admin-group"
        />
      </a-form-model-item>
      <a-form-model-item
        label="角色名"
        prop="cname"
      >
        <a-input
          v-model="role.cname"
          placeholder="例如：管理组"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { permissionApi as services } from '@/services';

export default {
  props: {
    projectId: {
      type: String,
      default: '',
    },
    env: {
      type: String,
      default: '',
    },
    hasBizIsolation: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'create',
    },
    names: {
      type: Array,
      default: () => [],
    },
    roleName: {
      type: String,
      default: '',
    },
    roleCName: {
      type: String,
      default: '',
    },
    roleType: {
      type: String,
      default: '',
    },
    handleChange: {
      type: Function,
      default: () => ({}),
    },
    saveRole: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      role: {
        projectId: this.projectId,
        name: '',
        cname: '',
        type: '',
      },
      rules: {
        name: [
          { required: true, message: '必填' },
          { max: 36, message: '最大长度 36' },
          { validator: this.checkName },
        ],
        cname: [
          { required: true, message: '必填' },
          { max: 36, message: '最大长度 36' },
        ],
      },
    };
  },
  watch: {
    roleName: {
      handler(val) {
        this.role.name = val;
      },
      immediate: true,
    },
    roleCName: {
      handler(val) {
        this.role.cname = val;
      },
      immediate: true,
    },
    roleType: {
      handler(val) {
        this.role.type = val;
      },
      immediate: true,
    },
  },
  methods: {
    checkName(rule, value, callback) {
      if (this.action === 'create' && !/^[\w\\-]+$/.test(value)) {
        callback('有效字符：英文字母、数字、下划线以及"-"');
        return;
      }
      if (this.action === 'create' && this.names.includes(value)) {
        callback('角色重复');
        return;
      }
      callback();
    },
    validate() {
      const ok = this.role.name !== '' && this.role.cname !== '';
      this.handleChange(ok);
    },
    async save() {
      try {
        await this.$refs.form.validate();
      } catch (err) {
        return '';
      }

      let ret = this.role.name;
      try {
        if (this.action === 'create') {
          await services.createRole(this.projectId, this.env, this.role);
        } else {
          await services.updateRole(this.projectId, this.env, this.role);
        }
        this.role.name = '';
        this.role.cname = '';
        this.role.type = '';
      } catch (err) {
        ret = '';
      }
      return ret;
    },
  },
};
</script>
