import { tryEval } from '@tencent/ui-core/lib/utils/stringInterop';
const executeAtom = ({ id, params = {}, renderer }) => {
    const { uc } = renderer;
    const atom = uc?.flowAtoms?.[id];
    if (!atom)
        throw new Error(`找不到 id=${id} 的行为原子`);
    // Mock Abort
    const abort = () => { };
    return atom.execute({ uc, renderer, tryEval, abort }, { ...atom.defaultParams, ...params });
};
export const buildAtoms = ({ renderer }) => {
    const atoms = {
        // UI交互
        // 弹出消息窗口
        alert(params) {
            return executeAtom({ id: 'uicore:alert', params, renderer });
        },
        // 弹出确认框
        confirm(params) {
            return executeAtom({ id: 'uicore:confirm', params: { ...params, abortIfRejected: false }, renderer });
        },
        // 弹出文字输入框
        prompt(params) {
            return executeAtom({
                id: 'uicore:prompt',
                params: {
                    message: params.message,
                    defaultValue: params.defaultValue ?? '',
                    inputType: params.inputType ?? 'input',
                    allowEmpty: params.allowEmpty ?? false,
                    abortIfRejected: false,
                },
                renderer,
            });
        },
        // 弹出提示
        message(params) {
            return executeAtom({ id: 'uicore:message', params, renderer });
        },
        // 弹出通知框
        notification(params) {
            return executeAtom({ id: 'uicore:notification', params, renderer });
        },
        // 逻辑
        // 校验表单
        validateForm(params) {
            return executeAtom({ id: 'uicore:validateForm', params, renderer });
        },
        // 调用 LessCode 方法
        invokeLessCode(methodName, ...methodArgs) {
            return executeAtom({
                id: 'xy:invokeLessCode',
                params: {
                    methodName,
                    args: methodArgs,
                },
                renderer,
            });
        },
        // TODO: 触发页面片事件
        // 复制文本
        clipboardWriteText(text, opt) {
            const { successMessage = true } = opt ?? {};
            return executeAtom({
                id: 'uicore:copyText',
                params: {
                    copyValue: text,
                    isRemind: successMessage,
                },
                renderer,
            });
        },
        // 数据源
        // 数据源操作
        invokeDataSourceMethod(id, method, args) {
            return executeAtom({
                id: 'xy:invokeDataSourceMethod',
                params: {
                    dataPath: `data.${id}.$methods`,
                    methodName: method,
                    argumentsType: 'form',
                    formArguments: args,
                },
                renderer,
            });
        },
        // TODO: 批量调用数据源接口
        // 数据源重新初始化
        dataSourceReInit() {
            return executeAtom({
                id: 'xy:source:batchInitCgi',
                renderer,
            });
        },
        // 导入导出
        // 上传至 COS
        uploadToCOS(params) {
            return executeAtom({
                id: 'xy:source:uploader.uploadToCOS',
                params,
                renderer,
            });
        },
        // Excel 导出
        exportExcel(params) {
            const { data = [], fields = [], fileName, sheetName, format } = params ?? {};
            return executeAtom({
                id: 'xy:exportExcel',
                params: {
                    jsCode: JSON.stringify(data),
                    mode: 'form',
                    fieldConfig: fields,
                    fileName,
                    sheetName,
                    format,
                },
                renderer,
            });
        },
        // Excel 导入
        importExcel() {
            return executeAtom({
                id: 'xy:importExcel',
                renderer,
            });
        },
        // 提取文件数据
        getFileData(params) {
            return executeAtom({
                id: 'xy:getFileData',
                params: params ?? {},
                renderer,
            });
        },
        // 页面导航
        // 跳转链接
        openLink(link, newWindow) {
            return executeAtom({
                id: 'xy:openLink',
                params: {
                    url: link,
                    newWindow: newWindow ?? false,
                },
                renderer,
            });
        },
        // 返回上一页
        historyBack() {
            return executeAtom({
                id: 'xy:hitstoryBack',
                renderer,
            });
        },
        // 刷新页面
        refreshPage() {
            return executeAtom({
                id: 'xy:refreshPage',
                renderer,
            });
        },
        // 刷新页面片
        reloadRenderer() {
            return executeAtom({
                id: 'xy:reloadUcRenderer',
                renderer,
            });
        },
        // 滚动页面
        pageScroll(params) {
            return executeAtom({
                id: 'xy:pageScroll',
                params: {
                    type: params.position,
                    yCoord: params?.y,
                    componentId: params?.componentId,
                    compOffsetType: params?.componentOffsetType,
                    compOffset: params?.componentOffset,
                    behavior: params?.behavior,
                },
                renderer,
            });
        },
        // 草稿
        // 保存草稿
        saveDraft(params) {
            return executeAtom({
                id: 'xy:source:saveDraft',
                params: {
                    namespace: params?.namespace,
                    showConfirm: params?.showConfirm,
                },
                renderer,
            });
        },
        // 恢复草稿
        restoreDraft(params) {
            return executeAtom({
                id: 'xy:source:restoreDraft',
                params: {
                    namespace: params?.namespace,
                    showConfirm: params?.showConfirm,
                    remainDraftAfterCancel: params?.remainDraftAfterCancel,
                },
                renderer,
            });
        },
        // 删除草稿
        removeDraft(params) {
            return executeAtom({
                id: 'xy:source:removeDraft',
                params: {
                    namespace: params?.namespace,
                },
                renderer,
            });
        },
        // 外部通知
        // 邮件通知
        email(params) {
            return executeAtom({
                id: 'xy:source:emailNotification',
                params,
                renderer,
            });
        },
        // 企微机器人通知
        wxworkRobot(params) {
            return executeAtom({
                id: 'xy:source:robotNotification',
                params: {
                    robotId: params?.robotId,
                    data: params?.content,
                    successNotice: params?.successMessage,
                },
                renderer,
            });
        },
        // 企业微信通知
        wxworkNotification(params) {
            const { app: weworkApp, receivers, type: msgType, content, title, description, url, buttonText: btnTxt, } = params ?? {};
            return executeAtom({
                id: 'xy:source:weworkNotification',
                params: {
                    weworkMsgConfig: {
                        weworkApp,
                        receiverType: 'static',
                        receivers,
                        msgType,
                        content,
                        title,
                        description,
                        url,
                        btnTxt,
                    },
                },
                renderer,
            });
        },
        // 唤起企业微信
        openWxwork(params) {
            const config = { type: params?.type };
            if (params?.type === 'username') {
                config.username = params?.value;
            }
            else if (params?.type === 'uin') {
                config.uin = params?.value;
            }
            return executeAtom({
                id: 'xyEvent:wxwork:message',
                params: { messageConfig: config },
                renderer,
            });
        },
        // 发送gpt消息
        sendGptMessage(params) {
            return executeAtom({
                id: 'xy:ai:sendMessage',
                params,
                renderer,
            });
        },
    };
    return atoms;
};
