import { defineComponent, ref, computed } from '@vue/composition-api';
import { BIZ_OR_PROJECT, GUEST_ROLE_ID } from './constants';
import { permissionApi } from '@/services';
import { message, Modal } from 'ant-design-vue';
import DeptRtx from './DeptRtx';
import { useExpire } from '@pages/modules/permission/expire';
export const userRoleFormProps = {
    projectId: {
        type: String,
        required: true,
    },
    env: {
        type: String,
        required: true,
    },
    isRuntimeMode: {
        type: Boolean,
        default: false,
    },
    defaultBizId: {
        type: String,
        default: '',
    },
    bizList: {
        type: Array,
        default: null,
    },
    operation: {
        type: String,
        required: true,
    },
    userId: {
        type: String,
        default: '',
    },
    userName: {
        type: String,
        default: '',
    },
    userType: {
        type: String,
        default: 'staff',
    },
    roles: {
        type: Array,
        required: true,
    },
    userRoleList: {
        type: Array,
        required: true,
    },
};
export default defineComponent({
    name: 'UserRoleForm',
    props: userRoleFormProps,
    setup(props) {
        const bizId = ref('');
        const addRoles = ref([]);
        const delRoles = ref([]);
        const formData = ref({
            bizId: props.defaultBizId || '',
            userId: props.userId,
            roles: [],
        });
        const availableRoles = computed(() => props.roles
            .filter(r => r.name !== GUEST_ROLE_ID)
            .filter(r => (formData.value.bizId ? r.type === 'biz' : !r.type)));
        const userList = computed(() => props.userRoleList.map(i => i.userId));
        const { expireOptions, renderCreateExpire } = useExpire(computed(() => [formData.value.userId]));
        const addUserRoles = async () => {
            let user = formData.value.userId;
            if (props.userType === 'org' && props.userId) {
                user = { label: props.userName, value: props.userId };
            }
            try {
                const result = await permissionApi.createRoleUser(props.projectId, props.env, {
                    bizId: formData.value.bizId,
                    users: [user],
                    roles: addRoles.value,
                    type: props.userType,
                    expireRoles: expireOptions.expireAt,
                });
                if (result.some(x => !!x?.error)) {
                    const errList = result.filter(x => x.error).map(x => `添加角色(${x.role})失败: ${x.error}`);
                    message.warn({
                        content: <div>
              {errList.map((err, i) => <div key={i}><a-icon type="exclamation-circle" theme="filled"/>{err}</div>)}
            </div>,
                        icon: <span />,
                    });
                }
                else {
                    message.success('已保存');
                }
                return true;
            }
            catch (error) {
                message.error('添加角色失败');
            }
            return false;
        };
        const delUserRoles = async () => {
            const userRoleList = props.userRoleList.find(i => i.userId === formData.value.userId)?.roles ?? [];
            const promises = [];
            userRoleList.forEach((i) => {
                if (i.bizId === formData.value.bizId && delRoles.value.includes(i.role)) {
                    promises.push(permissionApi.deleteRoleUser(props.projectId, props.env, i._id));
                }
            });
            try {
                await Promise.all(promises);
                message.success('已保存');
                return true;
            }
            catch (error) {
                message.error('删除角色失败');
            }
            return false;
        };
        const confirmToDelete = async () => {
            const { bizId } = formData.value;
            const promise = new Promise((resolve) => {
                Modal.confirm({
                    title: '确定删除角色？',
                    content: (<div>
              <br />
              将删除角色:{' '}
              <ul>
                {delRoles.value.map(i => (<li key={i}>
                    {availableRoles.value.find(r => r.name === i)?.cname ?? i}
                    {bizId ? `（${props.bizList.find(i => i.value === bizId)?.label ?? bizId}）` : ''}
                  </li>))}
              </ul>
            </div>),
                    onOk: async () => {
                        const result = await delUserRoles();
                        resolve(result);
                    },
                    onCancel: () => resolve(false),
                });
            });
            return await promise;
        };
        const save = async () => {
            const data = { ...formData.value };
            if (!props.userId && userList.value.includes(data.userId))
                return false;
            const result = await (props.operation === 'delete-role' ? confirmToDelete() : addUserRoles());
            return result;
        };
        return {
            bizId,
            userList,
            addRoles,
            delRoles,
            save,
            formData,
            availableRoles,
            renderCreateExpire,
        };
    },
    watch: {
        defaultBizId: {
            handler() {
                this.bizId = this.defaultBizId === BIZ_OR_PROJECT ? '' : this.defaultBizId;
                this.formData.bizId = this.bizId;
                if (this.userId) {
                    const record = this.userRoleList.find(i => i.userId === this.userId);
                    const roles = record.roles.filter(i => (this.formData.bizId ? i.bizId === this.formData.bizId : !i.bizId));
                    this.formData.roles = roles.map(r => r.role);
                }
            },
            immediate: true,
        },
    },
    render() {
        const change = () => {
            const data = {
                operation: this.operation,
                roles: this.operation === 'delete-role' ? this.delRoles : this.addRoles,
            };
            this.$emit('change', data);
        };
        const bizChange = () => {
            if (this.userId) {
                // 管理角色
                const record = this.userRoleList.find(i => i.userId === this.userId);
                const roles = record.roles.filter(i => (this.formData.bizId ? i.bizId === this.formData.bizId : !i.bizId));
                this.formData.roles = roles.map(r => r.role);
            }
            else {
                // 添加用户，在应用与业务之间切换时，不需保留
                this.formData.roles = this.formData.roles.filter(r => this.availableRoles.some(i => i.name === r));
            }
            if (['add-role', 'add-user'].includes(this.operation)) {
                this.addRoles = [];
            }
            if (this.operation === 'delete-role') {
                this.delRoles = [];
            }
            change();
        };
        const rules = {
            userId: [
                {
                    trigger: 'change',
                    validator: (rule, value, callback) => {
                        if (this.userId) {
                            callback();
                            return;
                        }
                        if (this.userList.includes(value)) {
                            callback('用户已存在');
                            return;
                        }
                        callback();
                    },
                },
            ],
        };
        return (<div>
        <a-form-model ref="form" rules={rules} {...{ props: { model: this.formData } }} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} labelAlign="left">
          <a-form-model-item label={this.userType === 'staff' ? '用户' : '组织'} required prop="userId">
            {this.userName ? (<span>{this.userName}</span>) : (<DeptRtx v-model={this.formData.userId} onChange={change} type={this.userType}/>)}
          </a-form-model-item>
          <a-form-model-item label="所属业务">
            {this.bizList ? (<a-select v-model={this.formData.bizId} onChange={bizChange} disabled={this.isRuntimeMode}>
                <a-select-option value="">
                  平台 <span style="font-size:10px;font-style:italic">应用类型角色</span>
                </a-select-option>
                {this.bizList
                    .filter(o => (this.isRuntimeMode ? o.value === this.bizId : true))
                    .map(o => (<a-select-option key={o.value} value={o.value}>
                      {o.label}
                    </a-select-option>))}
              </a-select>) : (<span>平台</span>)}
          </a-form-model-item>
          <a-form-model-item label="关联角色" prop="roles">
            {this.formData.roles.length > 0 ? (<a-select mode="multiple" v-model={this.formData.roles} disabled>
                {this.availableRoles.map(r => (<a-select-option key={r.name} value={r.name}>
                    {r.cname}
                  </a-select-option>))}
              </a-select>) : ('无')}
          </a-form-model-item>
          {this.operation === 'delete-role' ? (<a-form-model-item label="删除角色" required>
              <a-select v-model={this.delRoles} mode="multiple" onChange={change}>
                {this.availableRoles
                    .filter(r => this.formData.roles.includes(r.name))
                    .map(r => (<a-select-option key={r.name} value={r.name}>
                      {r.cname}
                    </a-select-option>))}
              </a-select>
            </a-form-model-item>) : [
                <a-form-model-item label="添加角色" required>
              <a-select mode="multiple" v-model={this.addRoles} onChange={change}>
                {this.availableRoles.map(r => (<a-select-option key={r.name} value={r.name} disabled={this.formData.roles.includes(r.name)}>
                    {r.cname}
                  </a-select-option>))}
              </a-select>
            </a-form-model-item>,
                this.renderCreateExpire(),
            ]}
        </a-form-model>
      </div>);
    },
});
