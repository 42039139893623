var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { getDefaultValue } from '@tencent/data-schema-core';
import { ExprInput } from '@tencent/ui-core';
import { Label } from './Label';
import styles from './style.module.scss';
let ArrayForm = class ArrayForm extends Vue {
    schema;
    value;
    array = [];
    id;
    isJsonString;
    title;
    uc;
    get isArray() {
        return this.schema.type === 'array';
    }
    get itemsSchema() {
        return this.schema.items;
    }
    get filedsColumns() {
        if (!this.isArray)
            return [];
        const fileds = this.schema.items?.fields;
        if (!fileds) {
            return [];
        }
        const items = fileds.map((item) => {
            const column = {
                title: item.title || item.id,
                dataIndex: item.id,
                scopedSlots: { customRender: item.id },
            };
            let input;
            if (item.enum) {
                const source = item.enum.map(item => ({ value: item.value, text: item.label }));
                input = record => (<a-auto-complete style="width:100%;" v-model={record[item.id]} onChange={this.onChange} dataSource={source}></a-auto-complete>);
            }
            else {
                input = (record) => {
                    const onChange = (v) => {
                        // eslint-disable-next-line no-param-reassign
                        record[item.id] = v;
                        this.onChange();
                    };
                    const dataStub = this.uc?.designer.renderer.ucDataStub;
                    return (<ExprInput onChange={onChange} value={record[item.id]} type="interopable-string" dataStub={dataStub}></ExprInput>);
                };
            }
            const slot = (_val, record) => (<div class="editable-cell">
          <div class="editable-cell-input-wrapper">{input(record)}</div>
        </div>);
            return {
                schema: item,
                column,
                slot,
            };
        });
        return items;
    }
    get columns() {
        return [
            ...this.filedsColumns.map(item => item.column),
            {
                title: '操作',
                scopedSlots: { customRender: 'operation' },
            },
        ];
    }
    created() {
        if (this.isJsonString && typeof this.value === 'string') {
            const value = JSON.parse(this.value);
            this.array = value;
        }
        else if (Array.isArray(this.value)) {
            this.array = this.value;
        }
    }
    render() {
        return this.isArray ? this.renderArray() : null;
    }
    renderArray() {
        const scopedSlots = {
            operation: (_text, _record, index) => [
                <a-button size="small" shape="circle" icon="delete" title="删除" onClick={() => this.onDelete(index)}></a-button>,
            ],
        };
        this.filedsColumns.forEach((item) => {
            // @ts-ignore
            scopedSlots[item.schema.id] = item.slot;
        });
        const rowKey = (_record, index) => index;
        return (<div class={styles.argFormItem}>
        <Label props={{ id: this.id || this.schema.id, title: this.title || this.schema.title }}></Label>
        <a-table bordered data-source={this.array} columns={this.columns} pagination={false} size="small" scopedSlots={scopedSlots} rowKey={rowKey}></a-table>
        <a-button style={{ marginTop: '6px' }} key="plus" size="small" shape="circle" icon="plus" onClick={() => this.onAdd()}></a-button>
      </div>);
    }
    onAdd() {
        const { itemsSchema } = this;
        if (!itemsSchema)
            return;
        const defalutObj = getDefaultValue(itemsSchema);
        const newValue = this.array ? this.array : [];
        newValue.push(defalutObj);
        this.emit(newValue);
    }
    emit(val) {
        this.$emit('input', val);
    }
    onDelete(index) {
        this.array.splice(index, 1);
        this.emit(this.array);
    }
    onChange() {
        this.emit(this.array);
    }
};
__decorate([
    Prop()
], ArrayForm.prototype, "schema", void 0);
__decorate([
    Prop()
], ArrayForm.prototype, "value", void 0);
__decorate([
    Prop()
], ArrayForm.prototype, "id", void 0);
__decorate([
    Prop({ default: false })
], ArrayForm.prototype, "isJsonString", void 0);
__decorate([
    Prop()
], ArrayForm.prototype, "title", void 0);
__decorate([
    Inject('uicore:bus')
], ArrayForm.prototype, "uc", void 0);
ArrayForm = __decorate([
    Component({
        name: 'ArrayForm',
    })
], ArrayForm);
export { ArrayForm };
