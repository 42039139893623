import { COMPONENT_HUB_API_PATH, PROFILE_API_PREFIX, getComponentHubApiPath } from '@config/constant';
import wujiFetch from './wujiFetch';
export const getComponentHubStoreKey = (registry, group) => `${registry}@${group}`;
/**
 * 根据分组获取组件商店请求的hubList参数
 * @param {string[]} groups 私有商店组列表
 * @returns
 */
export const getHubListQuery = (groups) => {
    // 本地的私有商店需要有local@前缀
    const privateHubs = formatHubList(groups);
    return ['public', 'local', ...privateHubs].filter(i => i).join(',');
};
// 刷新编辑时组件商店的缓存
export const refreshDevComponentHubCache = (projectId, hubList) => {
    const refreshQueryObj = {
        projectId,
        hubList: getHubListQuery(hubList.split(',')),
    };
    wujiFetch(`${getComponentHubApiPath('dev')}?${(new URLSearchParams(refreshQueryObj)).toString()}`);
};
export const refreshComponentHubCache = async (projectId, projectEnv) => {
    const query = new URLSearchParams({
        projectId,
        projectEnv,
    });
    wujiFetch(`${getComponentHubApiPath(projectEnv)}/refresh?${query.toString()}`);
};
// 获取某个组件商店的使用情况
export const fetchComponentHubLock = async (registry, group) => {
    const query = new URLSearchParams({ registry });
    return wujiFetch(`${COMPONENT_HUB_API_PATH}/lock/hub-lock/${group}?${query.toString()}`);
};
export const fetchComponentHubLibraryInfo = async (registry, group) => {
    const query = new URLSearchParams({ registry, group });
    return wujiFetch(`${PROFILE_API_PREFIX}/library?${query.toString()}`);
};
/**
 * 通过组件id获取组件的registry和group
 * @param {string} componentId 组件id
 * @param {string[]} hubList 可能的分组列表，我们需要通过这个分组列表来判断是否来自私有商店
 * @returns
 */
export const getRegistryAndGroupByComponentId = (componentId, hubList) => {
    const registry = componentId.startsWith('public') ? 'public' : 'local';
    // 将registry信息去掉，用来获取group信息
    const idWithoutRegistry = componentId.replace(`${registry}-`, '');
    let group = '';
    // hubList可能有两种形态
    // 1. public@group1,public@group2,local@group3
    // 2. group1,group2 - 这种情况下都是local
    hubList.some((hub) => {
        let formattedGroup = hub;
        if (hub.includes('@')) {
            [, formattedGroup] = hub.split('@');
        }
        if (idWithoutRegistry.startsWith(formattedGroup)) {
            group = formattedGroup;
            return true;
        }
        return false;
    });
    return {
        registry,
        group,
    };
};
export const getComponentVersions = async (componentId, group = '', registry = 'local') => {
    const res = await wujiFetch(`${PROFILE_API_PREFIX}/component/${componentId}/version?registry=${registry}&group=${group}`);
    return res;
};
export const fetchProjectLock = async (projectId, projectEnv = 'dev') => {
    try {
        const lockRules = await wujiFetch(`${getComponentHubApiPath(projectEnv)}/profile/project-lock/${projectId}`);
        return lockRules?.lock || null;
    }
    catch {
        return null;
    }
};
export const fetchComponentInfo = async (projectId, hubList, extraQuery = {}) => {
    const formattedHubList = formatHubList(hubList);
    const query = new URLSearchParams({
        ...extraQuery,
        projectId,
        hubList: formattedHubList.join(','),
    });
    const componentsList = await wujiFetch(`${COMPONENT_HUB_API_PATH}?${query.toString()}`);
    return componentsList;
};
export const formatHubList = (hubList) => hubList.map((hub) => {
    if (hub.includes('@'))
        return hub;
    if (hub === 'public' || hub === 'local')
        return hub;
    return `local@${hub}`;
});
/**
 * 将local@group1, local@group2 转换成group1, group2
 * 传入不带registry的商店名会保持原样
 * @param hubList
 * @returns
 */
export const toHubListWithoutRegistryPrefix = (hubList) => hubList.map((hub) => {
    if (hub.includes('@')) {
        const [, group] = hub.split('@');
        return group;
    }
    return hub;
});
// 获取当前用户拥有权限的本地分组
export const getLocalGroup = async () => {
    const res = await wujiFetch(`${PROFILE_API_PREFIX}/groups`);
    return res.local;
};
export const trimRegistryFromId = (id) => {
    if (id.startsWith('public-')) {
        return id.replace('public-', '');
    }
    if (id.startsWith('local-')) {
        return id.replace('local-', '');
    }
    return id;
};
export const trimGroupAndRegistry = (id, group) => {
    if (id.startsWith('local-')) {
        return id.replace(`local-${group}-`, '');
    }
    return id.replace(`public-${group}-`, '');
};
