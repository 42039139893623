export async function injectAegis({ rtx, project, projectId, mode }) {
    const deployConfig = window.WUJI_SWITCH?.aegis_config ?? {}; // 部署级配置
    let { open = false } = deployConfig;
    const { id } = deployConfig;
    const projectOpen = project.advanceConfig?.aegis; // 项目级配置
    if (open && typeof projectOpen === 'boolean') {
        open = projectOpen;
    }
    if (!window.aegisInstance && open && id) {
        const aegis = await import('@tencent/aegis-web-sdk');
        window.aegisInstance = new aegis.default({
            id,
            uin: rtx,
            reportApiSpeed: true,
            reportAssetSpeed: true,
            spa: true,
            ext1: projectId,
            ext2: mode,
            ext3: '',
            delay: 1000 * 10, // 延迟10秒上报
        });
    }
}
;
